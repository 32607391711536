import React, { Component, Fragment } from 'react';

import Header from '../../Components/Layout/Header/Header';
import Navigation from '../Navigation/Navigation';
import Footer from '../../Components/Layout/Footer/Footer';

class Layout extends Component {
    render() {
        return (
            <Fragment>
                <div style={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <div style={{ marginBottom: '15px' }}>
                        <Header />
                        <Navigation />
                    </div>
                    <div>
                        <Footer />
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default Layout;