import React, { memo, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Typography } from '@material-ui/core';
import cssModule from './Datenschutz.module.css';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import { Link } from 'react-router-dom';
import {Link as ExternalLink} from '@material-ui/core';
import ReactGA from "react-ga4";
import {Helmet} from "react-helmet";

const useStyles = makeStyles((theme) => ({
    HomeLink: {
        fontSize: '.9rem',
        textAlign: 'Left',
        fontWeight: '500',
        color: '#0288D1',
        marginTop: '0px',
        marginBottom: '0px'
    },
    dataParent: {
        padding: '20px',
        boxShadow: '0 0 13px 0 rgb(82 63 105 / 5%)',
        marginTop: '25px',
        textAlign: 'left',
        borderRadius: '5px',
        backgroundColor: '#fff',
    },
    dataText: {
        fontSize: '1.1rem',
        marginBottom: '1rem',
        fontFamily: '"Exo 2", sans-serif',
        fontWeight: '400',
    },
    dataHead: {
        fontSize: '2rem',
        fontFamily: '"Exo 2", sans-serif',
        fontWeight: '400',
    },
    dataSubHead: {
        fontSize: '1.7rem',
        fontFamily: '"Exo 2", sans-serif',
        fontWeight: '400',
    },
    dataSubHead1: {
        fontSize: '1.1rem',
        fontFamily: '"Exo 2", sans-serif',
        fontWeight: '400',
    }
}));

const Datenschutz = memo((props) => {

    const classes = useStyles();

    React.useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
      }, []);

    return (
        <Fragment>
            <Helmet>
                <title>Q.met Risk Control - Datenschutz</title>
            </Helmet>
            <div className={cssModule.AccordItem}>
                <Container maxWidth={false}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Link className={classes.HomeLink} to="/">
                                    <HomeOutlinedIcon />
                                </Link>
                                <span style={{ marginLeft: '10px' }}><Link className={classes.HomeLink} to="/">Home</Link></span>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <Container maxWidth={false}>
                <div className={classes.dataParent}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography className={classes.dataHead}>Datenschutz</Typography>
                        </Grid>
                        <Grid item sm={4}>
                            <Typography className={classes.dataSubHead}>Datenschutzerklärung</Typography>
                        </Grid>
                        <Grid item sm={8}>
                            <Typography className={classes.dataText}>
                                Wir freuen uns sehr über Ihr Interesse an unserem Unternehmen. Datenschutz hat einen besonders hohen Stellenwert
                                für die Geschäftsleitung der Q.met GmbH. Eine Nutzung der Internetseiten der Q.met GmbH ist grundsätzlich ohne
                                jede Angabe personenbezogener Daten möglich. Sofern eine betroffene Person besondere Services unseres Unternehmens
                                über unsere Internetseite in Anspruch nehmen möchte, könnte jedoch eine Verarbeitung personenbezogener Daten
                                erforderlich werden. Ist die Verarbeitung personenbezogener Daten erforderlich und besteht für eine solche
                                Verarbeitung keine gesetzliche Grundlage, holen wir generell eine Einwilligung der betroffenen Person ein.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Die Verarbeitung personenbezogener Daten, beispielsweise des Namens, der Anschrift, E-Mail-Adresse oder
                                Telefonnummer einer betroffenen Person, erfolgt stets im Einklang mit der Datenschutz-Grundverordnung und
                                in Übereinstimmung mit den für die Q.met GmbH geltenden landesspezifischen Datenschutzbestimmungen.
                                Mittels dieser Datenschutzerklärung möchte unser Unternehmen die Öffentlichkeit über Art, Umfang und
                                Zweck der von uns erhobenen, genutzten und verarbeiteten personenbezogenen Daten informieren. Ferner
                                werden betroffene Personen mittels dieser Datenschutzerklärung über die ihnen zustehenden Rechte aufgeklärt.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Die Q.met GmbH hat als für die Verarbeitung Verantwortlicher zahlreiche technische und organisatorische
                                Maßnahmen umgesetzt, um einen möglichst lückenlosen Schutz der über diese Internetseite verarbeiteten
                                personenbezogenen Daten sicherzustellen. Dennoch können Internetbasierte Datenübertragungen grundsätzlich
                                Sicherheitslücken aufweisen, sodass ein absoluter Schutz nicht gewährleistet werden kann. Aus diesem Grund
                                steht es jeder betroffenen Person frei, personenbezogene Daten auch auf alternativen Wegen, beispielsweise
                                telefonisch, an uns zu übermitteln.
                            </Typography>
                        </Grid>
                        <Grid item sm={4}>
                            <Typography className={classes.dataSubHead}>1. Begriffsbestimmungen</Typography>
                        </Grid>
                        <Grid item sm={8}>
                            <Typography className={classes.dataText}>
                                Die Datenschutzerklärung der Q.met GmbH beruht auf den Begrifflichkeiten, die durch den Europäischen Richtlinien-
                                und Verordnungsgeber beim Erlass der Datenschutz-Grundverordnung (DSGVO) verwendet wurden. Unsere Datenschutzerklärung
                                soll sowohl für die Öffentlichkeit als auch für unsere Kunden und Geschäftspartner einfach lesbar und verständlich sein.
                                Um dies zu gewährleisten, möchten wir vorab die verwendeten Begrifflichkeiten erläutern.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Wir verwenden in dieser Datenschutzerklärung unter anderem die folgenden Begriffe:
                            </Typography>
                            <Typography className={classes.dataText}><strong>a) personenbezogene Daten</strong></Typography>
                            <Typography className={classes.dataText}>
                                Personenbezogene Daten sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person
                                (im Folgenden “betroffene Person”) beziehen. Als identifizierbar wird eine natürliche Person angesehen, die direkt oder
                                indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten,
                                zu einer Online-Kennung oder zu einem oder mehreren besonderen Merkmalen, die Ausdruck der physischen, physiologischen,
                                genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person sind,
                                identifiziert werden kann.
                            </Typography>
                            <Typography className={classes.dataText}><strong>b) betroffene Person</strong></Typography>
                            <Typography className={classes.dataText}>
                                Betroffene Person ist jede identifizierte oder identifizierbare natürliche Person, deren personenbezogene Daten
                                von dem für die Verarbeitung Verantwortlichen verarbeitet werden.
                            </Typography>
                            <Typography className={classes.dataText}><strong>c) Verarbeitung</strong></Typography>
                            <Typography className={classes.dataText}>
                                Verarbeitung ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführte Vorgang oder jede solche
                                Vorgangsreihe im Zusammenhang mit personenbezogenen Daten wie das Erheben, das Erfassen, die Organisation,
                                das Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen, die Verwendung,
                                die Offenlegung durch Übermittlung, Verbreitung oder eine andere Form der Bereitstellung, den Abgleich oder
                                die Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung.
                            </Typography>
                            <Typography className={classes.dataText}><strong>d) Einschränkung der Verarbeitung</strong></Typography>
                            <Typography className={classes.dataText}>
                                Einschränkung der Verarbeitung ist die Markierung gespeicherter personenbezogener Daten mit dem Ziel, ihre künftige
                                Verarbeitung einzuschränken.
                            </Typography>
                            <Typography className={classes.dataText}><strong>e) Profiling</strong></Typography>
                            <Typography className={classes.dataText}>
                                Profiling ist jede Art der automatisierten Verarbeitung personenbezogener Daten, die darin besteht, dass
                                diese personenbezogenen Daten verwendet werden, um bestimmte persönliche Aspekte, die sich auf eine
                                natürliche Person beziehen, zu bewerten, insbesondere, um Aspekte bezüglich Arbeitsleistung,
                                wirtschaftlicher Lage, Gesundheit, persönlicher Vorlieben, Interessen, Zuverlässigkeit, Verhalten,
                                Aufenthaltsort oder Ortswechsel dieser natürlichen Person zu analysieren oder vorherzusagen.
                            </Typography>
                            <Typography className={classes.dataText}><strong>f) Pseudonymisierung</strong></Typography>
                            <Typography className={classes.dataText}>
                                Pseudonymisierung ist die Verarbeitung personenbezogener Daten in einer Weise, auf welche die
                                personenbezogenen Daten ohne Hinzuziehung zusätzlicher Informationen nicht mehr einer
                                spezifischen betroffenen Person zugeordnet werden können, sofern diese zusätzlichen
                                Informationen gesondert aufbewahrt werden und technischen und organisatorischen Maßnahmen
                                unterliegen, die gewährleisten, dass die personenbezogenen Daten nicht einer identifizierten
                                oder identifizierbaren natürlichen Person zugewiesen werden.
                            </Typography>
                            <Typography className={classes.dataText}><strong>g) Verantwortlicher oder für die Verarbeitung Verantwortlicher</strong></Typography>
                            <Typography className={classes.dataText}>
                                Verantwortlicher oder für die Verarbeitung Verantwortlicher ist die natürliche oder juristische Person,
                                Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen über die Zwecke und
                                Mittel der Verarbeitung von personenbezogenen Daten entscheidet. Sind die Zwecke und Mittel dieser
                                Verarbeitung durch das Unionsrecht oder das Recht der Mitgliedstaaten vorgegeben, so kann der
                                Verantwortliche beziehungsweise können die bestimmten Kriterien seiner Benennung nach dem Unionsrecht
                                oder dem Recht der Mitgliedstaaten vorgesehen werden.
                            </Typography>
                            <Typography className={classes.dataText}><strong>h) Auftragsverarbeiter</strong></Typography>
                            <Typography className={classes.dataText}>
                                Auftragsverarbeiter ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere
                                Stelle, die personenbezogene Daten im Auftrag des Verantwortlichen verarbeitet.
                            </Typography>
                            <Typography className={classes.dataText}><strong>i) Empfänger</strong></Typography>
                            <Typography className={classes.dataText}>
                                Empfänger ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle,
                                der personenbezogene Daten offengelegt werden, unabhängig davon, ob es sich bei ihr um einen
                                Dritten handelt oder nicht. Behörden, die im Rahmen eines bestimmten Untersuchungsauftrags nach
                                dem Unionsrecht oder dem Recht der Mitgliedstaaten möglicherweise personenbezogene Daten erhalten,
                                gelten jedoch nicht als Empfänger.
                            </Typography>
                            <Typography className={classes.dataText}><strong>j) Dritter</strong></Typography>
                            <Typography className={classes.dataText}>
                                Dritter ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle
                                außer der betroffenen Person, dem Verantwortlichen, dem Auftragsverarbeiter und den Personen,
                                die unter der unmittelbaren Verantwortung des Verantwortlichen oder des Auftragsverarbeiters
                                befugt sind, die personenbezogenen Daten zu verarbeiten.
                            </Typography>
                            <Typography className={classes.dataText}><strong>k) Einwilligung</strong></Typography>
                            <Typography className={classes.dataText}>
                                Einwilligung ist jede von der betroffenen Person freiwillig für den bestimmten Fall in informierter
                                Weise und unmissverständlich abgegebene Willensbekundung in Form einer Erklärung oder einer sonstigen
                                eindeutigen bestätigenden Handlung, mit der die betroffene Person zu verstehen gibt, dass sie mit der
                                Verarbeitung der sie betreffenden personenbezogenen Daten einverstanden ist.
                            </Typography>
                        </Grid>
                        <Grid item sm={4}>
                            <Typography className={classes.dataSubHead}>2. Name und Anschrift des für die Verarbeitung Verantwortlichen</Typography>
                        </Grid>
                        <Grid item sm={8}>
                            <Typography className={classes.dataText}>
                                Verantwortlicher im Sinne der Datenschutz-Grundverordnung, sonstiger in den Mitgliedstaaten der Europäischen
                                Union geltenden Datenschutzgesetze und anderer Bestimmungen mit datenschutzrechtlichem Charakter ist
                            </Typography>
                            <Typography className={classes.dataText}>
                                <br />Herr Eckardt Renner<br />
                                Q.met GmbH<br />
                                Hagenauer Straße 1a<br />
                                65203 Wiesbaden<br />
                                Deutschland<br />
                                Tel.: +4961189052-0<br />
                                E-Mail: info@www.qmet.de
                            </Typography>
                        </Grid>
                        <Grid item sm={4}>
                            <Typography className={classes.dataSubHead}>3. Cookies</Typography>
                        </Grid>
                        <Grid item sm={8}>
                            <Typography className={classes.dataText}>
                                Die Internetseiten der Q.met GmbH verwenden Cookies. Cookies sind Textdateien, welche über einen
                                Internetbrowser auf einem Computersystem abgelegt und gespeichert werden.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Zahlreiche Internetseiten und Server verwenden Cookies. Viele Cookies enthalten eine sogenannte
                                Cookie-ID. Eine Cookie-ID ist eine eindeutige Kennung des Cookies. Sie besteht aus einer Zeichenfolge,
                                durch welche Internetseiten und Server dem konkreten Internetbrowser zugeordnet werden können, in dem
                                das Cookie gespeichert wurde. Dies ermöglicht es den besuchten Internetseiten und Servern, den
                                individuellen Browser der betroffenen Person von anderen Internetbrowsern, die andere Cookies
                                enthalten, zu unterscheiden. Ein bestimmter Internetbrowser kann über die eindeutige Cookie-ID
                                wiedererkannt und identifiziert werden.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Durch den Einsatz von Cookies kann die Q.met GmbH den Nutzern dieser Internetseite nutzerfreundlichere
                                Services bereitstellen, die ohne die Cookie-Setzung nicht möglich wären.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Mittels eines Cookies können die Informationen und Angebote auf unserer Internetseite im Sinne des
                                Benutzers optimiert werden. Cookies ermöglichen uns, wie bereits erwähnt, die Benutzer unserer
                                Internetseite wiederzuerkennen. Zweck dieser Wiedererkennung ist es, den Nutzern die Verwendung
                                unserer Internetseite zu erleichtern. Der Benutzer einer Internetseite, die Cookies verwendet,
                                muss beispielsweise nicht bei jedem Besuch der Internetseite erneut seine Zugangsdaten eingeben,
                                weil dies von der Internetseite und dem auf dem Computersystem des Benutzers abgelegten Cookie
                                übernommen wird. Ein weiteres Beispiel ist das Cookie eines Warenkorbes im Online-Shop. Der
                                Online-Shop merkt sich die Artikel, die ein Kunde in den virtuellen Warenkorb gelegt hat,
                                über ein Cookie.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Die betroffene Person kann die Setzung von Cookies durch unsere Internetseite jederzeit mittels
                                einer entsprechenden Einstellung des genutzten Internetbrowsers verhindern und damit der Setzung
                                von Cookies dauerhaft widersprechen. Ferner können bereits gesetzte Cookies jederzeit über einen
                                Internetbrowser oder andere Softwareprogramme gelöscht werden. Dies ist in allen gängigen
                                Internetbrowsern möglich. Deaktiviert die betroffene Person die Setzung von Cookies in dem
                                genutzten Internetbrowser, sind unter Umständen nicht alle Funktionen unserer Internetseite
                                vollumfänglich nutzbar.
                            </Typography>
                        </Grid>
                        <Grid item sm={4}>
                            <Typography className={classes.dataSubHead}>4. Erfassung von allgemeinen Daten und Informationen</Typography>
                        </Grid>
                        <Grid item sm={8}>
                            <Typography className={classes.dataText}>
                                Die Internetseite der Q.met GmbH erfasst mit jedem Aufruf der Internetseite durch eine betroffene Person
                                oder ein automatisiertes System eine Reihe von allgemeinen Daten und Informationen. Diese allgemeinen
                                Daten und Informationen werden in den Logfiles des Servers gespeichert. Erfasst werden können die (1)
                                verwendeten Browsertypen und Versionen, (2) das vom zugreifenden System verwendete Betriebssystem, (3)
                                die Internetseite, von welcher ein zugreifendes System auf unsere Internetseite gelangt (sogenannte Referrer),
                                (4) die Unterwebseiten, welche über ein zugreifendes System auf unserer Internetseite angesteuert werden, (5)
                                das Datum und die Uhrzeit eines Zugriffs auf die Internetseite, (6) eine Internet-Protokoll-Adresse (IP-Adresse),
                                (7) der Internet-Service-Provider des zugreifenden Systems und (8) sonstige ähnliche Daten und Informationen,
                                die der Gefahrenabwehr im Falle von Angriffen auf unsere informationstechnologischen Systeme dienen.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Bei der Nutzung dieser allgemeinen Daten und Informationen zieht die Q.met GmbH keine Rückschlüsse auf die
                                betroffene Person. Diese Informationen werden vielmehr benötigt, um (1) die Inhalte unserer Internetseite
                                korrekt auszuliefern, (2) die Inhalte unserer Internetseite sowie die Werbung für diese zu optimieren,
                                (3) die dauerhafte Funktionsfähigkeit unserer informationstechnologischen Systeme und der Technik unserer
                                Internetseite zu gewährleisten sowie (4) um Strafverfolgungsbehörden im Falle eines Cyberangriffes die zur
                                Strafverfolgung notwendigen Informationen bereitzustellen. Diese anonym erhobenen Daten und Informationen
                                werden durch die Q.met GmbH daher einerseits statistisch und ferner mit dem Ziel ausgewertet, den Datenschutz
                                und die Datensicherheit in unserem Unternehmen zu erhöhen, um letztlich ein optimales Schutzniveau für die
                                von uns verarbeiteten personenbezogenen Daten sicherzustellen. Die anonymen Daten der Server-Logfiles werden
                                getrennt von allen durch eine betroffene Person angegebenen personenbezogenen Daten gespeichert.
                            </Typography>
                        </Grid>
                        <Grid item sm={4}>
                            <Typography className={classes.dataSubHead}>5. Registrierung auf unserer Internetseite</Typography>
                        </Grid>
                        <Grid item sm={8}>
                            <Typography className={classes.dataText}>
                                Die betroffene Person hat die Möglichkeit, sich auf der Internetseite des für die Verarbeitung Verantwortlichen
                                unter Angabe von personenbezogenen Daten zu registrieren. Welche personenbezogenen Daten dabei an den für die
                                Verarbeitung Verantwortlichen übermittelt werden, ergibt sich aus der jeweiligen Eingabemaske, die für die
                                Registrierung verwendet wird. Die von der betroffenen Person eingegebenen personenbezogenen Daten werden
                                ausschließlich für die interne Verwendung bei dem für die Verarbeitung Verantwortlichen und für eigene Zwecke
                                erhoben und gespeichert. Der für die Verarbeitung Verantwortliche kann die Weitergabe an einen oder mehrere
                                Auftragsverarbeiter, beispielsweise einen Paketdienstleister, veranlassen, der die personenbezogenen Daten
                                ebenfalls ausschließlich für eine interne Verwendung, die dem für die Verarbeitung Verantwortlichen zuzurechnen
                                ist, nutzt.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Durch eine Registrierung auf der Internetseite des für die Verarbeitung Verantwortlichen wird ferner die vom
                                Internet-Service-Provider (ISP) der betroffenen Person vergebene IP-Adresse, das Datum sowie die Uhrzeit der
                                Registrierung gespeichert. Die Speicherung dieser Daten erfolgt vor dem Hintergrund, dass nur so der Missbrauch
                                unserer Dienste verhindert werden kann, und diese Daten im Bedarfsfall ermöglichen, begangene Straftaten aufzuklären.
                                Insofern ist die Speicherung dieser Daten zur Absicherung des für die Verarbeitung Verantwortlichen erforderlich.
                                Eine Weitergabe dieser Daten an Dritte erfolgt grundsätzlich nicht, sofern keine gesetzliche Pflicht zur Weitergabe
                                besteht oder die Weitergabe der Strafverfolgung dient.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Die Registrierung der betroffenen Person unter freiwilliger Angabe personenbezogener Daten dient dem für die Verarbeitung
                                Verantwortlichen dazu, der betroffenen Person Inhalte oder Leistungen anzubieten, die aufgrund der Natur der Sache nur
                                registrierten Benutzern angeboten werden können. Registrierten Personen steht die Möglichkeit frei, die bei der
                                Registrierung angegebenen personenbezogenen Daten jederzeit abzuändern oder vollständig aus dem Datenbestand des
                                für die Verarbeitung Verantwortlichen löschen zu lassen.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Der für die Verarbeitung Verantwortliche erteilt jeder betroffenen Person jederzeit auf Anfrage Auskunft darüber,
                                welche personenbezogenen Daten über die betroffene Person gespeichert sind. Ferner berichtigt oder löscht der für
                                die Verarbeitung Verantwortliche personenbezogene Daten auf Wunsch oder Hinweis der betroffenen Person, soweit dem
                                keine gesetzlichen Aufbewahrungspflichten entgegenstehen. Ein in dieser Datenschutzerklärung namentlich benannter
                                Datenschutzbeauftragter und die Gesamtheit der Mitarbeiter des für die Verarbeitung Verantwortlichen stehen der
                                betroffenen Person in diesem Zusammenhang als Ansprechpartner zur Verfügung.
                            </Typography>
                        </Grid>
                        <Grid item sm={4}>
                            <Typography className={classes.dataSubHead}>6. Wer bekommt Ihre personenbezogenen Daten und warum?</Typography>
                        </Grid>
                        <Grid item sm={8}>
                            <Typography className={classes.dataText}>
                                Weitergabe von Daten an Dritte
                            </Typography>
                            <Typography className={classes.dataText}>
                                Ihre personenbezogenen Daten werden von uns grundsätzlich nur dann an Dritte gegeben, soweit dies zur Vertragserfüllung
                                erforderlich ist, wir oder der Dritte ein berechtigtes Interesse an der Weitergabe haben oder Ihre Einwilligung hierfür
                                vorliegt. Sofern Daten an Dritte übermittelt werden, wird dies in diesen Datenschutzbestimmungen erläutert. Bei einer
                                Übermittlung auf Basis einer Einwilligung kann die Erläuterung auch bei Einholung der Einwilligung erteilt werden.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Darüber hinaus können Daten an Dritte übermittelt werden, soweit wir aufgrund gesetzlicher Bestimmungen oder durch
                                vollstreckbare behördliche oder gerichtliche Anordnung hierzu verpflichtet sein sollten.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Wir stellen Daten unseren Partnern, anderen vertrauenswürdigen Unternehmen oder Personen zur Verfügung, die diese in
                                unserem Auftrag verarbeiten. Dies geschieht auf der Grundlage unserer Weisungen und im Einklang mit dem geltenden Recht,
                                sowie geeigneten Vertraulichkeits- und Sicherheitsmaßnahmen mit denen wir jederzeit den Schutz Ihrer Daten sicherstellen.
                                Soweit wir mit Hilfe von Webanalysetools Daten erheben bzw. erheben lassen und Webwerbe-Tools verwenden und in diesem
                                Zusammenhang mit eigenverantwortlichen Dritten (die nicht Auftragnehmer einer Auftragsverarbeitung sind) zusammenarbeiten,
                                informieren wir Sie im Detail dazu unter Ziff. 3. An dieser Stelle erhalten Sie auch jeweils die Information, wie Sie Ihr
                                Recht auf Widerspruch (Opt-Out) ausüben können und welche technischen Möglichkeit Sie haben, um Datenerhebungen zu verhindern.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Wir werden Daten an Unternehmen, Organisationen oder Personen außerhalb der Q.met GmbH weitergeben, wenn wir nach Treu und
                                Glauben davon ausgehen dürfen, dass der Zugriff auf diese Daten oder ihre Nutzung, Aufbewahrung oder Weitergabe notwendig ist, um
                            </Typography>
                            <ul>
                                <li><Typography className={classes.dataText}>geltende Gesetze, Vorschriften oder Rechtsverfahren einzuhalten oder einer vollstreckbaren behördlichen Anordnung nachzukommen</Typography></li>
                                <li><Typography className={classes.dataText}>Betrug, Sicherheitsmängel oder technische Probleme aufzudecken zu verhindern oder anderweitig zu bekämpfen</Typography></li>
                                <li><Typography className={classes.dataText}>die Rechte, das Eigentum oder die Sicherheit der Q.met GmbH, unserer Nutzer oder der Öffentlichkeit vor Schaden zu schützen,
                                    soweit gesetzlich zulässig oder erforderlich</Typography></li>
                                <li><Typography className={classes.dataText}>Für die Verarbeitung durch andere Stellen</Typography></li>
                                <li><Typography className={classes.dataText}>Wir stellen Daten unseren Partnern, anderen vertrauenswürdigen Unternehmen oder Personen zur Verfügung, die diese in unserem
                                    Auftrag verarbeiten (siehe im Einzelnen unter Ziff 2.). Dies geschieht auf der Grundlage unserer Weisungen und im Einklang mit
                                    unserer Datenschutzerklärung sowie geeigneten Vertraulichkeits- und Sicherheitsmaßnahmen mit denen wir jederzeit den Schutz
                                    Ihrer Daten sicherstellen. Soweit wir mit Hilfe von Webanalysetools Daten erheben bzw. erheben lassen und Webwerbe-Tools verwenden
                                    und in diesem Zusammenhang mit eigenverantwortlichen Dritten (die nicht Auftragnehmer einer Auftragsverarbeitung sind)
                                    zusammenarbeiten, in-formieren wir Sie im Detail dazu unter Ziff. 3. An den betreffenden Stellen erhalten Sie auch jeweils
                                    die Information, wie Sie Ihr Recht auf Widerspruch (Opt-Out) ausüben können und welche technische Möglichkeiten Ihnen zur
                                    Verfügung stehen, um Datenerhebungen zu verhindern.</Typography></li>
                            </ul>
                            <Typography className={classes.dataText}>
                                Wir geben möglicherweise nicht personenbezogene Daten, d.h. solche Daten, bei denen kein Rückschluss auf eine konkrete Person
                                möglich ist, an die Öffentlichkeit und unsere Partner weiter, beispielsweise an andere Werbetreibende oder verbundene Websites.
                                So veröffentlichen wir zum Beispiel Informationen, um Trends bei der allgemeinen Nutzung unserer Dienste aufzuzeigen.
                            </Typography>
                            <Typography className={classes.dataSubHead1}><strong>EU</strong></Typography>
                            <Typography className={classes.dataText}>
                                Ihre persönlichen Daten werden sowohl innerhalb als auch außerhalb der Europäischen Union (“<strong>EU</strong>“) und des Europäischen Wirtschaftsraums (“<strong>EWR</strong>“)
                                durch uns oder durch uns beauftragte Partner verarbeitet. Bitte beachten Sie: Wenn Ihre Daten von Ihrem Heimatland in ein anderes Land
                                übermittelt werden, können die dortigen Gesetze zum Schutz Ihrer Daten von denen Ihres Landes abweichen (und gegebenenfalls nur ein
                                geringeres Schutz-niveau gewährleisten). Beispielsweise gelten insbesondere in Ländern außerhalb des EWR andere Voraussetzungen, unter
                                denen Strafverfolgungsbehörden Zugriff auf Ihre Daten nehmen können, als innerhalb des EWR. Sofern wir Ihre Daten in ein Land außerhalb
                                des EWR übermitteln, werden wir geeignete Maßnahmen treffen, um ein angemessenes Datenschutzniveau sicherzustellen (etwa durch den Abschluss
                                von Standardvertragsklauseln).
                            </Typography>
                            <Typography className={classes.dataSubHead1}><strong>Dienstleister</strong></Typography>
                            <Typography className={classes.dataText}>
                                Wir behalten uns vor, bei der Erhebung bzw. Verarbeitung von Daten Dienstleister einzusetzen. Dienstleister erhalten von uns nur die
                                personenbezogenen Daten, die sie für ihre konkrete Tätigkeit benötigen. So kann z. B. Ihre E-Mail-Adresse an einen Dienstleister
                                weitergeben werden, damit dieser Ihnen einen bestellten Newsletter ausliefern kann. Dienstleister können auch damit beauftragt werden,
                                Serverkapazitäten zur Verfügung zu stellen. Dienstleister werden in der Regel als sogenannte Auftragsverarbeiter eingebunden, die
                                personenbezogene Daten der Nutzer unserer Online-Angebote nur nach unseren Weisungen verarbeiten dürfen.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Soweit Dienstleister in diesen Datenschutzbestimmungen nicht bereits namentlich benannt sind (z. B. im Bereich Webanalyse), handelt es
                                sich um folgende Kategorien von Dienstleistern:
                            </Typography>
                            <Typography className={classes.dataText}>
                                Serverprovider/Webhosting:<br />
                                Hetzner Online GmbH
                                Industriestr. 25
                                91710 Gunzenhausen
                                Deutschland
                                Tel.: +49 (0)9831 505-0
                                Fax: +49 (0)9831 505-3
                            </Typography>
                        </Grid>
                        <Grid item sm={4}>
                            <Typography className={classes.dataSubHead}>7. Wie lange speichern wir Ihre Daten?</Typography>
                        </Grid>
                        <Grid item sm={8}>
                            <Typography className={classes.dataText}>
                                Dauer der Speicherung und Aufbewahrungsfristen
                            </Typography>
                            <Typography className={classes.dataText}>
                                Wir speichern Ihre Daten solange, wie dies zur Erbringung unserer Online-Angebote und den damit verbundenen Services erforderlich
                                ist bzw. wir ein berechtigtes Interesse an der weiteren Speicherung haben. In allen anderen Fällen löschen wir Ihre personenbezogenen
                                Daten mit Ausnahme solcher Daten, die wir zur Erfüllung vertraglicher oder gesetzlicher (z. B. steuer- oder handelsrechtlicher)
                                Aufbewahrungsfristen weiter vorhalten müssen (z. B. Rechnungen). Dabei können vertragliche Aufbewahrungsfristen auch aus Verträgen
                                mit Dritten herrühren (z. B. Inhabern von Urheber- und Leistungsschutzrechten).
                            </Typography>
                            <Typography className={classes.dataText}>
                                Daten, die einer Aufbewahrungsfrist unterliegen, sperren wir bis zum Ablauf der Frist.
                            </Typography>
                        </Grid>
                        <Grid item sm={4}>
                            <Typography className={classes.dataSubHead}>8. Log-Dateien</Typography>
                        </Grid>
                        <Grid item sm={8}>
                            <Typography className={classes.dataText}>
                                Bei jeder Nutzung des Internets werden von Ihrem Internet-Browser automatisch bestimmte Informationen übermittelt und von uns
                                in sogenannten Log-Dateien gespeichert.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Die Log-Dateien werden von uns ausschließlich zur Ermittlung von Störungen und aus Sicherheitsgründen (z. B. zur Aufklärung
                                von Angriffsversuchen) für 4 bis 6 Wochen gespeichert und danach gelöscht. Log-Dateien, deren weitere Aufbewahrung zu Beweiszwecken
                                erforderlich ist, sind von der Löschung ausgenommen bis zur endgültigen Klärung des jeweiligen Vorfalls und können im Einzelfall an
                                Ermittlungsbehörden weitergegeben werden.
                            </Typography>
                            <Typography className={classes.dataText}>
                                In den Log-Dateien werden insbesondere folgende Informationen gespeichert:
                            </Typography>
                            <ul>
                                <li>
                                    <Typography className={classes.dataText}>
                                        IP-Adresse (Internetprotokoll-Adresse) des Endgeräts, von dem aus auf das Online-Angebot zugriffen wird;
                                    </Typography>
                                </li>
                                <li>
                                    <Typography className={classes.dataText}>
                                        Internetadresse der Website, von der aus das Online-Angebot aufgerufen wurde (sog. Herkunfts- oder Referrer-URL);
                                    </Typography>
                                </li>
                                <li>
                                    <Typography className={classes.dataText}>
                                        Name des Service-Providers, über den der Zugriff auf das Online-Angebot erfolgt;
                                    </Typography>
                                </li>
                                <li>
                                    <Typography className={classes.dataText}>
                                        Name der abgerufenen Dateien bzw. Informationen;
                                    </Typography>
                                </li>
                                <li>
                                    <Typography className={classes.dataText}>
                                        Datum und Uhrzeit sowie Dauer des Abrufs;
                                    </Typography>
                                </li>
                                <li>
                                    <Typography className={classes.dataText}>
                                        übertragene Datenmenge;
                                    </Typography>
                                </li>
                                <li>
                                    <Typography className={classes.dataText}>
                                        Betriebssystem und Informationen zum verwendeten Internet-Browser einschließlich installierter Add-Ons (z. B. für den Flash Player);
                                    </Typography>
                                </li>
                                <li>
                                    <Typography className={classes.dataText}>
                                        http-Status-Code (z. B. „Anfrage erfolgreich“ oder „angeforderte Datei nicht gefunden“
                                    </Typography>
                                </li>
                            </ul>
                        </Grid>
                        <Grid item sm={4}>
                            <Typography className={classes.dataSubHead}>9. Erfassung und Auswertung von Nutzungsverhalten für interessenbasierte Werbung (auch durch Dritte)</Typography>
                        </Grid>
                        <Grid item sm={8}>
                            <Typography className={classes.dataText}>
                                Wir möchten unseren Nutzern auf ihre Interessen zugeschnittene Werbung bzw. besondere Angebote präsentieren („interessenbasierte Werbung“)
                                und die Häufigkeit der Anzeige bestimmter Werbung begrenzen. Zu diesem Zweck setzen wir die nachstehend beschriebenen Tools ein.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Die von den Tools mithilfe von Werbe-Cookies bzw. Werbe-Cookies Dritter, sogenannten Web Beacons (unsichtbare Grafiken, die auch Pixel
                                oder Zählpixel genannt werden) oder vergleichbaren Technologien erstellten Nutzungsprofile werden nicht mit personenbezogenen Daten zusammengeführt.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Die Tools werden von den Anbietern dafür verwendet, unseren Nutzern in unseren Online-Angeboten und in Angeboten Dritter interessenbasierte
                                Werbung anzuzeigen und um zu steuern, mit welcher Häufigkeit die Nutzer bestimmte Anzeigen sehen. Verantwortliche Stelle für die
                                Verarbeitung von Daten im Zusammenhang mit den Tools ist der jeweilige Anbieter, soweit wir dies nicht anders angegeben haben.
                                Die Anbieter der Tools geben Informationen zu den vorstehend benannten Zwecken ggf. auch an Dritte weiter.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Die Tools verarbeiten IP-Adressen der Nutzer entweder überhaupt nicht oder kürzen diese sofort nach der Erhebung.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Verantwortlicher für die Verarbeitung von Daten im Zusammenhang mit den Tools ist der jeweilige Anbieter, soweit wir dies nicht anders
                                angegeben haben. Die Anbieter der Tools geben Informationen zu den vorstehend benannten Zwecken ggf. auch an Dritte weiter.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Sie finden bei jedem Tool Informationen zum Anbieter und dazu, wie Sie der Erhebung von Daten mittels dieses Tools widersprechen
                                können. Bitte beachten Sie, dass Sie mit Ihrem Widerspruch nicht die Werbung abschalten. Ihr Widerspruch führt nur dazu, dass
                                Ihnen keine auf Ihr Nutzungsverhalten gestützte interessenbasierte Werbung angezeigt werden kann.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Bei Tools, die mit Opt-Out-Cookies arbeiten, ist zu beachten, dass die Opt-Out Funktion geräte- bzw. browserbezogen ist und
                                grundsätzlich nur für das gerade verwendete Endgerät bzw. den verwendeten Browser gilt. Wenn Sie mehrere Endgeräte bzw.
                                Browser nutzen, müssen Sie das Opt-Out auf jedem einzelnen Endgerät und in jedem verwendeten Browser setzen.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Darüber hinaus können Sie die Bildung von Nutzungsprofilen auch insgesamt verhindern, indem Sie generell die Verwendung
                                von Cookies deaktivieren.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Weitere Informationen zu interessenbasierter Werbung sind auf dem Verbraucherportal http://www.meine-cookies.org erhältlich.
                                Über nachstehenden Link zu diesem Portal können Sie darüber hinaus den Stand der Aktivierung bezüglich Tools unterschiedlicher
                                Anbieter einsehen und der Erhebung bzw. Auswertung Ihrer Daten durch diese Tools widersprechen:
                                http://www.meine-cookies.org/cookies_verwalten/praeferenzmanager-beta.html.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Eine zentrale Widerspruchsmöglichkeit für verschiedene Tools insbesondere US-amerikanischer Anbieter ist auch unter folgendem
                                Link erreichbar: http://optout.networkadvertising.org
                            </Typography>
                            <Typography className={classes.dataText}>
                                Des Weiteren gestatten wir bzw. unser Vermarkter Mirando GmbH & Co KG https://www.mirando.de/ es Dritten, beispielsweise
                                Media-Agenturen, Cookies zu setzen, um die Nutzung von Online-Werbung zu analysieren und Ihnen anhand Ihrer Vorlieben
                                spezielle, Sie interessierende Werbung auf unseren Online-Angeboten zu präsentieren. Darüber hinaus soll den
                                Media-Agenturen ermöglicht werden, die Zielgruppe ihrer Werbetreibenden möglichst genau und ohne große Streuverluste
                                anzusprechen.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Hierzu werden z.B. anonyme Nutzerstatistiken erstellt und die Daten zu Marktforschungszwecken verwendet.
                                Ein Rückschluss auf eine Person ist nicht möglich.
                            </Typography>
                            <Typography className={classes.dataText}><strong>Ihre Rechte / Widerspruch: </strong>
                                Natürlich können Sie unsere Angebote grundsätzlich auch ohne Cookies nutzen. Browser lassen sich so einstellen, dass Cookies
                                generell abgelehnt werden. Ebenso können Sie bereits gesetzte Cookies in Ihren Browser-Einstellungen löschen. Wenn Sie keine
                                Cookies verwenden, könnte dies jedoch dazu führen, dass Sie die Funktionen unserer Webseite nicht mehr in vollem Umfang nutzen können.
                            </Typography>
                            <Typography className={classes.dataSubHead1} gutterBottom><strong>Ähnliche Technologien</strong></Typography>
                            <Typography className={classes.dataText} gutterBottom>
                                <strong>(1) Conversion Tracking</strong>
                            </Typography>
                            <Typography className={classes.dataText} gutterBottom>
                                Wenn Sie über eine Google-Anzeige oder eine Affiliate-Anzeige auf unsere Webseiten gelangt sind, können mithilfe eines Cookies von
                                Google AdWords, Metalyzer oder Teradata, das nach 30 Tagen seine Gültigkeit verliert, Statistiken über unsere Konversionsrate
                                erstellt werden. Das bedeutet, dass wir erfahren, wie viele Nutzer über einen der oben genannten Wege unsere Webseite besuchen
                                und innerhalb von 30 Tagen ein Produkt über unsere Webseiten erwerben. Ein Rückschluss auf Ihre Person ist dabei nicht möglich.
                            </Typography>
                            <Typography className={classes.dataText}><strong>Ihre Rechte / Widerspruch: </strong>
                                Wenn Sie nicht an dem Tracking-Verfahren teilnehmen möchten, können Sie die Conversion-Cookies deaktivieren, indem Sie in
                                Ihren Browser-Einstellungen festlegen, dass Cookies von der entsprechenden Domain blockiert werden:
                            </Typography>
                            <Typography className={classes.dataText}>Google Adwords: googleadservices.com</Typography>
                            <Typography className={classes.dataText}>Metalyzer: tracking.mlsat02.de und tracking.metalyzer.com</Typography>
                            <Typography className={classes.dataText}>Teradata: email-telekom.de</Typography>
                            <Typography className={classes.dataText} gutterBottom>
                                <strong>(2) Google-Analytics</strong>
                            </Typography>
                            <Typography className={classes.dataText}>Wir verwenden den Analysedienst Google Analytics der Firma Google Inc.,
                                1600 Amphitheatre Parkway, Mountain View, CA 94043, USA (Google). Google Analytics verwendet. Die durch den Cookie
                                erzeugten Informationen über Ihre Benutzung der Webseite werden an einen Server von Google in den USA übertragen
                                und dort gespeichert.</Typography>
                            <Typography className={classes.dataText}>Google wird in unserem Auftrag diese Informationen benutzen, um die Nutzung
                                unserer Webseite auszuwerten, um Reports über Webseitenaktivitäten zusammenzustellen und um weitere mit der
                                Webseitennutzung und der Internetnutzung verbundene Dienstleistungen uns gegenüber zu erbringen. Aus den
                                verarbeiteten Daten können pseudonyme Nutzungsprofile erstellt werden.</Typography>
                            <Typography className={classes.dataText}>Wir haben auf unserer Webseite Google Analytics um den Code “anonymizeIP” erweitert.
                                Dies bedeutet, die IP-Adresse der Nutzer wird innerhalb der EU oder in anderen Vertragsstaaten des Abkommens über den
                                Europäischen Wirtschaftsraum gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in
                                den USA übertragen und dort gekürzt. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse
                                wird nicht mit anderen Daten von Google zusammengeführt.</Typography>
                            <Typography className={classes.dataText}>Die Gesetze zum Schutz von personenbezogenen Daten können in den USA von
                                dem Schutz innerhalb der EU abweichen und gegebenenfalls nur ein geringeres Schutzniveau gewährleiten. Sollten
                                personenbezogene Daten durch Google Analytics in den USA verarbeitet werden findet dies auf Basis geeigneter
                                Vertraulichkeits- und Sicherheitsmaßnahmen statt, die den Schutz Ihrer Daten sicherstellen. Google ist nach
                                dem Privacy Shield-Abkommen zertifiziert; weitere Informationen zum Privacy Shield-Abkommen finden können Sie
                                unter https://www.privacyshield.gov/welcome finden.</Typography>
                            <Typography className={classes.dataText}><strong>Ihre Rechte / Widerspruch: </strong>
                                Die Speicherung der Cookies können Sie durch eine entsprechende Einstellung in Ihrem Browser verhindern.
                                Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung dieser Website
                                bezogenen Daten an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter
                                dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren: http://tools.google.com/dlpage/gaoptout?hl=de.
                                Die Webanalyse bleibt in diesem Fall solange deaktiviert, wie das Add-On von Google nicht seinerseits deaktiviert bzw.
                                gelöscht wird. Daher löschen Sie bitte das Add-On nicht, solange die Webanalyse nicht gewollt ist. Das Add-On ist pro
                                Browser und Rechner gesetzt. Falls Sie unsere Webseite also mit unterschiedlichen Browsern oder von unterschiedlichen
                                Rechnern aus aufrufen, müssen Sie für jeden Browser bzw. Rechner das Add-On gesondert hinzufügen. Alternativ zum
                                Browser-Add-On, insbesondere bei Browsern auf mobilen Endgeräten, können Sie die Erfassung durch Google Analytics
                                zudem verhindern, indem Sie auf diesen Link klicken: Google Analytics Tracking beenden. Es wird ein Opt-Out-Cookie
                                gesetzt, das die zukünftige Erfassung Ihrer Daten beim Besuch dieser Website verhindert. Der Opt-Out-Cookie gilt nur
                                in diesem Browser und nur für unsere Website und wird auf Ihrem Gerät abgelegt. Löschen Sie die Cookies in diesem
                                Browser, müssen Sie das Opt-Out-Cookie erneut setzen. Wir nutzen Google Analytics des Weiteren dazu, Daten aus
                                DoubleClick-Cookies und auch AdWords zu statistischen Zwecken auszuwerten. Dies umfasst demografischen Merkmale
                                (z.B. Alter und Geschlecht) sowie Interessen. Diese Daten sind nicht auf eine bestimmte Person zurückzuführen.
                                Sollten Sie dies nicht wünschen, können Sie dies jederzeit über die Anzeigeneinstellungen deaktivieren.
                            </Typography>
                            <Typography className={classes.dataText} gutterBottom>
                                <strong>(3) Optimizely</strong>
                            </Typography>
                            <Typography className={classes.dataText}>Wir setzen einen Dienst der Optimizely GmbH (https://www.optimizely.com/de) ein, der über Cookies die Nutzung unserer
                                digitalen Angebote analysiert. Die Informationen werden in der Regel zur Auswertung an einen Server in den USA
                                übertragen und dort gespeichert, nur in Ausnahmefällen wird aber die volle IP-Adresse übertragen und erst dort
                                gekürzt. Die Informationen werden nicht mit anderen Daten von Optimizely kombiniert.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Link Datenschutzhinweise: https://www.optimizely.com/de/privacy/
                            </Typography>
                            <Typography className={classes.dataText}>
                                Link zum Opt Out: https://www.optimizely.com/de/legal/opt-out/
                            </Typography>
                            <Typography className={classes.dataText} gutterBottom>
                                <strong>(4) Taboola</strong>
                            </Typography>
                            <Typography className={classes.dataText}>
                                Wir setzen auf unsere Webseite den Service von Taboola ein, der es ermöglicht, nutzerindividuelle Empfehlungen für
                                Inhalte und Anzeigen auf Basis von Surfverhalten und Kundeninteressen auszuspielen, um damit die Nutzerfreundlichkeit
                                unseres Angebots zu verbessern. Die Nutzungsprofile werden unter Verwendung von Pseudonymen erstellt, sie werden nicht
                                mit den Daten über den Träger des Pseudonyms zusammengeführt und lassen keine Rückschlüsse auf persönliche Daten zu.
                                Taboola erhebt mittels Cookies folgende Nutzer-Informationen:
                            </Typography>
                            <Typography className={classes.dataText}>
                                Betriebssystem des Nutzers
                            </Typography>
                            <ul>
                                <li>
                                    <Typography className={classes.dataText}>
                                        Aufgerufene Webseiten/Inhalte auf unseren Webseiten
                                    </Typography>
                                </li>
                                <li>
                                    <Typography className={classes.dataText}>
                                        Referrer/Link, über den der User auf unsere Website gekommen ist
                                    </Typography>
                                </li>
                                <li>
                                    <Typography className={classes.dataText}>
                                        Zeitpunkt und Anzahl der Website-Aufrufe
                                    </Typography>
                                </li>
                                <li>
                                    <Typography className={classes.dataText}>
                                        Aufrufe von Fehlerseiten
                                    </Typography>
                                </li>
                                <li>
                                    <Typography className={classes.dataText}>
                                        Standortinformationen (Stadt und Bundesland)
                                    </Typography>
                                </li>
                                <li>
                                    <Typography className={classes.dataText}>
                                        IP Adressen in gekürzter Form
                                    </Typography>
                                </li>
                            </ul>
                            <Typography className={classes.dataText}><strong>Ihre Rechte / Widerspruch: </strong>
                                Weitere Informationen zu Taboola finden Sie unter https://www.taboola.com/privacy-policy. Dort können Sie im Abschnitt
                                “User Choices and Opting Out” jederzeit das Tracking deaktivieren. Nach erfolgtem OptOut werden Ihnen keine personalisierten
                                Inhalte/Werbung mehr ausgespielt.
                            </Typography>
                            <Typography className={classes.dataText} gutterBottom>
                                <strong>(5) Civey</strong>
                            </Typography>
                            <Typography className={classes.dataText}>
                                Wir erheben Online-Umfragen mit der Civey GmbH (im folgenden Civey genannt). Civey hat eine Datenlogik entwickelt,
                                mit der repräsentative Meinungsumfragen erstellt und ausgewertet werden können. Civey ermöglicht seinen Nutzern,
                                sich an repräsentativen Umfragen zu beteiligen und die Ergebnisse direkt einzusehen. Sie haben die Möglichkeit an
                                diesen Online-Umfragen anonym teilzunehmen. In diesem Fall erhebt Civey keine personenbezogenen Daten. Sie können
                                sich jedoch auch bei Civey mit Ihrer E-Mail-Adresse registrieren und als registriertes Civey-Mitglied an den
                                Online-Umfragen teilnehmen. Nutzer, die sich mit einer E-Mail-Adresse registrieren, müssen die Nutzungsbedingungen
                                von Civey akzeptieren.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Link Datenschutzhinweise: www.civey.com/datenschutz
                            </Typography>
                            <Typography className={classes.dataText}>
                                Ein Widerspruch kann per Mail an datenschutz@civey.com eingereicht werden.
                            </Typography>
                            <Typography className={classes.dataText} gutterBottom>
                                <strong>(6) Conversario</strong>
                            </Typography>
                            <Typography className={classes.dataText}>
                                Conversario erhebt, verarbeitet und nutzt Ihre personenbezogenen Daten nur unter Einhaltung der einschlägigen
                                Datenschutzbestimmungen und nur soweit es notwendig ist, damit sie unsere Software nutzen können. Darüber hinaus
                                erhebt Conversario Zugriffsdaten auf ihren Server sowie Statistikdaten. In keinem Fall wird Conversario Ihre
                                personenbezogenen Daten zu Werbe- oder Marketingzwecken oder unbefugt zu anderen Zwecken Dritten übermitteln.
                                Ihre Daten werden nur dann an Dritte weitergeleitet, wenn dies gesetzlich erlaubt ist oder Sie in die Weiterleitung
                                eingewilligt haben. Conversario trifft organisatorische, vertragliche und technische Sicherheitsmaßnahmen,
                                um sicher zu stellen, dass die Vorschriften der Datenschutzgesetze eingehalten werden und damit die durch
                                Conversario verwalteten Daten gegen zufällige oder vorsätzliche Manipulationen, Verlust, Zerstörung oder
                                gegen den Zugriff unberechtigter Personen zu schützen.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Link Datenschutzhinweise: https://www.conversar.io/de/privacy.html
                            </Typography>
                            <Typography className={classes.dataText}>
                                Link zum Opt Out: http://tools.google.com/dlpage/gaoptout?hl=de
                            </Typography>
                            <Typography className={classes.dataText} gutterBottom>
                                <strong>(7) Opinary</strong>
                            </Typography>
                            <Typography className={classes.dataText}>
                                Die Opinary GmbH bietet einen Service an, mit dem Benutzer sowohl über redaktionelle als auch über gesponserte Umfragen abstimmen
                                und interaktiv Ihre Meinung abgeben können. In diesem Zusammenhang speichert unser Service automatisch ein Cookie auf dem Computer
                                des Benutzers, wodurch wir die Abstimmungsergebnisse statistisch aussagekräftig zusammenfassen und unseren Service und die im
                                Interesse des Benutzers präsentierten Informationen optimieren können. Bei der Nutzung eines Opinary-Tools werden keine
                                personenbezogenen Daten erhoben. Die IP Adressen der anfragenden Rechner werden verkürzt und somit anonymisiert protokolliert.
                                Eine Rückverfolgung ist nicht möglich. Cookies: Bei der Nutzung von Opinary-Tools werden Cookies auf Ihrem Browser in dem dafür
                                vorgesehenen Verzeichnis abgelegt. Die Cookies dienen dazu, dass ein und derselbe Nutzer nicht mehrfach an einer Umfrage
                                teilnehmen kann. Über das Cookie wird somit lediglich die Information gespeichert, wer bereits an der Umfrage teilgenommen
                                hat. Über die Cookies werden ausschließlich die verkürze IP-Adresse sowie die anonymisierten Logfile-Datensätze (u.a. Datum
                                und Uhrzeit des Abrufs, Browsertyp und -version) übermittelt. Weitere Informationen zu Opinary: https://opinary.com/home-de/
                            </Typography>
                            <Typography className={classes.dataText}>
                                Link Datenschutzhinweise: https://opinary.com/privacy-policy/
                            </Typography>
                            <Typography className={classes.dataText}>
                                Link zum Opt Out: https://compass.pressekompass.net/static/optout.html
                            </Typography>
                            <Typography className={classes.dataText} gutterBottom>
                                <strong>(9) Seeding Alliance</strong>
                            </Typography>
                            <Typography className={classes.dataText}>
                                Seeding Alliance erfasst anonymisierte Informationen über das Userverhalten, beispielsweise welche Inhalte oder Produkte angesehen wurden,
                                die Native Advertising Kampagnen von Seeding Alliance ausspielen. Dies hilft Seeding Alliance, Native Advertising Kampagnen besser
                                auszusteuern, und Ihnen relevantere Werbung anzuzeigen. Alle erfassbaren Informationen werden von Seeding Alliance anonymisiert einer
                                mehrstelligen Identifikationsnummer zugeordnet, die in einem Cookie auf dem Rechner des Users gespeichert wird. So ist es nicht möglich
                                einen Rückschluss auf die Identität zu erhalten. Sofern IP-Adressen erfasst werden, geschieht dies unmittelbar anonymisiert.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Erhobene Daten werden in Rechenzentren innerhalb der EU verarbeitet und verschlüsselt und datenschutzkonform gespeichert.
                                Personenbezogene Daten werden grundsätzlich nicht erhoben.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Weitere Informationen: http://seeding-alliance.de/datenschutz/
                            </Typography>
                            <Typography className={classes.dataText}>
                                Link zum Opt Out: https://seeding-alliance.de/opt-out/
                            </Typography>
                        </Grid>
                        <Grid item sm={4}>
                            <Typography className={classes.dataSubHead}>10. Technologien von eigenverantwortlichen Dritten</Typography>
                        </Grid>
                        <Grid item sm={8}>
                            <Typography className={classes.dataText}>
                                Des Weiteren finden sich auf unserer Website Technologien, die von eigenverantwortlichen Dritten betrieben werden.
                            </Typography>
                        </Grid>
                        <Grid item sm={4}>
                            <Typography className={classes.dataSubHead}>10.1 Reichweitenmessung / Webanalyse</Typography>
                        </Grid>
                        <Grid item sm={8}>
                            <Typography className={classes.dataText}>
                                <strong>(1) Webseiten-Analyse mit Webtrekk und Tealium</strong>
                            </Typography>
                            <Typography className={classes.dataText}>
                                Wir verwenden die Dienste der Firma Webtrekk GmbH, um statistische Daten über die Nutzung unserer Websites zu erheben und unser 
                                Angebot entsprechend zu optimieren. Die Webtrekk GmbH ist für Datenschutz, im Bereich Web Controlling Software, vom TÜV Saarland 
                                zertifiziert. Teil der Zertifizierung ist ein Audit bei Webtrekk vor Ort in Berlin und im Hosting-Standort, in dem die Erfassung 
                                und Verarbeitung von Tracking-Daten auf Datenschutzkonformität und Datensicherheit hin geprüft wurden. Im Rahmen Ihres Besuchs 
                                der Anwendung werden für unser Webcontrolling einige Informationen erhoben und ausgewertet, die Ihr Browser übermittelt. 
                                Die Erhebung erfolgt durch einen Pixel, der auf jeder Seite eingebunden ist und durch Cookies.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Folgende Cookies werden mit Webtrekk gesetzt:
                            </Typography>
                            <ul>
                                <li>
                                    <Typography className={classes.dataText}>
                                        Session Cookie (für Session-Erkennung, Lebensdauer: eine Session)
                                    </Typography>
                                </li>
                                <li>
                                    <Typography className={classes.dataText}>
                                        Langzeit-Cookie (zur Erkennung Neu-/Stammkunde: 6 Monate)
                                    </Typography>
                                </li>
                                <li>
                                    <Typography className={classes.dataText}>
                                        Opt-out-Cookie (bei Widerspruch gegen das Tracking: 60 Monate)
                                    </Typography>
                                </li>
                            </ul>
                            <Typography className={classes.dataText}>
                                Folgende Daten werden erhoben:
                            </Typography>
                            <ul>
                                <li>
                                    <Typography className={classes.dataText}>
                                        Request (Dateiname der angeforderten Datei)
                                    </Typography>
                                </li>
                                <li>
                                    <Typography className={classes.dataText}>
                                        Browsertyp/-version (Bsp.: Internet Explorer 6.0)
                                    </Typography>
                                </li>
                                <li>
                                    <Typography className={classes.dataText}>
                                        Browsersprache (Bsp.: Deutsch)
                                    </Typography>
                                </li>
                                <li>
                                    <Typography className={classes.dataText}>
                                        verwendetes Betriebssystem (Bsp.: Windows XP)
                                    </Typography>
                                </li>
                                <li>
                                    <Typography className={classes.dataText}>
                                        innere Auflösung des Browserfensters
                                    </Typography>
                                </li>
                                <li>
                                    <Typography className={classes.dataText}>
                                        Bildschirmauflösung
                                    </Typography>
                                </li>
                                <li>
                                    <Typography className={classes.dataText}>
                                        Javascriptaktivierung
                                    </Typography>
                                </li>
                                <li>
                                    <Typography className={classes.dataText}>
                                        Java An / Aus
                                    </Typography>
                                </li>
                                <li>
                                    <Typography className={classes.dataText}>
                                        Cookies An / Aus
                                    </Typography>
                                </li>
                                <li>
                                    <Typography className={classes.dataText}>
                                        Farbtiefe
                                    </Typography>
                                </li>
                            </ul>
                            <Typography className={classes.dataText}>
                                Referrer URL (die zuvor besuchte Seite) IP Adresse – wird umgehend anonymisiert und nach Verarbeitung wieder gelöscht:
                            </Typography>
                            <ul>
                                <li>
                                    <Typography className={classes.dataText}>
                                        Uhrzeit des Zugriffs
                                    </Typography>
                                </li>
                                <li>
                                    <Typography className={classes.dataText}>
                                        Klicks
                                    </Typography>
                                </li>
                                <li>
                                    <Typography className={classes.dataText}>
                                        Formularinhalte (bei Freitextfeldern, z.B. Name und Passwort, wird nur “ausgefüllt” oder “nicht ausgefüllt” übertragen)
                                    </Typography>
                                </li>
                                <li>
                                    <Typography className={classes.dataText}>
                                        IP-Adresse – wird ausschließlich in gekürzter, anonymisierter Form gespeichert, nur zur Sessionerkennung, Geolokalisierung 
                                        (bis auf Stadt-Ebene) und für die Abwehr von Attacken verwendet und direkt nach ihrer Verwendung wieder gelöscht
                                    </Typography>
                                </li>
                            </ul>
                            <Typography className={classes.dataText}><strong>Ihre Rechte / Widerspruch: </strong>
                                In Ihren Browsereinstellungen können Sie festlegen, ob Cookies gesetzt werden dürfen oder nicht. Wenn Sie spezifisch 
                                für unsere Websites nicht getrackt werden möchten, können Sie dem Tracking widersprechen. Zur Vervollständigung des 
                                Widerspruchs klicken Sie bitte auf den folgenden Link: http://www.webtrekk.com/index/datenschutzerklaerung/opt-out.html
                            </Typography>
                            <Typography className={classes.dataText}>
                                Durch Bestätigung des Links wird ein Cookie mit dem Namen “webtrekkOptOut” von der Domain Webtrekk.net gesetzt. 
                                Dieser Widerspruch gilt so lange, wie Sie das Cookie nicht löschen, maximal jedoch für die Lebensdauer des Cookies 
                                von 60 Monaten. Das Cookie ist für die genannte Domain, pro Browser und Rechner gesetzt. Falls Sie daher zum 
                                Beispiel unsere Webseite von zu Hause und der Arbeit oder mit unterschiedlichen Browsern besuchen, müssten 
                                Sie von jedem Rechner und Browser das Cookie gesondert setzen.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Weitere Informationen finden Sie auf der Website der Webtrekk GmbH: Hannoversche Str. 19, 10115 Berlin: http://www.webtrekk.com/
                            </Typography>
                            <Typography className={classes.dataText}>
                                <strong>(2) Tag-Management mit Tealium</strong>
                            </Typography>
                            <Typography className={classes.dataText}>
                                Tealium IQ ist ein Tag-Management-System mit dem Messpixel von Drittanbietern auf den Seiten der 
                                Subdomain http://tarife-und-produkte.t-online.de/ geladen werden (bspw. das Webtrekk Messpixel). 
                                Zur Optimierung des Ladens der Messpixel erfasst Tealium über ein Cookie einige nicht personenbezogene 
                                Daten. Dieses Cookie verliert nach 12 Monaten seine Gültigkeit. Folgende Informationen werden in dem 
                                Tealium Cookie gespeichert:
                            </Typography>
                            <ul>
                                <li>
                                    <Typography className={classes.dataText}>Zeitstempel des Webseitenbesuchs</Typography>
                                </li>
                                <li>
                                    <Typography className={classes.dataText}>ID für den Seitenaufruf</Typography>
                                </li>
                                <li>
                                    <Typography className={classes.dataText}>ID für den Besucher</Typography>
                                </li>
                                <li>
                                    <Typography className={classes.dataText}>ID für die Session</Typography>
                                </li>
                            </ul>
                            <Typography className={classes.dataText}><strong>Ihre Rechte / Widerspruch: </strong>
                                Möchten Sie den Tealium Cookies widersprechen, können Sie diese deaktivieren. Legen Sie in Ihren Browser-Einstellungen fest, 
                                dass Cookies von der Domain “tealium.hs.llnwd.net” oder “tags-eu.tiqcdn.com” blockiert werden sollen.
                            </Typography>
                        </Grid>
                         <Grid item sm={4}>
                            <Typography className={classes.dataSubHead}>10.2 Werbung / Individualisierung</Typography>
                        </Grid>
                        <Grid item sm={8}>
                            <Typography className={classes.dataText}>
                                <strong>(1) Nutzungsbasierte Werbung – Online Behavorial Advertisement (OBA)</strong>
                            </Typography>
                            <Typography className={classes.dataText}>
                                Unsere Internetseiten werden durch Mirando GmbH & Co. KG vermarktet. Dazu werden auf unseren Websites verschiedene 
                                Werbeeinblendungen vorgenommen
                            </Typography>
                            <Typography className={classes.dataText}>
                                Diese Werbeeinblendungen ermöglichen es der Q.met GmbH, ihre Internetseiten kostenlos anzubieten. Zu diesem 
                                Zweck wird die Technologie der Mirando GmbH & Co. KG eingesetzt, die eine verbesserte Auslieferung der Werbeanzeigen 
                                ermöglicht. Dazu werden beispielsweise Daten zu Klickverhalten, Besuchsdauer, Systemeinstellungen, der 
                                Geolokalisation und der Herkunft der Besucher durch den Einsatz von Cookies gesammelt. Für diese Auswertungen 
                                werden Cookies auf ihrem Computer gespeichert. Diese erhobenen Daten, bei denen es sich ausnahmslos um anonymisierte 
                                Daten handelt, können auch auf den Servern der Kooperationspartner der Mirando GmbH & Co. KG gespeichert 
                                und verarbeitet werden. In allen Fällen gilt: Die erhobenen anonymisierten Daten werden nicht zur persönlichen 
                                Identifizierung des jeweiligen Nutzers verwendet und auch nicht mit anderen persönlichen Informationen des 
                                Nutzers zusammengeführt.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Wenn Sie unsere Internetseiten besuchen, werden mittels einer anonymen Benutzernummer im Auftrag der Mirando GmbH 
                                & Co.KG in einem Cookie Interessen (z.B. geklickte Werbebanner, besuchte Unterseiten, anonymisierte Fragebögen etc.) 
                                von Ihnen festgehalten. Diese Daten werden zum Zweck der Einblendung von Inhalten und Werbung passend zu Ihren 
                                Interessen verwendet. In keinem Fall werden dabei Daten wie Name, Anschrift, E-Mail-Adresse oder IP-Adresse in 
                                den Cookies gespeichert.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Sie können die Installation der Cookies jederzeit durch eine entsprechende Einstellung ihrer Browsersoftware 
                                verhindern. Wenn Sie keine nutzungsbasierten Werbeeinblendungen dieser Art mehr erhalten möchten, können Sie 
                                auch auf die folgenden Links klicken und widersprechen damit der jeweiligen Datenerhebung durch die Mirando 
                                GmbH und Co. KG: https://www.mirando.de/philosophie/optin-optout
                            </Typography>
                            <Typography className={classes.dataText}>
                                Die Auslieferung nutzungsbasierter Online Werbung wird vom digitalen Werbevermarkter Mirando GmbH & Co. KG 
                                (www.mirando.de) betrieben.
                            </Typography>
                            <Typography className={classes.dataText}>
                                <strong>(2) Empfehlungs- und Personalisierungs-System von Taboola Europe Ltd.</strong>
                            </Typography>
                            <Typography className={classes.dataText}>
                                Wir setzen auf unsere Webseite den Service von Taboola ein, der es ermöglicht, nutzerindividuelle Empfehlungen 
                                für Inhalte und Anzeigen auf Basis von Surfverhalten und Kundeninteressen auszuspielen, um damit die 
                                Nutzerfreundlichkeit unseres Angebots zu verbessern. Die Nutzungsprofile werden unter Verwendung von 
                                Pseudonymen erstellt, sie werden nicht mit den Daten über den Träger des Pseudonyms zusammengeführt 
                                und lassen keine Rückschlüsse auf persönliche Daten zu. Taboola erhebt mittels Cookies folgende 
                                Nutzer-Informationen:
                            </Typography>
                            <ul>
                                <li>
                                    <Typography className={classes.dataText}>
                                        Betriebssystem des Nutzers
                                    </Typography>
                                </li>
                                <li>
                                    <Typography className={classes.dataText}>
                                        Aufgerufene Webseiten/Inhalte auf unseren Webseiten
                                    </Typography>
                                </li>
                                <li>
                                    <Typography className={classes.dataText}>
                                        Referrer/Link, über den der User auf unsere Website gekommen ist
                                    </Typography>
                                </li>
                                <li>
                                    <Typography className={classes.dataText}>
                                        Zeitpunkt und Anzahl der Website-Aufrufe
                                    </Typography>
                                </li>
                                <li>
                                    <Typography className={classes.dataText}>
                                        Aufrufe von Fehlerseiten
                                    </Typography>
                                </li>
                                <li>
                                    <Typography className={classes.dataText}>
                                        Standortinformationen (Stadt und Bundesland)
                                    </Typography>
                                </li>
                                <li>
                                    <Typography className={classes.dataText}>
                                        IP Adressen in gekürzter Form
                                    </Typography>
                                </li>
                            </ul>
                            <Typography className={classes.dataText}>
                                <strong>Ihre Rechte / Widerspruch: </strong>Weitere Informationen zu Taboola finden Sie unter 
                                https://www.taboola.com/privacy-policy. Dort können Sie im Abschnitt “User Choices” jederzeit das Tracking 
                                deaktivieren. Nach erfolgtem OptOut werden Ihnen keine personalisierten Inhalte/Werbung mehr ausgespielt.
                            </Typography>
                            <Typography className={classes.dataText}>
                                <strong>(3) Folgende Tools sind für die Datenerhebung zur Auslieferung von nutzungsbasierter Online-Werbung zuständig:</strong>
                            </Typography>
                            <Typography className={classes.dataText}>
                                <strong>&#9679;&nbsp;&nbsp;Mirando (https://www.mirando.de/)</strong>
                            </Typography>
                            <Typography className={classes.dataText}>
                                Mirando vermarktet unsere digitalen Angebote. Anbieter von Mirando ist die Mirando GmbH & Co. KG, Rotdornweg 6, 52355 Düren, Deutschland. 
                                Mirando setzt Technologien zur Steuerung von nutzungsbasierten Werbeeinblendungen ein.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Link Datenschutzhinweise: https://www.mirando.de/datenschutz/
                            </Typography>
                            <Typography className={classes.dataText}>
                                Link zum Opt.Out: https://www.mirando.de/philosophie/optin-optout/
                            </Typography>
                            <Typography className={classes.dataText}>
                                <strong>&#9679;&nbsp;&nbsp;Yieldlab (https://www.yieldlab.com/)</strong>
                            </Typography>
                            <Typography className={classes.dataText}>
                                Yieldlab wird eingesetzt von dem digitalen Werbevermarkter Mirando GmbH & Co KG. Anbieter von Yieldlab ist die YIELDLAB AG, Colonnaden 41, 
                                20354 Hamburg, Deutschland. Yieldlab setzt Technologien zur Steuerung von nutzungsbasierten Werbeeinblendungen ein.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Link Datenschutzhinweise und zum Opt.Out: https://yieldlab.com/privacy-platform/
                            </Typography>
                            <Typography className={classes.dataText}>
                                <strong>&#9679;&nbsp;&nbsp;SmartAdServer (http://smartadserver.com)</strong>
                            </Typography>
                            <Typography className={classes.dataText}>
                                SmartAdServer wird eingesetzt von dem digitalen Werbevermarkters Mirando GmbH & Co KG. Anbieter von SmartAdServer 
                                ist die SmartAdServer GmbH, Mehring-damm 33, 10961 Berlin, Deutschland. SmartAdServer setzt Technologien ein, um 
                                Ihnen für Sie relevante Werbeanzeigen auszuliefern.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Link Datenschutzhinweise: https://smartadserver.com/end-user-privacy-policy/
                            </Typography>
                            <Typography className={classes.dataText}>
                                Link zum Opt.Out: http://www.smartadserver.com/diffx/gdprOptoutAll
                            </Typography>
                            <Typography className={classes.dataText}>
                                <strong>&#9679;&nbsp;&nbsp;criteo (https://www.criteo.com/de/)</strong>
                            </Typography>
                            <Typography className={classes.dataText}>
                                Criteo wird eingesetzt von dem digitalen Werbevermarkter Mirando GmbH & Co KG. Anbieter ist Criteo SA, 32 Rue Blanche, 
                                75009 Paris, Frankreich. Criteo setzt Technologien ein, um Ihnen für Sie relevante Werbeanzeigen zu präsentieren.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Link Datenschutzhinweise und zum Opt.Out: https://www.criteo.com/de/privacy/
                            </Typography>
                            <Typography className={classes.dataText}>
                                <strong>&#9679;&nbsp;&nbsp;Vibrantmedia (https://www.vibrantmedia.com/)</strong>
                            </Typography>
                            <Typography className={classes.dataText}>
                                Vibrantmedia wird eingesetzt von dem digitalen Werbevermarkter Mirando GmbH & Co KG. Anbieter von Vibrantmedia ist die 
                                Vibrant Media GmbH, Neuer Wall 59, 20354 Hamburg, Deutschland. Vibrantmedia setzt Technologien zur Steuerung von 
                                nutzungsbasierten Werbeeinblendungen ein.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Link Datenschutzhinweise: https://www.vibrantmedia.com/de/privacy-policy/
                            </Typography>
                            <Typography className={classes.dataText}>
                                Link zum Opt.Out unter Punkt „Verhaltensbezogene Werbung und Opt-Out; Selbstregulierung“: https://www.vibrantmedia.com/de/cookie-statement/
                            </Typography>
                            <Typography className={classes.dataText}>
                                <strong>&#9679;&nbsp;&nbsp;OpenX (https://www.openx.com/de_de/)</strong>
                            </Typography>
                            <Typography className={classes.dataText}>
                                OpenX wird eingesetzt von dem digitalen Werbevermarkter Mirando GmbH & Co KG. Anbieter von OpenX ist die OpenX Limited, 
                                1-3 Maple Place, London, W1T 4BB, United Kingdom. OpenX setzt Technologien ein, um Ihnen für Sie relevante Werbeanzeigen auszuliefern.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Link Datenschutzhinweise: https://www.openx.com/legal/privacy-policy/
                            </Typography>
                            <Typography className={classes.dataText}>
                                Link zum Opt.Out: http://u.openx.net/privacy/edaa/optout
                            </Typography>
                            <Typography className={classes.dataText}>
                                <strong>&#9679;&nbsp;&nbsp;Justpremium (http://justpremium.com)</strong>
                            </Typography>
                            <Typography className={classes.dataText}>
                                Justpremium wird eingesetzt von dem digitalen Werbevermarkter Mirando GmbH & Co KG. Anbieter von Justpremium ist die 
                                JustPremium B.V., Constellation CL, Stationsplein Noord-Oost 410, Schiphol, Niederlande. Just Premium setzt Technologien 
                                ein, um die Einblendung von Werbemitteln, für den User auszusteuern und zu optimieren.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Link Datenschutzhinweise: http://justpremium.com/privacy-policy
                            </Typography>
                            <Typography className={classes.dataText}>
                                Link zum Opt.Out: https://jpx.ads.justpremium.com/opt-out
                            </Typography>
                            <Typography className={classes.dataText}>
                                <strong>&#9679;&nbsp;&nbsp;Twiago (https://www.twiago.com/)</strong>
                            </Typography>
                            <Typography className={classes.dataText}>
                                Twiago wird eingesetzt von dem digitalen Werbevermarkter Mirando GmbH & Co KG. Anbieter von Twiago ist die twiago GmbH, 
                                Gustav-Heinemann-Ufer 72b, 50968 Köln, Deutschland. Twiago setzt Technologien ein, um die Einblendung von Werbemitteln 
                                für den User auszusteuern und zu optimieren.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Link Datenschutzhinweise und zum Opt.Out: https://www.twiago.com/datenschutz/
                            </Typography>
                            <Typography className={classes.dataText}>
                                <strong>&#9679;&nbsp;&nbsp;Xaxis (https://www.xaxis.com)</strong>
                            </Typography>
                            <Typography className={classes.dataText}>
                                Xaxis wird eingesetzt von dem digitalen Werbevermarkter Mirando GmbH & Co KG. Anbieter von Xaxis ist die Xaxis, LLC, 
                                140 East 45th St, 12th Floor, New York, NY 10017, United States. Xaxis setzt Technologien ein, um die Einblendung von 
                                Werbemitteln für den User auszusteuern und zu optimieren.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Link Datenschutzhinweise und zum Opt.Out:
                            </Typography>
                            <Typography className={classes.dataText}>
                                https://www.xaxis.com/privacy-notice/datenschutzerklarung-von-xaxis/
                            </Typography>
                            <Typography className={classes.dataText}>
                                <strong>&#9679;&nbsp;&nbsp;Quisma (https://quisma.com/)</strong>
                            </Typography>
                            <Typography className={classes.dataText}>
                                Quisma wird eingesetzt von dem digitalen Werbevermarkter Mirando GmbH & Co KG. Anbieter von Quisma ist die Group M 
                                Digital Germany GmbH, Derendorfer Allee 4, 40476 Düsseldorf, Deutschland. Quisma setzt Technologien zur Steuerung 
                                von nutzungsbasierten Werbeeinblendungen ein.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Link Datenschutzhinweise und zum Opt.Out: https://quisma.com/datenschutz/
                            </Typography>
                            <Typography className={classes.dataText}>
                                <strong>&#9679;&nbsp;&nbsp;Rubiconproject (http://rubiconproject.com)</strong>
                            </Typography>
                            <Typography className={classes.dataText}>
                                Rubiconproject wird eingesetzt von dem digitalen Werbevermarkter Mirando GmbH & Co KG. Anbieter von Rubiconproject 
                                ist die The Rubicon Project Ltd., Walmar House, 5th Floor, 296 Regent St., London, W1B 3HR, Großbritannien. 
                                Rubiconproject setzt Technologien ein, um Ihnen für Sie relevante Werbeanzeigen auszuliefern.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Link Datenschutzhinweise: http://rubiconproject.com/privacy/
                            </Typography>
                            <Typography className={classes.dataText}>
                                Link zum Opt.Out: http://rubiconproject.com/privacy/consumer-online-profile-and-opt-out/
                            </Typography>
                            <Typography className={classes.dataText}>
                                <strong>&#9679;&nbsp;&nbsp;Advertising Alliance (https://www.advertising-alliance.de/)</strong>
                            </Typography>
                            <Typography className={classes.dataText}>
                                Advertising Alliance wird eingesetzt von dem digitalen Werbevermarkter Mirando GmbH & Co KG. 
                                Anbieter Advertising Alliance ist die Advertising Alliance GmbH, Harkortstraße 81, 22765 Hamburg, Deutschland. 
                                Advertising Alliance setzt Technologien zur Steuerung von nutzungsbasierten Werbeeinblendungen ein.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Link Datenschutzhinweise: https://www.advertising-alliance.de/datenschutz.html
                            </Typography><Typography className={classes.dataText}>
                                Link zum Opt.Out: https://www.advertising-alliance.de/datenschutz-opt-out.html?result=optedout
                            </Typography>
                            <Typography className={classes.dataText}>
                                <strong>Sonstige Dritte</strong>
                            </Typography>
                            <Typography className={classes.dataText}>
                                Darüber hinaus kann es im Rahmen von Werbeausspielungen dazu kommen, dass weitere Technologie-Anbieter 
                                Informationen sammeln, die im Zusammenhang mit der Einblendung von Werbung stehen. (Auslieferungshäufigkeit 
                                von Werbebannern, Messung der Sichtbarkeit oder Akzeptanz der Werbefläche, Klick auf das Werbebanner oder 
                                ähnliches) Diese Informationen werden mittels Einsatz von Cookies gewonnen.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Browser lassen sich so einstellen, dass Cookies generell abgelehnt werden. Ebenso können Sie bereits 
                                gesetzte Cookies in Ihren Browser-Einstellungen löschen. Wenn Sie keine Cookies verwenden, könnte dies 
                                jedoch dazu führen, dass Sie die Funktionen unserer Webseite nicht mehr in vollem Umfang nutzen können.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Weitere Informationen zu Cookies sind ebenso auf folgenden Verbraucherportal zu finden und lassen sich 
                                dort auch global steuern. Dort können Sie darüber hinaus den Stand der Aktivierung bezüglich Tools 
                                unterschiedlicher Anbieter einsehen und der Erhebung bzw. Auswertung Ihrer Daten durch diese Tools 
                                widersprechen:
                            </Typography>
                            <Typography className={classes.dataText}>
                                www.meine-cookies.org
                            </Typography>
                            <Typography className={classes.dataText}>
                                www.youronlinechoices.com/de/praferenzmanagement
                            </Typography>
                            <Typography className={classes.dataText}>
                                optout.networkadvertising.org: https://adssettings.google.com/authenticated?hl=de
                            </Typography>
                        </Grid>
                        <Grid item sm={4}>
                            <Typography className={classes.dataSubHead}>11. Soziale Medien</Typography>
                        </Grid>
                        <Grid item sm={8}>
                            <Typography className={classes.dataText}>
                                <strong>Facebook-Plugins (Like & Share-Button)</strong>
                            </Typography>
                            <Typography className={classes.dataText}>
                                Auf unseren Seiten sind Plugins des sozialen Netzwerks Facebook, Anbieter Facebook Inc., 1 Hacker Way, Menlo Park, California 94025, 
                                USA, integriert. Die Facebook-Plugins erkennen Sie an dem Facebook-Logo oder dem „Like-Button“ („Gefällt mir“) auf unserer Seite. 
                                Eine Übersicht über die Facebook-Plugins finden Sie hier: <ExternalLink href="https://developers.facebook.com/docs/plugins/" target="_blank" rel="noreferrer">https://developers.facebook.com/docs/plugins/</ExternalLink>. Wenn Sie unsere Seite
                                besuchen, wird über das Plugin eine direkte Verbindung zwischen Ihrem Browser und dem Facebook-Server hergestellt. Facebook erhält 
                                dadurch die Information, dass Sie mit Ihrer IP-Adresse unsere Seite besucht haben. Wenn Sie den Facebook „Like-Button“ anklicken 
                                während Sie in Ihrem Facebook-Account eingeloggt sind, können Sie die Inhalte unserer Seiten auf Ihrem Facebook-Profil verlinken. 
                                Dadurch kann Facebook den Besuch unserer Seiten Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin, dass wir als Anbieter der 
                                Seiten keine Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung durch Facebook erhalten. Weitere Informationen hierzu 
                                finden Sie in der Datenschutzerklärung von Facebook unter: <ExternalLink href="https://de-de.facebook.com/policy.php" target="_blank" rel="noreferrer">https://de-de.facebook.com/policy.php</ExternalLink>. Wenn Sie nicht wünschen, dass
                                Facebook den Besuch unserer Seiten Ihrem Facebook-Nutzerkonto zuordnen kann, loggen Sie sich bitte aus Ihrem Facebook-Benutzerkonto aus.
                            </Typography>
                            <Typography className={classes.dataText}>
                                <strong>Google+ Plugin</strong>
                            </Typography>
                            <Typography className={classes.dataText}>
                                Unsere Seiten nutzen Funktionen von Google+. Anbieter ist die Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Erfassung und Weitergabe von Informationen: Mithilfe der Google+-Schaltfläche können Sie Informationen weltweit veröffentlichen. 
                                Über die Google+-Schaltfläche erhalten Sie und andere Nutzer personalisierte Inhalte von Google und unseren Partnern. Google speichert 
                                sowohl die Information, dass Sie für einen Inhalt +1 gegeben haben, als auch Informationen über die Seite, die Sie beim Klicken auf +1 angesehen haben. 
                                Ihre +1 können als Hinweise zusammen mit Ihrem Profilnamen und Ihrem Foto in Google-Diensten, wie etwa in Suchergebnissen oder in Ihrem 
                                Google-Profil, oder an anderen Stellen auf Websites und Anzeigen im Internet eingeblendet werden.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Google zeichnet Informationen über Ihre +1-Aktivitäten auf, um die Google-Dienste für Sie und andere zu verbessern. Um die Google+-Schaltfläche 
                                verwenden zu können, benötigen Sie ein weltweit sichtbares, öffentliches Google-Profil, das zumindest den für das Profil gewählten Namen 
                                enthalten muss. Dieser Name wird in allen Google-Diensten verwendet. In manchen Fällen kann dieser Name auch einen anderen Namen ersetzen, 
                                den Sie beim Teilen von Inhalten über Ihr Google-Konto verwendet haben. Die Identität Ihres Google-Profils kann Nutzern angezeigt werden, 
                                die Ihre E-Mail-Adresse kennen oder über andere identifizierende Informationen von Ihnen verfügen.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Verwendung der erfassten Informationen: Neben den oben erläuterten Verwendungszwecken werden die von Ihnen bereitgestellten Informationen 
                                gemäß den geltenden Google-Datenschutzbestimmungen genutzt. Google veröffentlicht möglicherweise zusammengefasste Statistiken über 
                                die +1-Aktivitäten der Nutzer bzw. gibt diese an Nutzer und Partner weiter, wie etwa Publisher, Inserenten oder verbundene Websites.
                            </Typography>
                            <Typography className={classes.dataText}>
                                <strong>Twitter-Plugins (Like & Share-Button)</strong>
                            </Typography>
                            <Typography className={classes.dataText}>
                                Auf unseren Seiten sind Plugins des Mikrobloggingdienstes Twitter, Anbieter Twitter International Company, One Cumberland Place, 
                                Fenian Street, Dublin 2, Ireland, integriert. Die Twitter-Plugins erkennen Sie an dem Twitter-Logo oder dem „Like-Button“ 
                                („Gefällt mir“) auf unserer Seite. Wenn Sie unsere Seiten besuchen, wird über das Plugin eine direkte Verbindung zwischen 
                                Ihrem Browser und dem Twitter-Server hergestellt. Facebook erhält dadurch die Information, dass Sie mit Ihrer IP-Adresse 
                                unsere Seite besucht haben. Wenn Sie den Facebook „Like-Button“ anklicken während Sie in Ihrem Facebook-Account eingeloggt 
                                sind, können Sie die Inhalte unserer Seiten auf Ihrem Twitter-Profil verlinken. Dadurch kann Twitter den Besuch unserer 
                                Seiten Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt 
                                der übermittelten Daten sowie deren Nutzung durch Twitter erhalten. Weitere Informationen hierzu finden Sie in der 
                                Datenschutzerklärung von Twitter unter: https://help.twitter.com/en/rules-and-policies. Wenn Sie nicht wünschen, dass 
                                Twitter den Besuch unserer Seiten Ihrem Twitter-Nutzerkonto zuordnen kann, loggen Sie sich bitte aus Ihrem Twitter-Benutzerkonto aus.
                            </Typography>
                            <Typography className={classes.dataText}>
                                <strong>Eingebettete Youtube-Videos</strong>
                            </Typography>
                            <Typography className={classes.dataText}>
                                Auf unsere Webseiten betten wir Youtube-Videos ein. Betreiber der entsprechenden Plugins ist die YouTube, LLC, 901 Cherry Ave., 
                                San Bruno, CA 94066, USA das zum US-Unternehmen Google LLC gehört. Für Nutzer ist innerhalb der EU ist die Google Ireland Limited, 
                                Gordon House Barrow St, Dublin 4, Irland. Wenn Sie eine Seite mit dem YouTube-Plugin besuchen, wird eine Verbindung zu Servern von 
                                Youtube hergestellt. Dabei wird Youtube mitgeteilt, welche Seiten Sie besuchen. Wenn Sie in Ihrem Youtube-Account eingeloggt sind, 
                                kann Youtube Ihr Surfverhalten Ihnen persönlich zuzuordnen. Dies verhindern Sie, indem Sie sich vorher aus Ihrem Youtube-Account 
                                ausloggen.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Wird ein Youtube-Video gestartet, setzt der Anbieter Cookies ein, die Hinweise über das Nutzerverhalten sammeln.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Wer das Speichern von Cookies für das Google-Ad-Programm deaktiviert hat, wird auch beim Anschauen von Youtube-Videos mit keinen 
                                solchen Cookies rechnen müssen. Youtube legt aber auch in anderen Cookies nicht-personenbezogene Nutzungsinformationen ab. 
                                Möchten Sie dies verhindern, so müssen Sie das Speichern von Cookies im Browser blockieren.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Weitere Informationen zum Datenschutz bei „Youtube“ finden Sie in der Datenschutzerklärung des Anbieters 
                                unter: www.google.de/intl/de/policies/privacy/.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Informationen zu Ihrem Datenschutz finden Sie auch hier: https://www.datenschutz.org/youtube/
                            </Typography>
                        </Grid>
                        <Grid item sm={4}>
                            <Typography className={classes.dataSubHead}>12. Analyse Tools</Typography>
                        </Grid>
                        <Grid item sm={8}>
                            <Typography className={classes.dataText}>
                                <strong>Google Analytics</strong>
                            </Typography>
                            <Typography className={classes.dataText}>
                                Diese Website nutzt Funktionen des Webanalysedienstes Google Analytics. Anbieter ist die Google Inc., 1600 
                                Amphitheatre Parkway, Mountain View, CA 94043, USA.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Google Analytics verwendet so genannte „Cookies“. Das sind Textdateien, die auf Ihrem Computer gespeichert werden 
                                und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch das Cookie erzeugten Informationen 
                                über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort 
                                gespeichert.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Die Speicherung von Google-Analytics-Cookies erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. 
                                Der Websitebetreiber hat ein berechtigtes Interesse an der Analyse des Nutzerverhaltens, um sowohl 
                                sein Webangebot als auch seine Werbung zu optimieren.
                            </Typography>
                            <Typography className={classes.dataText}>
                                * IP Anonymisierung
                            </Typography>
                            <Typography className={classes.dataText}>
                                Wir haben auf dieser Website die Funktion IP-Anonymisierung aktiviert. Dadurch wird Ihre IP-Adresse von Google 
                                innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den 
                                Europäischen Wirtschaftsraum vor der Übermittlung in die USA gekürzt. Nur in Ausnahmefällen wird die volle 
                                IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser 
                                Website wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die 
                                Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene 
                                Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser 
                                übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.
                            </Typography>
                            <Typography className={classes.dataText}>
                                * Browser Plugin
                            </Typography>
                            <Typography className={classes.dataText}>
                                Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; 
                                wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser 
                                Website vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der durch den Cookie 
                                erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die 
                                Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link verfügbare 
                                Browser-Plugin herunterladen und installieren: <ExternalLink href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank" rel="noreferrer">https://tools.google.com/dlpage/gaoptout?hl=de</ExternalLink>.
                            </Typography>
                            <Typography className={classes.dataText}>
                                * Widerspruch gegen Datenerfassung
                            </Typography>
                            <Typography className={classes.dataText}>
                                Sie können die Erfassung Ihrer Daten durch Google Analytics verhindern, indem Sie auf folgenden Link klicken. 
                                Es wird ein Opt-Out-Cookie gesetzt, der die Erfassung Ihrer Daten bei zukünftigen Besuchen dieser Website 
                                verhindert: https://tools.google.com/dlpage/gaoptout?hl=de
                            </Typography>
                            <Typography className={classes.dataText}>
                                Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics finden Sie in der Datenschutzerklärung von 
                                Google: <ExternalLink href="https://support.google.com/analytics/answer/6004245?hl=de" target="_blank" rel="noreferrer">https://support.google.com/analytics/answer/6004245?hl=de</ExternalLink>.
                            </Typography>
                            <Typography className={classes.dataText}>
                                * Auftragsdatenverarbeitung
                            </Typography>
                            <Typography className={classes.dataText}>
                                Wir haben mit Google einen Vertrag zur Auftragsdatenverarbeitung abgeschlossen und setzen die strengen 
                                Vorgaben der deutschen Datenschutzbehörden bei der Nutzung von Google Analytics vollständig um.
                            </Typography>
                            <Typography className={classes.dataText}>
                                * Demografische Merkmale bei Google Analytics
                            </Typography>
                            <Typography className={classes.dataText}>
                                Diese Website nutzt die Funktion “demografische Merkmale” von Google Analytics. Dadurch können Berichte 
                                erstellt werden, die Aussagen zu Alter, Geschlecht und Interessen der Seitenbesucher enthalten. 
                                Diese Daten stammen aus interessenbezogener Werbung von Google sowie aus Besucherdaten von Drittanbietern. 
                                Diese Daten können keiner bestimmten Person zugeordnet werden. Sie können diese Funktion jederzeit über 
                                die Anzeigeneinstellungen in Ihrem Google-Konto deaktivieren oder die Erfassung Ihrer Daten durch Google 
                                Analytics wie im Punkt “Widerspruch gegen Datenerfassung” dargestellt generell untersagen.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Google Analytics Remarketing
                            </Typography>
                            <Typography className={classes.dataText}>
                                Unsere Websites nutzen die Funktionen von Google Analytics Remarketing in Verbindung mit den 
                                geräteübergreifenden Funktionen von Google AdWords und Google DoubleClick. Anbieter ist die Google Inc., 
                                1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Diese Funktion ermöglicht es die mit Google Analytics Remarketing erstellten Werbe-Zielgruppen mit den 
                                geräteübergreifenden Funktionen von Google AdWords und Google DoubleClick zu verknüpfen. Auf diese 
                                Weise können interessenbezogene, personalisierte Werbebotschaften, die in Abhängigkeit Ihres früheren 
                                Nutzungs- und Surfverhaltens auf einem Endgerät (z.B. Handy) an Sie angepasst wurden auch auf einem 
                                anderen Ihrer Endgeräte (z.B. Tablet oder PC) angezeigt werden.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Haben Sie eine entsprechende Einwilligung erteilt, verknüpft Google zu diesem Zweck Ihren Web- und 
                                App-Browserverlauf mit Ihrem Google-Konto. Auf diese Weise können auf jedem Endgerät auf dem Sie 
                                sich mit Ihrem Google-Konto anmelden, dieselben personalisierten Werbebotschaften geschaltet werden.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Zur Unterstützung dieser Funktion erfasst Google Analytics google-authentifizierte IDs der Nutzer, die 
                                vorübergehend mit unseren Google-Analytics-Daten verknüpft werden, um Zielgruppen für die geräteübergreifende 
                                Anzeigenwerbung zu definieren und zu erstellen.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Sie können dem geräteübergreifenden Remarketing/Targeting dauerhaft widersprechen, indem Sie personalisierte Werbung 
                                in Ihrem Google-Konto deaktivieren; folgen Sie hierzu diesem Link: 
                                <ExternalLink href="https://www.google.com/settings/ads/onweb/" target="_blank" rel="noreferrer">https://www.google.com/settings/ads/onweb/</ExternalLink>.
                                Die Zusammenfassung der erfassten Daten in Ihrem Google-Konto erfolgt ausschließlich auf Grundlage Ihrer Einwilligung, 
                                die Sie bei Google abgeben oder widerrufen können (Art. 6 Abs. 1 lit. a DSGVO). Bei Datenerfassungsvorgängen, 
                                die nicht in Ihrem Google-Konto zusammengeführt werden (z. B. weil Sie kein Google-Konto haben oder der 
                                Zusammenführung widersprochen haben) beruht die Erfassung der Daten auf Art. 6 Abs. 1 lit. f DSGVO. Das 
                                berechtigte Interesse ergibt sich daraus, dass der Websitebetreiber ein Interesse an der anonymisierten 
                                Analyse der Websitebesucher zu Werbezwecken hat.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Weitergehende Informationen und die Datenschutzbestimmungen finden Sie in der Datenschutzerklärung von Google unter: 
                                <ExternalLink href="https://www.google.com/policies/technologies/ads/" target="_blank" rel="noreferrer">https://www.google.com/policies/technologies/ads/</ExternalLink>.
                            </Typography>
                            <Typography className={classes.dataText}>
                                <strong>Google AdWords und Google Conversion-Tracking</strong>
                            </Typography>
                            <Typography className={classes.dataText}>
                                Diese Websiten verwendet Google AdWords. AdWords ist ein Online-Werbeprogramm der 
                                Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, United States (“Google”).
                            </Typography>
                            <Typography className={classes.dataText}>
                                Im Rahmen von Google AdWords nutzen wir das so genannte Conversion-Tracking. Wenn Sie auf eine von Google geschaltete 
                                Anzeige klicken wird ein Cookie für das Conversion-Tracking gesetzt. Bei Cookies handelt es sich um kleine Textdateien, 
                                die der Internet-Browser auf dem Computer des Nutzers ablegt. Diese Cookies verlieren nach 30 Tagen ihre Gültigkeit 
                                und dienen nicht der persönlichen Identifizierung der Nutzer. Besucht der Nutzer bestimmte Seiten dieser Website und 
                                das Cookie ist noch nicht abgelaufen, können Google und wir erkennen, dass der Nutzer auf die Anzeige geklickt hat 
                                und zu dieser Seite weitergeleitet wurde.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Jeder Google AdWords-Kunde erhält ein anderes Cookie. Die Cookies können nicht über die Websites von AdWords-Kunden 
                                nachverfolgt werden. Die mithilfe des Conversion-Cookies eingeholten Informationen dienen dazu, 
                                Conversion-Statistiken für AdWords-Kunden zu erstellen, die sich für Conversion-Tracking entschieden 
                                haben. Die Kunden erfahren die Gesamtanzahl der Nutzer, die auf ihre Anzeige geklickt haben und zu 
                                einer mit einem Conversion-Tracking-Tag versehenen Seite weitergeleitet wurden. Sie erhalten jedoch 
                                keine Informationen, mit denen sich Nutzer persönlich identifizieren lassen. Wenn Sie nicht am Tracking 
                                teilnehmen möchten, können Sie dieser Nutzung widersprechen, indem Sie das Cookie des Google 
                                Conversion-Trackings über ihren Internet-Browser unter Nutzereinstellungen leicht deaktivieren. 
                                Sie werden sodann nicht in die Conversion-Tracking Statistiken aufgenommen.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Die Speicherung von “Conversion-Cookies” erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. 
                                Der Websitebetreiber hat ein berechtigtes Interesse an der Analyse des Nutzerverhaltens, um sowohl 
                                sein Webangebot als auch seine Werbung zu optimieren.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Mehr Informationen zu Google AdWords und Google Conversion-Tracking finden Sie in den Datenschutzbestimmungen 
                                von Google: 
                                <ExternalLink href="https://www.google.de/policies/privacy/" target="_blank" rel="noreferrer">https://www.google.de/policies/privacy/</ExternalLink>. Sie können Ihren Browser so einstellen, dass Sie über das
                                Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für 
                                bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des 
                                Browsers aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.
                            </Typography>
                            <Typography className={classes.dataText}>
                                <strong>Facebook Pixel</strong>
                            </Typography>
                            <Typography className={classes.dataText}>
                                Unsere Website nutzt zur Konversionsmessung das Besucheraktions-Pixel von Facebook, Facebook Inc., 1601 S. 
                                California Ave, Palo Alto, CA 94304, USA (“Facebook”).
                            </Typography>
                            <Typography className={classes.dataText}>
                                So kann das Verhalten der Seitenbesucher nachverfolgt werden, nachdem diese durch Klick auf eine 
                                Facebook-Werbeanzeige auf die Website des Anbieters weitergeleitet wurden. 
                                Dadurch können die Wirksamkeit der Facebook-Werbeanzeigen für statistische und 
                                Marktforschungszwecke ausgewertet werden und zukünftige Werbemaßnahmen optimiert werden.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Die erhobenen Daten sind für uns als Betreiber dieser Website anonym, wir können keine Rückschlüsse auf die Identität der Nutzer ziehen. 
                                Die Daten werden aber von Facebook gespeichert und verarbeitet, sodass eine Verbindung zum jeweiligen Nutzerprofil möglich ist und 
                                Facebook die Daten für eigene Werbezwecke, entsprechend der Facebook-Datenverwendungsrichtlinie verwenden kann. Dadurch kann Facebook 
                                das Schalten von Werbeanzeigen auf Seiten von Facebook sowie außerhalb von Facebook ermöglichen. Diese Verwendung der Daten kann von 
                                uns als Seitenbetreiber nicht beeinflusst werden. In den Datenschutzhinweisen von Facebook finden Sie weitere Hinweise zum Schutz 
                                Ihrer Privatsphäre: 
                                <ExternalLink href="https://www.facebook.com/about/privacy/" target="_blank" rel="noreferrer">https://www.facebook.com/about/privacy/</ExternalLink>. 
                                Sie können außerdem die Remarketing-Funktion “Custom Audiences”
                                im Bereich Einstellungen für Werbeanzeigen unter 
                                <ExternalLink href="https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen" target="_blank" rel="noreferrer">https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen</ExternalLink>
                                deaktivieren. Dazu müssen Sie bei Facebook angemeldet sein. Wenn Sie kein Facebook Konto besitzen, können Sie nutzungsbasierte 
                                Werbung von Facebook auf der Website der European Interactive Digital Advertising Alliance deaktivieren: 
                                <ExternalLink href="http://www.youronlinechoices.com/de/praferenzmanagement/" target="_blank" rel="noreferrer">http://www.youronlinechoices.com/de/praferenzmanagement/</ExternalLink>.
                            </Typography>
                        </Grid>
                        <Grid item sm={4}>
                            <Typography className={classes.dataSubHead}>13. Abonnement unseres Newsletters</Typography>
                        </Grid>
                        <Grid item sm={8}>
                            <Typography className={classes.dataText}>
                                Auf der Internetseite der Q.met GmbH wird den Benutzern die Möglichkeit eingeräumt, den Newsletter unseres Unternehmens 
                                zu abonnieren. Welche personenbezogenen Daten bei der Bestellung des Newsletters an den für die Verarbeitung Verantwortlichen 
                                übermittelt werden, ergibt sich aus der hierzu verwendeten Eingabemaske.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Die Q.met GmbH informiert ihre Kunden und Geschäftspartner in regelmäßigen Abständen im Wege eines Newsletters über Angebote 
                                des Unternehmens. Der Newsletter unseres Unternehmens kann von der betroffenen Person grundsätzlich nur dann empfangen werden, 
                                wenn (1) die betroffene Person über eine gültige E-Mail-Adresse verfügt und (2) die betroffene Person sich für den 
                                Newsletterversand registriert. An die von einer betroffenen Person erstmalig für den Newsletterversand eingetragene 
                                E-Mail-Adresse wird aus rechtlichen Gründen eine Bestätigungsmail im Double-Opt-In-Verfahren versendet. Diese Bestätigungsmail 
                                dient der Überprüfung, ob der Inhaber der E-Mail-Adresse als betroffene Person den Empfang des Newsletters autorisiert hat.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Bei der Anmeldung zum Newsletter speichern wir ferner die vom Internet-Service-Provider (ISP) vergebene IP-Adresse des von 
                                der betroffenen Person zum Zeitpunkt der Anmeldung verwendeten Computersystems sowie das Datum und die Uhrzeit der Anmeldung. 
                                Die Erhebung dieser Daten ist erforderlich, um den (möglichen) Missbrauch der E-Mail-Adresse einer betroffenen Person zu einem 
                                späteren Zeitpunkt nachvollziehen zu können und dient deshalb der rechtlichen Absicherung des für die Verarbeitung Verantwortlichen.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Die im Rahmen einer Anmeldung zum Newsletter erhobenen personenbezogenen Daten werden ausschließlich zum Versand unseres 
                                Newsletters verwendet. Ferner könnten Abonnenten des Newsletters per E-Mail informiert werden, sofern dies für den 
                                Betrieb des Newsletter-Dienstes oder eine diesbezügliche Registrierung erforderlich ist, wie dies im Falle von 
                                Änderungen am Newsletterangebot oder bei der Veränderung der technischen Gegebenheiten der Fall sein könnte. 
                                Es erfolgt keine Weitergabe der im Rahmen des Newsletter-Dienstes erhobenen personenbezogenen Daten an Dritte. 
                                Das Abonnement unseres Newsletters kann durch die betroffene Person jederzeit gekündigt werden. Die Einwilligung 
                                in die Speicherung personenbezogener Daten, die die betroffene Person uns für den Newsletterversand erteilt hat, 
                                kann jederzeit widerrufen werden. Zum Zwecke des Widerrufs der Einwilligung findet sich in jedem Newsletter ein 
                                entsprechender Link. Ferner besteht die Möglichkeit, sich jederzeit auch direkt auf der Internetseite des für die 
                                Verarbeitung Verantwortlichen vom Newsletterversand abzumelden oder dies dem für die Verarbeitung Verantwortlichen 
                                auf andere Weise mitzuteilen.
                            </Typography>
                        </Grid>
                        <Grid item sm={4}>
                            <Typography className={classes.dataSubHead}>14. Newsletter-Tracking</Typography>
                        </Grid>
                        <Grid item sm={8}>
                            <Typography className={classes.dataText}>
                                Die Newsletter der Q.met GmbH enthalten sogenannte Zählpixel. Ein Zählpixel ist eine Miniaturgrafik, die in solche E-Mails 
                                eingebettet wird, welche im HTML-Format versendet werden, um eine Logdatei-Aufzeichnung und eine Logdatei-Analyse zu 
                                ermöglichen. Dadurch kann eine statistische Auswertung des Erfolges oder Misserfolges von Online-Marketing-Kampagnen 
                                durchgeführt werden. Anhand des eingebetteten Zählpixels kann die Q.met GmbH erkennen, ob und wann eine E-Mail von einer 
                                betroffenen Person geöffnet wurde und welche in der E-Mail befindlichen Links von der betroffenen Person aufgerufen wurden.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Solche über die in den Newslettern enthaltenen Zählpixel erhobenen personenbezogenen Daten, werden von dem für die 
                                Verarbeitung Verantwortlichen gespeichert und ausgewertet, um den Newsletterversand zu optimieren und den Inhalt 
                                zukünftiger Newsletter noch besser den Interessen der betroffenen Person anzupassen. Diese personenbezogenen 
                                Daten werden nicht an Dritte weitergegeben. Betroffene Personen sind jederzeit berechtigt, die diesbezügliche 
                                gesonderte, über das Double-Opt-In-Verfahren abgegebene Einwilligungserklärung zu widerrufen. Nach einem Widerruf 
                                werden diese personenbezogenen Daten von dem für die Verarbeitung Verantwortlichen gelöscht. Eine Abmeldung vom 
                                Erhalt des Newsletters deutet die Q.met GmbH automatisch als Widerruf.
                            </Typography>
                        </Grid>
                        <Grid item sm={4}>
                            <Typography className={classes.dataSubHead}>15. Kontaktmöglichkeit über die Internetseite</Typography>
                        </Grid>
                        <Grid item sm={8}>
                            <Typography className={classes.dataText}>
                                Die Internetseite der Q.met GmbH enthält aufgrund von gesetzlichen Vorschriften Angaben, die eine schnelle 
                                elektronische Kontaktaufnahme zu unserem Unternehmen sowie eine unmittelbare Kommunikation mit uns ermöglichen, 
                                was ebenfalls eine allgemeine Adresse der sogenannten elektronischen Post (E-Mail-Adresse) umfasst. Sofern eine 
                                betroffene Person per E-Mail oder über ein Kontaktformular den Kontakt mit dem für die Verarbeitung 
                                Verantwortlichen aufnimmt, werden die von der betroffenen Person übermittelten personenbezogenen Daten 
                                automatisch gespeichert. Solche auf freiwilliger Basis von einer betroffenen Person an den für die Verarbeitung 
                                Verantwortlichen übermittelten personenbezogenen Daten werden für Zwecke der Bearbeitung oder der Kontaktaufnahme 
                                zur betroffenen Person gespeichert. Es erfolgt keine Weitergabe dieser personenbezogenen Daten an Dritte.
                            </Typography>
                        </Grid>
                        <Grid item sm={4}>
                            <Typography className={classes.dataSubHead}>16. Routinemäßige Löschung und Sperrung von personenbezogenen Daten</Typography>
                        </Grid>
                        <Grid item sm={8}>
                            <Typography className={classes.dataText}>
                                Der für die Verarbeitung Verantwortliche verarbeitet und speichert personenbezogene Daten der betroffenen 
                                Person nur für den Zeitraum, der zur Erreichung des Speicherungszwecks erforderlich ist oder sofern dies durch 
                                den Europäischen Richtlinien- und Verordnungsgeber oder einen anderen Gesetzgeber in Gesetzen oder Vorschriften, 
                                welchen der für die Verarbeitung Verantwortliche unterliegt, vorgesehen wurde.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Entfällt der Speicherungszweck oder läuft eine vom Europäischen Richtlinien- und Verordnungsgeber oder einem 
                                anderen zuständigen Gesetzgeber vorgeschriebene Speicherfrist ab, werden die personenbezogenen Daten 
                                routinemäßig und entsprechend den gesetzlichen Vorschriften gesperrt oder gelöscht.
                            </Typography>
                        </Grid>
                        <Grid item sm={4}>
                            <Typography className={classes.dataSubHead}>17. Rechte der betroffenen Person</Typography>
                        </Grid>
                        <Grid item sm={8}>
                            <Typography className={classes.dataText}>
                                <strong>a) Recht auf Bestätigung</strong>
                            </Typography>
                            <Typography className={classes.dataText}>
                                Jede betroffene Person hat das vom Europäischen Richtlinien- und Verordnungsgeber eingeräumte Recht, von dem für 
                                die Verarbeitung Verantwortlichen eine Bestätigung darüber zu verlangen, ob sie betreffende personenbezogene 
                                Daten verarbeitet werden. Möchte eine betroffene Person dieses Bestätigungsrecht in Anspruch nehmen, kann sie 
                                sich hierzu jederzeit an unseren Datenschutzbeauftragten oder einen anderen Mitarbeiter des für die 
                                Verarbeitung Verantwortlichen wenden.
                            </Typography>
                            <Typography className={classes.dataText}>
                                <strong>b) Recht auf Auskunft</strong>
                            </Typography>
                            <Typography className={classes.dataText}>
                                Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen Richtlinien- 
                                und Verordnungsgeber gewährte Recht, jederzeit von dem für die Verarbeitung Verantwortlichen unentgeltliche 
                                Auskunft über die zu seiner Person gespeicherten personenbezogenen Daten und eine Kopie dieser Auskunft 
                                zu erhalten. Ferner hat der Europäische Richtlinien- und Verordnungsgeber der betroffenen Person Auskunft 
                                über folgende Informationen zugestanden:
                            </Typography>
                            <ul>
                                <li>
                                    <Typography className={classes.dataText}>
                                        die Verarbeitungszwecke
                                    </Typography>
                                </li>
                                <li>
                                    <Typography className={classes.dataText}>
                                        die Kategorien personenbezogener Daten, die verarbeitet werden
                                    </Typography>
                                </li>
                                <li>
                                    <Typography className={classes.dataText}>
                                        die Empfänger oder Kategorien von Empfängern, gegenüber denen die personenbezogenen Daten offengelegt 
                                        worden sind oder noch offengelegt werden, insbesondere bei Empfängern in Drittländern oder bei 
                                        internationalen Organisationen
                                    </Typography>
                                </li>
                                <li>
                                    <Typography className={classes.dataText}>
                                        falls möglich die geplante Dauer, für die die personenbezogenen Daten gespeichert werden, oder, 
                                        falls dies nicht möglich ist, die Kriterien für die Festlegung dieser Dauer
                                    </Typography>
                                </li>
                                <li>
                                    <Typography className={classes.dataText}>
                                        das Bestehen eines Rechts auf Berichtigung oder Löschung der sie betreffenden personenbezogenen 
                                        Daten oder auf Einschränkung der Verarbeitung durch den Verantwortlichen oder eines 
                                        Widerspruchsrechts gegen diese Verarbeitung
                                    </Typography>
                                </li>
                                <li>
                                    <Typography className={classes.dataText}>
                                        das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde
                                    </Typography>
                                </li>
                                <li>
                                    <Typography className={classes.dataText}>
                                        wenn die personenbezogenen Daten nicht bei der betroffenen Person erhoben 
                                        werden: Alle verfügbaren Informationen über die Herkunft der Daten
                                    </Typography>
                                </li>
                                <li>
                                    <Typography className={classes.dataText}>
                                        das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling gemäß Artikel 22 Abs.1 
                                        und 4 DSGVO und – zumindest in diesen Fällen – aussagekräftige Informationen über die involvierte Logik 
                                        sowie die Tragweite und die angestrebten Auswirkungen einer derartigen Verarbeitung für die betroffene Person
                                    </Typography>
                                </li>
                            </ul>
                            <Typography className={classes.dataText}>
                                Ferner steht der betroffenen Person ein Auskunftsrecht darüber zu, ob personenbezogene Daten an ein Drittland 
                                oder an eine internationale Organisation übermittelt wurden. Sofern dies der Fall ist, so steht der betroffenen 
                                Person im Übrigen das Recht zu, Auskunft über die geeigneten Garantien im Zusammenhang mit der Übermittlung zu erhalten.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Möchte eine betroffene Person dieses Auskunftsrecht in Anspruch nehmen, kann sie sich hierzu jederzeit 
                                an unseren Datenschutzbeauftragten oder einen anderen Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.
                            </Typography>
                            <Typography className={classes.dataText}>
                                <strong>c) Recht auf Berichtigung</strong>
                            </Typography>
                            <Typography className={classes.dataText}>
                                Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das von Europäischen Richtlinien- und 
                                Verordnungsgeber gewährte Recht, die unverzügliche Berichtigung sie betreffender unrichtiger personenbezogener 
                                Daten zu verlangen. Ferner steht der betroffenen Person das Recht zu, unter Berücksichtigung der Zwecke der 
                                Verarbeitung, die Vervollständigung unvollständiger personenbezogener Daten – auch mittels einer ergänzenden 
                                Erklärung – zu verlangen.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Möchte eine betroffene Person dieses Berichtigungsrecht in Anspruch nehmen, kann sie sich hierzu jederzeit 
                                an unseren Datenschutzbeauftragten oder einen anderen Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.
                            </Typography>
                            <Typography className={classes.dataText}>
                                <strong>d) Recht auf Löschung (Recht auf Vergessen werden)</strong>
                            </Typography>
                            <Typography className={classes.dataText}>
                                Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen Richtlinien- und 
                                Verordnungsgeber gewährte Recht, von dem Verantwortlichen zu verlangen, dass die sie betreffenden 
                                personenbezogenen Daten unverzüglich gelöscht werden, sofern einer der folgenden Gründe zutrifft 
                                und soweit die Verarbeitung nicht erforderlich ist:
                            </Typography>
                            <Typography className={classes.dataText}>
                                * Die personenbezogenen Daten wurden für solche Zwecke erhoben oder auf sonstige Weise verarbeitet, 
                                für welche sie nicht mehr notwendig sind.
                            </Typography>
                            <Typography className={classes.dataText}>
                                * Die betroffene Person widerruft ihre Einwilligung, auf die sich die Verarbeitung gemäß Art. 6 Abs. 1 
                                Buchstabe a DSGVO oder Art. 9 Abs. 2 Buchstabe a DSGVO stützte, und es fehlt an einer anderweitigen 
                                Rechtsgrundlage für die Verarbeitung.
                            </Typography>
                            <Typography className={classes.dataText}>
                                * Die betroffene Person legt gemäß Art. 21 Abs. 1 DSGVO Widerspruch gegen die Verarbeitung ein, und es 
                                liegen keine vorrangigen berechtigten Gründe für die Verarbeitung vor, oder die betroffene Person legt 
                                gemäß Art. 21 Abs. 2 DSGVO Widerspruch gegen die Verarbeitung ein.
                            </Typography>
                            <Typography className={classes.dataText}>
                                * Die personenbezogenen Daten wurden unrechtmäßig verarbeitet.
                            </Typography>
                            <Typography className={classes.dataText}>
                                * Die Löschung der personenbezogenen Daten ist zur Erfüllung einer rechtlichen Verpflichtung nach dem 
                                Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem der Verantwortliche unterliegt.
                            </Typography>
                            <Typography className={classes.dataText}>
                                * Die personenbezogenen Daten wurden in Bezug auf angebotene Dienste der Informationsgesellschaft gemäß Art. 8 Abs. 1 DSGVO erhoben.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Sofern einer der oben genannten Gründe zutrifft und eine betroffene Person die Löschung von personenbezogenen Daten, 
                                die bei der Q.met GmbH gespeichert sind, veranlassen möchte, kann sie sich hierzu jederzeit an unseren 
                                Datenschutzbeauftragten oder einen anderen Mitarbeiter des für die Verarbeitung Verantwortlichen wenden. 
                                Der Datenschutzbeauftragte der Q.met GmbH oder ein anderer Mitarbeiter wird veranlassen, dass dem 
                                Löschverlangen unverzüglich nachgekommen wird.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Wurden die personenbezogenen Daten von der Q.met GmbH öffentlich gemacht und ist unser Unternehmen als 
                                Verantwortlicher gemäß Art. 17 Abs. 1 DSGVO zur Löschung der personenbezogenen Daten verpflichtet, so 
                                trifft die Q.met GmbH unter Berücksichtigung der verfügbaren Technologie und der Implementierungskosten 
                                angemessene Maßnahmen, auch technischer Art, um andere für die Datenverarbeitung Verantwortliche, welche 
                                die veröffentlichten personenbezogenen Daten verarbeiten, darüber in Kenntnis zu setzen, dass die betroffene 
                                Person von diesen anderen für die Datenverarbeitung Verantwortlichen die Löschung sämtlicher Links zu diesen 
                                personenbezogenen Daten oder von Kopien oder Replikationen dieser personenbezogenen Daten verlangt hat, 
                                soweit die Verarbeitung nicht erforderlich ist. Der Datenschutzbeauftragte der Q.met GmbH oder ein anderer 
                                Mitarbeiter wird im Einzelfall das Notwendige veranlassen.
                            </Typography>
                            <Typography className={classes.dataText}>
                                <strong>e) Recht auf Einschränkung der Verarbeitung</strong>
                            </Typography>
                            <Typography className={classes.dataText}>
                                Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen Richtlinien- 
                                und Verordnungsgeber gewährte Recht, von dem Verantwortlichen die Einschränkung der Verarbeitung zu verlangen, 
                                wenn eine der folgenden Voraussetzungen gegeben ist:
                            </Typography>
                            <Typography className={classes.dataText}>
                                Die Richtigkeit der personenbezogenen Daten wird von der betroffenen Person bestritten, und zwar für eine Dauer, 
                                die es dem Verantwortlichen ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Die Verarbeitung ist unrechtmäßig, die betroffene Person lehnt die Löschung der personenbezogenen Daten ab und 
                                verlangt stattdessen die Einschränkung der Nutzung der personenbezogenen Daten.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Der Verantwortliche benötigt die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger, die betroffene 
                                Person benötigt sie jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Die betroffene Person hat Widerspruch gegen die Verarbeitung gem. Art. 21 Abs. 1 DSGVO eingelegt und es steht noch 
                                nicht fest, ob die berechtigten Gründe des Verantwortlichen gegenüber denen der betroffenen Person überwiegen.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Sofern eine der oben genannten Voraussetzungen gegeben ist und eine betroffene Person die Einschränkung von 
                                personenbezogenen Daten, die bei der Q.met GmbH gespeichert sind, verlangen möchte, kann sie sich hierzu jederzeit 
                                an unseren Datenschutzbeauftragten oder einen anderen Mitarbeiter des für die Verarbeitung Verantwortlichen wenden. 
                                Der Datenschutzbeauftragte der Q.met GmbH oder ein anderer Mitarbeiter wird die Einschränkung der Verarbeitung veranlassen.
                            </Typography>
                            <Typography className={classes.dataText}>
                                <strong>f) Recht auf Datenübertragbarkeit</strong>
                            </Typography>
                            <Typography className={classes.dataText}>
                                Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen Richtlinien- und 
                                Verordnungsgeber gewährte Recht, die sie betreffenden personenbezogenen Daten, welche durch die betroffene 
                                Person einem Verantwortlichen bereitgestellt wurden, in einem strukturierten, gängigen und maschinenlesbaren 
                                Format zu erhalten. Sie hat außerdem das Recht, diese Daten einem anderen Verantwortlichen ohne Behinderung 
                                durch den Verantwortlichen, dem die personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern die 
                                Verarbeitung auf der Einwilligung gemäß Art. 6 Abs. 1 Buchstabe a DSGVO oder Art. 9 Abs. 2 Buchstabe a DSGVO 
                                oder auf einem Vertrag gemäß Art. 6 Abs. 1 Buchstabe b DSGVO beruht und die Verarbeitung mithilfe automatisierter 
                                Verfahren erfolgt, sofern die Verarbeitung nicht für die Wahrnehmung einer Aufgabe erforderlich ist, die im 
                                öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, welche dem Verantwortlichen 
                                übertragen wurde.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Ferner hat die betroffene Person bei der Ausübung ihres Rechts auf Datenübertragbarkeit gemäß Art. 
                                20 Abs. 1 DSGVO das Recht, zu erwirken, dass die personenbezogenen Daten direkt von einem Verantwortlichen 
                                an einen anderen Verantwortlichen übermittelt werden, soweit dies technisch machbar ist und sofern hiervon 
                                nicht die Rechte und Freiheiten anderer Personen beeinträchtigt werden.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Zur Geltendmachung des Rechts auf Datenübertragbarkeit kann sich die betroffene Person jederzeit an 
                                den von der Q.met GmbH bestellten Datenschutzbeauftragten oder einen anderen Mitarbeiter wenden.
                            </Typography>
                            <Typography className={classes.dataText}>
                                <strong>g) Recht auf Widerspruch</strong>
                            </Typography>
                            <Typography className={classes.dataText}>
                                Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen Richtlinien- und 
                                Verordnungsgeber gewährte Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, jederzeit gegen 
                                die Verarbeitung sie betreffender personenbezogener Daten, die aufgrund von Art. 6 Abs. 1 Buchstaben e oder f 
                                DSGVO erfolgt, Widerspruch einzulegen. Dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Die Q.met GmbH verarbeitet die personenbezogenen Daten im Falle des Widerspruchs nicht mehr, es sei denn, wir 
                                können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die den Interessen, Rechten und Freiheiten 
                                der betroffenen Person überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung 
                                von Rechtsansprüchen.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Verarbeitet die Q.met GmbH personenbezogene Daten, um Direktwerbung zu betreiben, so hat die betroffene Person 
                                das Recht, jederzeit Widerspruch gegen die Verarbeitung der personenbezogenen Daten zum Zwecke derartiger 
                                Werbung einzulegen. Dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht. 
                                Widerspricht die betroffene Person gegenüber der Q.met GmbH der Verarbeitung für Zwecke der Direktwerbung, 
                                so wird die Q.met GmbH die personenbezogenen Daten nicht mehr für diese Zwecke verarbeiten.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Zudem hat die betroffene Person das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, 
                                gegen die sie betreffende Verarbeitung personenbezogener Daten, die bei der Q.met GmbH zu wissenschaftlichen 
                                oder historischen Forschungszwecken oder zu statistischen Zwecken gemäß Art. 89 Abs. 1 DSGVO erfolgen, 
                                Widerspruch einzulegen, es sei denn, eine solche Verarbeitung ist zur Erfüllung einer im öffentlichen 
                                Interesse liegenden Aufgabe erforderlich.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Zur Ausübung des Rechts auf Widerspruch kann sich die betroffene Person direkt an den Datenschutzbeauftragten 
                                der Q.met GmbH oder einen anderen Mitarbeiter wenden. Der betroffenen Person steht es ferner frei, im 
                                Zusammenhang mit der Nutzung von Diensten der Informationsgesellschaft, ungeachtet der Richtlinie 2002/58/EG, 
                                ihr Widerspruchsrecht mittels automatisierter Verfahren auszuüben, bei denen technische Spezifikationen 
                                verwendet werden.
                            </Typography>
                            <Typography className={classes.dataText}>
                                <strong>h) Automatisierte Entscheidungen im Einzelfall einschließlich Profiling</strong>
                            </Typography>
                            <Typography className={classes.dataText}>
                                Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen 
                                Richtlinien- und Verordnungsgeber gewährte Recht, nicht einer ausschließlich auf einer 
                                automatisierten Verarbeitung – einschließlich Profiling – beruhenden Entscheidung unterworfen 
                                zu werden, die ihr gegenüber rechtliche Wirkung entfaltet oder sie in ähnlicher Weise erheblich 
                                beeinträchtigt, sofern die Entscheidung (1) nicht für den Abschluss oder die Erfüllung eines 
                                Vertrags zwischen der betroffenen Person und dem Verantwortlichen erforderlich ist, oder 
                                (2) aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten, denen der Verantwortliche 
                                unterliegt, zulässig ist und diese Rechtsvorschriften angemessene Maßnahmen zur Wahrung 
                                der Rechte und Freiheiten sowie der berechtigten Interessen der betroffenen Person enthalten 
                                oder (3) mit ausdrücklicher Einwilligung der betroffenen Person erfolgt.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Ist die Entscheidung (1) für den Abschluss oder die Erfüllung eines Vertrags zwischen der 
                                betroffenen Person und dem Verantwortlichen erforderlich oder (2) erfolgt sie mit ausdrücklicher 
                                Einwilligung der betroffenen Person, trifft die Q.met GmbH angemessene Maßnahmen, um die 
                                Rechte und Freiheiten sowie die berechtigten Interessen der betroffenen Person zu wahren, 
                                wozu mindestens das Recht auf Erwirkung des Eingreifens einer Person seitens des 
                                Verantwortlichen, auf Darlegung des eigenen Standpunkts und auf Anfechtung der Entscheidung gehört.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Möchte die betroffene Person Rechte mit Bezug auf automatisierte Entscheidungen geltend machen, 
                                kann sie sich hierzu jederzeit an unseren Datenschutzbeauftragten oder einen anderen Mitarbeiter 
                                des für die Verarbeitung Verantwortlichen wenden.
                            </Typography>
                            <Typography className={classes.dataText}>
                                <strong>i) Recht auf Widerruf einer datenschutzrechtlichen Einwilligung</strong>
                            </Typography>
                            <Typography className={classes.dataText}>
                                Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen 
                                Richtlinien- und Verordnungsgeber gewährte Recht, eine Einwilligung zur Verarbeitung personenbezogener 
                                Daten jederzeit zu widerrufen.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Möchte die betroffene Person ihr Recht auf Widerruf einer Einwilligung geltend machen, kann sie sich 
                                hierzu jederzeit an unseren Datenschutzbeauftragten oder einen anderen Mitarbeiter des für 
                                die Verarbeitung Verantwortlichen wenden.
                            </Typography>
                        </Grid>
                        <Grid item sm={4}>
                            <Typography className={classes.dataSubHead}>18. Datenschutz bei Bewerbungen und im Bewerbungsverfahren</Typography>
                        </Grid>
                        <Grid item sm={8}>
                            <Typography className={classes.dataText}>
                                Der für die Verarbeitung Verantwortliche erhebt und verarbeitet die personenbezogenen Daten von 
                                Bewerbern zum Zwecke der Abwicklung des Bewerbungsverfahrens. Die Verarbeitung kann auch auf 
                                elektronischem Wege erfolgen. Dies ist insbesondere dann der Fall, wenn ein Bewerber entsprechende 
                                Bewerbungsunterlagen auf dem elektronischen Wege, beispielsweise per E-Mail oder über ein auf der 
                                Internetseite befindliches Webformular, an den für die Verarbeitung Verantwortlichen übermittelt. 
                                Schließt der für die Verarbeitung Verantwortliche einen Anstellungsvertrag mit einem Bewerber, 
                                werden die übermittelten Daten zum Zwecke der Abwicklung des Beschäftigungsverhältnisses unter 
                                Beachtung der gesetzlichen Vorschriften gespeichert. Wird von dem für die Verarbeitung 
                                Verantwortlichen kein Anstellungsvertrag mit dem Bewerber geschlossen, so werden die 
                                Bewerbungsunterlagen zwei Monate nach Bekanntgabe der Absageentscheidung automatisch gelöscht, 
                                sofern einer Löschung keine sonstigen berechtigten Interessen des für die Verarbeitung 
                                Verantwortlichen entgegenstehen. Sonstiges berechtigtes Interesse in diesem Sinne ist 
                                beispielsweise eine Beweispflicht in einem Verfahren nach dem Allgemeinen 
                                Gleichbehandlungsgesetz (AGG).
                            </Typography>
                        </Grid>
                        <Grid item sm={4}>
                            <Typography className={classes.dataSubHead}>19. Rechtsgrundlage der Verarbeitung</Typography>
                        </Grid>
                        <Grid item sm={8}>
                            <Typography className={classes.dataText}>
                                Art. 6 I lit. a DSGVO dient unserem Unternehmen als Rechtsgrundlage für Verarbeitungsvorgänge, 
                                bei denen wir eine Einwilligung für einen bestimmten Verarbeitungszweck einholen. Ist die 
                                Verarbeitung personenbezogener Daten zur Erfüllung eines Vertrags, dessen Vertragspartei 
                                die betroffene Person ist, erforderlich, wie dies beispielsweise bei Verarbeitungsvorgängen 
                                der Fall ist, die für eine Lieferung von Waren oder die Erbringung einer sonstigen Leistung 
                                oder Gegenleistung notwendig sind, so beruht die Verarbeitung auf Art. 6 I lit. b DSGVO. 
                                Gleiches gilt für solche Verarbeitungsvorgänge die zur Durchführung vorvertraglicher 
                                Maßnahmen erforderlich sind, etwa in Fällen von Anfragen zur unseren Produkten oder 
                                Leistungen. Unterliegt unser Unternehmen einer rechtlichen Verpflichtung durch welche 
                                eine Verarbeitung von personenbezogenen Daten erforderlich wird, wie beispielsweise 
                                zur Erfüllung steuerlicher Pflichten, so basiert die Verarbeitung auf Art. 6 I lit. c DSGVO. 
                                In seltenen Fällen könnte die Verarbeitung von personenbezogenen Daten erforderlich werden, 
                                um lebenswichtige Interessen der betroffenen Person oder einer anderen natürlichen Person 
                                zu schützen. Dies wäre beispielsweise der Fall, wenn ein Besucher in unserem Betrieb verletzt 
                                werden würde und daraufhin sein Name, sein Alter, seine Krankenkassendaten oder sonstige 
                                lebenswichtige Informationen an einen Arzt, ein Krankenhaus oder sonstige Dritte 
                                weitergegeben werden müssten. Dann würde die Verarbeitung auf Art. 6 I lit. d DSGVO beruhen. 
                                Letztlich könnten Verarbeitungsvorgänge auf Art. 6 I lit. f DSGVO beruhen. Auf dieser 
                                Rechtsgrundlage basieren Verarbeitungsvorgänge, die von keiner der vorgenannten 
                                Rechtsgrundlagen erfasst werden, wenn die Verarbeitung zur Wahrung eines berechtigten 
                                Interesses unseres Unternehmens oder eines Dritten erforderlich ist, sofern die 
                                Interessen, Grundrechte und Grundfreiheiten des Betroffenen nicht überwiegen. 
                                Solche Verarbeitungsvorgänge sind uns insbesondere deshalb gestattet, weil sie 
                                durch den Europäischen Gesetzgeber besonders erwähnt wurden. Er vertrat insoweit 
                                die Auffassung, dass ein berechtigtes Interesse anzunehmen sein könnte, wenn die 
                                betroffene Person ein Kunde des Verantwortlichen ist (Erwägungsgrund 47 Satz 2 DSGVO).
                            </Typography>
                        </Grid>
                        <Grid item sm={4}>
                            <Typography className={classes.dataSubHead}>20. Berechtigte Interessen an der Verarbeitung, 
                            die von dem Verantwortlichen oder einem Dritten verfolgt werden</Typography>
                        </Grid>
                        <Grid item sm={8}>
                            <Typography className={classes.dataText}>
                                Basiert die Verarbeitung personenbezogener Daten auf Artikel 6 I lit. f DSGVO ist unser 
                                berechtigtes Interesse die Durchführung unserer Geschäftstätigkeit zugunsten des 
                                Wohlergehens all unserer Mitarbeiter und unserer Gesellschafter.
                            </Typography>
                        </Grid>
                        <Grid item sm={4}>
                            <Typography className={classes.dataSubHead}>21. Dauer, für die die personenbezogenen Daten gespeichert werden</Typography>
                        </Grid>
                        <Grid item sm={8}>
                            <Typography className={classes.dataText}>
                                Das Kriterium für die Dauer der Speicherung von personenbezogenen Daten ist die jeweilige 
                                gesetzliche Aufbewahrungsfrist. Nach Ablauf der Frist werden die entsprechenden Daten 
                                routinemäßig gelöscht, sofern sie nicht mehr zur Vertragserfüllung oder Vertragsanbahnung 
                                erforderlich sind.
                            </Typography>
                        </Grid>
                        <Grid item sm={4}>
                            <Typography className={classes.dataSubHead}>22. Gesetzliche oder vertragliche Vorschriften zur Bereitstellung 
                            der personenbezogenen Daten; Erforderlichkeit für den Vertragsabschluss; Verpflichtung der betroffenen Person, 
                            die personenbezogenen Daten bereitzustellen; mögliche Folgen der Nichtbereitstellung</Typography>
                        </Grid>
                        <Grid item sm={8}>
                            <Typography className={classes.dataText}>
                                Wir klären Sie darüber auf, dass die Bereitstellung personenbezogener Daten zum Teil gesetzlich 
                                vorgeschrieben ist (z.B. Steuervorschriften) oder sich auch aus vertraglichen Regelungen 
                                (z.B. Angaben zum Vertragspartner) ergeben kann. Mitunter kann es zu einem Vertragsschluss 
                                erforderlich sein, dass eine betroffene Person uns personenbezogene Daten zur Verfügung stellt, 
                                die in der Folge durch uns verarbeitet werden müssen. Die betroffene Person ist beispielsweise 
                                verpflichtet uns personenbezogene Daten bereitzustellen, wenn unser Unternehmen mit ihr einen 
                                Vertrag abschließt. Eine Nichtbereitstellung der personenbezogenen Daten hätte zur Folge, 
                                dass der Vertrag mit dem Betroffenen nicht geschlossen werden könnte. Vor einer Bereitstellung 
                                personenbezogener Daten durch den Betroffenen muss sich der Betroffene an unseren 
                                Datenschutzbeauftragten wenden. Unser Datenschutzbeauftragter klärt den Betroffenen einzelfallbezogen 
                                darüber auf, ob die Bereitstellung der personenbezogenen Daten gesetzlich oder vertraglich 
                                vorgeschrieben oder für den Vertragsabschluss erforderlich ist, ob eine Verpflichtung besteht, 
                                die personenbezogenen Daten bereitzustellen, und welche Folgen die Nichtbereitstellung der 
                                personenbezogenen Daten hätte.
                            </Typography>
                        </Grid>
                        <Grid item sm={4}>
                            <Typography className={classes.dataSubHead}>23. Bestehen einer automatisierten Entscheidungsfindung</Typography>
                        </Grid>
                        <Grid item sm={8}>
                            <Typography className={classes.dataText}>
                                Als verantwortungsbewusstes Unternehmen verzichten wir auf eine automatische Entscheidungsfindung oder ein Profiling.
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            </Container>
        </Fragment>
    );
});

export default Datenschutz;