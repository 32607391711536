import React, {memo} from 'react';
import { makeStyles } from '@material-ui/core/styles';

import QmetLogo from '../../../Assets/images/qmet-Logo-w.png';

const useStyles = makeStyles((theme) => ({
    headerParent: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        alignItems:'center',
        padding:'6px 15px'
    },
    qmetLogo:{
        height:'6vh'
    },
    branding:{
        color: '#fff',
        textAlign:'right',
    },
    productName:{
        fontSize: '2.2rem',
        fontWeight: '300'
    },
    kundenNummer:{
        lineHeight:'1',
        fontSize: '.9rem',
    }
}));

const Header = memo((props) => {

    const classes = useStyles();

    return (
        <header>
            <div className={classes.headerParent}>
                <div>
                    <img src={QmetLogo} alt="Qmet-Logo" className={classes.qmetLogo}/>
                </div>
                {/*<div>Link</div>*/}
                <div className={classes.branding}>
                    <div className={classes.productName}>Risk<strong>Control</strong></div>
                    <div className={classes.kundenNummer}>Kundennummer: 1234-5678</div>
                </div>
            </div>
        </header>
    );
});

export default Header;