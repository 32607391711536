import React, { Fragment, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Typography,
    Grid,
    Divider,
    Button,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';
import KeyboardArrowRightOutlinedIcon from '@material-ui/icons/KeyboardArrowRightOutlined';
import LocationSearchingOutlinedIcon from '@material-ui/icons/LocationSearchingOutlined';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import {Helmet} from "react-helmet";
import ReactGA from "react-ga4";

const useStyles = makeStyles((theme) => ({
    pageHeader: {
        textAlign: 'left',
        fontSize: '2rem',
        fontWeight: '400',
        fontFamily: '"Exo 2", sans-serif',
        lineHeight: '1',
        color: 'rgba(0,0,0,.9)',
    },
    pageSubHeader: {
        textAlign: 'left',
        fontSize: '1rem',
        fontWeight: '400',
        fontFamily: '"Exo 2", sans-serif',
        color: 'rgba(0,0,0,.8)',
    },
    sectDivider: {
        marginTop: '20px',
        marginBottom: '35px'
    },
    pageLabel: {
        textAlign: 'left',
        fontSize: '1rem',
        fontWeight: '500',
        fontFamily: '"Exo 2", sans-serif',
        color: 'rgba(0,0,0,.8)',
    },
    formButton: {
        color: '#fff',
        fontFamily: '"Exo 2", sans-serif',
        textTransform: 'none',
        borderRadius: '25px',
        minWidth: '150px',
        '&:hover': {
            backgroundColor: '#0098d6'
        }
    },
    prufenItem: {
        backgroundColor: '#009fe0',
        color: '#fff',
        borderRadius: '5px',
        padding: '15px',
        boxShadow: '0 0 13px 0 rgb(82 63 105 / 5%)',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        minHeight: '25vh'
    },
    prufenHead: {
        fontWeight: '500',
        fontFamily: '"Exo 2", sans-serif',
        fontSize: '1.5rem',
        marginBottom: '.5rem'
    },
    prufenData: {
        paddingTop: '0px',
        paddingBottom: '0px',
        '& .MuiListItem-root': {
            paddingLeft: '0px',
            paddingRight: '0px',
            paddingTop: '0px',
            paddingBottom: '5px',
            alignItems: 'flex-start',
        },
        '& .MuiListItemIcon-root': {
            minWidth: '30px',
            color: 'rgba(255, 255, 255, .7)',
            paddingTop: '4px'
        },
        '& .MuiTypography-body2': {
            fontWeight: '400 !important',
            fontFamily: '"Exo 2", sans-serif !important',
            fontSize: '1rem !important',
            color: '#fff'
        }
    },
    prufenItemAndernSect: {
        textAlign: 'right'
    },
    buttonAndern: {
        color: '#fff',
        textTransform: 'none',
        fontSize: '1rem',
        fontFamily: '"Exo 2", sans-serif',
        fontWeight: '400',
        padding: '0px',
        '&:hover': {
            textDecoration: 'underline',
        }
    }
}));

const PrufenAnfordenComponent = memo((props) => {

    const classes = useStyles();

    const getDayOfWeek = (argDate) => {
        try {
            let date = new Date(argDate);
            return date.toLocaleDateString('de-DE', { weekday: 'long' })
        } catch (error) {
            if(props.isClient){
                ReactGA.event("rc_prufenanforden", {
                    rc_prufenanforden_error: "202208111145" + error
                });
            }
        }
    }

    const getDate = (argDate) => {
        try {
            let date = new Date(argDate);
            return date.toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' })
        } catch (error) {
            console.log('202109021549', error);
            if(props.isClient){
                ReactGA.event("rc_prufenanforden", {
                    rc_prufenanforden_error: "202208111146" + error
                });
            }
        }
    }

    const getTime = (argDate) => {
        try {
            let date = new Date(argDate);
            return date.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' })
        } catch (error) {
            if(props.isClient){
                ReactGA.event("rc_prufenanforden", {
                    rc_prufenanforden_error: "202208111147" + error
                });
            }
        }
    }

    const WetterauskunftAnfordern = () => {
        try{
            props.setWetterauskunftActive();
            if (props.isClient) {
                ReactGA.event("rc_prufenanforden_wetterauskunftanforden_btnclick", {
                    rc_wetterauskunftanforden_btnclick: "clicked"
                });
            }
        }catch(error){
            if (props.isClient) {
                ReactGA.event("rc_prufenanforden", {
                    rc_prufenanforden_error: "202208111148" + error
                });
            }
        }
    }

    let locationDetailsOrganizer = null;

    try{
        if (props.selLange !== null && props.selBreite !== null && !isNaN(props.selLange) && !isNaN(props.selBreite)){
            locationDetailsOrganizer = (
                    <ListItem>
                        <ListItemIcon>
                            <LocationSearchingOutlinedIcon fontSize='small' />
                        </ListItemIcon>
                        <ListItemText primary={
                            <span>
                                Geogr. Länge: &nbsp; {!isNaN(props.selLange) ? props.selLange : null}<br />
                                Geogr. Breite: &nbsp; {!isNaN(props.selBreite) ? props.selBreite : null}
                            </span>
                        } />
                    </ListItem>
            );
        }else{
            if (props.selLocationByLocation && props.selLocationByLocation.name){
                locationDetailsOrganizer = (
                    <ListItem>
                        <ListItemIcon>
                            <RoomOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary={
                            <span>
                                {props.strasse ? <React.Fragment><span>{props.strasse.toString()}</span>&nbsp;</React.Fragment> : null}
                                {props.houseNummer ? <React.Fragment><span>{props.houseNummer.toString()}</span><br /></React.Fragment> : null}
                                {props.selLocationByLocation ? props.selLocationByLocation.postal_code ? <React.Fragment><span>{props.selLocationByLocation.postal_code.toString()}</span>&nbsp;</React.Fragment> : null : null}
                                {props.selLocationByLocation ? props.selLocationByLocation.name ? props.selLocationByLocation.name.toString() : null : null}
                            </span>
                        } />
                    </ListItem>
                );
            }
        }
    }catch(error){
        if (props.isClient) {
            ReactGA.event("rc_prufenanforden", {
                rc_prufenanforden_error: "202208111149" + error
            });
        }else{
            console.log("202208111149", error);
        }
    }

    return (
        <Fragment>
            <Helmet>
                <title>Q.met Risk Control - Prüfen &nbsp; Anfordern</title>
            </Helmet>
            <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                <Grid item sm={12}>
                    <Typography variant="h3" component="h3" className={classes.pageHeader}>Prüfen &amp; Anfordern</Typography>
                    <Typography variant="h6" component="h6" className={classes.pageSubHeader}>Bitte überprüfen Sie Ihre Angaben</Typography>
                </Grid>
                <Grid item sm={12} className='HideOnMobile'>
                    <Divider className={classes.sectDivider} />
                </Grid>
            </Grid>
            <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="flex-start">
                <Grid item md={4} sm={12} xs={12}>
                    <div className={classes.prufenItem}>
                        <div className={classes.prufenItemDataSect}>
                            <Typography variant="h4" component="h4" className={classes.prufenHead}>
                                Ort
                            </Typography>
                            <List className={classes.prufenData} dense>
                                {locationDetailsOrganizer}
                            </List>
                        </div>
                        <div className={classes.prufenItemAndernSect}>
                            <Button
                                color="primary"
                                className={classes.buttonAndern}
                                startIcon={<KeyboardArrowRightOutlinedIcon />}
                                onClick={props.setAuskunftsdatenActive}>
                                ändern
                            </Button>
                        </div>
                    </div>
                </Grid>
                <Grid item md={4} sm={12} xs={12}>
                    <div className={classes.prufenItem}>
                        <div className={classes.prufenItemDataSect}>
                            <Typography variant="h4" component="h4" className={classes.prufenHead}>
                                Zeit
                            </Typography>
                            <List className={classes.prufenData} dense>
                                <ListItem>
                                    <ListItemIcon>
                                        <DateRangeOutlinedIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={<span>von {props.startDate ? getDayOfWeek(props.startDate) : "-"}, den<br />{props.startDate ? getDate(props.startDate) : "-"}, {props.startDate ? getTime(props.startDate) : "-"} Uhr</span>} />
                                </ListItem>
                            </List>
                            <List className={classes.prufenData} dense>
                                <ListItem>
                                    <ListItemIcon>
                                        <DateRangeOutlinedIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={<span>bis {props.startDate ? getDayOfWeek(props.endDate) : "-"}, den<br />{props.startDate ? getDate(props.endDate) : "-"}, {props.startDate ? getTime(props.endDate) : "-"} Uhr</span>} />
                                </ListItem>
                            </List>
                        </div>
                        <div className={classes.prufenItemAndernSect}>
                            <Button
                                color="primary"
                                className={classes.buttonAndern}
                                startIcon={<KeyboardArrowRightOutlinedIcon />}
                                onClick={props.setAuskunftsdatenActive}>
                                ändern
                            </Button>
                        </div>
                    </div>
                </Grid>
                <Grid item md={4} sm={12} xs={12}>
                    <div className={classes.prufenItem}>
                        <div className={classes.prufenItemDataSect}>
                            <Typography variant="h4" component="h4" className={classes.prufenHead}>
                                Wetterereignis
                            </Typography>

                            {props.temperaturChecked ?
                                <List className={classes.prufenData} dense>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckBoxOutlinedIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={'Temperatur / Frost'} />
                                    </ListItem>
                                </List>
                                : null}

                            {props.GlatteFrostChecked ?
                                <List className={classes.prufenData} dense>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckBoxOutlinedIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={'Glätte'} />
                                    </ListItem>
                                </List>
                                : null}

                            {props.NiederschlagsmengeChecked ?
                                <List className={classes.prufenData} dense>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckBoxOutlinedIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={'Niederschlagsmenge'} />
                                    </ListItem>
                                </List>
                                : null}

                            {props.WindgeschwindigkeitChecked ?
                                <List className={classes.prufenData} dense>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckBoxOutlinedIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={'Windgeschwindigkeit'} />
                                    </ListItem>
                                </List>
                                : null}

                            {props.HagelChecked ?
                                <List className={classes.prufenData} dense>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckBoxOutlinedIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={'Hagel'} />
                                    </ListItem>
                                </List>
                                : null}

                            {props.BlitzChecked ?
                                <List className={classes.prufenData} dense>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckBoxOutlinedIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={'Blitz'} />
                                    </ListItem>
                                </List>
                                : null}

                            {props.SichtLichtverhaltnisseChecked ?
                                <List className={classes.prufenData} dense>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckBoxOutlinedIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={'Sicht/Lichtverhältnisse'} />
                                    </ListItem>
                                </List>
                                : null}

                        </div>
                        <div className={classes.prufenItemAndernSect}>
                            <Button
                                color="primary"
                                className={classes.buttonAndern}
                                startIcon={<KeyboardArrowRightOutlinedIcon />}
                                onClick={props.setAuskunftsdatenActive}>
                                ändern
                            </Button>
                        </div>
                    </div>
                </Grid>
                <Grid item sm={12}>
                    <Divider className={classes.sectDivider} />
                </Grid>
            </Grid>
            <Grid container spacing={2} direction="row" justifyContent="flex-end" alignItems="center">
                <Grid item sm={12} style={{ textAlign: 'right' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.formButton}
                        startIcon={<KeyboardArrowRightOutlinedIcon />}
                        onClick={WetterauskunftAnfordern}>
                        Wetterauskunft anfordern
                    </Button>
                </Grid>
            </Grid>
        </Fragment>
    );
});

export default PrufenAnfordenComponent;