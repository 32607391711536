import React, { memo, Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import axios from 'axios';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import ReactGA from "react-ga4";

import AuskunftsdatenComponent from '../AuskunftsdatenComponent/AuskunftsdatenComponent';
import PrufenAnfordenComponent from '../PrufenAnfordenComponent/PrufenAnfordenComponent';
import WetterauskunftComponent from '../Wetterauskunft/Wetterauskunft';

const useStyles = makeStyles((theme) => ({
    stepperLeftContainer: {
        padding: '20px 20px',
        backgroundColor: '#fff',
        marginTop: '15px',
        borderRadius: '5px',
        boxShadow: '0 0 13px 0 rgb(82 63 105 / 5%)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        textAlign: 'left',
    },
    stepperRightContainer: {
        padding: '20px 20px',
        backgroundColor: '#fff',
        marginTop: '15px',
        borderRadius: '5px',
        boxShadow: '0 0 13px 0 rgb(82 63 105 / 5%)',
        minHeight: '50%'
    },
    stepperLeftSectTop: {
        width: '100%'
    },
    OptionItem: {
        display: 'flex',
        borderBottom: '1px solid rgba(0,0,0,.125)',
        alignItems: 'center',
        padding: '8px 0px',
        fontWeight: '400',
        color: 'rgba(0,0,0,.8)',
        cursor: 'pointer',
        '&:last-child': {
            borderBottom: '1px solid rgba(0,0,0,0)',
        }
    },
    optionNumber: {
        border: '2px solid  rgba(0,0,0,.25)',
        borderRadius: '50%',
        width: '25px',
        height: '25px',
        padding: '8px',
        textAlign: 'center',
        fontSize: '1rem',
        lineHeight: '1.5',
        marginRight: '10px',
        color: 'rgba(0,0,0,.5)',
        fontWeight: '500',
    },
    optionText: {
        flexGrow: '1'
    },
    finishIcons: {
        color: 'transparent'
    },
    OptionItemSelected: {
        display: 'flex',
        borderBottom: '1px solid rgba(0,0,0,.125)',
        alignItems: 'center',
        padding: '8px 0px',
        fontWeight: '500',
        color: 'rgba(0,0,0,.8)',
        '&:last-child': {
            borderBottom: '1px solid rgba(0,0,0,0)',
        }
    },
    optionNumberSelected: {
        border: '2px solid  #009fe0',
        borderRadius: '50%',
        width: '25px',
        height: '25px',
        padding: '8px',
        textAlign: 'center',
        fontSize: '1rem',
        lineHeight: '1.5',
        marginRight: '10px',
        color: 'rgba(255,255,255,1)',
        fontWeight: '600',
        backgroundColor: '#009fe0',
        cursor: 'default',
    },
    finishIconsValid: {
        color: '#00E676'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const StepperComponent = memo((props) => {

    const classes = useStyles();

    const [hookActivePage, setActivePage] = useState('Auskunftsdaten');
    const [hookAuskunftsdatenValid, setAuskunftsdatenValid] = useState(false);
    const [hookPrufenAnfordernValid, setPrufenAnfordernValid] = useState(false);
    const [hookWetterauskunftValid, setWetterauskunftValid] = useState(false);
    const [hookLocations, setLocations] = useState([]);
    const [hookOrt, setOrt] = useState(null);
    const [hookOrtError, setOrtError] = useState({ error: false, errorMessage: "" });
    /*const [hookLatLonOrt, setLatLonOrt] = useState(null);*/
    const [hookStrasse, setStrasse] = useState("");
    const [hookStrasseError, setStrasseError] = useState({ error: false, errorMessage: "" });
    const [hookHausnummer, setHausnummer] = useState("");
    const [hookHausnummerError, setHausnummerError] = useState({ error: false, errorMessage: "" });
    const [hookStartDatumZeit, setStartDatumZeit] = useState(null);
    const [hookStartDatumError, setStartDatumError] = useState({ error: false, errorMessage: "" });
    const [hookEndDatumZeit, setEndDatumZeit] = useState(null);
    const [hookEndDatumError, setEndDatumError] = useState({ error: false, errorMessage: "" });
    const [hookTemperatur, setTemperatur] = useState(false);
    const [hookNiederschlagsmenge, setNiederschlagsmenge] = useState(false);
    const [hookGlatteFrost, setGlatteFrost] = useState(false);
    const [hookWindgeschwindigkeit, setWindgeschwindigkeit] = useState(false);
    const [hookHagel, setHagel] = useState(false);
    const [hookBlitz, setBlitz] = useState(false);
    const [hookSichtLichtverhaltnisse, setSichtLichtverhaltnisse] = useState(false);
    const [hookWetterauskunftError, setWetterauskunftError] = useState({ error: false, errorMessage: "" });
    const [hookPDFProcessing, setPDFProcessing] = useState(false);
    const [hookWeatherData, setWeatherData] = useState(null);
    const [hookWeatherDataError, setWeatherDataError] = useState({ error: false, errorMessage: "" });
    const [hookBlitzData, setBlitzData] = useState(null);
    const [hookBlitzDataError, setBlitzDataError] = useState(false);
    const [hookLange, setLange] = React.useState(undefined);
    const [hookBreite, setBreite] = React.useState(undefined);
    const [hookCordinatesError, setCordinatesError] = useState({ error: false, errorMessage: "" });
    const [hookLatLonLocLoading, setLatLonLocLoading] = useState(false);

    /* #################FETCH DATA################## */

    const getLocation = async (argSearch) => {

        try {
            if (props.token && props.token.length > 0) {
                if (argSearch.length > 2) {
                    setOrtError({ error: false, errorMessage: "" });
                    let locationResponse = null;
                    const axiosInstance = axios.create({
                        //baseURL: 'http://165.227.162.31:8000/v4/',
                        baseURL: 'https://apps.qmet.de/v4',
                        timeout: 1000 * 50,
                        headers: { 'Authorization': `Bearer ${props.token}` }
                    });
                    //locationResponse = await axiosInstance.get(`location/${argSearch}`);
                    locationResponse = await axiosInstance.get(`location/de/postal/${argSearch}`);
                    if (locationResponse && locationResponse.data && locationResponse.data.data && locationResponse.data.data && locationResponse.data.data.length > 0) {
                        setLocations(locationResponse.data.data);
                    } else {
                        //setOrt({ name: argSearch, lat: undefined, lon: undefined });
                        if(props.isClient){
                            ReactGA.event("rc_stepercomponent", {
                                rc_stepercomponent_error: "202208111150 - No data or empty object"
                            });
                        }
                    }
                }
            } else {
                setOrt({ name: argSearch, lat: undefined, lon: undefined });
                if (props.isClient) {
                    ReactGA.event("rc_stepercomponent", {
                        rc_stepercomponent_error: "202208111151 - No token found"
                    });
                }
            }
        } catch (error) {
            if (props.isClient) {
                ReactGA.event("rc_stepercomponent", {
                    rc_stepercomponent_error: "202109021052 - " + error
                });
            }else{
                console.log('202109021052', error);
            }
        }
    }

    /*const getLocationbyLatLon = async(argLat, argLon) => {
        try{
            setLatLonLocLoading(true);
            if (props.token && props.token.length > 0){
                if(argLat !== "" && argLon !== "" && argLat.length && argLat.length > 0 && argLon.length && argLon.length > 0 && argLat != null && argLon != null && !isNaN(argLat) && !isNaN(argLon)) {
                    setCordinatesError({ error: false, errorMessage: "" });
                    let locationResponse = null;

                    const axiosInstance = axios.create({
                        baseURL: 'https://apps.qmet.de/v2',
                        timeout: 1000 * 30,
                        headers: { 'Authorization': `Bearer ${props.token}` },
                    });
                    //locationResponse = await axiosInstance.get(`location/${argSearch}`);
                    locationResponse = await axiosInstance.get(`/location/geo/${argLon}/${argLat}`);
                    if (locationResponse && locationResponse.data && locationResponse.data.data && locationResponse.data.data && locationResponse.data.data.name) {
                        setLatLonOrt(locationResponse.data.data);
                        setLatLonLocLoading(false);
                    } else {
                        setLatLonOrt(null);
                        setCordinatesError({ error: true, errorMessage: "Problem beim Laden des Standorts mit Koordinaten. Bitte versuchen Sie es mit anderen Koordinaten oder versuchen Sie es nach einiger Zeit erneut" });
                        setLatLonLocLoading(false);
                        if (props.isClient) { ReactGA.event({ category: "Stepper Component", action: "getLocation", label: "getLocation - API error" }); }
                    }
            }else{
                    setCordinatesError({ error: true, errorMessage: "ungültige Koordinaten" });
                    setLatLonLocLoading(false);
            }
            } else {
                setLatLonOrt(null);
                setCordinatesError({ error: true, errorMessage: "Problem beim Laden des Standorts mit Koordinaten. Bitte versuchen Sie es mit anderen Koordinaten oder versuchen Sie es nach einiger Zeit erneut" });
                setLatLonLocLoading(false);
                if (props.isClient) { ReactGA.event({ category: "Stepper Component", action: "getLocation", label: "getLocation - Token error" }); }
            }
        }catch(error){
            setLatLonOrt(null);
            setCordinatesError({ error: true, errorMessage: "Problem beim Laden des Standorts mit Koordinaten. Bitte versuchen Sie es mit anderen Koordinaten oder versuchen Sie es nach einiger Zeit erneut" });
            setLatLonLocLoading(false);
            console.log('202207280945',error);
        }
    }*/

    /* #################FETCH DATA################## */

    /* #################FUNCTIONS################## */

    const clearForm = () => {
        try {
            if (props.isClient) {
                ReactGA.event("rc_stepercomponent_clearform_btnclick", {
                    rc_clearform_btnclick: "clicked"
                });
            }
            setActivePage('Auskunftsdaten');
            setAuskunftsdatenValid(false);
            setPrufenAnfordernValid(false);
            setWetterauskunftValid(false);
            setLocations([]);
            setOrt(null);
            setOrtError({ error: false, errorMessage: "" });
            setStrasse("");
            setHausnummer("");
            setStrasseError({ error: false, errorMessage: "" });
            setHausnummerError({ error: false, errorMessage: "" });
            setStartDatumZeit(null);
            setStartDatumError({ error: false, errorMessage: "" });
            setEndDatumZeit(null);
            setEndDatumError({ error: false, errorMessage: "" });
            setTemperatur(false);
            setNiederschlagsmenge(false);
            setGlatteFrost(false);
            setWindgeschwindigkeit(false);
            setHagel(false);
            setBlitz(false);
            setSichtLichtverhaltnisse(false);
            setWetterauskunftError({ error: false, errorMessage: "" });
            setPDFProcessing(false);
            setWeatherData(null);
            setWeatherDataError({ error: false, errorMessage: "" });
            setBlitzData(null);
            setBlitzDataError(false);
            setLange("");
            setBreite("");
            setCordinatesError({ error: false, errorMessage: "" });
            /*hookLatLonOrt(null);*/
            setLatLonLocLoading(false);
        } catch (error) {
            if (props.isClient) {
                ReactGA.event("rc_stepercomponent", {
                    rc_stepercomponent_error: "202109021058 - " + error
                });
            }else{
                console.log('202109021058', error);
            }
        }
    }

    const handleBackdropClose = () => {
        try {
            setPDFProcessing(false);
        } catch (error) {
            if (props.isClient) {
                ReactGA.event("rc_stepercomponent", {
                    rc_stepercomponent_error: "202109021059 - " + error
                });
            } else {
                console.log('202109021059', error);
            }
        }
    }

    const setAuskunftsdatenActive = () => {
        try {
            setActivePage('Auskunftsdaten');
            setWeatherData(null);
            setBlitzData(null);
            if (props.isClient) {
                ReactGA.event("rc_auskunftdatenpage_open", {
                    rc_auskunftdatenpage_open: "open"
                });
            }
        } catch (error) {
            if (props.isClient) {
                ReactGA.event("rc_stepercomponent", {
                    rc_stepercomponent_error: "202208111200 - " + error
                });
            } else {
                console.log('202208111200', error);
            }
        }
    }

    const setPrufenAnfordernActive = () => {
        try {
            if (props.isClient) {
                ReactGA.event("rc_prufenanfordern_open", {
                    rc_prufenanfordern_open: "open"
                });
            }
            if (!validateAuskunftsdaten()) {
                setAuskunftsdatenValid(true);
                setActivePage('PrufenAnfordern');
                setWeatherData(null);
                setBlitzData(null);
            } else {
                setActivePage('Auskunftsdaten');
                setAuskunftsdatenValid(false);
                setPrufenAnfordernValid(false);
                setWetterauskunftValid(false);
                setWeatherData(null);
                setBlitzData(null);
            }
        } catch (error) {
            if (props.isClient) {
                ReactGA.event("rc_stepercomponent", {
                    rc_stepercomponent_error: "202208111201 - " + error
                });
            } else {
                console.log('202208111201', error);
            }
        }
    }

    const setWetterauskunftActive = async () => {
        try {
            if (props.isClient) {
                ReactGA.event("rc_wetterauskunft_open", {
                    rc_wetterauskunft_open: "open"
                });
            }
            if ( await fetchWeatherData()) {
                if (!validateAuskunftsdaten()) {
                    setPrufenAnfordernValid(true);
                    setActivePage('Wetterauskunft');
                } else {
                    setActivePage('Auskunftsdaten');
                    setAuskunftsdatenValid(false);
                    setPrufenAnfordernValid(false);
                    setWetterauskunftValid(false);
                    setWeatherData(null);
                    setBlitzData(null);
                }
            } else {
                setActivePage('Auskunftsdaten');
                setAuskunftsdatenValid(false);
                setPrufenAnfordernValid(false);
                setWetterauskunftValid(false);
                setWeatherData(null);
                setBlitzData(null);
            }
        } catch (error) {
            if (props.isClient) {
                ReactGA.event("rc_stepercomponent", {
                    rc_stepercomponent_error: "202208111202 - " + error
                });
            } else {
                console.log('202208111202', error);
            }
        }
    }

    const setSelLocName = (event, value, reason) => {
        try {
            setOrt(value);
            setOrtError({ error: false, errorMessage: "" });
            setCordinatesError({ error: false, errorMessage: "" });
            /*if(value && value.name && !isNaN(value.lat) && !isNaN(value.lon)){
                setLange(value.lat.toString());
                setBreite(value.lon.toString());
            }*/
            setStrasse("");
            setHausnummer("");
            setStrasseError({ error: false, errorMessage: "" });
            setHausnummerError({ error: false, errorMessage: "" });
            if (props.isClient) {
                ReactGA.event("rc_location_select", {
                    rc_location: "select"
                });
            }
        } catch (error) {
            if (props.isClient) {
                ReactGA.event("rc_stepercomponent", {
                    rc_stepercomponent_error: "202208111203 - " + error
                });
            } else {
                console.log('202208111203', error);
            }
        }

    }

    const setStartDateAndTime = (argStartDate) => {
        try {
            setStartDatumZeit(argStartDate);
            setEndDatumZeit(null);
            setWeatherData(null);
            if (props.isClient) {
                ReactGA.event("rc_startdate_select", {
                    rc_startdate: "select"
                });
            }
        } catch (error) {
            console.log('202109021233', error);
            if (props.isClient) {
                ReactGA.event("rc_stepercomponent", {
                    rc_stepercomponent_error: "202208111204 - " + error
                });
            } else {
                console.log('202208111204', error);
            }
        }
    }

    const setEndDateAndTime = (argEndDate) => {
        try {
            if(argEndDate > new Date()){
                setEndDatumZeit(new Date());
            }else{
                setEndDatumZeit(argEndDate);
            }
            if (props.isClient) {
                ReactGA.event("rc_enddate_select", {
                    rc_enddate: "select"
                });
            }
        } catch (error) {
            if (props.isClient) {
                ReactGA.event("rc_stepercomponent", {
                    rc_stepercomponent_error: "202208111205 - " + error
                });
            } else {
                console.log('202208111205', error);
            }
        }
    }

    const setToggleTemperatur = (argChecked) => {
        try {
            setTemperatur(argChecked);
            if (props.isClient) {
                ReactGA.event("rc_temperature_toggle", {
                    rc_temperature: argChecked
                });
            }
        } catch (error) {
            if (props.isClient) {
                ReactGA.event("rc_stepercomponent", {
                    rc_stepercomponent_error: "202208111206 - " + error
                });
            } else {
                console.log('202208111206', error);
            }
        }
    }

    const setToggleNiederschlagsmenge = (argChecked) => {
        try {
            setNiederschlagsmenge(argChecked);
            if (props.isClient) {
                ReactGA.event("rc_niederschlagsmenge_toggle", {
                    rc_niederschlagsmenge: argChecked
                });
            }
        } catch (error) {
            if (props.isClient) {
                ReactGA.event("rc_stepercomponent", {
                    rc_stepercomponent_error: "202208111207 - " + error
                });
            } else {
                console.log('202208111207', error);
            }
        }
    }

    const setToggleGlatteFrost = (argChecked) => {
        try {
            setGlatteFrost(argChecked);
            if (props.isClient) {
                ReactGA.event("rc_glattefrost_toggle", {
                    rc_glattefrost: argChecked
                });
            }
        } catch (error) {
            if (props.isClient) {
                ReactGA.event("rc_stepercomponent", {
                    rc_stepercomponent_error: "202208111208 - " + error
                });
            } else {
                console.log('202208111208', error);
            }
        }
    }

    const setToggleWindgeschwindigkeit = (argChecked) => {
        try {
            setWindgeschwindigkeit(argChecked);
            if (props.isClient) {
                ReactGA.event("rc_windgeschwindigkeit_toggle", {
                    rc_windgeschwindigkeit: argChecked
                });
            }
        } catch (error) {
            if (props.isClient) {
                ReactGA.event("rc_stepercomponent", {
                    rc_stepercomponent_error: "202208111209 - " + error
                });
            } else {
                console.log('202208111209', error);
            }
        }
    }

    const setToggleHagel = (argChecked) => {
        try {
            setHagel(argChecked);
            if (props.isClient) {
                ReactGA.event("rc_hagel_toggle", {
                    rc_hagel: argChecked
                });
            }
        } catch (error) {
            if (props.isClient) {
                ReactGA.event("rc_stepercomponent", {
                    rc_stepercomponent_error: "202208111210 - " + error
                });
            } else {
                console.log('202208111210', error);
            }
        }
    }

    const setToggleBlitz = (argChecked) => {
        try {
            setBlitz(argChecked);
            if (props.isClient) {
                ReactGA.event("rc_blitz_toggle", {
                    rc_blitz: argChecked
                });
            }
        } catch (error) {
            if (props.isClient) {
                ReactGA.event("rc_stepercomponent", {
                    rc_stepercomponent_error: "202208111211 - " + error
                });
            } else {
                console.log('202208111211', error);
            }
        }
    }

    const setToggleSichtLichtverhaltnisse = (argChecked) => {
        try {
            setSichtLichtverhaltnisse(argChecked);
            if (props.isClient) {
                ReactGA.event("rc_sichtlichtverhaltnisse_toggle", {
                    rc_sichtlichtverhaltnisse: argChecked
                });
            }
        } catch (error) {
            if (props.isClient) {
                ReactGA.event("rc_stepercomponent", {
                    rc_stepercomponent_error: "202208111212 - " + error
                });
            } else {
                console.log('202208111212', error);
            }
        }
    }

    function dateDiffInDays(a, b) {
        try{
        // Discard the time and time-zone information.
        const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
        const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

        return Math.floor((utc2 - utc1) / (1000 * 60 * 60 * 24));
    } catch (error) {
            if (props.isClient) {
                ReactGA.event("rc_stepercomponent", {
                    rc_stepercomponent_error: "202208111214 - " + error
                });
            } else {
                console.log('202208111214', error);
            }
    }
    }

    const validateAuskunftsdaten = () => {
        try {
            let hasValidated = false;

            /*if (hookBreite === "" || hookBreite === undefined || hookBreite === null ||
                hookLange === "" || hookBreite === undefined || hookLange === null) {
                setLatLonOrt(null);
            }*/

            if (hookOrt === null || hookOrt === undefined || hookOrt.length < 1) {
                setOrt(null);
            }

            if (hookCordinatesError && hookCordinatesError.error) {
                    hasValidated = true;
            }

            if ((hookBreite === "" || hookBreite === undefined || hookBreite === null ||
                hookLange === "" || hookBreite === undefined || hookLange === null) &&
                (hookOrt === null || hookOrt === undefined || hookOrt.length < 1)) {
                hasValidated = true;
                setOrtError({ error: true, errorMessage: "Bitte Koordinaten eingeben oder PLZ/Ort auswählen!" });
                setCordinatesError({ error: true, errorMessage: "" });
            } else {
                setOrtError({ error: false, errorMessage: "" });
                setCordinatesError({ error: false, errorMessage: "" });
            }

            /*STRAßE AND NUMER NOT MANDATORY ANYMORE*/
            /*if (hookStrasse === null || hookHausnummer === null || hookStrasse === undefined || hookHausnummer === undefined || hookStrasse.length < 1 || hookHausnummer.length < 1) {
                hasValidated = true;
                setStrasseError({ error: true, errorMessage: "Bitte Straße und Hausnummer eingeben!" });
                setHausnummerError({ error: true, errorMessage: " " });
            } else {
                setStrasseError({ error: false, errorMessage: "" });
                setHausnummerError({ error: false, errorMessage: "" });
            }*/

            if (hookStartDatumZeit === null || hookStartDatumZeit === undefined || hookStartDatumZeit.length < 1) {
                hasValidated = true;
                setStartDatumError({ error: true, errorMessage: "Bitte Startdatum und -zeit auswählen!" });
            } else {
                if (new Date(hookStartDatumZeit) > new Date()) {
                    hasValidated = true;
                    setStartDatumError({ error: true, errorMessage: "Das Startdatum und -zeit muss kleiner als das aktuelle Datum und -zeit sein!" });
                } else {
                    setStartDatumError({ error: false, errorMessage: "" });
                }
            }

            if (hookEndDatumZeit === null || hookEndDatumZeit === undefined || hookEndDatumZeit.length < 1) {
                hasValidated = true;
                setEndDatumError({ error: true, errorMessage: "Bitte Enddatum und -zeit auswählen!" });
            } else {
                if (new Date(hookStartDatumZeit) > new Date(hookEndDatumZeit)) {
                    hasValidated = true;
                    setEndDatumError({ error: true, errorMessage: "Das Startdatum und -zeit muss größer als das Enddatum und -zeit sein!" });
                } else if (new Date(hookEndDatumZeit) > new Date()) {
                    hasValidated = true;
                    setEndDatumError({ error: true, errorMessage: "Das Enddatum und -zeit muss kleiner als das aktuelle Datum und -zeit sein!" });
                }
                else if (dateDiffInDays(new Date(hookStartDatumZeit), new Date(hookEndDatumZeit)) > 4) {
                    hasValidated = true;
                    setEndDatumError({ error: true, errorMessage: "Bitte wählen Sie maximal 5 Tage!" });
                }
                else {
                    setEndDatumError({ error: false, errorMessage: "" });
                }
            }

            if (hookTemperatur === false && hookGlatteFrost === false && hookNiederschlagsmenge === false && hookWindgeschwindigkeit === false && hookHagel === false && hookBlitz === false && hookSichtLichtverhaltnisse === false) {
                hasValidated = true;
                setWetterauskunftError({ error: true, errorMessage: "Bitte wählen Sie mindestens eine Option aus!" });
            } else {
                setWetterauskunftError({ error: false, errorMessage: "" });
            }

            return hasValidated;
        } catch (error) {
            if (props.isClient) {
                ReactGA.event("rc_stepercomponent", {
                    rc_stepercomponent_error: "202208111215 - " + error
                });
            } else {
                console.log('202208111215', error);
            }
        }
    }

    const getDayOfWeek = (argDate) => {
        try {
            let date = new Date(argDate);
            return date.toLocaleDateString('de-DE', { weekday: 'long' })
        } catch (error) {
            console.log('202109021548', error)
            if (props.isClient) {ReactGA.event({category: "Stepper Component", action: "getDayOfWeek", label: "getDayOfWeek - Error"});}
        }
    }

    const getDate = (argDate) => {
        try {
            let date = new Date(argDate);
            return date.toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' })
        } catch (error) {
            console.log('202109021549', error);
            if (props.isClient) {ReactGA.event({category: "Stepper Component", action: "getDate", label: "getDate - Error"});}
        }
    }

    const getDateFullMonth = (argDate) => {
        try {
            let date = new Date(argDate);
            return date.toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: '2-digit' })
        } catch (error) {
            console.log('202109021549', error);
            if (props.isClient) {ReactGA.event({category: "Stepper Component", action: "getDateFullMonth", label: "getDateFullMonth - Error"});}
        }
    }

    const getTime = (argDate) => {
        try {
            let date = new Date(argDate);
            return date.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' })
        } catch (error) {
            console.log('202109021550', error);
            if (props.isClient) {ReactGA.event({category: "Stepper Component", action: "getTime", label: "getTime - Error"});}
        }
    }

    // eslint-disable-next-line no-extend-native
    Date.prototype.addDays = function (days) {
        try{
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    } catch (error) {
            if (props.isClient) {
                ReactGA.event("rc_stepercomponent", {
                    rc_stepercomponent_error: "202208111216 - " + error
                });
            } else {
                console.log('202208111216', error);
            }
    }
    }

    const localizeNumber = (argNumber) => {
        try {
          return(Number(argNumber).toLocaleString('de-DE'));
        } catch (error) {
            if (props.isClient) {
                ReactGA.event("rc_stepercomponent", {
                    rc_stepercomponent_error: "202208111217 - " + error
                });
            } else {
                console.log('202208111217', error);
            }
        }
    }

    const formateNumber = (argNumber) => {
        try{
            return Number(argNumber) < 10 ? '0' + argNumber : argNumber;
        }catch(error){
            if (props.isClient) {
                ReactGA.event("rc_stepercomponent", {
                    rc_stepercomponent_error: "202208111218 - " + error
                });
            } else {
                console.log('202208111218', error);
            }
        }
    }

    const formateDate = (argDate) => {
        try{
            //2022-02-01 00:00:00
            let dateValue = new Date(argDate);
            let dateYear = new Date(dateValue).getFullYear();
            let dateMonth = formateNumber(new Date(dateValue).getMonth() + 1);
            let dateDay = formateNumber(new Date(dateValue).getDate());
            let dateHour = formateNumber(new Date(dateValue).getHours());
            let dateMinute = formateNumber(new Date(dateValue).getMinutes());
            let dateSecond = formateNumber(new Date(dateValue).getSeconds());
            return(dateYear + '-' + dateMonth + '-' + dateDay + ' ' + dateHour + ':' + dateMinute + ':' + dateSecond);
        }catch(error){
            if (props.isClient) {
                ReactGA.event("rc_stepercomponent", {
                    rc_stepercomponent_error: "202208111219 - " + error
                });
            } else {
                console.log('202208111219', error);
            }
        }
    }

    const fetchWeatherData = async () => {
        try {

            //let ortZipCode = null;
            let ortLat = null;
            let ortLng = null;
            if (hookLange !== null && hookBreite !== null && !isNaN(hookLange) && !isNaN(hookBreite)){
                ortLat = Number(hookBreite);
                ortLng = Number(hookLange);
            } else if (hookOrt && !isNaN(hookOrt.lon) && !isNaN(hookOrt.lat)){
                //cordinates name interchanged, because names mismatch
                ortLat = hookOrt ? !isNaN(hookOrt.lon) ? hookOrt.lon : null : null;
                ortLng = hookOrt ? !isNaN(hookOrt.lat) ? hookOrt.lat : null : null;
            }else{
                //ortZipCode = null;
                ortLat = null;
                ortLng = null;
            }
            let startDate = hookStartDatumZeit ? formateDate(hookStartDatumZeit) : null;
            let endDate = hookEndDatumZeit ? formateDate(hookEndDatumZeit) : null;
            let params = '';
            if (hookTemperatur) {
                params = params + '1';
            }

            if (hookGlatteFrost) {
                params = params + ',2';
            }

            if (hookNiederschlagsmenge) {
                params = params + ',3';
            }

            if (hookWindgeschwindigkeit) {
                params = params + ',4';
            }

            if (hookSichtLichtverhaltnisse) {
                params = params + ',5';
            }

            if (hookHagel) {
                params = params + ',6';
            }

            if (hookBlitz) {
                /*params = params + ',7';*/
                let blitzData = await HandleFetchBlitzData(startDate, endDate, ortLat, ortLng);
            }

            let weatherDataResponse = null;
            if (hookTemperatur || hookGlatteFrost || hookNiederschlagsmenge || hookWindgeschwindigkeit || hookSichtLichtverhaltnisse || hookHagel) {
                const axiosInstance = axios.create({
                    baseURL: 'https://beta.api.riskcontrol.qmet.de/v1/',
                    timeout: 1000 * 500,
                    //headers: { 'Authorization': `Bearer ${props.token}` }
                });
                if (ortLat !== null && ortLng !== null && !isNaN(ortLat) && !isNaN(ortLng) && startDate && endDate && params) {
                    setPDFProcessing(true);
                    weatherDataResponse = await axiosInstance.post(`measurement/?lat=${ortLat.toString()}&lon=${ortLng.toString()}&start_date=${startDate.toString()}&end_date=${endDate.toString()}&parameter=${params.toString()}`);
                    if (weatherDataResponse && weatherDataResponse.data && weatherDataResponse.data.data) {
                        setWeatherData(weatherDataResponse.data.data);
                        setPDFProcessing(false);
                        setWeatherDataError({ error: false, errorMessage: "" });
                        return true;
                    } else {
                        setWeatherData(null);
                        setPDFProcessing(false);
                        setWeatherDataError({ error: true, errorMessage: "Wetterdaten können nicht geladen werden! Bitte versuchen Sie es nach einiger Zeit erneut." });
                        if (props.isClient) {
                            ReactGA.event("rc_stepercomponent", {
                                rc_stepercomponent_error: "202208111237 - fetchweatherdata error, empty object or no data"
                            });
                        } else {
                            console.log('202208111237');
                        }
                        return false;
                    }
                }
                else {
                    setPDFProcessing(false);
                    setWeatherDataError({ error: true, errorMessage: "Wetterdaten können nicht geladen werden! Bitte versuchen Sie es nach einiger Zeit erneut." });
                    if (props.isClient) {
                        ReactGA.event("rc_stepercomponent", {
                            rc_stepercomponent_error: "202208111238 - No parameter available"
                        });
                    } else {
                        console.log('202208111238');
                    }
                    return false;
                }
            } else {
                setWeatherData(null);
                return true;
            }
        } catch (error) {
            setPDFProcessing(false);
            setWeatherDataError({ error: true, errorMessage: "Wetterdaten können nicht geladen werden! Bitte versuchen Sie es nach einiger Zeit erneut." });
            if (props.isClient) {
                ReactGA.event("rc_stepercomponent", {
                    rc_stepercomponent_error: "202208111220 - " + error
                });
            } else {
                console.log('202208111220', error);
            }
            return false;
        }
    }

    const addPrefix = ( argNumber ) => {
        try{
            if(Number(argNumber) < 10){
                return '0' + argNumber;
            }
            else{
                return argNumber;
            }
            }catch(error){
            if (props.isClient) {
                ReactGA.event("rc_stepercomponent", {
                    rc_stepercomponent_error: "202208111221 - " + error
                });
            } else {
                console.log('202208111221', error);
            }
            }
    }

    const formateBlitzDate = (argDate) => {
        try{
            const valYear = new Date(argDate).getFullYear();
            const valMonth = addPrefix(new Date(argDate).getMonth() + 1);
            const valDay = addPrefix(new Date(argDate).getDate());
            const valHour = addPrefix(new Date(argDate).getHours());
            const valMinute = addPrefix(new Date(argDate).getMinutes());
            const valSecond = addPrefix(new Date(argDate).getSeconds());
            let valTimeZoneOffset = (new Date(argDate).getTimezoneOffset()) / 60;
            let value = (valTimeZoneOffset).toString();
            if(value < 0){
                let valA = value.toString().split('.');
                if(valA.length>1){
                    let pre = valA[0].toString().replace('-','');
                    let flo = valA[1].toString();
                    if(Number(pre)<10){
                        pre = '0'+pre;
                    }
                    if(flo.length === 1){
                        flo = flo+'0';
                    }
                    value = '-' + pre +':'+ flo;
                }else{
                    let pre = valA[0].toString().replace('-','');
                    if(Number(pre)<10){
                        pre = '0'+pre;
                    }
                    value = '-' + pre + ':00';
                }
            }else if(value === 0){
                value = "00:00";
            }else{
                let valA = value.toString().split('.');
                if(valA.length>1){
                    let pre = valA[0].toString().replace('-','');
                    let flo = valA[1].toString();
                    if(Number(pre)<10){
                        pre = '0'+pre;
                    }
                    if(flo.length === 1){
                        flo = flo+'0';
                    }
                    value = '+' + pre +':'+ flo;
                }else{
                    let pre = valA[0].toString().replace('-','');
                    if(Number(pre)<10){
                        pre = '0'+pre;
                    }
                    value = '+' + pre + ':00';
                }
            }
            return( valYear+'-'+valMonth+'-'+valDay+'T'+valHour+':'+valMinute+':'+valSecond+value);
        }catch(error){
            if (props.isClient) {
                ReactGA.event("rc_stepercomponent", {
                    rc_stepercomponent_error: "202208111222 - " + error
                });
            } else {
                console.log('202208111222', error);
            }
        }
    }

    const formateBlitzData = (argData) => {
        try{
        if(argData && argData.result && argData.result.features && argData.result.features.length > 0){
            const BlitzData = argData.result.features.map((item, index) => {
                if(item && item.properties){
                    return({
                        amplitude: !isNaN(item.properties.amplitude) ? item.properties.amplitude : null,
                        distance: !isNaN(item.properties.distance) ? item.properties.distance : null,
                        time: item.properties.time ? item.properties.time : null,
                        type: !isNaN(item.properties.type) ? item.properties.type : null
                    });
                }
            });
            if(BlitzData && BlitzData.length > 0){
                return BlitzData;
            }else{
                return([{amplitude: null, distance: null, time: null, type: null}]);
            }
        }else{
            return([{amplitude: null, distance: null, time: null, type: null}]);
        }
    }catch(error){
            if (props.isClient) {
                ReactGA.event("rc_stepercomponent", {
                    rc_stepercomponent_error: "202208111223 - " + error
                });
            } else {
                console.log('202208111223', error);
            }
    }
    }

    // eslint-disable-next-line no-extend-native
    Date.prototype.addDays = function (days) {
        try {
            var date = new Date(this.valueOf());
            date.setDate(date.getDate() + days);
            return date;
        } catch (error) {
            if (props.isClient) {
                ReactGA.event("rc_stepercomponent", {
                    rc_stepercomponent_error: "202208111224 - " + error
                });
            } else {
                console.log('202208111224', error);
            }
        }
    }

    const HandleFetchBlitzData = async (argStartDate, argEndDate, argOrtLat, argOrtLng) => {
        try {
            const dateDiff = dateDiffInDays(new Date(argStartDate), new Date(argEndDate));
            const axiosInstance1 = axios.create({
                baseURL: "https://beta.api.riskcontrol.qmet.de/v1/",
                timeout: 1000 * 500,
                headers: {
                    "Content-Type": "application/json",
                }
            });
            if (argStartDate && argEndDate && argOrtLat !== null && argOrtLng !== null && !isNaN(argOrtLat) && !isNaN(argOrtLng)) {
                setPDFProcessing(true);
                if (dateDiff < 3) {
                    const formatedStartDate = formateBlitzDate(new Date(argStartDate));
                    const formatedEndDate = formateBlitzDate(new Date(argEndDate));
                    let responceData = await axiosInstance1.post('measurement/blids',
                        {
                            "start": formatedStartDate, //"2020-06-17T00:00:00-02:00",
                            "end": formatedEndDate, //"2020-06-19T23:00:00-02:00",
                            "customerid": "0815",
                            "radius": 1,
                            "point": {
                                "type": "Point",
                                "coordinates": [argOrtLng, argOrtLat] //[8.34, 49.03]
                            }
                        });
                    if (responceData && responceData.data) {
                        const blitzData = formateBlitzData(responceData.data);
                        setBlitzData(blitzData);
                        setPDFProcessing(false);
                        setWeatherDataError({ error: false, errorMessage: "" });
                        return true;
                    } else {
                        setBlitzData(null);
                        setBlitzDataError(true);
                        setPDFProcessing(false);
                        setWeatherDataError({ error: true, errorMessage: "Problem mit Blitzdatenabruf!" });
                        if (props.isClient) {
                            ReactGA.event("rc_stepercomponent", {
                                rc_stepercomponent_error: "202208111240 - 1. HandleFetchBlitzData error, empty object or no data"
                            });
                        } else {
                            console.log('202208111240 - 1. HandleFetchBlitzData error, empty object or no data');
                        }
                        return false;
                    }
                } else {
                    let blitzDataCallOne = [];
                    let blitzDataCallTwo = [];
                    const formatedStartDateCallOne = formateBlitzDate(new Date(argStartDate));
                    const formatedEndDateCallOne = formateBlitzDate(new Date(argStartDate).addDays(2).setHours(23,59,59));
                    const formatedStartDateCallTwo = formateBlitzDate(new Date(argStartDate).addDays(3).setHours(0,0,0));
                    const formatedEndDateCallTwo = formateBlitzDate(new Date(argEndDate));
                    setPDFProcessing(true);
                    let responceDataCallOne = await axiosInstance1.post('measurement/blids',
                        {
                            "start": formatedStartDateCallOne, //"2020-06-17T00:00:00-02:00",
                            "end": formatedEndDateCallOne, //"2020-06-19T23:00:00-02:00",
                            "customerid": "0815",
                            "radius": 1,
                            "point": {
                                "type": "Point",
                                "coordinates": [argOrtLng, argOrtLat] //[8.34, 49.03]
                            }
                        });
                    if (responceDataCallOne && responceDataCallOne.data) {
                        blitzDataCallOne = formateBlitzData(responceDataCallOne.data);
                        //setBlitzData(blitzData);
                        setPDFProcessing(false);
                        //setWeatherDataError({ error: false, errorMessage: "" });
                        //return true;
                    } else {
                        setBlitzData(null);
                        setBlitzDataError(true);
                        setPDFProcessing(false);
                        setWeatherDataError({ error: true, errorMessage: "Problem mit Blitzdatenabruf!" });
                        if (props.isClient) {
                            ReactGA.event("rc_stepercomponent", {
                                rc_stepercomponent_error: "202208111241 - 2. HandleFetchBlitzData error, empty object or no data"
                            });
                        } else {
                            console.log('202208111241 - 2. HandleFetchBlitzData error, empty object or no data');
                        }
                        //return false;
                    }
                    setPDFProcessing(true);
                    let responceDataCallTwo = await axiosInstance1.post('measurement/blids',
                        {
                            "start": formatedStartDateCallTwo, //"2020-06-17T00:00:00-02:00",
                            "end": formatedEndDateCallTwo, //"2020-06-19T23:00:00-02:00",
                            "customerid": "0815",
                            "radius": 1,
                            "point": {
                                "type": "Point",
                                "coordinates": [argOrtLng, argOrtLat] //[8.34, 49.03]
                            }
                        });
                    if (responceDataCallTwo && responceDataCallTwo.data) {
                        blitzDataCallTwo = formateBlitzData(responceDataCallTwo.data);
                        //setBlitzData(blitzData);
                        setPDFProcessing(false);
                        //setWeatherDataError({ error: false, errorMessage: "" });
                        //return true;
                    } else {
                        setBlitzData(null);
                        setBlitzDataError(true);
                        setPDFProcessing(false);
                        setWeatherDataError({ error: true, errorMessage: "Problem mit Blitzdatenabruf!" });
                        if (props.isClient) {
                            ReactGA.event("rc_stepercomponent", {
                                rc_stepercomponent_error: "202208111242 - 3. HandleFetchBlitzData error, empty object or no data"
                            });
                        } else {
                            console.log('202208111242 - 3. HandleFetchBlitzData error, empty object or no data');
                        }
                        //return false;
                    }
                    blitzDataCallOne = blitzDataCallOne.concat(blitzDataCallTwo);
                    if(blitzDataCallOne && blitzDataCallOne.length > 0){
                        setBlitzData(blitzDataCallOne);
                        setPDFProcessing(false);
                        setWeatherDataError({ error: false, errorMessage: "" });
                    }else{
                        setBlitzData(null);
                        setBlitzDataError(true);
                        setPDFProcessing(false);
                        setWeatherDataError({ error: true, errorMessage: "Problem mit Blitzdatenabruf!" });
                        if (props.isClient) {
                            ReactGA.event("rc_stepercomponent", {
                                rc_stepercomponent_error: "202208111243 - Blitzdata error"
                            });
                        } else {
                            console.log('202208111243 - Blitzdata error');
                        }
                    }
                }
            } else {
                setPDFProcessing(false);
                setBlitzData(null);
                setBlitzDataError(true);
                setWeatherDataError({ error: true, errorMessage: "Blitzdaten können nicht geladen werden! Bitte versuchen Sie es nach einiger Zeit erneut." });
                if (props.isClient) {
                    ReactGA.event("rc_stepercomponent", {
                        rc_stepercomponent_error: "202208111225 - Parameters not available"
                    });
                } else {
                    console.log('202208111225 - Parameters not available');
                }
                return false;
            }
            /*
            let responceData = await axiosInstance1.post('lightningreport',
            {
                "start":"2020-06-17T00:00:00+02:00",
                "end":"2020-06-19T23:00:00+02:00",
                "customerid": process.env.NEXT_PUBLIC_WA_LIGHTNING_API_CUSTOMERID,
                "radius": 3,
                "point":{
                    "type":"Point",
                    "coordinates":[8.34, 49.03]
                }
            });*/
        } catch (error) {
            setPDFProcessing(false);
            setBlitzData(null);
            setBlitzDataError(true);
            setWeatherDataError({ error: true, errorMessage: "Problem mit Blitzdatenabruf!" });
            if (props.isClient) {
                ReactGA.event("rc_stepercomponent", {
                    rc_stepercomponent_error: "202208111226 - " + error
                });
            } else {
                console.log('202208111226 - ' + error);
            }
        }
    }

    const HandleWeatherDataMessageClose = () => {
        try{
            setWeatherDataError({ error: false, errorMessage: "" });
            setBlitzDataError({ error: false, errorMessage: "" });
        }catch(error){
            if (props.isClient) {
                ReactGA.event("rc_stepercomponent", {
                    rc_stepercomponent_error: "202208111227 - " + error
                });
            } else {
                console.log('202208111227 - ' + error);
            }
        }
    }

    const handleLangeChange = (argLange) => {
        try {
                //länge = longitude
                if(argLange === "." || argLange === "+" || argLange === "-"){
                    setLange(argLange);
                    setCordinatesError({ error: false, errorMessage: "" });
                    setOrtError({ error: false, errorMessage: "" });
                }else if(isNaN(argLange)){
                    setCordinatesError({ error: true, errorMessage: "Bitte geben Sie gültige Koordinaten ein" });
                }
                else if(Number(argLange) > 180 || Number(argLange) < -180){
                    setCordinatesError({ error: true, errorMessage: "Bitte geben Sie gültige Koordinaten ein" });
                }else{
                    setLange(argLange);
                    setCordinatesError({ error: false, errorMessage: "" });
                    setOrtError({ error: false, errorMessage: "" });
                }
        } catch (error) {
            if (props.isClient) {
                ReactGA.event("rc_stepercomponent", {
                    rc_stepercomponent_error: "202208111228 - " + error
                });
            } else {
                console.log('202208111228 - ' + error);
            }
        }
    }

    const handleBreiteChange = (argBreite) => {
        try {
            //breite = latitude
            if (argBreite === "." || argBreite === "+" || argBreite === "-") {
                setBreite(argBreite);
                setCordinatesError({ error: false, errorMessage: "" });
            } else if (isNaN(argBreite)) {
                setCordinatesError({ error: true, errorMessage: "Bitte geben Sie gültige Koordinaten ein" });
            }
            else if (Number(argBreite) > 90 || Number(argBreite) < -90) {
                setCordinatesError({ error: true, errorMessage: "Bitte geben Sie gültige Koordinaten ein" });
            } else {
                setBreite(argBreite);
                setCordinatesError({ error: false, errorMessage: "" });
            }
        } catch (error) {
            if (props.isClient) {
                ReactGA.event("rc_stepercomponent", {
                    rc_stepercomponent_error: "202208111229 - " + error
                });
            } else {
                console.log('202208111229 - ' + error);
            }
        }
    }

    /* #################FUNCTIONS################## */

    return (
        <Fragment>
            <Grid item md={3} sm={12} xs={12}>
                <div className={classes.stepperLeftContainer}>
                    <div className={classes.stepperLeftSectTop}>
                        <div className={hookActivePage === "Auskunftsdaten" ? classes.OptionItemSelected : classes.OptionItem} onClick={setAuskunftsdatenActive}>
                            <span className={hookActivePage === "Auskunftsdaten" ? classes.optionNumberSelected : classes.optionNumber}>1</span>
                            <span className={classes.optionText}>Auskunftsdaten</span>
                            <span className={classes.optionTick}>
                                <CheckOutlinedIcon className={hookAuskunftsdatenValid ? classes.finishIconsValid : classes.finishIcons} fontSize="large" />
                            </span>
                        </div>
                        <div className={hookActivePage === "PrufenAnfordern" ? classes.OptionItemSelected : classes.OptionItem} onClick={setPrufenAnfordernActive}>
                            <span className={hookActivePage === "PrufenAnfordern" ? classes.optionNumberSelected : classes.optionNumber}>2</span>
                            <span className={classes.optionText}>Prüfen &amp; Anfordern</span>
                            <span className={classes.optionTick}>
                                <CheckOutlinedIcon className={hookPrufenAnfordernValid ? classes.finishIconsValid : classes.finishIcons} fontSize="large" />
                            </span>
                        </div>
                        <div className={hookActivePage === "Wetterauskunft" ? classes.OptionItemSelected : classes.OptionItem} onClick={setWetterauskunftActive}>
                            <span className={hookActivePage === "Wetterauskunft" ? classes.optionNumberSelected : classes.optionNumber}>3</span>
                            <span className={classes.optionText}>Wetterauskunft</span>
                            <span className={classes.optionTick}>
                                <CheckOutlinedIcon className={hookWetterauskunftValid ? classes.finishIconsValid : classes.finishIcons} fontSize="large" />
                            </span>
                        </div>
                    </div>
                    <div className={classes.stepperLeftSectBottom}>
                    </div>
                </div>
            </Grid>
            <Grid item md={9} sm={12} xs={12}>
                <div className={classes.stepperRightContainer}>
                    {
                        hookActivePage === "Auskunftsdaten" ?
                            <AuskunftsdatenComponent
                                isClient={props.isClient}
                                selectedLocation={hookOrt}
                                lange={hookLange}
                                breite={hookBreite}
                                locations={hookLocations}
                                startDate={hookStartDatumZeit}
                                endDate={hookEndDatumZeit}
                                strasse={hookStrasse}
                                hausNummer={hookHausnummer}
                                TemperaturChecked={hookTemperatur}
                                NiederschlagsmengeChecked={hookNiederschlagsmenge}
                                GlatteFrostChecked={hookGlatteFrost}
                                WindgeschwindigkeitChecked={hookWindgeschwindigkeit}
                                HagelChecked={hookHagel}
                                BlitzChecked={hookBlitz}
                                SichtLichtverhaltnisseChecked={hookSichtLichtverhaltnisse}
                                OrtError={hookOrtError}
                                StrasseError={hookStrasseError}
                                HausNummerError={hookHausnummerError}
                                StartDateError={hookStartDatumError}
                                EndDateError={hookEndDatumError}
                                WetterauskunftError={hookWetterauskunftError}
                                getLocation={getLocation}
                                /*getLocationLatLon={getLocationbyLatLon}*/
                                setLangeVal={handleLangeChange}
                                setBreiteVal={handleBreiteChange}
                                coordinatesError={hookCordinatesError}
                                setPrufenAnfordernActive={setPrufenAnfordernActive}
                                setSelLocName={setSelLocName}
                                setStrasse={setStrasse}
                                setHausnummer={setHausnummer}
                                setStartDateAndTime={setStartDateAndTime}
                                setEndDateAndTime={setEndDateAndTime}
                                setToggleTemperatur={setToggleTemperatur}
                                setToggleNiederschlagsmenge={setToggleNiederschlagsmenge}
                                setToggleGlatteFrost={setToggleGlatteFrost}
                                setToggleWindgeschwindigkeit={setToggleWindgeschwindigkeit}
                                setToggleHagel={setToggleHagel}
                                setToggleBlitz={setToggleBlitz}
                                setToggleSichtLichtverhaltnisse={setToggleSichtLichtverhaltnisse}
                                clearForm={clearForm}
                                latLonLocationLoading={hookLatLonLocLoading}
                            />
                            :
                            hookActivePage === "PrufenAnfordern" ?
                                <PrufenAnfordenComponent
                                    isClient={props.isClient}
                                    selectedLocation={hookOrt}
                                    selLocationByLocation={hookOrt}
                                    /*selLocationByLatLon={hookLatLonOrt}*/
                                    selLange={hookLange}
                                    selBreite={hookBreite}
                                    strasse={hookStrasse}
                                    houseNummer={hookHausnummer}
                                    startDate={hookStartDatumZeit}
                                    endDate={hookEndDatumZeit}
                                    temperaturChecked={hookTemperatur}
                                    NiederschlagsmengeChecked={hookNiederschlagsmenge}
                                    GlatteFrostChecked={hookGlatteFrost}
                                    WindgeschwindigkeitChecked={hookWindgeschwindigkeit}
                                    HagelChecked={hookHagel}
                                    BlitzChecked={hookBlitz}
                                    SichtLichtverhaltnisseChecked={hookSichtLichtverhaltnisse}
                                    setAuskunftsdatenActive={setAuskunftsdatenActive}
                                    setWetterauskunftActive={setWetterauskunftActive}
                                    fetchWeatherData ={fetchWeatherData} />
                                :
                                hookActivePage === "Wetterauskunft" ?
                                    <WetterauskunftComponent
                                        isClient={props.isClient}
                                        selectedLocation={hookOrt}
                                        selLocationByLocation={hookOrt}
                                        /*selLocationByLatLon={hookLatLonOrt}*/
                                        lange={hookLange}
                                        breite={hookBreite}
                                        strasse={hookStrasse}
                                        houseNummer={hookHausnummer}
                                        startDate={hookStartDatumZeit}
                                        endDate={hookEndDatumZeit}
                                        temperaturChecked={hookTemperatur}
                                        NiederschlagsmengeChecked={hookNiederschlagsmenge}
                                        GlatteFrostChecked={hookGlatteFrost}
                                        WindgeschwindigkeitChecked={hookWindgeschwindigkeit}
                                        HagelChecked={hookHagel}
                                        BlitzChecked={hookBlitz}
                                        SichtLichtverhaltnisseChecked={hookSichtLichtverhaltnisse}
                                        setWetterauskunftActive={setWetterauskunftActive}
                                        clearForm={clearForm}
                                        weatherData = {hookWeatherData}
                                        blitzData={hookBlitzData}
                                        blitzDataError={hookBlitzDataError}
                                    /> :
                                    null
                    }
                </div>
            </Grid>
            <Backdrop className={classes.backdrop} open={hookPDFProcessing}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar open={hookWeatherDataError.error} autoHideDuration={5000} onClose={HandleWeatherDataMessageClose} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
                    <Alert onClose={HandleWeatherDataMessageClose} severity="info">
                        {hookWeatherDataError.errorMessage}
                    </Alert>
                </Snackbar>
        </Fragment>
    );
});

export default StepperComponent;