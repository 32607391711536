import React, { Fragment, memo, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Typography,
    Grid,
    Divider,
    TextField,
    IconButton,
    InputAdornment,
    FormControlLabel,
    Checkbox,
    Button
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DateFnsUtils from '@date-io/date-fns';
import { de } from "date-fns/locale";
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import ChevronRightOutlinedIcon from '@material-ui/icons/ChevronRightOutlined';
import {Helmet} from "react-helmet";
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactGA from "react-ga4";


const useStyles = makeStyles((theme) => ({
    pageHeader: {
        textAlign: 'left',
        fontSize: '2rem',
        fontWeight: '400',
        fontFamily: '"Exo 2", sans-serif',
        lineHeight: '1',
        color: 'rgba(0,0,0,.9)',
    },
    pageSubHeader: {
        textAlign: 'left',
        fontSize: '1rem',
        fontWeight: '400',
        fontFamily: '"Exo 2", sans-serif',
        color: 'rgba(0,0,0,.8)',
    },
    sectDivider: {
        marginTop: '10px',
        marginBottom: '10px'
    },
    pageLabel: {
        textAlign: 'left',
        fontSize: '1rem',
        fontWeight: '500',
        fontFamily: '"Exo 2", sans-serif',
        color: 'rgba(0,0,0,.8)',
    },
    pageSubLabel: {
        textAlign: 'left',
        fontSize: '1rem',
        fontWeight: '400',
        fontFamily: '"Exo 2", sans-serif',
        color: 'rgba(0,0,0,.7)',
    },
    pageInfoLabel: {
        textAlign: 'left',
        fontSize: '.8rem',
        fontWeight: '400',
        fontFamily: '"Exo 2", sans-serif',
        color: 'rgba(0,0,0,.8)',
        fontStyle: 'italic',
    },
    locationSearch: {
        fontFamily: '"Exo 2", sans-serif',
        '& .MuiFormControl-root': {
            marginTop: '0px',
            marginBottom: '0px',
            fontFamily: '"Exo 2", sans-serif',
        },
        '& .MuiInputBase-root': {
            borderRadius: '0px'
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#009fe0',
            borderWidth: '2px'
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#009fe0',
            borderWidth: '2px'
        },
    },
    formTextField: {
        fontFamily: '"Exo 2", sans-serif',
        '& .MuiFormControl-root': {
            marginTop: '0px',
            marginBottom: '0px',
            fontFamily: '"Exo 2", sans-serif',
        },
        '& .MuiInputBase-root': {
            borderRadius: '0px'
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#009fe0',
            borderWidth: '2px'
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#009fe0',
            borderWidth: '2px'
        }
    },
    datePickers: {
        fontFamily: '"Exo 2", sans-serif',
        '& .MuiOutlinedInput-root': {
            borderRadius: '0px',
        },
        '& .MuiInputBase-input': {
            padding: '12px 14px'
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#009fe0',
            borderWidth: '2px'
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#009fe0',
            borderWidth: '2px'
        },
    },
    checkbox: {
        '& .MuiFormControlLabel-label': {
            fontFamily: '"Exo 2", sans-serif',
            color: 'rgba(0, 0, 0, .8)'
        }
    },
    formButton: {
        color: '#fff',
        fontFamily: '"Exo 2", sans-serif',
        textTransform: 'capitalize',
        borderRadius: '25px',
        minWidth: '150px',
        '&:hover': {
            backgroundColor: '#0098d6'
        }
    },
    pageError: {
        color: '#f44336',
        fontSize: '0.75rem',
        fontFamily: '"Exo 2", sans-serif',
    },
    checkBoxContainer:{
        paddingTop:'0px !important',
        paddingBottom:'0px !important',
    },
    formClearButton: {
        color: '#0098d6',
        fontFamily: '"Exo 2", sans-serif',
        textTransform: 'capitalize',
        borderRadius: '25px',
        borderWidth: '2px',
        minWidth: '100px',
        '&:hover': {
            backgroundColor: '#0098d6',
            borderWidth: '2px',
            color: '#fff'
        }
    },
}));

const AuskunftsdatenComponent = memo((props) => {

    const classes = useStyles();

    //console.log('min date', props.startDate);

    const [hookMaxDate, setMaxDate] = React.useState(new Date());
    //const [hookMinDate, setMinDate] = React.useState(new Date("01/01/2020"));
    const [hookLangeBreiteChange, setLangeBreiteChange] = React.useState(0);
    const [hookIsClient, setIsClient] = useState(false);

    let timeout = 0;

    useEffect(() => {
        try {
            async function checkLogin() {
                let isClient = sessionStorage.getItem('isClient');
                if (isClient && isClient === 'true') {
                    setIsClient(true);
                } else {
                    setIsClient(false);
                }
            }
            checkLogin();
        } catch (error) {
            if (hookIsClient) {
                ReactGA.event("rc_ankunftdaten", {
                    rc_ankunftdaten_error: "202208111133" + error
                });
            }
        }
    });

    // eslint-disable-next-line no-extend-native
    Date.prototype.addDays = function (days) {
        try{
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        if (new Date() <= new Date(date)) {
            return new Date();
        }
        else {
            return date;
        }
        }catch(error){
            if (hookIsClient) {
                ReactGA.event("rc_ankunftdaten", {
                    rc_ankunftdaten_error: "202208111134" + error
                });
            }
        }
    }

    const getMaxDate = () => {
        try{
        /* Get max date
        max date should be 'min date + 5' unless 'min date + 5' is greater than current date */
        if (props.startDate) {
            var date = new Date(props.startDate);
            setMaxDate(new Date(date.addDays(4)));
        }
        }catch(error){
            if (hookIsClient) {
                ReactGA.event("rc_ankunftdaten", {
                    rc_ankunftdaten_error: "202208111135" + error
                });
            }
        }
    }

    useEffect(() => {
        getMaxDate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.startDate]);

    /*useEffect(() => {
        if (props.breite !== undefined && props.lange !== undefined && props.breite !== "" && props.lange !== ""){
            fetchLocDetails();
        }
    },[hookLangeBreiteChange]);

    const fetchLocDetails = () => {
        props.getLocationLatLon(props.breite, props.lange);
    }*/

    const handleLangeValueChange = (argLangeValue) => {
        try{
            props.setLangeVal(argLangeValue);
        }catch(error){
            if (hookIsClient) {
                ReactGA.event("rc_ankunftdaten", {
                    rc_ankunftdaten_error: "202208111136" + error
                });
            }
        }
    }

    const handleBreiteValueChange = (argBreiteValue) => {
        try {
            props.setBreiteVal(argBreiteValue);
        } catch (error) {
            if (hookIsClient) {
                ReactGA.event("rc_ankunftdaten", {
                    rc_ankunftdaten_error: "202208111137" + error
                });
            }
        }
    }

    /*const getLocationDetails = () => {
        try{
            clearTimeout(timeout);
            timeout = setTimeout(function () {
                setLangeBreiteChange(hookLangeBreiteChange + 1);
            }, 3000);
        }catch(error){
            console.log('202207291045', error);
        }
    }*/

    return (
        <Fragment>
            <Helmet>
                <title>Q.met Risk Control - Auskunftsdaten</title>
            </Helmet>
            <Grid container spacing={2} direction="row" justifyContent="flex-end" alignItems="center">
                <Grid item sm={12}>
                    <Typography variant="h3" component="h3" className={classes.pageHeader}>Auskunftsdaten</Typography>
                    <Typography variant="h6" component="h6" className={classes.pageSubHeader}>Bitte geben Sie die gewünschte Abfrage ein:</Typography>
                </Grid>
                <Grid item sm={12}>
                    <Divider className={classes.sectDivider} />
                </Grid>
            </Grid>
            <form className={classes.root} noValidate autoComplete="off">
                <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">

                    <Grid item sm={2} className='HideOnMobile'>
                        <Typography variant="subtitle1" className={classes.pageLabel}>Ort</Typography>
                    </Grid>
                    <Grid item sm={2}>
                        <Typography variant="subtitle1" className={classes.pageSubLabel}>Geogr. Länge</Typography>
                    </Grid>
                    <Grid item md={2} sm={12} xs={12}>
                        <TextField
                            required
                            id="outlined-lange"
                            variant="outlined"
                            size="small"
                            fullWidth
                            className={classes.formTextField}
                            value={props.lange}
                            onChange={(event) => { handleLangeValueChange(event.target.value) }}
                            error={props.coordinatesError.error}
                            /*onKeyUp={getLocationDetails}*/
                            /*SINCE THE WEATHER DATA API NOT USING ZIP CODE ANY MORE NO NEED TO FETCH LOCATION DETAILS WITH LAT AND LON ANYMORE */
                        />
                    </Grid>

                    <Grid item sm={2}>
                        <Typography variant="subtitle1" className={classes.pageSubLabel}>&nbsp; &nbsp; &nbsp; &nbsp; Geogr. Breite</Typography>
                    </Grid>
                    <Grid item md={2} sm={12} xs={12}>
                        <TextField
                            required
                            id="outlined-required"
                            variant="outlined"
                            size="small"
                            fullWidth
                            className={classes.formTextField}
                            value={props.breite}
                            onChange={(event) => { handleBreiteValueChange(event.target.value) }}
                            error={props.coordinatesError.error}
                            /*onKeyUp={getLocationDetails}*/
                        />
                    </Grid>
                    <Grid item sm={2} className='HideOnMobile'>
                        {props.latLonLocationLoading ? (<CircularProgress size={30} />) : null}
                    </Grid>

                        {props.coordinatesError ? props.coordinatesError.error ?
                        (<React.Fragment>
                            <Grid item sm={2} className='HideOnMobile'></Grid>
                            <Grid item sm={10}>
                                <Typography variant="body1" className={classes.pageError}>
                                    {props.coordinatesError.errorMessage}
                                </Typography>
                            </Grid>
                        </React.Fragment>) : null : null}

                    <Grid item sm={2}>
                        <Typography variant="subtitle1" className={classes.pageLabel}></Typography>
                    </Grid>
                    <Grid item sm={2}>
                        <Typography variant="subtitle1" className={classes.pageSubLabel}>PLZ / Ort</Typography>
                    </Grid>
                    {/*<Grid item sm={2}>
                        <TextField
                            required
                            id="outlined-required"
                            defaultValue=""
                            variant="outlined"
                            size="small"
                            fullWidth
                            className={classes.formTextField}
                        />
                    </Grid>*/}
                    <Grid item md={6} sm={12} xs={12}>
                        <Autocomplete
                            className={classes.locationSearch}
                            id="search-location"
                            freeSolo={false}
                            value={props.selectedLocation}
                            disableClearable
                            size="small"
                            closeIcon={null}
                            options={props.locations}
                            getOptionLabel={(location) => location.postal_code + " " + location.name}
                            getOptionSelected={(option, value) => option.name === value.name}
                            onInputChange={(event, value, reason) => { props.getLocation(value) }}
                            onChange={props.setSelLocName}
                            noOptionsText={<span>Kein Standort gefunden!</span>}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    margin="normal"
                                    variant="outlined"
                                    InputProps={{ ...params.InputProps, type: 'search' }}
                                    error={props.OrtError.error}
                                />
                            )}
                        />
                        <span className={classes.pageInfoLabel}>Bitte nur deutsche Orte eingeben.</span>
                    </Grid>
                    <Grid item sm={2} className='HideOnMobile'>
                        <Typography variant="subtitle1" className={classes.pageSubLabel}></Typography>
                    </Grid>

                    {props.OrtError ? props.OrtError.error ?
                        (<React.Fragment>
                            <Grid item sm={2} className='HideOnMobile'></Grid>
                            <Grid item sm={10}>
                                <Typography variant="body1" className={classes.pageError}>
                                    {props.OrtError.errorMessage}
                                </Typography>
                            </Grid>
                        </React.Fragment>) : null : null}

                    <Grid item sm={2} className='HideOnMobile'>
                        <Typography variant="subtitle1" className={classes.pageLabel}></Typography>
                    </Grid>
                    <Grid item sm={2}>
                        <Typography variant="subtitle1" className={classes.pageSubLabel}>Straße und Nummer</Typography>
                    </Grid>
                    <Grid item md={4} sm={12} xs={12}>
                        <TextField
                            required
                            id="outlined-required"
                            variant="outlined"
                            size="small"
                            fullWidth
                            className={classes.formTextField}
                            value={props.strasse}
                            onChange={(event) => { props.setStrasse(event.target.value) }}
                            error={props.StrasseError.error}
                            helperText={props.StrasseError.errorMessage}
                        />
                    </Grid>
                    <Grid item md={2} sm={12} xs={12}>
                        <TextField
                            required
                            id="outlined-required"
                            variant="outlined"
                            size="small"
                            fullWidth
                            className={classes.formTextField}
                            value={props.hausNummer}
                            onChange={(event) => { props.setHausnummer(event.target.value) }}
                            error={props.HausNummerError.error}
                            helperText={props.HausNummerError.errorMessage}
                        />
                    </Grid>
                    <Grid item sm={2} className='HideOnMobile'>
                        <Typography variant="subtitle1" className={classes.pageSubLabel}></Typography>
                    </Grid>
                    <Grid item sm={12} className='HideOnMobile'>
                        <Divider className={classes.sectDivider} />
                    </Grid>
                </Grid>

                <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                    <Grid item sm={2}>
                        <Typography variant="subtitle1" className={classes.pageLabel}>Zeit</Typography>
                    </Grid>
                    <Grid item sm={2}>
                        <Typography variant="subtitle1" className={classes.pageSubLabel}>Startdatum und -zeit</Typography>
                    </Grid>
                    <Grid item md={4} sm={12} xs={12}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={de}>
                            <DateTimePicker
                                className={classes.datePickers}
                                value={props.startDate}
                                onChange={props.setStartDateAndTime}
                                inputVariant="outlined"
                                fullWidth
                                minDate={new Date("01/01/2020")}
                                maxDate={new Date()}
                                error={props.StartDateError.error}
                                helperText={props.StartDateError.errorMessage}
                                ampm={false}
                                autoOk
                                format="dd.MM.yyyy HH:mm"
                                cancelLabel="Abbrechen"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton>
                                                <DateRangeOutlinedIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }} />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item sm={4} className='HideOnMobile'>
                        <Typography variant="subtitle1" className={classes.pageSubLabel}></Typography>
                    </Grid>
                    <Grid item sm={2} className='HideOnMobile'>
                        <Typography variant="subtitle1" className={classes.pageLabel}></Typography>
                    </Grid>
                    <Grid item sm={2}>
                        <Typography variant="subtitle1" className={classes.pageSubLabel}>Enddatum und -zeit</Typography>
                    </Grid>
                    <Grid item md={4} sm={12} xs={12}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={de}>
                            <DateTimePicker
                                className={classes.datePickers}
                                value={props.endDate}
                                onChange={props.setEndDateAndTime}
                                inputVariant="outlined"
                                fullWidth
                                autoOk
                                //maxDate={new Date()}
                                minDate={new Date("01/01/2020")}
                                maxDate={hookMaxDate || new Date()}
                                error={props.EndDateError.error}
                                helperText={props.EndDateError.errorMessage}
                                ampm={false}
                                format="dd.MM.yyyy HH:mm"
                                cancelLabel="Abbrechen"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton>
                                                <DateRangeOutlinedIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }} />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item sm={4}>
                        <Typography variant="subtitle1" className={classes.pageInfoLabel}>Bitte wählen Sie maximal 5 Tage</Typography>
                    </Grid>
                    <Grid item sm={12} className='HideOnMobile'>
                        <Divider className={classes.sectDivider} />
                    </Grid>
                </Grid>
                <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                    <Grid item md={2} sm={12} xs={12}>
                        <Typography variant="subtitle1" className={classes.pageLabel}>Wetterereignis</Typography>
                    </Grid>
                    <Grid item md={4} sm={12} xs={12} textAlign="left" className={classes.checkBoxContainer}>
                        <FormControlLabel
                            className={classes.checkbox}
                            control={
                                <Checkbox
                                    checked={props.TemperaturChecked}
                                    name="checkedB"
                                    color="primary"
                                    size="medium"
                                    onChange={(event) => { props.setToggleTemperatur(event.target.checked) }}
                                />
                            }
                            label="Temperatur / Frost"
                        />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12} textAlign="left" className={classes.checkBoxContainer}>
                        <FormControlLabel
                            className={classes.checkbox}
                            control={
                                <Checkbox
                                    checked={props.GlatteFrostChecked}
                                    name="checkedB"
                                    color="primary"
                                    size="medium"
                                    onChange={(event) => { props.setToggleGlatteFrost(event.target.checked) }}
                                />
                            }
                            label="Glätte"
                        />
                    </Grid>
                    <Grid item sm={2} className='HideOnMobile'>
                        <Typography variant="subtitle1" className={classes.pageLabel}></Typography>
                    </Grid>
                    <Grid item sm={4} textAlign="left" className={classes.checkBoxContainer}>
                        <FormControlLabel
                            className={classes.checkbox}
                            control={
                                <Checkbox
                                    checked={props.NiederschlagsmengeChecked}
                                    name="checkedB"
                                    color="primary"
                                    size="medium"
                                    onChange={(event) => { props.setToggleNiederschlagsmenge(event.target.checked) }}
                                />
                            }
                            label="Niederschlagsmenge"
                        />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12} textAlign="left" className={classes.checkBoxContainer}>
                        <FormControlLabel
                            className={classes.checkbox}
                            control={
                                <Checkbox
                                    checked={props.WindgeschwindigkeitChecked}
                                    name="checkedB"
                                    color="primary"
                                    size="medium"
                                    onChange={(event) => { props.setToggleWindgeschwindigkeit(event.target.checked) }}
                                />
                            }
                            label="Windgeschwindigkeit"
                        />
                    </Grid>
                    <Grid item sm={2} className='HideOnMobile'>
                        <Typography variant="subtitle1" className={classes.pageLabel}></Typography>
                    </Grid>
                    <Grid item md={4} sm={12} xs={12} textAlign="left" className={classes.checkBoxContainer}>
                        <FormControlLabel
                            className={classes.checkbox}
                            helperText="Bitte wählen Sie maximal 5 Tage"
                            control={
                                <Checkbox
                                    checked={props.HagelChecked}
                                    name="checkedB"
                                    color="primary"
                                    size="medium"
                                    onChange={(event) => { props.setToggleHagel(event.target.checked) }}/>
                            }
                            label="Hagel"/>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12} textAlign="left" className={classes.checkBoxContainer}>
                        <FormControlLabel
                            className={classes.checkbox}
                            control={
                                <Checkbox
                                    checked={props.BlitzChecked}
                                    name="checkedB"
                                    color="primary"
                                    size="medium"
                                    onChange={(event) => { props.setToggleBlitz(event.target.checked) }}/>
                            }
                            label="Blitz"/>
                    </Grid>
                    <Grid item sm={2} className='HideOnMobile'>
                        <Typography variant="subtitle1" className={classes.pageLabel}></Typography>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12} textAlign="left" className={classes.checkBoxContainer}>
                        <FormControlLabel
                            className={classes.checkbox}
                            control={
                                <Checkbox
                                    checked={props.SichtLichtverhaltnisseChecked}
                                    name="checkedB"
                                    color="primary"
                                    size="medium"
                                    onChange={(event) => { props.setToggleSichtLichtverhaltnisse(event.target.checked) }}
                                />
                            }
                            label="Sicht/Lichtverhältnisse"
                        />
                    </Grid>
                    <Grid item sm={4}>
                        <Typography variant="subtitle1" className={classes.pageLabel}></Typography>
                    </Grid>
                    <Grid item sm={2}>
                        <Typography variant="subtitle1" className={classes.pageLabel}></Typography>
                    </Grid>
                    <Grid item sm={10} textAlign="left">
                        <Typography variant="body1" className={classes.pageError}>
                            {
                                props.WetterauskunftError.error ?
                                    props.WetterauskunftError.errorMessage :
                                    null
                            }
                        </Typography>
                    </Grid>
                    <Grid item sm={12} className='HideOnMobile'>
                        <Divider className={classes.sectDivider} />
                    </Grid>
                </Grid>
                <Grid container spacing={2} direction="row" justifyContent="flex-end" alignItems="center">
                    <Grid item sm={12} style={{ textAlign: 'right' }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            className={classes.formClearButton}
                            style={{ marginRight: '15px' }}
                            onClick={props.clearForm}>
                            Löschen
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.formButton}
                            startIcon={<ChevronRightOutlinedIcon />}
                            onClick={props.setPrufenAnfordernActive}>
                            Weiter
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Fragment>
    );
});

export default AuskunftsdatenComponent;
