import React, { memo, Fragment, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button } from '@material-ui/core';
import ReactGA from "react-ga4";
import { useHistory } from "react-router-dom";

import StepperContainer from '../StepperContainer/StepperContainer';

const useStyles = makeStyles((theme) => ({
    loginContainer: {
        minHeight: '80vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    containerParent: {
        backgroundColor: '#F5F5F5',
        borderRadius: '10px',
        padding: '15px 30px',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
    },
    pageHead: {
        textAlign: 'center',
        marginTop: '5px',
        fontSize: '1.5rem',
    },
    textFieldContainer: {
        marginTop: '10px',
    },
    buttonContainer: {
        marginTop: '25px',
        textAlign: 'right',
    },
    textField: {
        minWidth: '400px',
        fontSize: '.8rem',
    },
    buttonControlClear: {
        marginLeft: '5px',
        backgroundColor: '#000',
    },
    buttonControlLogin: {
        marginLeft: '5px',
        backgroundColor: 'rgb(0, 159, 224)',
    },
    errorMessage: {
        color: 'red',
        fontSize: '.8rem',
    }
}));

const hardUserName = "riskcontrol";
const hardPasswordAdmin = "nH9j#CN#";
const hardPasswordClient = "@SdJz95F";
const hardPasswordClientNoTime = "V$rVg?6P";
const hardPasswordClientTwoNoTime = "f%VbJ7ta";
const endDate = new Date(2022, 3, 10, 23, 59, 59, 59);

const LoginContainer = memo((props) => {

    const classes = useStyles();
    let history = useHistory();

    const [hookUserName, setUserName] = useState('');
    const [hookPassword, setPassword] = useState('');
    const [hookLoginValidation, setLoginValidation] = useState({ userNameError: false, userNameErrorMessage: "", passwordError: false, passwordErrorMessage: "" });
    const [hookLoginError, setLoginError] = useState({ loginError: false, loginErrorMessage: "" });
    const [hookIsLoggedIn, setIsLoggedIn] = useState(false);
    const [hookLogout, setLogout] = useState(false);
    const [hookIsClient, setIsClient] = useState(false);

    useEffect(() => {
        try {
            async function checkLogin() {
                let loginData = sessionStorage.getItem('isLoggedIn');
                if (loginData && loginData === 'true') {
                    setIsLoggedIn(true);
                } else {
                    setIsLoggedIn(false);
                }
                let isClient = sessionStorage.getItem('isClient');
                if (isClient && isClient === 'true') {
                    setIsClient(true);
                } else {
                    setIsClient(false);
                }
            }
            checkLogin();
        } catch (error) {
            if (hookIsClient){
                ReactGA.event("rc_login", {
                    rc_login_error: "202208111124" + error
                });
            }
        }
    });

    const handleUserNameChange = (argEvent) => {
        try {
            setUserName(argEvent.target.value);
            setLoginValidation({ ...hookLoginValidation, userNameError: false, userNameErrorMessage: "" });
            setLoginError({ loginError: false, loginErrorMessage: "" });
        } catch (error) {
            if (hookIsClient) {
                ReactGA.event("rc_login", {
                    rc_login_error: "202208111125" + error
                });
            }
        }
    }

    const handlePasswordChange = (argEvent) => {
        try {
            setPassword(argEvent.target.value);
            setLoginValidation({ ...hookLoginValidation, passwordError: false, passwordErrorMessage: "" });
            setLoginError({ loginError: false, loginErrorMessage: "" });
        } catch (error) {
            if (hookIsClient) {
                ReactGA.event("rc_login", {
                    rc_login_error: "202208111126" + error
                });
            }
        }
    }

    const validateLogin = () => {
        try {
            if (!hookUserName || hookUserName === '' || hookUserName === null || hookUserName === undefined) {
                setLoginValidation({ ...hookLoginValidation, userNameError: true, userNameErrorMessage: "Bitte geben sie einen Benutzernamen ein" });
                return false;
            }

            if (!hookPassword || hookPassword === '' || hookPassword === null || hookPassword === undefined) {
                setLoginValidation({ ...hookLoginValidation, passwordError: true, passwordErrorMessage: "Bitte geben sie ein Passwort ein" });
                return false;
            }
            return true;
        } catch (error) {
            if (hookIsClient) {
                ReactGA.event("rc_login", {
                    rc_login_error: "202208111127" + error
                });
            }
        }
    }

    const handleLogin = () => {
        try {
            if (validateLogin()) {
                if (hookUserName && hookUserName.toString() === hardUserName.toString()) {
                    if (hookPassword && hookPassword.toString() === hardPasswordAdmin.toString()) {
                        setLoginError({ loginError: false, loginErrorMessage: "" });
                        sessionStorage.setItem('isLoggedIn', 'true');
                        sessionStorage.setItem('isClient', 'false');
                        history.push("/");
                    } else if (hookPassword && hookPassword.toString() === hardPasswordClientNoTime.toString()) {
                            setLoginError({ loginError: false, loginErrorMessage: "" });
                            sessionStorage.setItem('isLoggedIn', 'true');
                            sessionStorage.setItem('isClient', 'true');
                            history.push("/");
                    } else if (hookPassword && hookPassword.toString() === hardPasswordClientTwoNoTime.toString()) {
                        setLoginError({ loginError: false, loginErrorMessage: "" });
                        sessionStorage.setItem('isLoggedIn', 'true');
                        sessionStorage.setItem('isClient', 'true');
                        history.push("/");
                    } else if (hookPassword && hookPassword.toString() === hardPasswordClient.toString()) {
                        if (new Date(endDate) < new Date()) {
                            sessionStorage.setItem('isLoggedIn', 'false');
                            sessionStorage.removeItem('isLoggedIn');
                            setLoginError({ loginError: true, loginErrorMessage: "Fehler bei der Anmeldung! Bitte versuche es erneut" });
                        } else {
                            setLoginError({ loginError: false, loginErrorMessage: "" });
                            sessionStorage.setItem('isLoggedIn', 'true');
                            history.push("/");
                        }
                    } else {
                        sessionStorage.setItem('isLoggedIn', 'false');
                        setLoginError({ loginError: true, loginErrorMessage: "Fehler bei der Anmeldung! Bitte versuche es erneut" });
                    }
                } else {
                    sessionStorage.setItem('isLoggedIn', 'false');
                    setLoginError({ loginError: true, loginErrorMessage: "Fehler bei der Anmeldung! Bitte versuche es erneut" });
                }
            }
        } catch (error) {
            sessionStorage.setItem('isLoggedIn', 'false');
            setLoginError({ loginError: true, loginErrorMessage: "Fehler bei der Anmeldung! Bitte versuche es erneut" });
            if (hookIsClient) {
                ReactGA.event("rc_login", {
                    rc_login_error: "202208111128" + error
                });
            }
        }
    }

    const handleClearForm = () => {
        try {
            setUserName('');
            setPassword('');
            setLoginValidation({ userNameError: false, userNameErrorMessage: "", passwordError: false, passwordErrorMessage: "" });
            setIsLoggedIn(false);
            setLoginError({ loginError: false, loginErrorMessage: "" });
        } catch (error) {
            if (hookIsClient) {
                ReactGA.event("rc_login", {
                    rc_login_error: "202208111129" + error
                });
            }
        }
    }

    const handleLogout = () => {
        try {
            sessionStorage.setItem('isLoggedIn', 'false');
            sessionStorage.removeItem('isLoggedIn');
            sessionStorage.removeItem('isClient');
            setLogout(!hookLogout);
        } catch (error) {
            if (hookIsClient) {
                ReactGA.event("rc_login", {
                    rc_login_error: "202208111130" + error
                });
            }
        }
    }

    let componentOrganizer = null;

    try{
    if (hookIsLoggedIn) {
        componentOrganizer = (<StepperContainer logoutApp={handleLogout} isClient={hookIsClient}/>);
    } else {
        componentOrganizer = (
            <div className={classes.loginContainer}>
                <div className={classes.containerParent}>
                    <h3 className={classes.pageHead}>Login</h3>
                    <div>
                        <div className={classes.textFieldContainer}>
                            <TextField
                                autoFocus
                                className={classes.textField}
                                error={hookLoginValidation.userNameError}
                                id="username-text"
                                label="Nutzername"
                                helperText={hookLoginValidation.userNameErrorMessage}
                                variant="standard"
                                size='small'
                                type="text"
                                onChange={handleUserNameChange}
                                value={hookUserName}
                            />
                        </div>
                        <div className={classes.textFieldContainer}>
                            <TextField
                                className={classes.textField}
                                error={hookLoginValidation.passwordError}
                                id="password-text"
                                label="Passwort"
                                helperText={hookLoginValidation.passwordErrorMessage}
                                variant="standard"
                                size='small'
                                type="password"
                                onChange={handlePasswordChange}
                                value={hookPassword}
                            />
                        </div>
                        <div>
                            {
                                hookLoginError && hookLoginError.loginError ?
                                    <p className={classes.errorMessage}>{hookLoginError && hookLoginError.loginErrorMessage ? hookLoginError.loginErrorMessage : null}</p> : null
                            }
                        </div>
                        <div className={classes.buttonContainer}>
                            <Button variant="contained" size="small" color="primary" className={classes.buttonControlClear} onClick={handleClearForm}>
                                <span style={{ textTransform: 'capitalize', color: 'white' }}>Abbrechen</span>
                            </Button>
                            <Button variant="contained" size="small" color="primary" className={classes.buttonControlLogin} onClick={handleLogin}>
                                <span style={{ textTransform: 'capitalize', color: 'white' }}>Anmeldung</span>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    }catch(error){
        if (hookIsClient) {
            ReactGA.event("rc_login", {
                rc_login_error: "202208111131" + error
            });
        }
    }

    return (
        <Fragment>
            {componentOrganizer}
        </Fragment>
    );


});

export default LoginContainer;