import React, { Fragment, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Typography,
    Grid,
    Divider,
    Button
} from '@material-ui/core';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import ChevronRightOutlinedIcon from '@material-ui/icons/ChevronRightOutlined';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Helmet} from "react-helmet";
import ReactGA from "react-ga4";

import { TwoPagePDFDownload, TwoPagePDFPrint, PDFDownload, PDFPrint } from '../../Assets/Functions/PDFFunctions';

const useStyles = makeStyles((theme) => ({
    pageHeader: {
        textAlign: 'left',
        fontSize: '2rem',
        fontWeight: '400',
        fontFamily: '"Exo 2", sans-serif',
        lineHeight: '1',
        color: 'rgba(0,0,0,.9)',
    },
    pageSubHeader: {
        textAlign: 'left',
        fontSize: '1rem',
        fontWeight: '400',
        fontFamily: '"Exo 2", sans-serif',
        color: 'rgba(0,0,0,.8)',
    },
    pageSubHeaderBold: {
        textAlign: 'left',
        fontSize: '1rem',
        fontWeight: '500',
        fontFamily: '"Exo 2", sans-serif',
        color: 'rgba(0,0,0,.8)',
    },
    sectDivider: {
        marginTop: '5px',
        marginBottom: '15px'
    },
    pageLabel: {
        textAlign: 'left',
        fontSize: '1rem',
        fontWeight: '500',
        fontFamily: '"Exo 2", sans-serif',
        color: 'rgba(0,0,0,.8)',
    },
    formButton: {
        color: '#fff',
        fontFamily: '"Exo 2", sans-serif',
        textTransform: 'none',
        borderRadius: '25px',
        minWidth: '150px',
        marginBottom: '.5rem',
        '&:hover': {
            backgroundColor: '#0098d6'
        }
    },
    formClearButton: {
        color: '#0098d6',
        fontFamily: '"Exo 2", sans-serif',
        textTransform: 'capitalize',
        borderRadius: '25px',
        borderWidth: '2px',
        minWidth: '100px',
        marginBottom: '.5rem',
        '&:hover': {
            backgroundColor: '#0098d6',
            borderWidth: '2px',
            color: '#fff'
        }
    },
    noTPadding: {
        paddingTop: '2px !important',
        paddingBottom: '0px !important'
    },
    resultContainer: {
        padding: '10px 15px',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderRadius: '5px',
        backgroundColor: 'rgba(0,0,0,0.01)',
        marginTop: '1rem'
    },
    resultTable: {
        color: '#616161',
        width: '100%',
        textAlign: 'left',
        marginBottom: '5px',
        fontFamily: '"Exo 2", sans-serif',
        fontWeight: '400',
        borderCollapse: 'collapse',
        '& td': {
            width: '20%'
        },
        '& thead tr': {
            backgroundColor: 'transparent !important',
        },
        '& thead tr th': {
            fontFamily: '"Exo 2", sans-serif',
            fontWeight: '500 !important',
            padding: '10px 15px',
            color: '#212121',
        },
        '& tr:nth-child(odd)': {
            backgroundColor: 'rgba(0,0,0,0.1)'
        },
        '& tr:nth-child(even)': {
            backgroundColor: '#F5F5F5',
        },
        '& tbody tr': {
            borderBottom: '3px solid #fff',
        },
        '& tbody tr td': {
            padding: '8px 15px',
        },
        '& tbody tr td:nth-child(1)': {
            fontWeight: '600',
            borderRight: "3px solid #fff",
        },
        '& tbody tr td:nth-child(3)': {
            fontWeight: '600',
            borderRight: "3px solid #fff",
        },
        '& tbody tr:nth-child(1) td:nth-child(5)': {
            borderTopRightRadius: '8px'
        },
        '& tbody tr:nth-child(1) td:nth-child(1)': {
            borderTopLeftRadius: '8px',
        },
        '& tbody tr:last-of-type': {
            borderBottom: '0px solid #fff',
        },
        '& tbody tr:last-of-type td:nth-child(1)': {
            borderBottomLeftRadius: '8px',
        },
        '& tbody tr:last-of-type td:nth-child(5)': {
            borderBottomRightRadius: '8px',
        }
    },
    'disable': {
        color: '#BDBDBD !important',
    },
    resultValue: {
        fontWeight: '500',
        fontSize: '1.1rem',
        color: '#000',
    },
    dateValue: {
        padding: '0px',
        marginTop: '0px',
        marginBottom: '0px',
        color: 'rgba(0,0,0,.6)',
        fontWeight: '600'
    },
    tableContainerDiv: {
        overflowX: 'auto',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    precipitationTotal:{
        fontSize: '1.1rem',
        textAlign:'right',
        marginTop:'.1rem',
        marginBottom:'.2rem',
        fontWeight:400,
    },
    precipitationTotalContainer:{
        backgroundColor: 'rgba(0,0,0,0.05)',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        padding:'5px 10px',
        borderRadius: '5px',
        marginTop: '1rem',
    }
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const WetterauskunftComponent = memo((props) => {

    const classes = useStyles();

    const [hookPrintError, setPrintError] = React.useState(false);
    const [hookPDFProcessing, setPDFProcessing] = React.useState(false);
    const [hookReferenzstation, setReferenzstation] = React.useState('');

    let totalPrecipitation = undefined;

    const getDayOfWeek = (argDate) => {
        try {
            let date = new Date(argDate);
            return date.toLocaleDateString('de-DE', { weekday: 'long' })
        } catch (error) {
            if (props.isClient) {
                ReactGA.event("rc_wetterauskunft", {
                    rc_wetterauskunft_error: "202208111404 - " + error
                });
            } else {
                console.log('202208111404 - ' + error);
            }
        }
    }

    const getDate = (argDate) => {
        try {
            let date = new Date(argDate);
            return date.toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' })
        } catch (error) {
            if (props.isClient) {
                ReactGA.event("rc_wetterauskunft", {
                    rc_wetterauskunft_error: "202208111405 - " + error
                });
            } else {
                console.log('202208111405 - ' + error);
            }
        }
    }

    const getFormattedDate = (argDate) => {
        try {
            if (argDate) {
                const dateYear = new Date(argDate).getFullYear();
                const dateMonth = new Date(argDate).getMonth() + 1;
                const dateDay = new Date(argDate).getDate();
                return (dateYear + '-' + (Number(dateMonth) < 10 ? "0" + dateMonth : dateMonth) + '-' + (Number(dateDay) < 10 ? "0" + dateDay : dateDay));
            }
        } catch (error) {
            if (props.isClient) {
                ReactGA.event("rc_wetterauskunft", {
                    rc_wetterauskunft_error: "202208111406 - " + error
                });
            } else {
                console.log('202208111406 - ' + error);
            }
        }
    }

    const getTime = (argDate) => {
        try {
            let date = new Date(argDate);
            return date.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' })
        } catch (error) {
            if (props.isClient) {
                ReactGA.event("rc_wetterauskunft", {
                    rc_wetterauskunft_error: "202208111407 - " + error
                });
            } else {
                console.log('202208111407 - ' + error);
            }
        }
    }

    const dateDiffInDays = (argStart, argEnd) => {
        try {
            // Discard the time and time-zone information.
            const utc1 = Date.UTC(argStart.getFullYear(), argStart.getMonth(), argStart.getDate());
            const utc2 = Date.UTC(argEnd.getFullYear(), argEnd.getMonth(), argEnd.getDate());
            return Math.floor((utc2 - utc1) / (1000 * 60 * 60 * 24));
        } catch (error) {
            if (props.isClient) {
                ReactGA.event("rc_wetterauskunft", {
                    rc_wetterauskunft_error: "202208111408 - " + error
                });
            } else {
                console.log('202208111408 - ' + error);
            }
        }
    }

    // eslint-disable-next-line no-extend-native
    Date.prototype.addDays = function (days) {
        try {
            var date = new Date(this.valueOf());
            date.setDate(date.getDate() + days);
            return date;
        } catch (error) {
            if (props.isClient) {
                ReactGA.event("rc_wetterauskunft", {
                    rc_wetterauskunft_error: "202208111409 - " + error
                });
            } else {
                console.log('202208111409 - ' + error);
            }
        }
    }

    const localizeNumber = (argNumber) => {
        try {
            return (Number(argNumber).toLocaleString('de-DE'));
        } catch (error) {
            console.log('20220181154', error);
            if (props.isClient) {ReactGA.event({category: "Wetterauskunft page", action: "localizeNumber", label: "localizeNumber - Error"});}
        }
    }

    const getBlitzFromToTime = (argTimeArray) => {
        try{
            let formattedArray = [];
            if(argTimeArray && argTimeArray.length > 0){
                for(let i = 0; i < argTimeArray.length; i++){
                    if(argTimeArray[i]){
                        let YR = argTimeArray[i].toString().substr(0, 4);
                        let MON = argTimeArray[i].toString().substr(5, 2);
                        let DT = argTimeArray[i].toString().substr(8, 2);
                        let HR = argTimeArray[i].toString().substr(11, 2);
                        let MIN = argTimeArray[i].toString().substr(14, 2);
                        let SEC = argTimeArray[i].toString().substr(17, 2);
                        //let OFST = argTimeArray[i].toString().substr(24, 5);
                        let formattedDate =YR + "." + MON + "." + DT;
                        let formattedTime = HR + ":" + MIN/* + ":" + SEC*/;
                        //formattedTime = formattedTime.toLocaleString('de-DE', { timeZone: 'Europe/Berlin' });
                        formattedArray.push({"date":formattedDate.toString(), "time": formattedTime.toString()});
                    }
                }
                if(formattedArray.length > 0){
                    formattedArray.sort(function (a, b) {
                        return a.time.localeCompare(b.time);
                    })
                }
                return({"from":formattedArray[0].time, "to":formattedArray[formattedArray.length - 1].time});
            }else{
                return({"from":"", "to":""});
            }
        }catch(error){
            if (props.isClient) {
                ReactGA.event("rc_wetterauskunft", {
                    rc_wetterauskunft_error: "202208111410 - " + error
                });
            } else {
                console.log('202208111410 - ' + error);
            }
        }
    }

    const getBlitzData = (argDate, argBlitzData) => {
        try{
            let dateArray = [];
            let fromToTime = "";
            if(argBlitzData && argBlitzData.length > 0){
                for(let i = 0; i < argBlitzData.length; i++){
                    if(argBlitzData[i].time){
                        if(argDate.toString() === argBlitzData[i].time.toString().substring(0, 10)){
                            dateArray[i] = argBlitzData[i].time.toString();
                        }
                    }
                }
                fromToTime = getBlitzFromToTime(dateArray);
                for(let i = 0; i < argBlitzData.length; i++){
                    if(argBlitzData[i].time){
                        if(argDate.toString() === argBlitzData[i].time.toString().substring(0, 10)){
                            return({blitz: 'Ja', from: fromToTime.from, to: fromToTime.to});
                        }else{
                            return({blitz: 'Nein', from:'', to:''});
                        }
                    }else{
                        return({blitz: 'Nein', from:'', to:''});
                    }
                }
            }else{
                return({blitz: 'Keine Daten', from:'', to:''});
            }
        }catch(error){
            if (props.isClient) {
                ReactGA.event("rc_wetterauskunft", {
                    rc_wetterauskunft_error: "202208111411 - " + error
                });
            } else {
                console.log('202208111411 - ' + error);
            }
        }
    }

    const printPDF = async () => {
        try {
            if (props.isClient) {
                ReactGA.event("rc_wetterauskunft_print_btnclick", {
                    rc_print_btnclick: "clicked"
                });
            }
            let tableData = await getformatedWeatherData(props.weatherData, props.blitzData, props.startDate, props.endDate);
            if (tableData && tableData.length > 0) {
                setPDFProcessing(true);
                if (dateDiffInDays(props.startDate, props.endDate) > 1) {
                    if(await TwoPagePDFPrint(
                        props.strasse,
                        props.houseNummer,
                        props.lange,
                        props.breite,
                        props.selectedLocation,
                        props.selLocationByLocation,
                        props.startDate,
                        props.endDate,
                        props.temperaturChecked,
                        props.NiederschlagsmengeChecked,
                        props.WindgeschwindigkeitChecked,
                        props.GlatteFrostChecked,
                        props.HagelChecked,
                        props.BlitzChecked,
                        props.SichtLichtverhaltnisseChecked,
                        tableData,
                        totalPrecipitation,
                        hookReferenzstation,
                        props.isClient
                    )){
                        setPDFProcessing(false);
                    }else{
                        setPrintError(true);
                        setPDFProcessing(false);
                        if (props.isClient) {
                            ReactGA.event("rc_wetterauskunft", {
                                rc_wetterauskunft_error: "202208111412 - Two page Printing failed"
                            });
                        } else {
                            console.log('202208111410 - Two page printing');
                        }
                    }
                } else {
                    if( await PDFPrint(
                        props.strasse,
                        props.houseNummer,
                        props.lange,
                        props.breite,
                        props.selectedLocation,
                        props.selLocationByLocation,
                        props.startDate,
                        props.endDate,
                        props.temperaturChecked,
                        props.NiederschlagsmengeChecked,
                        props.WindgeschwindigkeitChecked,
                        props.GlatteFrostChecked,
                        props.HagelChecked,
                        props.BlitzChecked,
                        props.SichtLichtverhaltnisseChecked,
                        tableData,
                        totalPrecipitation,
                        hookReferenzstation,
                        props.isClient
                    )){
                        setPDFProcessing(false);
                    }else{
                        setPrintError(true);
                        setPDFProcessing(false);
                        if (props.isClient) {
                            ReactGA.event("rc_wetterauskunft", {
                                rc_wetterauskunft_error: "202208111414 - Single page Printing failed"
                            });
                        } else {
                            console.log('202208111414 - Single page printing');
                        }
                    }
                }
            }
        } catch (error) {
            setPDFProcessing(false);
            if (props.isClient) {
                ReactGA.event("rc_wetterauskunft", {
                    rc_wetterauskunft_error: "202208111415 - " + error
                });
            } else {
                console.log('202208111415 - ' + error);
            }
        }
    }

    const downloadPDF = async () => {
        try {
            let tableData = await getformatedWeatherData(props.weatherData, props.blitzData, props.startDate, props.endDate);
            if (props.isClient) {
                ReactGA.event("rc_wetterauskunft_download_btnclick", {
                    rc_download_btnclick: "clicked"
                });
            }
            if (tableData && tableData.length > 0) {
                setPDFProcessing(true);
                if (dateDiffInDays(props.startDate, props.endDate) > 1) {
                    if(await TwoPagePDFDownload(
                        props.strasse,
                        props.houseNummer,
                        props.lange,
                        props.breite,
                        props.selectedLocation,
                        props.selLocationByLocation,
                        props.startDate,
                        props.endDate,
                        props.temperaturChecked,
                        props.NiederschlagsmengeChecked,
                        props.WindgeschwindigkeitChecked,
                        props.GlatteFrostChecked,
                        props.HagelChecked,
                        props.BlitzChecked,
                        props.SichtLichtverhaltnisseChecked,
                        tableData,
                        totalPrecipitation,
                        hookReferenzstation,
                        props.isClient
                    )){
                        setPDFProcessing(false);
                    }else{
                        setPrintError(true);
                        setPDFProcessing(false);
                        if (props.isClient) {
                            ReactGA.event("rc_wetterauskunft", {
                                rc_wetterauskunft_error: "202208111415 - Two page download failed"
                            });
                        } else {
                            console.log('202208111415 - Two page download failed');
                        }
                    }
                } else {
                    if(await PDFDownload(
                        props.strasse,
                        props.houseNummer,
                        props.lange,
                        props.breite,
                        props.selectedLocation,
                        props.selLocationByLocation,
                        props.startDate,
                        props.endDate,
                        props.temperaturChecked,
                        props.NiederschlagsmengeChecked,
                        props.WindgeschwindigkeitChecked,
                        props.GlatteFrostChecked,
                        props.HagelChecked,
                        props.BlitzChecked,
                        props.SichtLichtverhaltnisseChecked,
                        tableData,
                        totalPrecipitation,
                        hookReferenzstation,
                        props.isClient
                    )){
                        setPDFProcessing(false);
                    }else{
                        setPrintError(true);
                        setPDFProcessing(false);
                        if (props.isClient) {
                            ReactGA.event("rc_wetterauskunft", {
                                rc_wetterauskunft_error: "202208111416 - Single page download failed"
                            });
                        } else {
                            console.log('202208111416 - Single page download failed');
                        }
                    }
                }
            }
        } catch (error) {
            setPDFProcessing(false);
            if (props.isClient) {
                ReactGA.event("rc_wetterauskunft", {
                    rc_wetterauskunft_error: "202208111417 - " + error
                });
            } else {
                console.log('202208111417 - ' + error);
            }
        }
    }

    const getTemperatureData = (argDate, argTemperatureData) => {
        try {
            if (argTemperatureData && argTemperatureData.length > 0) {
                for (let i = 0; i < argTemperatureData.length; i++) {
                    if (argDate && argTemperatureData[i] && argTemperatureData[i].date) {
                        if (argDate.toString() === argTemperatureData[i].date.toString()) {
                            return ({
                                date: argTemperatureData[i].date ? argTemperatureData[i].date : "-",
                                TMax: argTemperatureData[i].TMax ? argTemperatureData[i].TMax : "-",
                                TMin: argTemperatureData[i].TMin ? argTemperatureData[i].TMin : "-",
                                TMax_time: argTemperatureData[i].TMax_time ? argTemperatureData[i].TMax_time : "-",
                                TMin_time: argTemperatureData[i].TMin_time ? argTemperatureData[i].TMin_time : "-",
                            });
                        }
                    } else {
                        return ({ date: "-", TMax: "-", TMin: "-", TMax_time: "-", TMin_time: "-" });
                    }
                }
            } else {
                return ({ date: "-", TMax: "-", TMin: "-", TMax_time: "-", TMin_time: "-" });
            }
        } catch (error) {
            if (props.isClient) {
                ReactGA.event("rc_wetterauskunft", {
                    rc_wetterauskunft_error: "202208111418 - " + error
                });
            } else {
                console.log('202208111418 - ' + error);
            }
        }
    }

    const getWindData = (argDate, argWindData) => {
        try {
            if (argWindData && argWindData.length > 0) {
                for (let i = 0; i < argWindData.length; i++) {
                    if (argDate && argWindData[i] && argWindData[i].date) {
                        if (argDate.toString() === argWindData[i].date.toString()) {
                            return ({
                                date: argWindData[i].date ? argWindData[i].date : "-",
                                windspeed: argWindData[i].windspeed ? argWindData[i].windspeed : "-",
                                time: argWindData[i].time ? argWindData[i].time : "-",
                            });
                        }
                    } else {
                        return ({ date: "-", windspeed: "-", time: "-" });
                    }
                }
            } else {
                return ({ date: "-", windspeed: "-", time: "-" });
            }
        } catch (error) {
            if (props.isClient) {
                ReactGA.event("rc_wetterauskunft", {
                    rc_wetterauskunft_error: "202208111419 - " + error
                });
            } else {
                console.log('202208111419 - ' + error);
            }
        }
    }

    const getGlatteData = (argDate, argGlatteData) => {
        try {
            if (argGlatteData && argGlatteData.length > 0) {
                for (let i = 0; i < argGlatteData.length; i++) {
                    if (argDate && argGlatteData[i] && argGlatteData[i].date) {
                        if (argDate.toString() === argGlatteData[i].date.toString()) {
                            return ({
                                date: argGlatteData[i].date ? argGlatteData[i].date : "-",
                                Glatte: argGlatteData[i].black_ice ? argGlatteData[i].black_ice : "-",
                                time: argGlatteData[i].time ? argGlatteData[i].time : "-",
                            });
                        }
                    } else {
                        return ({ date: "-", Frost: "-", time: "-" });
                    }
                }
            } else {
                return ({ date: "-", Frost: "-", time: "-" });
            }
        } catch (error) {
            if (props.isClient) {
                ReactGA.event("rc_wetterauskunft", {
                    rc_wetterauskunft_error: "202208111420 - " + error
                });
            } else {
                console.log('202208111420 - ' + error);
            }
        }
    }

    const getVisiblityData = (argDate, argVisiblityData) => {
        try {
            if (argVisiblityData && argVisiblityData.length > 0) {
                for (let i = 0; i < argVisiblityData.length; i++) {
                    if (argDate && argVisiblityData[i] && argVisiblityData[i].date) {
                        if (argDate.toString() === argVisiblityData[i].date.toString()) {
                            return ({
                                date: argVisiblityData[i].date ? argVisiblityData[i].date : "-",
                                visibility: argVisiblityData[i].vis_mode ? argVisiblityData[i].vis_mode : "-",
                            });
                        }
                    } else {
                        return ({ date: "-", visibility: "-" });
                    }
                }
            } else {
                return ({ date: "-", visibility: "-" });
            }
        } catch (error) {
            if (props.isClient) {
                ReactGA.event("rc_wetterauskunft", {
                    rc_wetterauskunft_error: "202208111421 - " + error
                });
            } else {
                console.log('202208111421 - ' + error);
            }
        }
    }

    const getPrecipitationData = (argDate, argPrecipitation) => {
        try {
            if (argPrecipitation && argPrecipitation.length > 0) {
                for (let i = 0; i < argPrecipitation.length; i++) {
                    if (argDate && argPrecipitation[i] && argPrecipitation[i].date) {
                        if (argDate.toString() === argPrecipitation[i].date.toString()) {
                            return ({
                                date: argPrecipitation[i].date ? argPrecipitation[i].date : "-",
                                rain_Amount: argPrecipitation[i].precipitation_amount ? argPrecipitation[i].precipitation_amount : "-",
                            });
                        }
                    } else {
                        return ({ date: "-", rain_Amount: "-" });
                    }
                }
            } else {
                return ({ date: "-", rain_Amount: "-" });
            }
        } catch (error) {
            if (props.isClient) {
                ReactGA.event("rc_wetterauskunft", {
                    rc_wetterauskunft_error: "202208111422 - " + error
                });
            } else {
                console.log('202208111422 - ' + error);
            }
        }
    }

    const getHagelData = (argDate, argHagelData) => {
        try{
            if(argHagelData && argHagelData.length > 0){
                for(let j = 0; j < argHagelData.length ; j++){
                    if (argDate && argHagelData[j] && argHagelData[j].date){
                        if (argDate.toString() === argHagelData[j].date.toString()){
                            return ({
                                date: argHagelData[j].date ? argHagelData[j].date : "-",
                                hail: argHagelData[j].hail ? argHagelData[j].hail : "-"
                            });
                        }
                    }
                }
            } else {
                return ({ date: "-", hail: "-" })
            }
        }catch(error){
            if (props.isClient) {
                ReactGA.event("rc_wetterauskunft", {
                    rc_wetterauskunft_error: "202208111423 - " + error
                });
            } else {
                console.log('202208111423 - ' + error);
            }
        }
    }

    const formatePrecipitation = (argNumber) => {
        try {
            if (!isNaN(argNumber)) {
                return Number(argNumber).toFixed(1);
            } else {
                return argNumber;
            }
        } catch (error) {
            if (props.isClient) {
                ReactGA.event("rc_wetterauskunft", {
                    rc_wetterauskunft_error: "202208111424 - " + error
                });
            } else {
                console.log('202208111424 - ' + error);
            }
        }
    }

    const getformatedWeatherData = (argData, argBlitzData, argStartDate, argEndDate) => {
        try {
            let tableData = [];
            let temperatureData = [];
            let niederschlagsmengeData = [];
            let windgeschwindigkeitData = [];
            let glatteFrostData = [];
            let hagelData = [];
            let blitzData = [];
            let sichtLichtverhaltnisseData = [];
            let referenzstation = "";

            if (argData) {

                if(props.temperaturChecked){
                    if(argData.temperature && argData.temperature.station && argData.temperature.station.data && argData.temperature.station.data.length > 0){
                        temperatureData = argData.temperature.station.data;
                    }else if(argData.temperature && argData.temperature && argData.temperature.data && argData.temperature.data.length > 0){
                        temperatureData = argData.temperature.data;
                    }else{
                        temperatureData = [];
                    }
                }else {
                    temperatureData = [];
                }

                if(argData.temperature && argData.temperature.station && argData.temperature.station.city_name && hookReferenzstation.toString() === ''){
                    setReferenzstation(argData.temperature.station.city_name);
                    referenzstation = argData.temperature.station.city_name;
                }

                /*if (props.temperaturChecked && argData.temperature && argData.temperature.data && argData.temperature.data.length > 0) {
                    temperatureData = argData.temperature.data;
                } else {
                    temperatureData = [];
                }*/

                if(props.WindgeschwindigkeitChecked){
                    if(argData.windspeed && argData.windspeed.station && argData.windspeed.station.data && argData.windspeed.station.data.length > 0){
                        windgeschwindigkeitData = argData.windspeed.station.data;
                    }else if(argData.windspeed && argData.windspeed && argData.windspeed.data && argData.windspeed.data.length > 0){
                        windgeschwindigkeitData = argData.windspeed.data;
                    }else{
                        windgeschwindigkeitData = [];
                    }
                }else {
                    windgeschwindigkeitData = [];
                }

                if(argData.windspeed && argData.windspeed.station && argData.windspeed.station.city_name && hookReferenzstation.toString() === ''){
                    setReferenzstation(argData.windspeed.station.city_name);
                    referenzstation = argData.windspeed.station.city_name;
                }

                /*if (props.WindgeschwindigkeitChecked && argData.windspeed && argData.windspeed.data && argData.windspeed.data.length > 0) {
                    windgeschwindigkeitData = argData.windspeed.data;
                } else {
                    windgeschwindigkeitData = [];
                }*/

                /*if(props.GlatteFrostChecked){
                    if(argData.frost && argData.frost.station && argData.frost.station.data && argData.frost.station.data.length > 0){
                        glatteFrostData = argData.frost.station.data;
                    }else if(argData.frost && argData.frost && argData.frost.data && argData.frost.data.length > 0){
                        glatteFrostData = argData.frost.data;
                    }else{
                        glatteFrostData = [];
                    }
                }else {
                    glatteFrostData = [];
                }*/

                if (props.GlatteFrostChecked) {
                    if (argData.black_ice && argData.black_ice.station && argData.black_ice.station.data && argData.black_ice.station.data.length > 0) {
                        glatteFrostData = argData.black_ice.station.data;
                    } else if (argData.black_ice && argData.black_ice && argData.black_ice.data && argData.black_ice.data.length > 0) {
                        glatteFrostData = argData.black_ice.data;
                    } else {
                        glatteFrostData = [];
                    }
                } else {
                    glatteFrostData = [];
                }

                if (argData.black_ice && argData.black_ice.station && argData.black_ice.station.city_name && hookReferenzstation.toString() === ''){
                    setReferenzstation(argData.black_ice.station.city_name);
                    referenzstation = argData.black_ice.station.city_name;
                }

                /*if (props.GlatteFrostChecked && argData.frost && argData.frost.data && argData.frost.data.length > 0) {
                    glatteFrostData = argData.frost.data;
                } else {
                    glatteFrostData = [];
                }*/

                if(props.SichtLichtverhaltnisseChecked){
                    if(argData.visibility && argData.visibility.station && argData.visibility.station.data && argData.visibility.station.data.length > 0){
                        sichtLichtverhaltnisseData = argData.visibility.station.data;
                    }else if(argData.visibility && argData.visibility && argData.visibility.data && argData.visibility.data.length > 0){
                        sichtLichtverhaltnisseData = argData.visibility.data;
                    }else{
                        sichtLichtverhaltnisseData = [];
                    }
                }else {
                    sichtLichtverhaltnisseData = [];
                }

                if(argData.visibility && argData.visibility.station && argData.visibility.station.city_name && hookReferenzstation.toString() === ''){
                    setReferenzstation(argData.visibility.station.city_name);
                    referenzstation = argData.visibility.station.city_name;
                }

                /*if (props.SichtLichtverhaltnisseChecked && argData.visibility && argData.visibility.data && argData.visibility.data.length > 0) {
                    sichtLichtverhaltnisseData = argData.visibility.data;
                } else {
                    sichtLichtverhaltnisseData = [];
                }*/

                if(props.NiederschlagsmengeChecked){
                    if(argData.precipitation && argData.precipitation.station && argData.precipitation.station.data && argData.precipitation.station.data.length > 0){
                        niederschlagsmengeData = argData.precipitation.station.data;
                    }else if(argData.precipitation && argData.precipitation && argData.precipitation.data && argData.precipitation.data.length > 0){
                        niederschlagsmengeData = argData.precipitation.data;
                    }else{
                        niederschlagsmengeData = [];
                    }
                }else {
                    niederschlagsmengeData = [];
                }

                if(argData.precipitation && argData.precipitation.station && argData.precipitation.station.city_name && hookReferenzstation.toString() === ''){
                    setReferenzstation(argData.precipitation.station.city_name);
                    referenzstation = argData.precipitation.station.city_name;
                }

                /*if (props.NiederschlagsmengeChecked && argData.precipitation && argData.precipitation.data && argData.precipitation.data.length > 0) {
                    niederschlagsmengeData = argData.precipitation.data;
                } else {
                    niederschlagsmengeData = [];
                }

                if(props.NiederschlagsmengeChecked && argData.precipitation && argData.precipitation.station && argData.precipitation.station.data && argData.precipitation.station.data.length > 0){
                    niederschlagsmengeData = argData.precipitation.station.data;
                }else {
                    niederschlagsmengeData = [];
                }

                if(argData.precipitation && argData.precipitation.station && argData.precipitation.station.city_name && hookReferenzstation.toString() === ''){
                    setReferenzstation(argData.precipitation.station.city_name);
                    referenzstation = argData.precipitation.station.city_name;
                }*/

                if (props.HagelChecked){
                    if (argData.Position_dBz && argData.Position_dBz.station && argData.Position_dBz.station.data && argData.Position_dBz.station.data.length > 0){
                        hagelData = argData.Position_dBz.station.data;
                    } else if (argData.Position_dBz && argData.Position_dBz.data && argData.Position_dBz.data.length > 0){
                        hagelData = argData.Position_dBz.data;
                    }else{
                        hagelData = [];
                    }
                }

                if (argData.Position_dBz && argData.Position_dBz.station && argData.Position_dBz.station.city_name && hookReferenzstation.toString() === '') {
                    setReferenzstation(argData.Position_dBz.station.city_name);
                    referenzstation = argData.Position_dBz.station.city_name;
                }

            }

            if(argBlitzData){
                if(props.BlitzChecked){
                    blitzData = argBlitzData;
                }
            }

            if(props.BlitzChecked && !props.temperaturChecked && !props.NiederschlagsmengeChecked && !props.GlatteFrostChecked && !props.WindgeschwindigkeitChecked && !props.HagelChecked){
                if( hookReferenzstation.toString() === ''){
                    if (props.selLocationByLocation && props.selLocationByLocation.name){
                        setReferenzstation(props.selLocationByLocation.name + ' + 3km Umkreis');
                        referenzstation = props.selLocationByLocation.name + ' + 3km Umkreis';
                    }
                    /* There will be no location name for LAT/LON case. so this section is hiding
                    /*else if (props.selLocationByLatLon && props.selLocationByLatLon.name){
                        setReferenzstation(props.selLocationByLatLon.name + ' + 3km Umkreis');
                        referenzstation = props.selLocationByLatLon.name + ' + 3km Umkreis';
                    }*/
                }
            }

            if (dateDiffInDays(argStartDate, argEndDate) > -1) {
                const formattedDate = getFormattedDate(argStartDate);
                if (formattedDate) {
                    tableData[0] = {
                        date: formattedDate,
                        unformattedDate: argStartDate,
                        referenzstation: referenzstation,
                        temperature: getTemperatureData(formattedDate, temperatureData),
                        windspeed: getWindData(formattedDate, windgeschwindigkeitData),
                        glatte: getGlatteData(formattedDate, glatteFrostData),
                        visibility: getVisiblityData(formattedDate, sichtLichtverhaltnisseData),
                        precipitation: getPrecipitationData(formattedDate, niederschlagsmengeData),
                        blitz: getBlitzData(formattedDate, blitzData),
                        hagel: getHagelData(formattedDate, hagelData)
                    };
                }
            }

            if (dateDiffInDays(argStartDate, argEndDate) > 0) {
                const formattedDate = getFormattedDate(new Date(argStartDate).addDays(1));
                if (formattedDate) {
                    tableData[1] = {
                        date: formattedDate,
                        unformattedDate: new Date(argStartDate).addDays(1),
                        referenzstation: referenzstation,
                        temperature: getTemperatureData(formattedDate, temperatureData),
                        windspeed: getWindData(formattedDate, windgeschwindigkeitData),
                        glatte: getGlatteData(formattedDate, glatteFrostData),
                        visibility: getVisiblityData(formattedDate, sichtLichtverhaltnisseData),
                        precipitation: getPrecipitationData(formattedDate, niederschlagsmengeData),
                        blitz: getBlitzData(formattedDate, blitzData),
                        hagel: getHagelData(formattedDate, hagelData)
                    };
                }
            }

            if (dateDiffInDays(argStartDate, argEndDate) > 1) {
                const formattedDate = getFormattedDate(new Date(argStartDate).addDays(2));
                if (formattedDate) {
                    tableData[2] = {
                        date: formattedDate,
                        unformattedDate: new Date(argStartDate).addDays(2),
                        referenzstation: referenzstation,
                        temperature: getTemperatureData(formattedDate, temperatureData),
                        windspeed: getWindData(formattedDate, windgeschwindigkeitData),
                        glatte: getGlatteData(formattedDate, glatteFrostData),
                        visibility: getVisiblityData(formattedDate, sichtLichtverhaltnisseData),
                        precipitation: getPrecipitationData(formattedDate, niederschlagsmengeData),
                        blitz: getBlitzData(formattedDate, blitzData),
                        hagel: getHagelData(formattedDate, hagelData)
                    };
                }
            }

            if (dateDiffInDays(argStartDate, argEndDate) > 2) {
                const formattedDate = getFormattedDate(new Date(argStartDate).addDays(3));
                if (formattedDate) {
                    tableData[3] = {
                        date: formattedDate,
                        unformattedDate: new Date(argStartDate).addDays(3),
                        referenzstation: referenzstation,
                        temperature: getTemperatureData(formattedDate, temperatureData),
                        windspeed: getWindData(formattedDate, windgeschwindigkeitData),
                        glatte: getGlatteData(formattedDate, glatteFrostData),
                        visibility: getVisiblityData(formattedDate, sichtLichtverhaltnisseData),
                        precipitation: getPrecipitationData(formattedDate, niederschlagsmengeData),
                        blitz: getBlitzData(formattedDate, blitzData),
                        hagel: getHagelData(formattedDate, hagelData)
                    };
                }
            }

            if (dateDiffInDays(argStartDate, argEndDate) > 3) {
                const formattedDate = getFormattedDate(new Date(argStartDate).addDays(4));
                if (formattedDate) {
                    tableData[4] = {
                        date: formattedDate,
                        unformattedDate: new Date(argStartDate).addDays(4),
                        referenzstation: referenzstation,
                        temperature: getTemperatureData(formattedDate, temperatureData),
                        windspeed: getWindData(formattedDate, windgeschwindigkeitData),
                        glatte: getGlatteData(formattedDate, glatteFrostData),
                        visibility: getVisiblityData(formattedDate, sichtLichtverhaltnisseData),
                        precipitation: getPrecipitationData(formattedDate, niederschlagsmengeData),
                        blitz: getBlitzData(formattedDate, blitzData),
                        hagel: getHagelData(formattedDate, hagelData)
                    };
                }
            }

            totalPrecipitation = undefined

            if (niederschlagsmengeData && niederschlagsmengeData.length > 0){
                for (let i = 0; i < niederschlagsmengeData.length; i++) {
                    if (niederschlagsmengeData[i] && niederschlagsmengeData[i].precipitation_total){
                        totalPrecipitation = niederschlagsmengeData[i].precipitation_total;
                    }
                }
            }

            if (tableData) {
                return tableData;
            } else {
                return [];
            }

        } catch (error) {
            if (props.isClient) {
                ReactGA.event("rc_wetterauskunft", {
                    rc_wetterauskunft_error: "202208111425 - " + error
                });
            } else {
                console.log('202208111425 - ' + error);
            }
        }
    }

    const HandlePrintMessageClose = () => {
        try{
            setPrintError(false);
        }catch(error){
            if (props.isClient) {
                ReactGA.event("rc_wetterauskunft", {
                    rc_wetterauskunft_error: "202208111426 - " + error
                });
            } else {
                console.log('202208111426 - ' + error);
            }
        }
    }

    let componentOrganizer = null;

    try {

        let tableData = getformatedWeatherData(props.weatherData, props.blitzData, props.startDate, props.endDate);

        if (tableData && tableData.length > 0) {
            componentOrganizer = tableData.map((dateData, index) => {
                return (
                    <div className={classes.resultContainer} key={index}>
                        <h3 className={classes.dateValue}>{tableData[index] ? tableData[index].unformattedDate ? getDate(tableData[index].unformattedDate) : "" : ""}</h3>
                        <Divider className={classes.sectDivider} />
                        <div className={classes.tableContainerDiv}>
                            <table className={classes.resultTable}>
                                <tbody>
                                    <tr>
                                        <td className={props.temperaturChecked ? null : classes.disable}>Temperatur / Frost</td>
                                        <td>{props.temperaturChecked ? "  Minimalwert:" : <span className={classes.disable}>Nicht abgefragt</span>}</td>
                                        <td>
                                            <span className={classes.resultValue}>
                                                {props.temperaturChecked ? tableData[index] ? tableData[index].temperature ? tableData[index].temperature.TMin ? tableData[index].temperature.TMin : "Keine Daten" : "Keine Daten" : "Keine Daten" : ""}
                                            </span>
                                        </td>
                                        <td>{props.temperaturChecked ? " Maximalwert:" : null}</td>
                                        <td>
                                            <span className={classes.resultValue}>
                                                {props.temperaturChecked ? tableData[index] ? tableData[index].temperature ? tableData[index].temperature.TMax ? tableData[index].temperature.TMax : "Keine Daten" : "Keine Daten" : "Keine Daten" : ""}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={props.NiederschlagsmengeChecked ? null : classes.disable}>Niederschlagsmenge</td>
                                        <td>{props.NiederschlagsmengeChecked ? "Niederschlagssumme:" : <span className={classes.disable}>Nicht abgefragt</span>}</td>
                                        <td>
                                            <span className={classes.resultValue}>
                                                {props.NiederschlagsmengeChecked ? tableData[index] ? tableData[index].precipitation ? tableData[index].precipitation.rain_Amount ? tableData[index].precipitation.rain_Amount : "Keine Daten" : "Keine Daten" : "Keine Daten" : ""}
                                            </span>
                                        </td>
                                        <td></td>
                                        <td><span className={classes.resultValue}></span></td>
                                    </tr>
                                    <tr>
                                        <td className={props.WindgeschwindigkeitChecked ? null : classes.disable}>Windgeschwindigkeit</td>
                                        <td>{props.WindgeschwindigkeitChecked ? "  Maximalwert:" : <span className={classes.disable}>Nicht abgefragt</span>}</td>
                                        <td>
                                            <span className={classes.resultValue}>
                                                {props.WindgeschwindigkeitChecked ? tableData[index] ? tableData[index].windspeed ? tableData[index].windspeed.windspeed ? tableData[index].windspeed.windspeed : "Keine Daten" : "Keine Daten" : "Keine Daten" : ""}
                                            </span>
                                        </td>
                                        <td></td>
                                        <td><span className={classes.resultValue}></span></td>
                                    </tr>
                                    <tr>
                                        <td className={props.GlatteFrostChecked ? null : classes.disable}>Glätte</td>
                                        <td>{props.GlatteFrostChecked ?
                                            <span className={classes.resultValue}>
                                                {tableData[index] ? tableData[index].glatte ? tableData[index].glatte.Glatte ? tableData[index].glatte.Glatte.toString() === "Yes" ? "Ja" : "Nein" : "Keine Daten" : "Keine Daten" : "Keine Daten"}
                                            </span>
                                            : <span className={classes.disable}>Nicht abgefragt</span>}
                                        </td>
                                        <td><span className={classes.resultValue}></span></td>
                                        <td></td>
                                        <td><span className={classes.resultValue}></span></td>
                                    </tr>
                                    <tr>
                                        <td className={props.HagelChecked ? null : classes.disable}>Hagel</td>
                                        <td>{props.HagelChecked ?
                                            <span className={classes.resultValue}>{tableData[index] ? tableData[index].hagel ? tableData[index].hagel.hail ? tableData[index].hagel.hail.toString() === "Yes" ? "Ja" : "Nein" : "Keine Daten" : "Keine Daten" : "Keine Daten"}</span>
                                            : <span className={classes.disable}>Nicht abgefragt</span>}
                                        </td>
                                        <td><span className={classes.resultValue}></span></td>
                                        <td><span className={classes.resultValue}></span></td>
                                        <td><span className={classes.resultValue}></span></td>
                                    </tr>
                                    <tr>
                                        <td className={props.BlitzChecked ? null : classes.disable}>Blitz</td>
                                        <td>{props.BlitzChecked ?
                                            <span className={classes.resultValue}>
                                                {tableData[index] ? tableData[index].blitz ? tableData[index].blitz.blitz ? tableData[index].blitz.blitz : "Keine Daten" : "Keine Daten" : "Keine Daten"}
                                            </span>
                                            : <span className={classes.disable}>Nicht abgefragt</span>}</td>
                                        <td><span className={classes.resultValue}></span></td>
                                        <td>{props.BlitzChecked ? tableData[index] ? tableData[index].blitz ? tableData[index].blitz.blitz ? tableData[index].blitz.blitz.toString() === "Ja" ? " von - bis :" : "" : "" : "" : "" : ""}</td>
                                        <td>
                                            <span className={classes.resultValue}>
                                            {props.BlitzChecked ? tableData[index] ? tableData[index].blitz ? tableData[index].blitz.from ? tableData[index].blitz.from + " - " : "" : "" : "" : ""}
                                                {props.BlitzChecked ? tableData[index] ? tableData[index].blitz ? tableData[index].blitz.from ? tableData[index].blitz.to : "" : "" : "" : ""}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={props.SichtLichtverhaltnisseChecked ? null : classes.disable}>Sicht/Lichtverhältnisse</td>
                                        <td>{props.SichtLichtverhaltnisseChecked ?
                                            <span className={classes.resultValue}>
                                                {/*props.componentData.one ? props.componentData.one.sicht_lichtverhaltnisse ? props.componentData.one.sicht_lichtverhaltnisse : "" : ""*/}
                                                {tableData[index] ? tableData[index].visibility ? tableData[index].visibility.visibility ? tableData[index].visibility.visibility : "Keine Daten" : "Keine Daten" : "Keine Daten"}
                                            </span>
                                            : <span className={classes.disable}>Nicht abgefragt</span>}
                                        </td>
                                        <td><span className={classes.resultValue}></span></td>
                                        <td><span className={classes.resultValue}></span></td>
                                        <td><span className={classes.resultValue}></span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                );
            });
        }

    } catch (error) {
        if (props.isClient) {
            ReactGA.event("rc_wetterauskunft", {
                rc_wetterauskunft_error: "202208111427 - " + error
            });
        } else {
            console.log('202208111427 - ' + error);
        }
    }

    let locationDetailsOrganizer = null;

    try{

        if (props.lange !== null && props.breite !== null && !isNaN(props.lange) && !isNaN(props.breite)){
            locationDetailsOrganizer = (
                <React.Fragment>
                    <Grid item md={2} sm={12} xs={12} className={classes.noTPadding}>
                        <Typography variant="h6" component="h6" className={classes.pageSubHeaderBold}>Geogr. Länge:</Typography>
                    </Grid>
                    <Grid item sm={10} className={classes.noTPadding}>
                        <Typography variant="h6" component="h6" className={classes.pageSubHeader}>
                            {!isNaN(props.lange) ? props.lange : null}
                        </Typography>
                    </Grid>
                    <Grid item md={2} sm={12} xs={12} className={classes.noTPadding}>
                        <Typography variant="h6" component="h6" className={classes.pageSubHeaderBold}>Geogr. Breite:</Typography>
                    </Grid>
                    <Grid item sm={10} className={classes.noTPadding}>
                        <Typography variant="h6" component="h6" className={classes.pageSubHeader}>
                            {!isNaN(props.breite) ? props.breite : null}
                        </Typography>
                    </Grid>
                </React.Fragment>
            );
        }else{
            if (props.selLocationByLocation && props.selLocationByLocation.name){
                locationDetailsOrganizer = (
                <React.Fragment>
                    <Grid item md={2} sm={12} xs={12} className={classes.noTPadding}>
                        <Typography variant="h6" component="h6" className={classes.pageSubHeaderBold}>Ort:</Typography>
                    </Grid>
                    <Grid item sm={10} className={classes.noTPadding}>
                        <Typography variant="h6" component="h6" className={classes.pageSubHeader}>
                            {props.strasse ? (<React.Fragment><span>{props.strasse.toString()}</span>&nbsp;</React.Fragment>) : null}
                            {props.houseNummer ? (<React.Fragment><span>{props.houseNummer.toString()}</span>,&nbsp;</React.Fragment>) : null}
                            {props.selLocationByLocation ? props.selLocationByLocation.postal_code ? props.selLocationByLocation.postal_code.toString() : null : null}&nbsp;
                            {props.selLocationByLocation ? props.selLocationByLocation.name ? props.selLocationByLocation.name.toString() : null : null}
                        </Typography>
                    </Grid>
                </React.Fragment>
                );
            }
        }

    }catch(error){
        if (props.isClient) {
            ReactGA.event("rc_wetterauskunft", {
                rc_wetterauskunft_error: "202208111428 - " + error
            });
        } else {
            console.log('202208111428 - ' + error);
        }
    }

    return (
        <Fragment>
            <Helmet>
                <title>Q.met Risk Control - Wetterauskunft</title>
            </Helmet>
            <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                <Grid item sm={12}>
                    <Typography variant="h3" component="h3" className={classes.pageHeader} gutterBottom>Ihre Wetterauskunft</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                {locationDetailsOrganizer}
                <Grid item md={2} sm={12} xs={12} className={classes.noTPadding}>
                    <Typography variant="h6" component="h6" className={classes.pageSubHeaderBold}>Zeit von:</Typography>
                </Grid>
                <Grid item sm={10} className={classes.noTPadding}>
                    <Typography variant="h6" component="h6" className={classes.pageSubHeader}>{props.startDate ? getDayOfWeek(props.startDate) : "-"}, den {props.startDate ? getDate(props.startDate) : "-"}, {props.startDate ? getTime(props.startDate) : "-"} Uhr</Typography>
                </Grid>
                <Grid item md={2} sm={12} xs={12} className={classes.noTPadding}>
                    <Typography variant="h6" component="h6" className={classes.pageSubHeaderBold}>Zeit bis:</Typography>
                </Grid>
                <Grid item sm={10} className={classes.noTPadding}>
                    <Typography variant="h6" component="h6" className={classes.pageSubHeader}>{props.endDate ? getDayOfWeek(props.endDate) : "-"}, den {props.endDate ? getDate(props.endDate) : "-"}, {props.endDate ? getTime(props.endDate) : "-"} Uhr</Typography>
                </Grid>
                <Grid item md={2} sm={12} xs={12} className={classes.noTPadding}>
                    <Typography variant="h6" component="h6" className={classes.pageSubHeaderBold}>Referenzstation:</Typography>
                </Grid>
                <Grid item sm={10} className={classes.noTPadding}>
                    <Typography variant="h6" component="h6" className={classes.pageSubHeader}>{hookReferenzstation ? hookReferenzstation : ""}</Typography>
                </Grid>
                <Grid item sm={12} className='HideOnMobile'>
                    <Divider className={classes.sectDivider} />
                </Grid>
            </Grid>
            <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                <Grid item sm={12} xs={12}>
                    {componentOrganizer}
                    {totalPrecipitation ?
                    <div className={classes.precipitationTotalContainer}>
                        <h6 className={classes.precipitationTotal}>Gesamtniederschlag im angefragten Zeitraum: {formatePrecipitation(totalPrecipitation)} mm</h6>
                    </div>
                    : null }
                </Grid>
                <Grid item sm={12} className='HideOnMobile'>
                    <Divider className={classes.sectDivider} />
                </Grid>
            </Grid>
            <Grid container spacing={2} direction="row" justifyContent="flex-end" alignItems="center">
                <Grid item md={12} sm={12} xs={12} style={{ textAlign: 'right' }}>
                    <div className='WetterAnkunftButtonContainer'>
                        <Button
                            variant="outlined"
                            color="primary"
                            className={classes.formClearButton}
                            style={{ marginRight: '15px' }}
                            onClick={props.clearForm}>
                            Löschen
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.formButton}
                            style={{ marginRight: '15px' }}
                            startIcon={<PictureAsPdfIcon />}
                            onClick={downloadPDF}>
                            Download als PDF
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.formButton}
                            startIcon={<PrintOutlinedIcon />}
                            onClick={printPDF}>
                            Drucken
                        </Button>
                    </div>
                </Grid>
            </Grid>
            <Backdrop className={classes.backdrop} open={hookPDFProcessing}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar open={hookPrintError} autoHideDuration={5000} onClose={HandlePrintMessageClose} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
                <Alert onClose={HandlePrintMessageClose} severity="info">
                    Etwas ist schief gelaufen, bitte versuchen Sie es später noch einmal
                </Alert>
            </Snackbar>
        </Fragment>
    );
});

export default WetterauskunftComponent;