import React, { memo, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Typography } from '@material-ui/core';
import cssModule from './Impressum.module.css';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import { Link } from 'react-router-dom';
import ImageVerband from '../../Assets/images/vdwlogo-300x46.jpg';
import ReactGA from "react-ga4";
import {Helmet} from "react-helmet";

const useStyles = makeStyles((theme) => ({
    HomeLink: {
        fontSize: '.9rem',
        textAlign: 'Left',
        fontWeight: '500',
        color: '#0288D1',
        marginTop: '0px',
        marginBottom: '0px'
    },
    dataParent:{
        padding:'20px',
        boxShadow: '0 0 13px 0 rgb(82 63 105 / 5%)',
        marginTop:'25px',
        textAlign:'left',
        borderRadius:'5px',
        backgroundColor:'#fff',
    },
    dataText: {
        fontSize:'1.1rem',
        marginBottom:'1rem',
        fontFamily: '"Exo 2", sans-serif',
        fontWeight:'400',
    },
    dataHead: {
        fontSize: '2rem',
        fontFamily: '"Exo 2", sans-serif',
        fontWeight: '400',
    }
}));

const Datenschutz = memo(() => {

    const classes = useStyles();

    React.useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
      }, []);

    return (
        <Fragment>
            <Helmet>
                <title>Q.met Risk Control - Impressum</title>
            </Helmet>
            <div className={cssModule.AccordItem}>
                <Container maxWidth={false}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Link className={classes.HomeLink} to="/">
                                    <HomeOutlinedIcon />
                                </Link>
                                <span style={{ marginLeft: '10px' }}><Link className={classes.HomeLink} to="/">Home</Link></span>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <Container maxWidth={false}>
                <div className={classes.dataParent}>
                <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography className={classes.dataHead}>Impressum</Typography>
                        </Grid>
                        <Grid item sm={4}>
                            <Typography className={classes.dataText}>Q.met GmbH<br/>
                                Hagenauer Str. 1a<br/>
                                65203 Wiesbaden<br/>
                                HRB 31990<br/>
                                Umsatzsteuer ID: DE 1939 79 413<br/>
                                Steuer Nr. 043 241 50253</Typography>

                            <Typography className={classes.dataText}>Telefon: 0611-89052-0<br/>
                                Fax: 0611-89052-62<br/>
                                E-Mail: info@qmet.de<br/>
                                Website: www.qmet.de</Typography>

                            <Typography className={classes.dataText}>Geschäftsführer: Norman Gabler, Dominik Jung</Typography>

                            <Typography className={classes.dataText}>Redaktionsleiter: Dominik Jung, Diplom-Meteorologe<br/>
                                Technischer Leiter: Eckardt Renner, Diplom-Informatiker</Typography>

                            <Typography className={classes.dataText}>Die Q.met GmbH ist Mitglied beim</Typography>

                            <img src={ImageVerband} alt="Verband Deutscher Wetterdienstleister"/>
                        </Grid>
                        <Grid item sm={8}>
                            <Typography className={classes.dataText}>
                                Alle auf dieser Seite zur Verfügung gestellten Informationen, Bilder und Grafiken sind urheberrechtlich geschützt und 
                                dürfen ohne schriftliche Genehmigung der Q.met GmbH nicht weiterverwendet oder verbreitet werden.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. („Google“). Google Analytics verwendet 
                                sog. „Cookies“, Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website 
                                durch Sie ermöglichen. Die durch das Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der 
                                Regel an einen Server von Google in den USA übertragen und dort gespeichert. Im Falle der Aktivierung der 
                                IP-Anonymisierung auf dieser Website, wird Ihre IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der 
                                Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. 
                                Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. 
                                Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Website 
                                auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und 
                                der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von 
                                Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. 
                                Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir 
                                weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website 
                                vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und 
                                auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser 
                                Daten durch Google verhindern, indem Sie das unter dem folgenden Link (http://tools.google.com/dlpage/gaoptout?hl=de) 
                                verfügbare Browser-Plugin herunterladen und installieren.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Sie können die Erfassung durch Google Analytics verhindern, indem Sie auf folgenden Link klicken. Es wird ein 
                                Opt-Out-Cookie gesetzt, das die zukünftige Erfassung Ihrer Daten beim Besuch dieser Website verhindert:
                                Google Analytics deaktivieren
                            </Typography>
                            <Typography className={classes.dataText}>
                                Nähere Informationen zu Nutzungsbedingungen und Datenschutz finden Sie unter http://www.google.com/analytics/terms/de.html 
                                bzw. unter https://www.google.de/intl/de/policies/.
                            </Typography>
                            <Typography className={classes.dataText}>
                                Wir weisen Sie darauf hin, dass auf dieser Website Google Analytics um den Code “ga(‘set’, ‘anonymizeIp’, true);” 
                                erweitert wurde, um eine anonymisierte Erfassung von IP-Adressen (sog. IP-Masking) zu gewährleisten.
                            </Typography>
                        </Grid>
                </Grid>
                </div>
            </Container>
        </Fragment>
    );
});

export default Datenschutz;