import React, { Fragment, Component } from 'react';
import { Grid, Container} from '@material-ui/core';
import axios from 'axios';
import PlayCircleFilledWhiteOutlinedIcon from '@material-ui/icons/PlayCircleFilledWhiteOutlined';
import Backdrop from '@material-ui/core/Backdrop';
import { withStyles } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import StepperComponent from '../../Components/StepperComponent/StepperComponent';
import cssModule from './StepperContainer.module.css';
import ReactGA from "react-ga4";

const styles = theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
  });

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

class StepperContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            token: null,
            tokenLoading: false,
            messageOpen:false,
        }
    }

    componentDidMount = async () => {
        try {
            ReactGA.send({ hitType: "pageview", page: window.location.pathname });
            let tokenResponse = null;
            this.setState({ tokenLoading: true });
            const axiosInstance = axios.create({
                baseURL: 'https://apps.qmet.de/v2/',
                timeout: 1000 * 50,
            });
            tokenResponse = await axiosInstance.get(`token`);
            if (tokenResponse && tokenResponse.data && tokenResponse.data.data && tokenResponse.data.data.token && tokenResponse.data.data.token.length > 0) {
                this.setState({ token: tokenResponse.data.data.token, tokenLoading: false });
                /*#F5F5F5if (this.props.isClient) {
                    ReactGA.event({
                        category: "Home page",
                        action: "Load Token",
                        label: "Load Token - Success"
                    });
                }*/
                ReactGA.event("rc_token_load", {
                    rc_process: "Token load",
                    rc_error_id: "202208111050",
                    rc_load_status: "failed",
                    rc_status_reason: "202208111050 Empty object or no value"
                });
            } else {
                this.setState({ messageOpen: true, tokenLoading: false });
                if (this.props.isClient) {
                    ReactGA.event("rc_token_load", {
                        rc_process:"Token load",
                        rc_error_id:"202208111050",
                        rc_load_status: "failed",
                        rc_status_reason:"202208111050 - Empty object or no value"
                    });
                }
            }
        } catch (error) {
            if (this.props.isClient) {
                ReactGA.event("rc_token_load", {
                    rc_process: "Token load",
                    rc_error_id: "202208111051",
                    rc_load_status: "failed",
                    rc_status_reason: "202208111051" + error
                });
            }
            this.setState({ messageOpen: true, tokenLoading: false });
        }
    }

    HandleMessageClose = () => {
        try {
            this.setState({ messageOpen: false });
            if (this.props.isClient) {
                /*ReactGA.event("rv_token_error_message", {
                    error_id: "202208111052",
                    status_reason: error
                });*/
            }
        } catch (error) {
            console.log('202203161618', error);
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <Fragment>
                <div className={cssModule.AccordItem}>
                    <Container maxWidth={false}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}} className={cssModule.AccordItemInner}>
                                    <div style={{display:'flex', alignItems:'center'}}>
                                        <PlayCircleFilledWhiteOutlinedIcon/>
                                        <span style={{marginLeft:'10px'}}>Wetterauskunft anfordern</span>
                                    </div>
                                    <div style={{cursor:'pointer'}} onClick={this.props.logoutApp}>Ausloggen</div>
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
                <Container maxWidth={false}>
                    <Grid container spacing={3}>
                        <StepperComponent token={this.state.token} isClient={this.props.isClient}/>
                    </Grid>
                </Container>
                <Snackbar open={this.state.messageOpen} autoHideDuration={5000} onClose={this.HandleMessageClose} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
                    <Alert onClose={this.HandleMessageClose} severity="info">
                        Token kann nicht abgerufen werden! Bitte versuchen Sie es nach einiger Zeit erneut.
                    </Alert>
                </Snackbar>
                <Backdrop className={classes.backdrop} open={this.state.tokenLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Fragment>
        );
    }
}

export default withStyles(styles)(StepperContainer);