import React, { memo, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link as ExternalLink } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ReactGA from "react-ga4";

const useStyles = makeStyles((theme) => ({
    footerParent: {
        backgroundColor:'#0288D1',
        padding:'10px 15px',
        display:'flex',
        justifyContent:'space-between'
    },
    footerText: {
        fontSize:'.8rem',
        textAlign:'center',
        color:'rgba(255,255,255,.8)',
        marginTop:'0px',
        marginBottom:'0px'
    },
    linkParent:{
        color:'#fff',
    },
    footerLink: {
        fontSize: '.8rem',
        textAlign: 'Left',
        fontWeight: '500',
        color: 'rgba(255,255,255,1)',
        marginTop: '0px',
        marginBottom: '0px'
    }
}));

const Header = memo((props) => {

    const classes = useStyles();

    const [hookIsClient, setIsClient] = useState(false);

    useEffect(() => {
        try {
            async function checkLogin() {
                let isClient = sessionStorage.getItem('isClient');
                if (isClient && isClient === 'true') {
                    setIsClient(true);
                } else {
                    setIsClient(false);
                }
            }
            checkLogin();
        } catch (error) {
            if (hookIsClient) {
                ReactGA.event("rc_ankunftdaten", {
                    rc_ankunftdaten_error: "202208111133" + error
                });
            }
        }
    });

    const datenschutzClicked = () => {
        if (hookIsClient) {
            ReactGA.event("rc_datenschutz_clicked", {
                rc_datenschutz_event: "clicked"
            });
        }
    }

    const impressumClicked = () => {
        if (hookIsClient) {
            ReactGA.event("rc_impressum_clicked", {
                rc_impressum_event: "clicked"
            });
        }
    }

    return (
        <footer>
            <div className={classes.footerParent}>
                <div className={classes.linkParent}>
                    <p className={classes.footerLink}><Link onClick={datenschutzClicked} to="/datenschutz" className={classes.footerLink}>Datenschutz</Link>&nbsp;&nbsp;|&nbsp;&nbsp;<Link onClick={impressumClicked} to="/impressum" className={classes.footerLink}>Impressum</Link></p>
                </div>
                <div>
                    <p className={classes.footerText}><ExternalLink className={classes.footerText} href="https://qmet.de/" target="_blank" rel="noopener">Q.met GmbH</ExternalLink> | Alle Rechte vorbehalten</p>
                </div>
                <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
            </div>
        </footer>
    );
});

export default Header;