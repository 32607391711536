import { PDFDocument, rgb, TextAlignment } from 'pdf-lib'
import printJS from 'print-js'
import fontkit from '@pdf-lib/fontkit';
import ReactGA from "react-ga4";

const getDayOfWeek = (argDate, isClient) => {
    try {
        let date = new Date(argDate);
        return date.toLocaleDateString('de-DE', { weekday: 'long' })
    } catch (error) {
        if (isClient) {
            ReactGA.event("rc_pdfprint", {
                rc_pdfprint_error: "202208111431 - " + error
            });
        } else {
            console.log('202208111431 - ' + error);
        }
    }
}

const getDateFullMonth = (argDate, isClient) => {
    try {
        let date = new Date(argDate);
        return date.toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: '2-digit' })
    } catch (error) {
        if (isClient) {
            ReactGA.event("rc_pdfprint", {
                rc_pdfprint_error: "202208111430 - " + error
            });
        } else {
            console.log('202208111430 - ' + error);
        }
    }
}

const getDate = (argDate, isClient) => {
    try {
        let date = new Date(argDate);
        return date.toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' })
    } catch (error) {
        if (isClient) {
            ReactGA.event("rc_pdfprint", {
                rc_pdfprint_error: "202208111429 - " + error
            });
        } else {
            console.log('202208111429 - ' + error);
        }
    }
}

const getTime = (argDate, isClient) => {
    try {
        let date = new Date(argDate);
        return date.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' })
    } catch (error) {
        if (isClient) {
            ReactGA.event("rc_pdfprint", {
                rc_pdfprint_error: "202208111428 - " + error
            });
        } else {
            console.log('202208111428 - ' + error);
        }
    }
}

function dateDiffInDays(a, b) {
    try{
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / (1000 * 60 * 60 * 24));
    }catch(error){
        /*if (isClient) {
            ReactGA.event("rc_pdfprint", {
                rc_pdfprint_error: "202208111427 - " + error
            });
        } else {
            console.log('202208111427 - ' + error);
        }*/
    }
}

const localizeNumber = (argNumber) => {
    try {
        return (Number(argNumber).toLocaleString('de-DE'));
    } catch (error) {
        console.log('20220181154', error);
        ReactGA.event({category: "PDFFunctions", action: "localizeNumber", label: "localizeNumber - Error"});
    }
}

const formatePrecipitation = (argNumber, isClient) => {
    try{
        if(!isNaN(argNumber)){
            return Number(argNumber).toFixed(1);
        }else{
            return argNumber;
        }
    }catch(error){
        if (isClient) {
            ReactGA.event("rc_pdfprint", {
                rc_pdfprint_error: "202208111426 - " + error
            });
        } else {
            console.log('202208111426 - ' + error);
        }
    }
}

export const PDFDownload = async (
    argStrasse,
    argHausnummer,
    argLange,
    argBreite,
    argOrt,
    argOrtByLocation,
    argStartDatumZeit,
    argEndDatumZeit,
    argTemperaturSelected,
    argNiederschlagsmengeSelected,
    argWindgeschwindigkeitSelected,
    argGlatteFrostSelected,
    argHagelSelected,
    argBlitzSelected,
    argSichtLichtverhaltnisseSelected,
    argWeatherData,
    argTotalPrecipitation,
    argReferenzstation,
    isClient) => {
    try {
        const boldFonturl = process.env.PUBLIC_URL + '/Exo2-Medium.ttf';
        const normalFonturl = process.env.PUBLIC_URL + '/Exo2-Light.ttf';
        const url = process.env.PUBLIC_URL + '/Qmet_Briefpapier.pdf';

        const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());
        const pdfDoc = await PDFDocument.load(existingPdfBytes);

        const boldFontBytes = await fetch(boldFonturl).then(res => res.arrayBuffer());
        const NormalFontBytes = await fetch(normalFonturl).then(res => res.arrayBuffer());

        pdfDoc.registerFontkit(fontkit);

        const boldCustomFont = await pdfDoc.embedFont(boldFontBytes);
        const normalCustomFont = await pdfDoc.embedFont(NormalFontBytes);

        const pages = pdfDoc.getPages();
        const firstPage = pages[0];
        const { width, height } = firstPage.getSize()
        const fontSize = 20;

        firstPage.drawText('Seite 1 von 1', {
            x: width - 90,
            y: height - 135,
            size: 10,
            color: rgb(.2, .2, .2),
            textAlign: TextAlignment.Right,
            font: normalCustomFont,
        });

        firstPage.drawRectangle({
            x: 43,
            y: height - 150,
            width: width - 75,
            height: .5,
            color: rgb(.7, .7, .7),
        });

        firstPage.drawRectangle({
            x: 43,
            y: height - 750,
            width: width - 75,
            height: .5,
            color: rgb(.7, .7, .7),
        });

        firstPage.drawText('Risk', {
            x: width - 140,
            y: height - 177,
            size: fontSize,
            color: rgb(.2, .2, .2),
            textAlign: TextAlignment.Right,
            font: normalCustomFont,
        });

        firstPage.drawText('Control', {
            x: width - 100,
            y: height - 177,
            size: fontSize,
            color: rgb(.2, .2, .2),
            textAlign: TextAlignment.Right,
            font: boldCustomFont,
        });

        firstPage.drawText('Kundennummer:', {
            x: width - 215,
            y: height - 195,
            size: 10,
            font: boldCustomFont,
            color: rgb(.2, .2, .2),
            textAlign: TextAlignment.Right
        });

        firstPage.drawText('1234-5678', {
            x: width - 80,
            y: height - 195,
            size: 10,
            font: normalCustomFont,
            color: rgb(.2, .2, .2),
            textAlign: TextAlignment.Right
        });

        firstPage.drawText('Rechnungsnummer:', {
            x: width - 215,
            y: height - 210,
            size: 10,
            font: boldCustomFont,
            color: rgb(.2, .2, .2),
            textAlign: TextAlignment.Right
        });

        firstPage.drawText('1234-5678', {
            x: width - 80,
            y: height - 210,
            size: 10,
            font: normalCustomFont,
            color: rgb(.2, .2, .2),
            textAlign: TextAlignment.Right
        });

        firstPage.drawText('Ihre Wetterauskunft', {
            x: 43,
            y: height - 245,
            size: 13,
            font: boldCustomFont,
            color: rgb(.2, .2, .2),
            textAlign: TextAlignment.Right
        });

        let today = getDayOfWeek(new Date(), isClient);
        today = today + ', ';
        today = today + getDateFullMonth(new Date(), isClient);

        firstPage.drawText(today, {
            x: 43,
            y: height - 260,
            size: 10,
            font: normalCustomFont,
            color: rgb(.2, .2, .2),
            textAlign: TextAlignment.Right
        });

        if (argLange !== null && argBreite !== null && !isNaN(argLange) && !isNaN(argBreite)){
            firstPage.drawText('Geogr. Länge:', {
                x: 43,
                y: height - 280,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText(argLange, {
                x: 160,
                y: height - 280,
                size: 10,
                font: normalCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText('Geogr. Breite:', {
                x: 43,
                y: height - 295,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText(argBreite, {
                x: 160,
                y: height - 295,
                size: 10,
                font: normalCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText('Zeitpunkt:', {
                x: 43,
                y: height - 310,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            let vonDatum = 'von ';
            vonDatum = vonDatum + getDayOfWeek(argStartDatumZeit, isClient);
            vonDatum = vonDatum + ', den ';
            vonDatum = vonDatum + getDate(argStartDatumZeit, isClient);
            vonDatum = vonDatum + ', ';
            vonDatum = vonDatum + getTime(argStartDatumZeit, isClient);
            vonDatum = vonDatum + ' Uhr';

            firstPage.drawText(vonDatum, {
                x: 160,
                y: height - 310,
                size: 10,
                font: normalCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            let bisDatum = 'bis ';
            bisDatum = bisDatum + getDayOfWeek(argEndDatumZeit, isClient);
            bisDatum = bisDatum + ', den ';
            bisDatum = bisDatum + getDate(argEndDatumZeit, isClient);
            bisDatum = bisDatum + ', ';
            bisDatum = bisDatum + getTime(argEndDatumZeit, isClient);
            bisDatum = bisDatum + ' Uhr';

            firstPage.drawText(bisDatum, {
                x: 160,
                y: height - 325,
                size: 10,
                font: normalCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText('Referenzstation:', {
                x: 43,
                y: height - 340,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText(argReferenzstation ? argReferenzstation : "", {
                x: 160,
                y: height - 340,
                size: 10,
                font: normalCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });
        }else{
            if(argOrtByLocation && argOrtByLocation.name){
                firstPage.drawText('Ort:', {
                    x: 43,
                    y: height - 280,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });

                let fullOrt = argStrasse ? argStrasse : null;
                fullOrt = argHausnummer ? fullOrt + " " : fullOrt;
                fullOrt = argHausnummer ? fullOrt + argHausnummer : fullOrt;
                fullOrt = argOrtByLocation.postal_code ? fullOrt + ", " : fullOrt;
                fullOrt = argOrtByLocation.postal_code ? fullOrt + argOrtByLocation.postal_code : fullOrt;
                fullOrt = argOrtByLocation.name ? fullOrt + " " : fullOrt;
                fullOrt = argOrtByLocation.name ? fullOrt + argOrtByLocation.name : fullOrt;

                firstPage.drawText(fullOrt, {
                    x: 160,
                    y: height - 280,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });

                firstPage.drawText('Zeitpunkt:', {
                    x: 43,
                    y: height - 295,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });

                let vonDatum = 'von ';
                vonDatum = vonDatum + getDayOfWeek(argStartDatumZeit, isClient);
                vonDatum = vonDatum + ', den ';
                vonDatum = vonDatum + getDate(argStartDatumZeit, isClient);
                vonDatum = vonDatum + ', ';
                vonDatum = vonDatum + getTime(argStartDatumZeit, isClient);
                vonDatum = vonDatum + ' Uhr';

                firstPage.drawText(vonDatum, {
                    x: 160,
                    y: height - 295,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });

                let bisDatum = 'bis ';
                bisDatum = bisDatum + getDayOfWeek(argEndDatumZeit, isClient);
                bisDatum = bisDatum + ', den ';
                bisDatum = bisDatum + getDate(argEndDatumZeit, isClient);
                bisDatum = bisDatum + ', ';
                bisDatum = bisDatum + getTime(argEndDatumZeit, isClient);
                bisDatum = bisDatum + ' Uhr';

                firstPage.drawText(bisDatum, {
                    x: 160,
                    y: height - 310,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });

                firstPage.drawText('Referenzstation:', {
                    x: 43,
                    y: height - 325,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });

                firstPage.drawText(argReferenzstation ? argReferenzstation : "", {
                    x: 160,
                    y: height - 325,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            }
        }

        /* #####################First date###################*/

        if (dateDiffInDays(argStartDatumZeit, argEndDatumZeit) > -1) {

            firstPage.drawRectangle({
                x: 43,
                y: height - 375,
                width: width - 75,
                height: 23,
                color: rgb(.9, .9, .9),
            });

            firstPage.drawText('Datum:', {
                x: 48,
                y: height - 367,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText(getDate(argStartDatumZeit, isClient), {
                x: 200,
                y: height - 367,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText('Temperatur / Frost:', {
                x: 48,
                y: height - 390,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argTemperaturSelected) {
                firstPage.drawText('Minimalwert', {
                    x: 200,
                    y: height - 390,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                firstPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 390,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            firstPage.drawText(argTemperaturSelected ? argWeatherData ? argWeatherData[0] ? argWeatherData[0].temperature ? argWeatherData[0].temperature.TMin ? argWeatherData[0].temperature.TMin : "" : "" : "" : "" : "", {
                x: 330,
                y: height - 390,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText(argTemperaturSelected ? 'Maximalwert' : '', {
                x: 400,
                y: height - 390,
                size: 10,
                font: normalCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText(argTemperaturSelected ? argWeatherData ? argWeatherData[0] ? argWeatherData[0].temperature ? argWeatherData[0].temperature.TMax ? argWeatherData[0].temperature.TMax : "" : "" : "" : "" : "", {
                x: 500,
                y: height - 390,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawRectangle({
                x: 43,
                y: height - 397,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            firstPage.drawText('Niederschlagsmenge:', {
                x: 48,
                y: height - 410,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argNiederschlagsmengeSelected) {
                firstPage.drawText('Niederschlagssumme', {
                    x: 200,
                    y: height - 410,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                firstPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 410,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            firstPage.drawText(argNiederschlagsmengeSelected ? argWeatherData ? argWeatherData[0] ? argWeatherData[0].precipitation ? argWeatherData[0].precipitation.rain_Amount ? argWeatherData[0].precipitation.rain_Amount : "" : "" : "" : "" : "", {
                x: 330,
                y: height - 410,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawRectangle({
                x: 43,
                y: height - 417,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            firstPage.drawText('Windgeschwindigkeit:', {
                x: 48,
                y: height - 430,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argWindgeschwindigkeitSelected) {
                firstPage.drawText('Maximalwert', {
                    x: 200,
                    y: height - 430,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                firstPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 430,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            firstPage.drawText(argWindgeschwindigkeitSelected ? argWeatherData ? argWeatherData[0] ? argWeatherData[0].windspeed ? argWeatherData[0].windspeed.windspeed ? argWeatherData[0].windspeed.windspeed : "" : "" : "" : "" : "", {
                x: 330,
                y: height - 430,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawRectangle({
                x: 43,
                y: height - 437,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            firstPage.drawText('Glätte:', {
                x: 48,
                y: height - 450,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argGlatteFrostSelected) {
                firstPage.drawText(argWeatherData ? argWeatherData[0] ? argWeatherData[0].glatte ? argWeatherData[0].glatte.Glatte ? argWeatherData[0].glatte.Glatte.toString() === "Yes" ? "Ja" : "Nein" : "" : "" : "" : "", {
                    x: 200,
                    y: height - 450,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                firstPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 450,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            firstPage.drawRectangle({
                x: 43,
                y: height - 457,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            firstPage.drawText('Hagel:', {
                x: 48,
                y: height - 470,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argHagelSelected) {
                firstPage.drawText(argWeatherData ? argWeatherData[0] ? argWeatherData[0].hagel ? argWeatherData[0].hagel.hail ? argWeatherData[0].hagel.hail.toString() === "Yes" ? "Ja" : "Nein" : "Keine Daten" : "Keine Daten" : "Keine Daten" : "Keine Daten", {
                    x: 200,
                    y: height - 470,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                firstPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 470,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            firstPage.drawRectangle({
                x: 43,
                y: height - 477,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            firstPage.drawText('Blitz:', {
                x: 48,
                y: height - 490,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argBlitzSelected) {
                firstPage.drawText(argWeatherData ? argWeatherData[0] ? argWeatherData[0].blitz ? argWeatherData[0].blitz.blitz ? argWeatherData[0].blitz.blitz : "" : "" : "" : "", {
                    x: 200,
                    y: height - 490,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                firstPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 490,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            firstPage.drawText(argBlitzSelected ? argWeatherData[0] ? argWeatherData[0].blitz ? argWeatherData[0].blitz.blitz ? argWeatherData[0].blitz.blitz === 'Ja' ? 'Zeit von - bis:' : '' : '' : '' : '' : '', {
                x: 400,
                y: height - 490,
                size: 10,
                font: normalCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText(argBlitzSelected ? argWeatherData ? argWeatherData[0] ? argWeatherData[0].blitz ? argWeatherData[0].blitz.from ? argWeatherData[0].blitz.from + " - " : "" : "" : "" : "" : "", {
                x: 500,
                y: height - 490,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText(argBlitzSelected ? argWeatherData ? argWeatherData[0] ? argWeatherData[0].blitz ? argWeatherData[0].blitz.to ? argWeatherData[0].blitz.to : "" : "" : "" : "" : "", {
                x: 533,
                y: height - 490,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawRectangle({
                x: 43,
                y: height - 497,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            firstPage.drawText('Sicht/Lichtverhältnisse:', {
                x: 48,
                y: height - 510,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argSichtLichtverhaltnisseSelected) {
                firstPage.drawText(argWeatherData ? argWeatherData[0] ? argWeatherData[0].visibility ? argWeatherData[0].visibility.visibility ? argWeatherData[0].visibility.visibility : "" : "" : "" : "", {
                    x: 200,
                    y: height - 510,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                firstPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 510,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

        }

        /* #####################First date###################*/

        /* #####################Second date###################*/

        if (dateDiffInDays(argStartDatumZeit, argEndDatumZeit) > 0) {

            firstPage.drawRectangle({
                x: 43,
                y: height - 545,
                width: width - 75,
                height: 23,
                color: rgb(.9, .9, .9),
            });

            firstPage.drawText('Datum:', {
                x: 48,
                y: height - 537,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText(getDate(new Date(argStartDatumZeit).addDays(1), isClient), {
                x: 200,
                y: height - 537,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText('Temperatur / Frost:', {
                x: 48,
                y: height - 560,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argTemperaturSelected) {
                firstPage.drawText('Minimalwert', {
                    x: 200,
                    y: height - 560,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                firstPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 560,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            firstPage.drawText(argTemperaturSelected ? argWeatherData ? argWeatherData[1] ? argWeatherData[1].temperature ? argWeatherData[1].temperature.TMin ? argWeatherData[1].temperature.TMin : "" : "" : "" : "" : "", {
                x: 330,
                y: height - 560,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText(argTemperaturSelected ? 'Maximalwert' : '', {
                x: 400,
                y: height - 560,
                size: 10,
                font: normalCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText(argTemperaturSelected ? argWeatherData ? argWeatherData[1] ? argWeatherData[1].temperature ? argWeatherData[1].temperature.TMax ? argWeatherData[1].temperature.TMax : "" : "" : "" : "" : "", {
                x: 500,
                y: height - 560,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawRectangle({
                x: 43,
                y: height - 567,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            firstPage.drawText('Niederschlagsmenge:', {
                x: 48,
                y: height - 580,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argNiederschlagsmengeSelected) {
                firstPage.drawText('Niederschlagssumme', {
                    x: 200,
                    y: height - 580,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                firstPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 580,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            firstPage.drawText(argNiederschlagsmengeSelected ? argWeatherData ? argWeatherData[1] ? argWeatherData[1].precipitation ? argWeatherData[1].precipitation.rain_Amount ? argWeatherData[1].precipitation.rain_Amount : "" : "" : "" : "" : "", {
                x: 330,
                y: height - 580,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawRectangle({
                x: 43,
                y: height - 587,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            firstPage.drawText('Windgeschwindigkeit:', {
                x: 48,
                y: height - 600,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argWindgeschwindigkeitSelected) {
                firstPage.drawText('Maximalwert', {
                    x: 200,
                    y: height - 600,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                firstPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 600,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            firstPage.drawText(argWindgeschwindigkeitSelected ? argWeatherData ? argWeatherData[1] ? argWeatherData[1].windspeed ? argWeatherData[1].windspeed.windspeed ? argWeatherData[1].windspeed.windspeed : "" : "" : "" : "" : "", {
                x: 330,
                y: height - 600,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawRectangle({
                x: 43,
                y: height - 607,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            firstPage.drawText('Glätte:', {
                x: 48,
                y: height - 620,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argGlatteFrostSelected) {
                firstPage.drawText(argWeatherData ? argWeatherData[1] ? argWeatherData[1].glatte ? argWeatherData[1].glatte.Glatte ? argWeatherData[1].glatte.Glatte.toString() === "Yes" ? "Ja" : "Nein" : "" : "" : "" : "", {
                    x: 200,
                    y: height - 620,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                firstPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 620,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            firstPage.drawRectangle({
                x: 43,
                y: height - 627,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            firstPage.drawText('Hagel:', {
                x: 48,
                y: height - 640,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argHagelSelected) {
                firstPage.drawText(argWeatherData ? argWeatherData[1] ? argWeatherData[1].hagel ? argWeatherData[1].hagel.hail ? argWeatherData[1].hagel.hail.toString() === "Yes" ? "Ja" : "Nein" : "Keine Daten" : "Keine Daten" : "Keine Daten" : "Keine Daten", {
                    x: 200,
                    y: height - 640,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                firstPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 640,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            firstPage.drawRectangle({
                x: 43,
                y: height - 647,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            firstPage.drawText('Blitz:', {
                x: 48,
                y: height - 660,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argBlitzSelected) {
                firstPage.drawText(argWeatherData ? argWeatherData[1] ? argWeatherData[1].blitz ? argWeatherData[1].blitz.blitz ? argWeatherData[1].blitz.blitz : "" : "" : "" : "", {
                    x: 200,
                    y: height - 660,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                firstPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 660,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            firstPage.drawText(argBlitzSelected ? argWeatherData[1] ? argWeatherData[1].blitz ? argWeatherData[1].blitz.blitz ? argWeatherData[1].blitz.blitz === 'Ja' ? 'Zeit von - bis:' : '' : '' : '' : '' : '', {
                x: 400,
                y: height - 660,
                size: 10,
                font: normalCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText(argBlitzSelected ? argWeatherData ? argWeatherData[1] ? argWeatherData[1].blitz ? argWeatherData[1].blitz.from ? argWeatherData[1].blitz.from + " - " : "" : "" : "" : "" : "", {
                x: 500,
                y: height - 660,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText(argBlitzSelected ? argWeatherData ? argWeatherData[1] ? argWeatherData[1].blitz ? argWeatherData[1].blitz.to ? argWeatherData[1].blitz.to : "" : "" : "" : "" : "", {
                x: 533,
                y: height - 660,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawRectangle({
                x: 43,
                y: height - 667,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            firstPage.drawText('Sicht/Lichtverhältnisse:', {
                x: 48,
                y: height - 680,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argSichtLichtverhaltnisseSelected) {
                firstPage.drawText(argWeatherData ? argWeatherData[1] ? argWeatherData[1].visibility ? argWeatherData[1].visibility.visibility ? argWeatherData[1].visibility.visibility : "" : "" : "" : "", {
                    x: 200,
                    y: height - 680,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                firstPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 680,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            /*firstPage.drawRectangle({
                x: 43,
                y: height - 687,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });*/
        }
        /* #####################Second date###################*/

        if(argNiederschlagsmengeSelected){

            firstPage.drawRectangle({
                x: 43,
                y: height - 710,
                width: width - 75,
                height: 23,
                color: rgb(.9, .9, .9),
                borderWidth: .5,
                borderColor: rgb(.7, .7, .7),
                borderOpacity: 1,
            });

            firstPage.drawText('Gesamtniederschlag im angefragten Zeitraum:', {
                x: 250,
                y: height - 702,
                size: 11,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText(argTotalPrecipitation ? formatePrecipitation(argTotalPrecipitation, isClient) + " mm" : "", {
                x: 500,
                y: height - 702,
                size: 11,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });
        }

        if (argLange !== null && argBreite !== null && !isNaN(argLange) && !isNaN(argBreite)) {
            pdfDoc.setTitle("Wetterauskunft_" + getDate(new Date(), isClient) + "_" + argBreite.toString() + "_" + argLange.toString());
        } else if (argOrt && argOrt.name) {
            pdfDoc.setTitle("Wetterauskunft_" + getDate(new Date(), isClient) + "_" + argOrt.name);
        } else {
            pdfDoc.setTitle("Wetterauskunft_" + getDate(new Date(), isClient));
        }
        pdfDoc.setSubject('RiskControl')
        pdfDoc.setAuthor('Qmet GmbH');
        pdfDoc.setCreationDate(new Date());
        pdfDoc.setModificationDate(new Date());

        const pdfBytes = await pdfDoc.save();
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        const blobURL = URL.createObjectURL(blob);

        //to view
        /*window.open(blobURL);*/

        //to download
        let link = document.createElement("a");
        link.style = "display: none";
        link.href = blobURL;
        if (argLange !== null && argBreite !== null && !isNaN(argLange) && !isNaN(argBreite)) {
            link.download = `${"Wetterauskunft_" + getDate(new Date(), isClient) + "_" + argBreite.toString() + "_" + argLange.toString()}.pdf`;
        } else if (argOrt && argOrt.name) {
            link.download = `${"Wetterauskunft_" + getDate(new Date(), isClient) + "_" + argOrt.name}.pdf`;
        } else {
            link.download = `${"Wetterauskunft_" + getDate(new Date(), isClient)}.pdf`;
        }
        link.click();
        window.URL.revokeObjectURL(blobURL);
        return true;
    } catch (error) {
        if (isClient) {
            ReactGA.event("rc_pdfdownload", {
                rc_pdfdownload_error: "202208111425 - " + error
            });
        } else {
            console.log('202208111425 - ' + error);
        }
        return false;
    }
}

export const TwoPagePDFDownload = async (
    argStrasse,
    argHausnummer,
    argLange,
    argBreite,
    argOrt,
    argOrtByLocation,
    argStartDatumZeit,
    argEndDatumZeit,
    argTemperaturSelected,
    argNiederschlagsmengeSelected,
    argWindgeschwindigkeitSelected,
    argGlatteFrostSelected,
    argHagelSelected,
    argBlitzSelected,
    argSichtLichtverhaltnisseSelected,
    argWeatherData,
    argTotalPrecipitation,
    argReferenzstation,
    isClient
) => {
    try {
        const boldFonturl = process.env.PUBLIC_URL + '/Exo2-Medium.ttf';
        const normalFonturl = process.env.PUBLIC_URL + '/Exo2-Light.ttf';
        const url = process.env.PUBLIC_URL + '/Qmet_Briefpapier2.pdf';

        const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());
        const pdfDoc = await PDFDocument.load(existingPdfBytes);

        const boldFontBytes = await fetch(boldFonturl).then(res => res.arrayBuffer());
        const NormalFontBytes = await fetch(normalFonturl).then(res => res.arrayBuffer());

        pdfDoc.registerFontkit(fontkit);

        const boldCustomFont = await pdfDoc.embedFont(boldFontBytes);
        const normalCustomFont = await pdfDoc.embedFont(NormalFontBytes);

        const pages = pdfDoc.getPages();
        const firstPage = pages[0];
        const secondPage = pages[1];
        const { width, height } = firstPage.getSize()
        const fontSize = 20;

        firstPage.drawText('Seite 1 von 2', {
            x: width - 90,
            y: height - 135,
            size: 10,
            color: rgb(.2, .2, .2),
            textAlign: TextAlignment.Right,
            font: normalCustomFont,
        });

        firstPage.drawRectangle({
            x: 43,
            y: height - 150,
            width: width - 75,
            height: .5,
            color: rgb(.7, .7, .7),
        });

        firstPage.drawRectangle({
            x: 43,
            y: height - 750,
            width: width - 75,
            height: .5,
            color: rgb(.7, .7, .7),
        });

        firstPage.drawText('Risk', {
            x: width - 140,
            y: height - 177,
            size: fontSize,
            color: rgb(.2, .2, .2),
            textAlign: TextAlignment.Right,
            font: normalCustomFont,
        });

        firstPage.drawText('Control', {
            x: width - 100,
            y: height - 177,
            size: fontSize,
            color: rgb(.2, .2, .2),
            textAlign: TextAlignment.Right,
            font: boldCustomFont,
        });

        firstPage.drawText('Kundennummer:', {
            x: width - 215,
            y: height - 195,
            size: 10,
            font: boldCustomFont,
            color: rgb(.2, .2, .2),
            textAlign: TextAlignment.Right
        });

        firstPage.drawText('1234-5678', {
            x: width - 80,
            y: height - 195,
            size: 10,
            font: normalCustomFont,
            color: rgb(.2, .2, .2),
            textAlign: TextAlignment.Right
        });

        firstPage.drawText('Rechnungsnummer:', {
            x: width - 215,
            y: height - 210,
            size: 10,
            font: boldCustomFont,
            color: rgb(.2, .2, .2),
            textAlign: TextAlignment.Right
        });

        firstPage.drawText('1234-5678', {
            x: width - 80,
            y: height - 210,
            size: 10,
            font: normalCustomFont,
            color: rgb(.2, .2, .2),
            textAlign: TextAlignment.Right
        });

        firstPage.drawText('Ihre Wetterauskunft', {
            x: 43,
            y: height - 245,
            size: 13,
            font: boldCustomFont,
            color: rgb(.2, .2, .2),
            textAlign: TextAlignment.Right
        });

        let today = getDayOfWeek(new Date(), isClient);
        today = today + ', ';
        today = today + getDateFullMonth(new Date(), isClient);

        firstPage.drawText(today, {
            x: 43,
            y: height - 260,
            size: 10,
            font: normalCustomFont,
            color: rgb(.2, .2, .2),
            textAlign: TextAlignment.Right
        });

        if (argLange !== null && argBreite !== null && !isNaN(argLange) && !isNaN(argBreite)) {
            firstPage.drawText('Geogr. Länge:', {
                x: 43,
                y: height - 280,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText(argLange, {
                x: 160,
                y: height - 280,
                size: 10,
                font: normalCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText('Geogr. Breite:', {
                x: 43,
                y: height - 295,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText(argBreite, {
                x: 160,
                y: height - 295,
                size: 10,
                font: normalCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText('Zeitpunkt:', {
                x: 43,
                y: height - 310,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            let vonDatum = 'von ';
            vonDatum = vonDatum + getDayOfWeek(argStartDatumZeit, isClient);
            vonDatum = vonDatum + ', den ';
            vonDatum = vonDatum + getDate(argStartDatumZeit, isClient);
            vonDatum = vonDatum + ', ';
            vonDatum = vonDatum + getTime(argStartDatumZeit, isClient);
            vonDatum = vonDatum + ' Uhr';

            firstPage.drawText(vonDatum, {
                x: 160,
                y: height - 310,
                size: 10,
                font: normalCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            let bisDatum = 'bis ';
            bisDatum = bisDatum + getDayOfWeek(argEndDatumZeit, isClient);
            bisDatum = bisDatum + ', den ';
            bisDatum = bisDatum + getDate(argEndDatumZeit, isClient);
            bisDatum = bisDatum + ', ';
            bisDatum = bisDatum + getTime(argEndDatumZeit, isClient);
            bisDatum = bisDatum + ' Uhr';

            firstPage.drawText(bisDatum, {
                x: 160,
                y: height - 325,
                size: 10,
                font: normalCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText('Referenzstation:', {
                x: 43,
                y: height - 340,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText(argReferenzstation ? argReferenzstation : "", {
                x: 160,
                y: height - 340,
                size: 10,
                font: normalCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });
        } else {
            if (argOrtByLocation && argOrtByLocation.name) {
                firstPage.drawText('Ort:', {
                    x: 43,
                    y: height - 280,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });

                let fullOrt = argStrasse ? argStrasse : null;
                fullOrt = argHausnummer ? fullOrt + " " : fullOrt;
                fullOrt = argHausnummer ? fullOrt + argHausnummer : fullOrt;
                fullOrt = argOrtByLocation.postal_code ? fullOrt + ", " : fullOrt;
                fullOrt = argOrtByLocation.postal_code ? fullOrt + argOrtByLocation.postal_code : fullOrt;
                fullOrt = argOrtByLocation.name ? fullOrt + " " : fullOrt;
                fullOrt = argOrtByLocation.name ? fullOrt + argOrtByLocation.name : fullOrt;

                firstPage.drawText(fullOrt, {
                    x: 160,
                    y: height - 280,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });

                firstPage.drawText('Zeitpunkt:', {
                    x: 43,
                    y: height - 295,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });

                let vonDatum = 'von ';
                vonDatum = vonDatum + getDayOfWeek(argStartDatumZeit, isClient);
                vonDatum = vonDatum + ', den ';
                vonDatum = vonDatum + getDate(argStartDatumZeit, isClient);
                vonDatum = vonDatum + ', ';
                vonDatum = vonDatum + getTime(argStartDatumZeit, isClient);
                vonDatum = vonDatum + ' Uhr';

                firstPage.drawText(vonDatum, {
                    x: 160,
                    y: height - 295,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });

                let bisDatum = 'bis ';
                bisDatum = bisDatum + getDayOfWeek(argEndDatumZeit, isClient);
                bisDatum = bisDatum + ', den ';
                bisDatum = bisDatum + getDate(argEndDatumZeit, isClient);
                bisDatum = bisDatum + ', ';
                bisDatum = bisDatum + getTime(argEndDatumZeit, isClient);
                bisDatum = bisDatum + ' Uhr';

                firstPage.drawText(bisDatum, {
                    x: 160,
                    y: height - 310,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });

                firstPage.drawText('Referenzstation:', {
                    x: 43,
                    y: height - 325,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });

                firstPage.drawText(argReferenzstation ? argReferenzstation : "", {
                    x: 160,
                    y: height - 325,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            }
        }

        /* #####################First date###################*/

        if (dateDiffInDays(argStartDatumZeit, argEndDatumZeit) > -1) {

            firstPage.drawRectangle({
                x: 43,
                y: height - 375,
                width: width - 75,
                height: 23,
                color: rgb(.9, .9, .9),
            });

            firstPage.drawText('Datum:', {
                x: 48,
                y: height - 367,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText(getDate(argStartDatumZeit, isClient), {
                x: 200,
                y: height - 367,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText('Temperatur / Frost:', {
                x: 48,
                y: height - 390,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argTemperaturSelected) {
                firstPage.drawText('Minimalwert', {
                    x: 200,
                    y: height - 390,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                firstPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 390,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            firstPage.drawText(argTemperaturSelected ? argWeatherData ? argWeatherData[0] ? argWeatherData[0].temperature ? argWeatherData[0].temperature.TMin ? argWeatherData[0].temperature.TMin : "" : "" : "" : "" : "", {
                x: 330,
                y: height - 390,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText(argTemperaturSelected ? 'Maximalwert' : '', {
                x: 400,
                y: height - 390,
                size: 10,
                font: normalCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText(argTemperaturSelected ? argWeatherData ? argWeatherData[0] ? argWeatherData[0].temperature ? argWeatherData[0].temperature.TMax ? argWeatherData[0].temperature.TMax : "" : "" : "" : "" : "", {
                x: 500,
                y: height - 390,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawRectangle({
                x: 43,
                y: height - 397,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            firstPage.drawText('Niederschlagsmenge:', {
                x: 48,
                y: height - 410,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argNiederschlagsmengeSelected) {
                firstPage.drawText('Niederschlagssumme', {
                    x: 200,
                    y: height - 410,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                firstPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 410,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            firstPage.drawText(argNiederschlagsmengeSelected ? argWeatherData ? argWeatherData[0] ? argWeatherData[0].precipitation ? argWeatherData[0].precipitation.rain_Amount ? argWeatherData[0].precipitation.rain_Amount : "" : "" : "" : "" : "", {
                x: 330,
                y: height - 410,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawRectangle({
                x: 43,
                y: height - 417,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            firstPage.drawText('Windgeschwindigkeit:', {
                x: 48,
                y: height - 430,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argWindgeschwindigkeitSelected) {
                firstPage.drawText('Maximalwert', {
                    x: 200,
                    y: height - 430,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                firstPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 430,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            firstPage.drawText(argWindgeschwindigkeitSelected ? argWeatherData ? argWeatherData[0] ? argWeatherData[0].windspeed ? argWeatherData[0].windspeed.windspeed ? argWeatherData[0].windspeed.windspeed : "" : "" : "" : "" : "", {
                x: 330,
                y: height - 430,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawRectangle({
                x: 43,
                y: height - 437,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            firstPage.drawText('Glätte:', {
                x: 48,
                y: height - 450,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argGlatteFrostSelected) {
                firstPage.drawText(argWeatherData ? argWeatherData[0] ? argWeatherData[0].glatte ? argWeatherData[0].glatte.Glatte ? argWeatherData[0].glatte.Glatte.toString() === "Yes" ? "Ja" : "Nein" : "" : "" : "" : "", {
                    x: 200,
                    y: height - 450,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                firstPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 450,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            firstPage.drawRectangle({
                x: 43,
                y: height - 457,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            firstPage.drawText('Hagel:', {
                x: 48,
                y: height - 470,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argHagelSelected) {
                firstPage.drawText(argWeatherData ? argWeatherData[0] ? argWeatherData[0].hagel ? argWeatherData[0].hagel.hail ? argWeatherData[0].hagel.hail.toString() === "Yes" ? "Ja" : "Nein" : "Keine Daten" : "Keine Daten" : "Keine Daten" : "Keine Daten", {
                    x: 200,
                    y: height - 470,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                firstPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 470,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            firstPage.drawRectangle({
                x: 43,
                y: height - 477,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            firstPage.drawText('Blitz:', {
                x: 48,
                y: height - 490,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argBlitzSelected) {
                firstPage.drawText(argWeatherData ? argWeatherData[0] ? argWeatherData[0].blitz ? argWeatherData[0].blitz.blitz ? argWeatherData[0].blitz.blitz : "" : "" : "" : "", {
                    x: 200,
                    y: height - 490,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                firstPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 490,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            firstPage.drawText(argBlitzSelected ? argWeatherData[0] ? argWeatherData[0].blitz ? argWeatherData[0].blitz.blitz ? argWeatherData[0].blitz.blitz === 'Ja' ? 'Zeit von - bis:' : '' : '' : '' : '' : '', {
                x: 400,
                y: height - 490,
                size: 10,
                font: normalCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText(argBlitzSelected ? argWeatherData ? argWeatherData[0] ? argWeatherData[0].blitz ? argWeatherData[0].blitz.from ? argWeatherData[0].blitz.from + " - " : "" : "" : "" : "" : "", {
                x: 500,
                y: height - 490,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText(argBlitzSelected ? argWeatherData ? argWeatherData[0] ? argWeatherData[0].blitz ? argWeatherData[0].blitz.to ? argWeatherData[0].blitz.to : "" : "" : "" : "" : "", {
                x: 533,
                y: height - 490,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawRectangle({
                x: 43,
                y: height - 497,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            firstPage.drawText('Sicht/Lichtverhältnisse:', {
                x: 48,
                y: height - 510,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argSichtLichtverhaltnisseSelected) {
                firstPage.drawText(argWeatherData ? argWeatherData[0] ? argWeatherData[0].visibility ? argWeatherData[0].visibility.visibility ? argWeatherData[0].visibility.visibility : "" : "" : "" : "", {
                    x: 200,
                    y: height - 510,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                firstPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 510,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }
        }

        /* #####################First date###################*/

        /* #####################Second date###################*/

        if (dateDiffInDays(argStartDatumZeit, argEndDatumZeit) > 0) {

            firstPage.drawRectangle({
                x: 43,
                y: height - 540,
                width: width - 75,
                height: 23,
                color: rgb(.9, .9, .9),
            });

            firstPage.drawText('Datum:', {
                x: 48,
                y: height - 532,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText(getDate(new Date(argStartDatumZeit).addDays(1), isClient), {
                x: 200,
                y: height - 532,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText('Temperatur / Frost:', {
                x: 48,
                y: height - 555,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argTemperaturSelected) {
                firstPage.drawText('Minimalwert', {
                    x: 200,
                    y: height - 555,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                firstPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 555,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            firstPage.drawText(argTemperaturSelected ? argWeatherData ? argWeatherData[1] ? argWeatherData[1].temperature ? argWeatherData[1].temperature.TMin ? argWeatherData[1].temperature.TMin : "" : "" : "" : "" : "", {
                x: 330,
                y: height - 555,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText(argTemperaturSelected ? 'Maximalwert' : '', {
                x: 400,
                y: height - 555,
                size: 10,
                font: normalCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText(argTemperaturSelected ? argWeatherData ? argWeatherData[1] ? argWeatherData[1].temperature ? argWeatherData[1].temperature.TMax ? argWeatherData[1].temperature.TMax : "" : "" : "" : "" : "", {
                x: 500,
                y: height - 555,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawRectangle({
                x: 43,
                y: height - 562,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            firstPage.drawText('Niederschlagsmenge:', {
                x: 48,
                y: height - 575,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argNiederschlagsmengeSelected) {
                firstPage.drawText('Niederschlagssumme', {
                    x: 200,
                    y: height - 575,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                firstPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 575,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            firstPage.drawText(argNiederschlagsmengeSelected ? argWeatherData ? argWeatherData[1] ? argWeatherData[1].precipitation ? argWeatherData[1].precipitation.rain_Amount ? argWeatherData[1].precipitation.rain_Amount : "" : "" : "" : "" : "", {
                x: 330,
                y: height - 575,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawRectangle({
                x: 43,
                y: height - 582,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            firstPage.drawText('Windgeschwindigkeit:', {
                x: 48,
                y: height - 595,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argWindgeschwindigkeitSelected) {
                firstPage.drawText('Maximalwert', {
                    x: 200,
                    y: height - 595,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                firstPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 595,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            firstPage.drawText(argWindgeschwindigkeitSelected ? argWeatherData ? argWeatherData[1] ? argWeatherData[1].windspeed ? argWeatherData[1].windspeed.windspeed ? argWeatherData[1].windspeed.windspeed : "" : "" : "" : "" : "", {
                x: 330,
                y: height - 595,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawRectangle({
                x: 43,
                y: height - 602,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            firstPage.drawText('Glätte:', {
                x: 48,
                y: height - 615,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argGlatteFrostSelected) {
                firstPage.drawText(argWeatherData ? argWeatherData[1] ? argWeatherData[1].glatte ? argWeatherData[1].glatte.Glatte ? argWeatherData[1].glatte.Glatte.toString() === "Yes" ? "Ja" : "Nein" : "" : "" : "" : "", {
                    x: 200,
                    y: height - 615,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                firstPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 615,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            firstPage.drawRectangle({
                x: 43,
                y: height - 622,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            firstPage.drawText('Hagel:', {
                x: 48,
                y: height - 635,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argHagelSelected) {
                firstPage.drawText(argWeatherData ? argWeatherData[1] ? argWeatherData[1].hagel ? argWeatherData[1].hagel.hail ? argWeatherData[1].hagel.hail.toString() === "Yes" ? "Ja" : "Nein" : "Keine Daten" : "Keine Daten" : "Keine Daten" : "Keine Daten", {
                    x: 200,
                    y: height - 635,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                firstPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 635,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            firstPage.drawRectangle({
                x: 43,
                y: height - 642,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            firstPage.drawText('Blitz:', {
                x: 48,
                y: height - 655,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argBlitzSelected) {
                firstPage.drawText(argWeatherData ? argWeatherData[1] ? argWeatherData[1].blitz ? argWeatherData[1].blitz.blitz ? argWeatherData[1].blitz.blitz : "" : "" : "" : "", {
                    x: 200,
                    y: height - 655,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                firstPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 655,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            firstPage.drawText(argBlitzSelected ? argWeatherData[1] ? argWeatherData[1].blitz ? argWeatherData[1].blitz.blitz ? argWeatherData[1].blitz.blitz === 'Ja' ? 'Zeit von - bis:' : '' : '' : '' : '' : '', {
                x: 400,
                y: height - 655,
                size: 10,
                font: normalCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText(argBlitzSelected ? argWeatherData ? argWeatherData[1] ? argWeatherData[1].blitz ? argWeatherData[1].blitz.from ? argWeatherData[1].blitz.from + " - " : "" : "" : "" : "" : "", {
                x: 500,
                y: height - 655,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText(argBlitzSelected ? argWeatherData ? argWeatherData[1] ? argWeatherData[1].blitz ? argWeatherData[1].blitz.to ? argWeatherData[1].blitz.to : "" : "" : "" : "" : "", {
                x: 533,
                y: height - 655,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawRectangle({
                x: 43,
                y: height - 662,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            firstPage.drawText('Sicht/Lichtverhältnisse:', {
                x: 48,
                y: height - 675,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argSichtLichtverhaltnisseSelected) {
                firstPage.drawText(argWeatherData ? argWeatherData[1] ? argWeatherData[1].visibility ? argWeatherData[1].visibility.visibility ? argWeatherData[1].visibility.visibility : "" : "" : "" : "", {
                    x: 200,
                    y: height - 675,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                firstPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 675,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

        }
        /* #####################Second date###################*/

        /* #####################Third date###################*/

        if (dateDiffInDays(argStartDatumZeit, argEndDatumZeit) > 1) {

            secondPage.drawRectangle({
                x: 43,
                y: height - 180,
                width: width - 75,
                height: 23,
                color: rgb(.9, .9, .9),
            });

            secondPage.drawText('Datum:', {
                x: 48,
                y: height - 172,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawText(getDate(new Date(argStartDatumZeit).addDays(2), isClient), {
                x: 200,
                y: height - 172,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawText('Temperatur / Frost:', {
                x: 48,
                y: height - 195,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argTemperaturSelected) {
                secondPage.drawText('Minimalwert', {
                    x: 200,
                    y: height - 195,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                secondPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 195,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            secondPage.drawText(argTemperaturSelected ? argWeatherData ? argWeatherData[2] ? argWeatherData[2].temperature ? argWeatherData[2].temperature.TMin ? argWeatherData[2].temperature.TMin : "" : "" : "" : "" : "", {
                x: 330,
                y: height - 195,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawText(argTemperaturSelected ? 'Maximalwert' : '', {
                x: 400,
                y: height - 195,
                size: 10,
                font: normalCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawText(argTemperaturSelected ? argWeatherData ? argWeatherData[2] ? argWeatherData[2].temperature ? argWeatherData[2].temperature.TMax ? argWeatherData[2].temperature.TMax : "" : "" : "" : "" : "", {
                x: 500,
                y: height - 195,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawRectangle({
                x: 43,
                y: height - 202,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            secondPage.drawText('Niederschlagsmenge:', {
                x: 48,
                y: height - 215,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argNiederschlagsmengeSelected) {
                secondPage.drawText('Niederschlagssumme', {
                    x: 200,
                    y: height - 215,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                secondPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 215,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            secondPage.drawText(argNiederschlagsmengeSelected ? argWeatherData ? argWeatherData[2] ? argWeatherData[2].precipitation ? argWeatherData[2].precipitation.rain_Amount ? argWeatherData[2].precipitation.rain_Amount : "" : "" : "" : "" : "", {
                x: 330,
                y: height - 215,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawRectangle({
                x: 43,
                y: height - 222,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            secondPage.drawText('Windgeschwindigkeit:', {
                x: 48,
                y: height - 235,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argWindgeschwindigkeitSelected) {
                secondPage.drawText('Maximalwert', {
                    x: 200,
                    y: height - 235,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                secondPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 235,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            secondPage.drawText(argWindgeschwindigkeitSelected ? argWeatherData ? argWeatherData[2] ? argWeatherData[2].windspeed ? argWeatherData[2].windspeed.windspeed ? argWeatherData[2].windspeed.windspeed : "" : "" : "" : "" : "", {
                x: 330,
                y: height - 235,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawRectangle({
                x: 43,
                y: height - 242,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            secondPage.drawText('Glätte:', {
                x: 48,
                y: height - 255,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argGlatteFrostSelected) {
                secondPage.drawText(argWeatherData ? argWeatherData[2] ? argWeatherData[2].glatte ? argWeatherData[2].glatte.Glatte ? argWeatherData[2].glatte.Glatte.toString() === "Yes" ? "Ja" : "Nein" : "" : "" : "" : "", {
                    x: 200,
                    y: height - 255,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                secondPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 255,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            secondPage.drawRectangle({
                x: 43,
                y: height - 262,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            secondPage.drawText('Hagel:', {
                x: 48,
                y: height - 275,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argHagelSelected) {
                secondPage.drawText(argWeatherData ? argWeatherData[2] ? argWeatherData[2].hagel ? argWeatherData[2].hagel.hail ? argWeatherData[2].hagel.hail.toString() === "Yes" ? "Ja" : "Nein" : "Keine Daten" : "Keine Daten" : "Keine Daten" : "Keine Daten", {
                    x: 200,
                    y: height - 275,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                secondPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 275,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            secondPage.drawRectangle({
                x: 43,
                y: height - 282,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            secondPage.drawText('Blitz:', {
                x: 48,
                y: height - 295,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argBlitzSelected) {
                secondPage.drawText(argWeatherData ? argWeatherData[2] ? argWeatherData[2].blitz ? argWeatherData[2].blitz.blitz ? argWeatherData[2].blitz.blitz : "" : "" : "" : "", {
                    x: 200,
                    y: height - 295,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                secondPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 295,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            secondPage.drawText(argBlitzSelected ? argWeatherData[2] ? argWeatherData[2].blitz ? argWeatherData[2].blitz.blitz ? argWeatherData[2].blitz.blitz === 'Ja' ? 'Zeit von - bis:' : '' : '' : '' : '' : '', {
                x: 400,
                y: height - 295,
                size: 10,
                font: normalCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawText(argBlitzSelected ? argWeatherData ? argWeatherData[2] ? argWeatherData[2].blitz ? argWeatherData[2].blitz.from ? argWeatherData[2].blitz.from + " - " : "" : "" : "" : "" : "", {
                x: 500,
                y: height - 295,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawText(argBlitzSelected ? argWeatherData ? argWeatherData[2] ? argWeatherData[2].blitz ? argWeatherData[2].blitz.to ? argWeatherData[2].blitz.to : "" : "" : "" : "" : "", {
                x: 533,
                y: height - 295,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawRectangle({
                x: 43,
                y: height - 302,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            secondPage.drawText('Sicht/Lichtverhältnisse:', {
                x: 48,
                y: height - 315,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argSichtLichtverhaltnisseSelected) {
                secondPage.drawText(argWeatherData ? argWeatherData[2] ? argWeatherData[2].visibility ? argWeatherData[2].visibility.visibility ? argWeatherData[2].visibility.visibility : "" : "" : "" : "", {
                    x: 200,
                    y: height - 315,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                secondPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 315,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

        }

        /* #####################Third date###################*/

        /* #####################Fourth date###################*/

        if (dateDiffInDays(argStartDatumZeit, argEndDatumZeit) > 2) {

            secondPage.drawRectangle({
                x: 43,
                y: height - 345,
                width: width - 75,
                height: 23,
                color: rgb(.9, .9, .9),
            });

            secondPage.drawText('Datum:', {
                x: 48,
                y: height - 337,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawText(getDate(new Date(argStartDatumZeit).addDays(3), isClient), {
                x: 200,
                y: height - 337,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawText('Temperatur / Frost:', {
                x: 48,
                y: height - 360,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argTemperaturSelected) {
                secondPage.drawText('Minimalwert', {
                    x: 200,
                    y: height - 360,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                secondPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 360,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            secondPage.drawText(argTemperaturSelected ? argWeatherData ? argWeatherData[3] ? argWeatherData[3].temperature ? argWeatherData[3].temperature.TMin ? argWeatherData[3].temperature.TMin : "" : "" : "" : "" : "", {
                x: 330,
                y: height - 360,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawText(argTemperaturSelected ? 'Maximalwert' : '', {
                x: 400,
                y: height - 360,
                size: 10,
                font: normalCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawText(argTemperaturSelected ? argWeatherData ? argWeatherData[3] ? argWeatherData[3].temperature ? argWeatherData[3].temperature.TMax ? argWeatherData[3].temperature.TMax : "" : "" : "" : "" : "", {
                x: 500,
                y: height - 360,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawRectangle({
                x: 43,
                y: height - 367,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            secondPage.drawText('Niederschlagsmenge:', {
                x: 48,
                y: height - 380,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argNiederschlagsmengeSelected) {
                secondPage.drawText('Niederschlagssumme', {
                    x: 200,
                    y: height - 380,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                secondPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 380,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            secondPage.drawText(argNiederschlagsmengeSelected ? argWeatherData ? argWeatherData[3] ? argWeatherData[3].precipitation ? argWeatherData[3].precipitation.rain_Amount ? argWeatherData[3].precipitation.rain_Amount : "" : "" : "" : "" : "", {
                x: 330,
                y: height - 380,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawRectangle({
                x: 43,
                y: height - 387,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            secondPage.drawText('Windgeschwindigkeit:', {
                x: 48,
                y: height - 400,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argWindgeschwindigkeitSelected) {
                secondPage.drawText('Maximalwert', {
                    x: 200,
                    y: height - 400,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                secondPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 400,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            secondPage.drawText(argWindgeschwindigkeitSelected ? argWeatherData ? argWeatherData[3] ? argWeatherData[3].windspeed ? argWeatherData[3].windspeed.windspeed ? argWeatherData[3].windspeed.windspeed : "" : "" : "" : "" : "", {
                x: 330,
                y: height - 400,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawRectangle({
                x: 43,
                y: height - 407,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            secondPage.drawText('Glätte:', {
                x: 48,
                y: height - 420,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argGlatteFrostSelected) {
                secondPage.drawText(argWeatherData ? argWeatherData[3] ? argWeatherData[3].glatte ? argWeatherData[3].glatte.Glatte ? argWeatherData[3].glatte.Glatte.toString() === "Yes" ? "Ja" : "Nein" : "" : "" : "" : "", {
                    x: 200,
                    y: height - 420,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                secondPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 420,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            secondPage.drawRectangle({
                x: 43,
                y: height - 427,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            secondPage.drawText('Hagel:', {
                x: 48,
                y: height - 440,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argHagelSelected) {
                secondPage.drawText(argWeatherData ? argWeatherData[3] ? argWeatherData[3].hagel ? argWeatherData[3].hagel.hail ? argWeatherData[3].hagel.hail.toString() === "Yes" ? "Ja" : "Nein" : "Keine Daten" : "Keine Daten" : "Keine Daten" : "Keine Daten", {
                    x: 200,
                    y: height - 440,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                secondPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 440,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            secondPage.drawRectangle({
                x: 43,
                y: height - 447,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            secondPage.drawText('Blitz:', {
                x: 48,
                y: height - 460,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argBlitzSelected) {
                secondPage.drawText(argWeatherData ? argWeatherData[3] ? argWeatherData[3].blitz ? argWeatherData[3].blitz.blitz ? argWeatherData[3].blitz.blitz : "" : "" : "" : "", {
                    x: 200,
                    y: height - 460,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                secondPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 460,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            secondPage.drawText(argBlitzSelected ? argWeatherData[3] ? argWeatherData[3].blitz ? argWeatherData[3].blitz.blitz ? argWeatherData[3].blitz.blitz === 'Ja' ? 'Zeit von - bis:' : '' : '' : '' : '' : '', {
                x: 400,
                y: height - 460,
                size: 10,
                font: normalCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawText(argBlitzSelected ? argWeatherData ? argWeatherData[3] ? argWeatherData[3].blitz ? argWeatherData[3].blitz.from ? argWeatherData[3].blitz.from + " - " : "" : "" : "" : "" : "", {
                x: 500,
                y: height - 460,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawText(argBlitzSelected ? argWeatherData ? argWeatherData[3] ? argWeatherData[3].blitz ? argWeatherData[3].blitz.to ? argWeatherData[3].blitz.to : "" : "" : "" : "" : "", {
                x: 533,
                y: height - 460,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawRectangle({
                x: 43,
                y: height - 467,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            secondPage.drawText('Sicht/Lichtverhältnisse:', {
                x: 48,
                y: height - 480,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argSichtLichtverhaltnisseSelected) {
                secondPage.drawText(argWeatherData ? argWeatherData[3] ? argWeatherData[3].visibility ? argWeatherData[3].visibility.visibility ? argWeatherData[3].visibility.visibility : "" : "" : "" : "", {
                    x: 200,
                    y: height - 480,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                secondPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 480,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }
        }
        /* #####################Fourth date###################*/

        /* #####################Fifth date###################*/

        if (dateDiffInDays(argStartDatumZeit, argEndDatumZeit) > 3) {

            secondPage.drawRectangle({
                x: 43,
                y: height - 510,
                width: width - 75,
                height: 23,
                color: rgb(.9, .9, .9),
            });

            secondPage.drawText('Datum:', {
                x: 48,
                y: height - 502,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawText(getDate(new Date(argStartDatumZeit).addDays(4), isClient), {
                x: 200,
                y: height - 502,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawText('Temperatur / Frost:', {
                x: 48,
                y: height - 525,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argTemperaturSelected) {
                secondPage.drawText('Minimalwert', {
                    x: 200,
                    y: height - 525,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                secondPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 525,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            secondPage.drawText(argTemperaturSelected ? argWeatherData ? argWeatherData[4] ? argWeatherData[4].temperature ? argWeatherData[4].temperature.TMin ? argWeatherData[4].temperature.TMin : "" : "" : "" : "" : "", {
                x: 330,
                y: height - 525,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawText(argTemperaturSelected ? 'Maximalwert' : '', {
                x: 400,
                y: height - 525,
                size: 10,
                font: normalCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawText(argTemperaturSelected ? argWeatherData ? argWeatherData[4] ? argWeatherData[4].temperature ? argWeatherData[4].temperature.TMax ? argWeatherData[4].temperature.TMax : "" : "" : "" : "" : "", {
                x: 500,
                y: height - 525,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawRectangle({
                x: 43,
                y: height - 532,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            secondPage.drawText('Niederschlagsmenge:', {
                x: 48,
                y: height - 545,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argNiederschlagsmengeSelected) {
                secondPage.drawText('Niederschlagssumme', {
                    x: 200,
                    y: height - 545,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                secondPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 545,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            secondPage.drawText(argNiederschlagsmengeSelected ? argWeatherData ? argWeatherData[4] ? argWeatherData[4].precipitation ? argWeatherData[4].precipitation.rain_Amount ? argWeatherData[4].precipitation.rain_Amount : "" : "" : "" : "" : "", {
                x: 330,
                y: height - 545,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawRectangle({
                x: 43,
                y: height - 552,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            secondPage.drawText('Windgeschwindigkeit:', {
                x: 48,
                y: height - 565,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argWindgeschwindigkeitSelected) {
                secondPage.drawText('Maximalwert', {
                    x: 200,
                    y: height - 565,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                secondPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 565,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            secondPage.drawText(argWindgeschwindigkeitSelected ? argWeatherData ? argWeatherData[4] ? argWeatherData[4].windspeed ? argWeatherData[4].windspeed.windspeed ? argWeatherData[4].windspeed.windspeed : "" : "" : "" : "" : "", {
                x: 330,
                y: height - 565,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawRectangle({
                x: 43,
                y: height - 572,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            secondPage.drawText('Glätte:', {
                x: 48,
                y: height - 585,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argGlatteFrostSelected) {
                secondPage.drawText(argWeatherData ? argWeatherData[4] ? argWeatherData[4].glatte ? argWeatherData[4].glatte.Glatte ? argWeatherData[4].glatte.Glatte.toString() === "Yes" ? "Ja" : "Nein" : "" : "" : "" : "", {
                    x: 200,
                    y: height - 585,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                secondPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 585,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            secondPage.drawRectangle({
                x: 43,
                y: height - 592,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            secondPage.drawText('Hagel:', {
                x: 48,
                y: height - 605,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argHagelSelected) {
                secondPage.drawText(argWeatherData ? argWeatherData[4] ? argWeatherData[4].hagel ? argWeatherData[4].hagel.hail ? argWeatherData[4].hagel.hail.toString() === "Yes" ? "Ja" : "Nein" : "Keine Daten" : "Keine Daten" : "Keine Daten" : "Keine Daten", {
                    x: 200,
                    y: height - 605,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                secondPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 605,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            secondPage.drawRectangle({
                x: 43,
                y: height - 612,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            secondPage.drawText('Blitz:', {
                x: 48,
                y: height - 625,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argBlitzSelected) {
                secondPage.drawText(argWeatherData ? argWeatherData[4] ? argWeatherData[4].blitz ? argWeatherData[4].blitz.blitz ? argWeatherData[4].blitz.blitz : "" : "" : "" : "", {
                    x: 200,
                    y: height - 625,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                secondPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 625,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            secondPage.drawText(argBlitzSelected ? argWeatherData[4] ? argWeatherData[4].blitz ? argWeatherData[4].blitz.blitz ? argWeatherData[4].blitz.blitz === 'Ja' ? 'Zeit von - bis:' : '' : '' : '' : '' : '', {
                x: 400,
                y: height - 625,
                size: 10,
                font: normalCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawText(argBlitzSelected ? argWeatherData ? argWeatherData[4] ? argWeatherData[4].blitz ? argWeatherData[4].blitz.from ? argWeatherData[4].blitz.from + " - " : "" : "" : "" : "" : "", {
                x: 500,
                y: height - 625,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawText(argBlitzSelected ? argWeatherData ? argWeatherData[4] ? argWeatherData[4].blitz ? argWeatherData[4].blitz.to ? argWeatherData[4].blitz.to : "" : "" : "" : "" : "", {
                x: 550,
                y: height - 625,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawRectangle({
                x: 43,
                y: height - 632,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            secondPage.drawText('Sicht/Lichtverhältnisse:', {
                x: 48,
                y: height - 645,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argSichtLichtverhaltnisseSelected) {
                secondPage.drawText(argWeatherData ? argWeatherData[4] ? argWeatherData[4].visibility ? argWeatherData[4].visibility.visibility ? argWeatherData[4].visibility.visibility : "" : "" : "" : "", {
                    x: 200,
                    y: height - 645,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                secondPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 645,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            /*firstPage.drawRectangle({
                x: 43,
                y: height - 652,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });*/
        }

        /* #####################Fifth date###################*/

        if (argNiederschlagsmengeSelected) {

            secondPage.drawRectangle({
                x: 43,
                y: height - 675,
                width: width - 75,
                height: 23,
                color: rgb(.9, .9, .9),
                borderWidth: .5,
                borderColor: rgb(.7, .7, .7),
                borderOpacity: 1,
            });

            secondPage.drawText('Gesamtniederschlag im angefragten Zeitraum:', {
                x: 250,
                y: height - 667,
                size: 11,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawText(argTotalPrecipitation ? formatePrecipitation(argTotalPrecipitation, isClient) + " mm" : "", {
                x: 500,
                y: height - 667,
                size: 11,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });
        }

        secondPage.drawText('Seite 2 von 2', {
            x: width - 90,
            y: height - 135,
            size: 10,
            color: rgb(.2, .2, .2),
            textAlign: TextAlignment.Right,
            font: normalCustomFont,
        });

        secondPage.drawRectangle({
            x: 43,
            y: height - 150,
            width: width - 75,
            height: .5,
            color: rgb(.7, .7, .7),
        });

        secondPage.drawRectangle({
            x: 43,
            y: height - 755,
            width: width - 75,
            height: .5,
            color: rgb(.7, .7, .7),
        });

        if (argLange !== null && argBreite !== null && !isNaN(argLange) && !isNaN(argBreite)) {
            pdfDoc.setTitle("Wetterauskunft_" + getDate(new Date(), isClient) + "_" + argBreite.toString() + "_" + argLange.toString());
        } else if (argOrt && argOrt.name) {
            pdfDoc.setTitle("Wetterauskunft_" + getDate(new Date(), isClient) + "_" + argOrt.name);
        } else {
            pdfDoc.setTitle("Wetterauskunft_" + getDate(new Date(), isClient));
        }
        pdfDoc.setSubject('RiskControl')
        pdfDoc.setAuthor('Qmet GmbH');
        pdfDoc.setCreationDate(new Date());
        pdfDoc.setModificationDate(new Date());

        const pdfBytes = await pdfDoc.save();
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        const blobURL = URL.createObjectURL(blob);

        //to view
        /*window.open(blobURL);*/

        //to download
        let link = document.createElement("a");
        link.style = "display: none";
        link.href = blobURL;
        if(argLange !== null && argBreite !== null && !isNaN(argLange) && !isNaN(argBreite)){
            link.download = `${"Wetterauskunft_" + getDate(new Date(), isClient) + "_" + argBreite.toString() + "_" + argLange.toString()}.pdf`;
        }else if(argOrt && argOrt.name){
            link.download = `${"Wetterauskunft_" + getDate(new Date(), isClient) + "_" + argOrt.name}.pdf`;
        }else{
            link.download = `${"Wetterauskunft_" + getDate(new Date(), isClient)}.pdf`;
        }
        link.click();
        window.URL.revokeObjectURL(blobURL);
        return true;
    } catch (error) {
        if (isClient) {
            ReactGA.event("rc_pdfdownload", {
                rc_pdfdownload_error: "202208111423 - " + error
            });
        } else {
            console.log('202208111423 - ' + error);
        }
        return false;
    }
}

export const PDFPrint = async (
    argStrasse,
    argHausnummer,
    argLange,
    argBreite,
    argOrt,
    argOrtByLocation,
    argStartDatumZeit,
    argEndDatumZeit,
    argTemperaturSelected,
    argNiederschlagsmengeSelected,
    argWindgeschwindigkeitSelected,
    argGlatteFrostSelected,
    argHagelSelected,
    argBlitzSelected,
    argSichtLichtverhaltnisseSelected,
    argWeatherData,
    argTotalPrecipitation,
    argReferenzstation,
    isClient
) => {
    try {
        const boldFonturl = process.env.PUBLIC_URL + '/Exo2-Medium.ttf';
        const normalFonturl = process.env.PUBLIC_URL + '/Exo2-Light.ttf';
        const url = process.env.PUBLIC_URL + '/Qmet_Briefpapier.pdf';

        const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());
        const pdfDoc = await PDFDocument.load(existingPdfBytes);

        const boldFontBytes = await fetch(boldFonturl).then(res => res.arrayBuffer());
        const NormalFontBytes = await fetch(normalFonturl).then(res => res.arrayBuffer());

        pdfDoc.registerFontkit(fontkit);

        const boldCustomFont = await pdfDoc.embedFont(boldFontBytes);
        const normalCustomFont = await pdfDoc.embedFont(NormalFontBytes);

        const pages = pdfDoc.getPages();
        const firstPage = pages[0];
        const { width, height } = firstPage.getSize()
        const fontSize = 20;

        firstPage.drawText('Seite 1 von 1', {
            x: width - 90,
            y: height - 135,
            size: 10,
            color: rgb(.2, .2, .2),
            textAlign: TextAlignment.Right,
            font: normalCustomFont,
        });

        firstPage.drawRectangle({
            x: 43,
            y: height - 150,
            width: width - 75,
            height: .5,
            color: rgb(.7, .7, .7),
        });

        firstPage.drawRectangle({
            x: 43,
            y: height - 750,
            width: width - 75,
            height: .5,
            color: rgb(.7, .7, .7),
        });

        firstPage.drawText('Risk', {
            x: width - 140,
            y: height - 177,
            size: fontSize,
            color: rgb(.2, .2, .2),
            textAlign: TextAlignment.Right,
            font: normalCustomFont,
        });

        firstPage.drawText('Control', {
            x: width - 100,
            y: height - 177,
            size: fontSize,
            color: rgb(.2, .2, .2),
            textAlign: TextAlignment.Right,
            font: boldCustomFont,
        });

        firstPage.drawText('Kundennummer:', {
            x: width - 215,
            y: height - 195,
            size: 10,
            font: boldCustomFont,
            color: rgb(.2, .2, .2),
            textAlign: TextAlignment.Right
        });

        firstPage.drawText('1234-5678', {
            x: width - 80,
            y: height - 195,
            size: 10,
            font: normalCustomFont,
            color: rgb(.2, .2, .2),
            textAlign: TextAlignment.Right
        });

        firstPage.drawText('Rechnungsnummer:', {
            x: width - 215,
            y: height - 210,
            size: 10,
            font: boldCustomFont,
            color: rgb(.2, .2, .2),
            textAlign: TextAlignment.Right
        });

        firstPage.drawText('1234-5678', {
            x: width - 80,
            y: height - 210,
            size: 10,
            font: normalCustomFont,
            color: rgb(.2, .2, .2),
            textAlign: TextAlignment.Right
        });

        firstPage.drawText('Ihre Wetterauskunft', {
            x: 43,
            y: height - 245,
            size: 13,
            font: boldCustomFont,
            color: rgb(.2, .2, .2),
            textAlign: TextAlignment.Right
        });

        let today = getDayOfWeek(new Date(), isClient);
        today = today + ', ';
        today = today + getDateFullMonth(new Date(), isClient);

        firstPage.drawText(today, {
            x: 43,
            y: height - 260,
            size: 10,
            font: normalCustomFont,
            color: rgb(.2, .2, .2),
            textAlign: TextAlignment.Right
        });

        if (argLange !== null && argBreite !== null && !isNaN(argLange) && !isNaN(argBreite)) {
            firstPage.drawText('Geogr. Länge:', {
                x: 43,
                y: height - 280,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText(argLange, {
                x: 160,
                y: height - 280,
                size: 10,
                font: normalCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText('Geogr. Breite:', {
                x: 43,
                y: height - 295,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText(argBreite, {
                x: 160,
                y: height - 295,
                size: 10,
                font: normalCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText('Zeitpunkt:', {
                x: 43,
                y: height - 310,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            let vonDatum = 'von ';
            vonDatum = vonDatum + getDayOfWeek(argStartDatumZeit, isClient);
            vonDatum = vonDatum + ', den ';
            vonDatum = vonDatum + getDate(argStartDatumZeit, isClient);
            vonDatum = vonDatum + ', ';
            vonDatum = vonDatum + getTime(argStartDatumZeit, isClient);
            vonDatum = vonDatum + ' Uhr';

            firstPage.drawText(vonDatum, {
                x: 160,
                y: height - 310,
                size: 10,
                font: normalCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            let bisDatum = 'bis ';
            bisDatum = bisDatum + getDayOfWeek(argEndDatumZeit, isClient);
            bisDatum = bisDatum + ', den ';
            bisDatum = bisDatum + getDate(argEndDatumZeit);
            bisDatum = bisDatum + ', ';
            bisDatum = bisDatum + getTime(argEndDatumZeit, isClient);
            bisDatum = bisDatum + ' Uhr';

            firstPage.drawText(bisDatum, {
                x: 160,
                y: height - 325,
                size: 10,
                font: normalCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText('Referenzstation:', {
                x: 43,
                y: height - 340,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText(argReferenzstation ? argReferenzstation : "", {
                x: 160,
                y: height - 340,
                size: 10,
                font: normalCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });
        } else {
            if (argOrtByLocation && argOrtByLocation.name) {
                firstPage.drawText('Ort:', {
                    x: 43,
                    y: height - 280,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });

                let fullOrt = argStrasse ? argStrasse : null;
                fullOrt = argHausnummer ? fullOrt + " " : fullOrt;
                fullOrt = argHausnummer ? fullOrt + argHausnummer : fullOrt;
                fullOrt = argOrtByLocation.postal_code ? fullOrt + ", " : fullOrt;
                fullOrt = argOrtByLocation.postal_code ? fullOrt + argOrtByLocation.postal_code : fullOrt;
                fullOrt = argOrtByLocation.name ? fullOrt + " " : fullOrt;
                fullOrt = argOrtByLocation.name ? fullOrt + argOrtByLocation.name : fullOrt;

                firstPage.drawText(fullOrt, {
                    x: 160,
                    y: height - 280,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });

                firstPage.drawText('Zeitpunkt:', {
                    x: 43,
                    y: height - 295,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });

                let vonDatum = 'von ';
                vonDatum = vonDatum + getDayOfWeek(argStartDatumZeit, isClient);
                vonDatum = vonDatum + ', den ';
                vonDatum = vonDatum + getDate(argStartDatumZeit, isClient);
                vonDatum = vonDatum + ', ';
                vonDatum = vonDatum + getTime(argStartDatumZeit, isClient);
                vonDatum = vonDatum + ' Uhr';

                firstPage.drawText(vonDatum, {
                    x: 160,
                    y: height - 295,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });

                let bisDatum = 'bis ';
                bisDatum = bisDatum + getDayOfWeek(argEndDatumZeit, isClient);
                bisDatum = bisDatum + ', den ';
                bisDatum = bisDatum + getDate(argEndDatumZeit, isClient);
                bisDatum = bisDatum + ', ';
                bisDatum = bisDatum + getTime(argEndDatumZeit, isClient);
                bisDatum = bisDatum + ' Uhr';

                firstPage.drawText(bisDatum, {
                    x: 160,
                    y: height - 310,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });

                firstPage.drawText('Referenzstation:', {
                    x: 43,
                    y: height - 325,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });

                firstPage.drawText(argReferenzstation ? argReferenzstation : "", {
                    x: 160,
                    y: height - 325,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            }
        }

        /* #####################First date###################*/

        if (dateDiffInDays(argStartDatumZeit, argEndDatumZeit) > -1) {

            firstPage.drawRectangle({
                x: 43,
                y: height - 375,
                width: width - 75,
                height: 23,
                color: rgb(.9, .9, .9),
            });

            firstPage.drawText('Datum:', {
                x: 48,
                y: height - 367,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText(getDate(argStartDatumZeit, isClient), {
                x: 200,
                y: height - 367,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText('Temperatur / Frost:', {
                x: 48,
                y: height - 390,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argTemperaturSelected) {
                firstPage.drawText('Minimalwert', {
                    x: 200,
                    y: height - 390,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                firstPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 390,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            firstPage.drawText(argTemperaturSelected ? argWeatherData ? argWeatherData[0] ? argWeatherData[0].temperature ? argWeatherData[0].temperature.TMin ? argWeatherData[0].temperature.TMin : "" : "" : "" : "" : "", {
                x: 330,
                y: height - 390,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText(argTemperaturSelected ? 'Maximalwert' : '', {
                x: 400,
                y: height - 390,
                size: 10,
                font: normalCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText(argTemperaturSelected ? argWeatherData ? argWeatherData[0] ? argWeatherData[0].temperature ? argWeatherData[0].temperature.TMax ? argWeatherData[0].temperature.TMax : "" : "" : "" : "" : "", {
                x: 500,
                y: height - 390,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawRectangle({
                x: 43,
                y: height - 397,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            firstPage.drawText('Niederschlagsmenge:', {
                x: 48,
                y: height - 410,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argNiederschlagsmengeSelected) {
                firstPage.drawText('Niederschlagssumme', {
                    x: 200,
                    y: height - 410,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                firstPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 410,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            firstPage.drawText(argNiederschlagsmengeSelected ? argWeatherData ? argWeatherData[0] ? argWeatherData[0].precipitation ? argWeatherData[0].precipitation.rain_Amount ? argWeatherData[0].precipitation.rain_Amount : "" : "" : "" : "" : "", {
                x: 330,
                y: height - 410,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawRectangle({
                x: 43,
                y: height - 417,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            firstPage.drawText('Windgeschwindigkeit:', {
                x: 48,
                y: height - 430,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argWindgeschwindigkeitSelected) {
                firstPage.drawText('Maximalwert', {
                    x: 200,
                    y: height - 430,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                firstPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 430,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            firstPage.drawText(argWindgeschwindigkeitSelected ? argWeatherData ? argWeatherData[0] ? argWeatherData[0].windspeed ? argWeatherData[0].windspeed.windspeed ? argWeatherData[0].windspeed.windspeed : "" : "" : "" : "" : "", {
                x: 330,
                y: height - 430,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawRectangle({
                x: 43,
                y: height - 437,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            firstPage.drawText('Glätte:', {
                x: 48,
                y: height - 450,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argGlatteFrostSelected) {
                firstPage.drawText(argWeatherData ? argWeatherData[0] ? argWeatherData[0].glatte ? argWeatherData[0].glatte.Glatte ? argWeatherData[0].glatte.Glatte.toString() === "Yes" ? "Ja" : "Nein" : "" : "" : "" : "", {
                    x: 200,
                    y: height - 450,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                firstPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 450,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            firstPage.drawRectangle({
                x: 43,
                y: height - 457,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            firstPage.drawText('Hagel:', {
                x: 48,
                y: height - 470,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argHagelSelected) {
                firstPage.drawText(argWeatherData ? argWeatherData[0] ? argWeatherData[0].hagel ? argWeatherData[0].hagel.hail ? argWeatherData[0].hagel.hail.toString() === "Yes" ? "Ja" : "Nein" : "Keine Daten" : "Keine Daten" : "Keine Daten" : "Keine Daten", {
                    x: 200,
                    y: height - 470,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                firstPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 470,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            firstPage.drawRectangle({
                x: 43,
                y: height - 477,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            firstPage.drawText('Blitz:', {
                x: 48,
                y: height - 490,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argBlitzSelected) {
                firstPage.drawText(argWeatherData ? argWeatherData[0] ? argWeatherData[0].blitz ? argWeatherData[0].blitz.blitz ? argWeatherData[0].blitz.blitz : "" : "" : "" : "", {
                    x: 200,
                    y: height - 490,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                firstPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 490,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            firstPage.drawText(argBlitzSelected ? argWeatherData[0] ? argWeatherData[0].blitz ? argWeatherData[0].blitz.blitz ? argWeatherData[0].blitz.blitz === 'Ja' ? 'Zeit von - bis:' : '' : '' : '' : '' : '', {
                x: 400,
                y: height - 490,
                size: 10,
                font: normalCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText(argBlitzSelected ? argWeatherData ? argWeatherData[0] ? argWeatherData[0].blitz ? argWeatherData[0].blitz.from ? argWeatherData[0].blitz.from + " - " : "" : "" : "" : "" : "", {
                x: 500,
                y: height - 490,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText(argBlitzSelected ? argWeatherData ? argWeatherData[0] ? argWeatherData[0].blitz ? argWeatherData[0].blitz.to ? argWeatherData[0].blitz.to : "" : "" : "" : "" : "", {
                x: 533,
                y: height - 490,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawRectangle({
                x: 43,
                y: height - 497,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            firstPage.drawText('Sicht/Lichtverhältnisse:', {
                x: 48,
                y: height - 510,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argSichtLichtverhaltnisseSelected) {
                firstPage.drawText(argWeatherData ? argWeatherData[0] ? argWeatherData[0].visibility ? argWeatherData[0].visibility.visibility ? argWeatherData[0].visibility.visibility : "" : "" : "" : "", {
                    x: 200,
                    y: height - 510,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                firstPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 510,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }
        }

        /* #####################First date###################*/

        /* #####################Second date###################*/

        if (dateDiffInDays(argStartDatumZeit, argEndDatumZeit) > 0) {

            firstPage.drawRectangle({
                x: 43,
                y: height - 540,
                width: width - 75,
                height: 23,
                color: rgb(.9, .9, .9),
            });

            firstPage.drawText('Datum:', {
                x: 48,
                y: height - 532,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText(getDate(new Date(argStartDatumZeit).addDays(1), isClient), {
                x: 200,
                y: height - 532,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText('Temperatur / Frost:', {
                x: 48,
                y: height - 555,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argTemperaturSelected) {
                firstPage.drawText('Minimalwert', {
                    x: 200,
                    y: height - 555,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                firstPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 555,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            firstPage.drawText(argTemperaturSelected ? argWeatherData ? argWeatherData[1] ? argWeatherData[1].temperature ? argWeatherData[1].temperature.TMin ? argWeatherData[1].temperature.TMin : "" : "" : "" : "" : "", {
                x: 330,
                y: height - 555,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText(argTemperaturSelected ? 'Maximalwert' : '', {
                x: 400,
                y: height - 555,
                size: 10,
                font: normalCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText(argTemperaturSelected ? argWeatherData ? argWeatherData[1] ? argWeatherData[1].temperature ? argWeatherData[1].temperature.TMax ? argWeatherData[1].temperature.TMax : "" : "" : "" : "" : "", {
                x: 500,
                y: height - 555,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawRectangle({
                x: 43,
                y: height - 562,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            firstPage.drawText('Niederschlagsmenge:', {
                x: 48,
                y: height - 575,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argNiederschlagsmengeSelected) {
                firstPage.drawText('Niederschlagssumme', {
                    x: 200,
                    y: height - 575,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                firstPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 575,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            firstPage.drawText(argNiederschlagsmengeSelected ? argWeatherData ? argWeatherData[1] ? argWeatherData[1].precipitation ? argWeatherData[1].precipitation.rain_Amount ? argWeatherData[1].precipitation.rain_Amount : "" : "" : "" : "" : "", {
                x: 330,
                y: height - 575,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawRectangle({
                x: 43,
                y: height - 582,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            firstPage.drawText('Windgeschwindigkeit:', {
                x: 48,
                y: height - 595,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argWindgeschwindigkeitSelected) {
                firstPage.drawText('Maximalwert', {
                    x: 200,
                    y: height - 595,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                firstPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 595,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            firstPage.drawText(argWindgeschwindigkeitSelected ? argWeatherData ? argWeatherData[1] ? argWeatherData[1].windspeed ? argWeatherData[1].windspeed.windspeed ? argWeatherData[1].windspeed.windspeed : "" : "" : "" : "" : "", {
                x: 330,
                y: height - 595,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawRectangle({
                x: 43,
                y: height - 602,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            firstPage.drawText('Glätte:', {
                x: 48,
                y: height - 615,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argGlatteFrostSelected) {
                firstPage.drawText(argWeatherData ? argWeatherData[1] ? argWeatherData[1].glatte ? argWeatherData[1].glatte.Glatte ? argWeatherData[1].glatte.Glatte.toString() === "Yes" ? "Ja" : "Nein" : "" : "" : "" : "", {
                    x: 200,
                    y: height - 615,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                firstPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 615,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            firstPage.drawRectangle({
                x: 43,
                y: height - 622,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            firstPage.drawText('Hagel:', {
                x: 48,
                y: height - 635,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argHagelSelected) {
                firstPage.drawText(argWeatherData ? argWeatherData[1] ? argWeatherData[1].hagel ? argWeatherData[1].hagel.hail ? argWeatherData[1].hagel.hail.toString() === "Yes" ? "Ja" : "Nein" : "Keine Daten" : "Keine Daten" : "Keine Daten" : "Keine Daten", {
                    x: 200,
                    y: height - 635,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                firstPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 635,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            firstPage.drawRectangle({
                x: 43,
                y: height - 642,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            firstPage.drawText('Blitz:', {
                x: 48,
                y: height - 655,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argBlitzSelected) {
                firstPage.drawText(argWeatherData ? argWeatherData[1] ? argWeatherData[1].blitz ? argWeatherData[1].blitz.blitz ? argWeatherData[1].blitz.blitz : "" : "" : "" : "", {
                    x: 200,
                    y: height - 655,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                firstPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 655,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            firstPage.drawText(argBlitzSelected ? argWeatherData[1] ? argWeatherData[1].blitz ? argWeatherData[1].blitz.blitz ? argWeatherData[1].blitz.blitz === 'Ja' ? 'Zeit von - bis:' : '' : '' : '' : '' : '', {
                x: 400,
                y: height - 655,
                size: 10,
                font: normalCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText(argBlitzSelected ? argWeatherData ? argWeatherData[1] ? argWeatherData[1].blitz ? argWeatherData[1].blitz.from ? argWeatherData[1].blitz.from + " - " : "" : "" : "" : "" : "", {
                x: 500,
                y: height - 655,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText(argBlitzSelected ? argWeatherData ? argWeatherData[1] ? argWeatherData[1].blitz ? argWeatherData[1].blitz.to ? argWeatherData[1].blitz.to : "" : "" : "" : "" : "", {
                x: 533,
                y: height - 655,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawRectangle({
                x: 43,
                y: height - 662,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            firstPage.drawText('Sicht/Lichtverhältnisse:', {
                x: 48,
                y: height - 675,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argSichtLichtverhaltnisseSelected) {
                firstPage.drawText(argWeatherData ? argWeatherData[1] ? argWeatherData[1].visibility ? argWeatherData[1].visibility.visibility ? argWeatherData[1].visibility.visibility : "" : "" : "" : "", {
                    x: 200,
                    y: height - 675,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                firstPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 675,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            /*firstPage.drawRectangle({
                x: 43,
                y: height - 682,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });*/
        }
        /* #####################Second date###################*/

        if (argNiederschlagsmengeSelected) {

            firstPage.drawRectangle({
                x: 43,
                y: height - 705,
                width: width - 75,
                height: 23,
                color: rgb(.9, .9, .9),
                borderWidth: .5,
                borderColor: rgb(.7, .7, .7),
                borderOpacity: 1,
            });

            firstPage.drawText('Gesamtniederschlag im angefragten Zeitraum:', {
                x: 250,
                y: height - 697,
                size: 11,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText(argTotalPrecipitation ? formatePrecipitation(argTotalPrecipitation, isClient) + " mm" : "", {
                x: 500,
                y: height - 697,
                size: 11,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });
        }

        if (argLange !== null && argBreite !== null && !isNaN(argLange) && !isNaN(argBreite)) {
            pdfDoc.setTitle("Wetterauskunft_" + getDate(new Date(), isClient) + "_" + argBreite.toString() + "_" + argLange.toString());
        } else if (argOrt && argOrt.name) {
            pdfDoc.setTitle("Wetterauskunft_" + getDate(new Date(), isClient) + "_" + argOrt.name);
        } else {
            pdfDoc.setTitle("Wetterauskunft_" + getDate(new Date(), isClient));
        }
        pdfDoc.setSubject('RiskControl')
        pdfDoc.setAuthor('Qmet GmbH');
        pdfDoc.setCreationDate(new Date());
        pdfDoc.setModificationDate(new Date());

        const pdfBytes = await pdfDoc.save();
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        const blobURL = URL.createObjectURL(blob);

        //to view
        /*window.open(blobURL);*/

        //to print
        printJS(blobURL);
        return true;

    } catch (error) {
        if (isClient) {
            ReactGA.event("rc_pdfprint", {
                rc_pdfprint_error: "202208111422 - " + error
            });
        } else {
            console.log('202208111422 - ' + error);
        }
        return false;
    }
}

export const TwoPagePDFPrint = async (
    argStrasse,
    argHausnummer,
    argLange,
    argBreite,
    argOrt,
    argOrtByLocation,
    argStartDatumZeit,
    argEndDatumZeit,
    argTemperaturSelected,
    argNiederschlagsmengeSelected,
    argWindgeschwindigkeitSelected,
    argGlatteFrostSelected,
    argHagelSelected,
    argBlitzSelected,
    argSichtLichtverhaltnisseSelected,
    argWeatherData,
    argTotalPrecipitation,
    argReferenzstation,
    isClient
) => {
    try {
        const boldFonturl = process.env.PUBLIC_URL + '/Exo2-Medium.ttf';
        const normalFonturl = process.env.PUBLIC_URL + '/Exo2-Light.ttf';
        const url = process.env.PUBLIC_URL + '/Qmet_Briefpapier2.pdf';

        const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());
        const pdfDoc = await PDFDocument.load(existingPdfBytes);

        const boldFontBytes = await fetch(boldFonturl).then(res => res.arrayBuffer());
        const NormalFontBytes = await fetch(normalFonturl).then(res => res.arrayBuffer());

        pdfDoc.registerFontkit(fontkit);

        const boldCustomFont = await pdfDoc.embedFont(boldFontBytes);
        const normalCustomFont = await pdfDoc.embedFont(NormalFontBytes);

        const pages = pdfDoc.getPages();
        const firstPage = pages[0];
        const secondPage = pages[1];
        const { width, height } = firstPage.getSize()
        const fontSize = 20;

        firstPage.drawText('Seite 1 von 2', {
            x: width - 90,
            y: height - 135,
            size: 10,
            color: rgb(.2, .2, .2),
            textAlign: TextAlignment.Right,
            font: normalCustomFont,
        });

        firstPage.drawRectangle({
            x: 43,
            y: height - 150,
            width: width - 75,
            height: .5,
            color: rgb(.7, .7, .7),
        });

        firstPage.drawRectangle({
            x: 43,
            y: height - 750,
            width: width - 75,
            height: .5,
            color: rgb(.7, .7, .7),
        });

        firstPage.drawText('Risk', {
            x: width - 140,
            y: height - 177,
            size: fontSize,
            color: rgb(.2, .2, .2),
            textAlign: TextAlignment.Right,
            font: normalCustomFont,
        });

        firstPage.drawText('Control', {
            x: width - 100,
            y: height - 177,
            size: fontSize,
            color: rgb(.2, .2, .2),
            textAlign: TextAlignment.Right,
            font: boldCustomFont,
        });

        firstPage.drawText('Kundennummer:', {
            x: width - 215,
            y: height - 195,
            size: 10,
            font: boldCustomFont,
            color: rgb(.2, .2, .2),
            textAlign: TextAlignment.Right
        });

        firstPage.drawText('1234-5678', {
            x: width - 80,
            y: height - 195,
            size: 10,
            font: normalCustomFont,
            color: rgb(.2, .2, .2),
            textAlign: TextAlignment.Right
        });

        firstPage.drawText('Rechnungsnummer:', {
            x: width - 215,
            y: height - 210,
            size: 10,
            font: boldCustomFont,
            color: rgb(.2, .2, .2),
            textAlign: TextAlignment.Right
        });

        firstPage.drawText('1234-5678', {
            x: width - 80,
            y: height - 210,
            size: 10,
            font: normalCustomFont,
            color: rgb(.2, .2, .2),
            textAlign: TextAlignment.Right
        });

        firstPage.drawText('Ihre Wetterauskunft', {
            x: 43,
            y: height - 245,
            size: 13,
            font: boldCustomFont,
            color: rgb(.2, .2, .2),
            textAlign: TextAlignment.Right
        });

        let today = getDayOfWeek(new Date());
        today = today + ', ';
        today = today + getDateFullMonth(new Date(), isClient);

        firstPage.drawText(today, {
            x: 43,
            y: height - 260,
            size: 10,
            font: normalCustomFont,
            color: rgb(.2, .2, .2),
            textAlign: TextAlignment.Right
        });

        if (argLange !== null && argBreite !== null && !isNaN(argLange) && !isNaN(argBreite)) {
            firstPage.drawText('Geogr. Länge:', {
                x: 43,
                y: height - 280,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText(argLange, {
                x: 160,
                y: height - 280,
                size: 10,
                font: normalCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText('Geogr. Breite:', {
                x: 43,
                y: height - 295,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText(argBreite, {
                x: 160,
                y: height - 295,
                size: 10,
                font: normalCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText('Zeitpunkt:', {
                x: 43,
                y: height - 310,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            let vonDatum = 'von ';
            vonDatum = vonDatum + getDayOfWeek(argStartDatumZeit, isClient);
            vonDatum = vonDatum + ', den ';
            vonDatum = vonDatum + getDate(argStartDatumZeit, isClient);
            vonDatum = vonDatum + ', ';
            vonDatum = vonDatum + getTime(argStartDatumZeit, isClient);
            vonDatum = vonDatum + ' Uhr';

            firstPage.drawText(vonDatum, {
                x: 160,
                y: height - 310,
                size: 10,
                font: normalCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            let bisDatum = 'bis ';
            bisDatum = bisDatum + getDayOfWeek(argEndDatumZeit, isClient);
            bisDatum = bisDatum + ', den ';
            bisDatum = bisDatum + getDate(argEndDatumZeit, isClient);
            bisDatum = bisDatum + ', ';
            bisDatum = bisDatum + getTime(argEndDatumZeit, isClient);
            bisDatum = bisDatum + ' Uhr';

            firstPage.drawText(bisDatum, {
                x: 160,
                y: height - 325,
                size: 10,
                font: normalCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText('Referenzstation:', {
                x: 43,
                y: height - 340,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText(argReferenzstation ? argReferenzstation : "", {
                x: 160,
                y: height - 340,
                size: 10,
                font: normalCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });
        } else {
            if (argOrtByLocation && argOrtByLocation.name) {
                firstPage.drawText('Ort:', {
                    x: 43,
                    y: height - 280,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });

                let fullOrt = argStrasse ? argStrasse : null;
                fullOrt = argHausnummer ? fullOrt + " " : fullOrt;
                fullOrt = argHausnummer ? fullOrt + argHausnummer : fullOrt;
                fullOrt = argOrtByLocation.postal_code ? fullOrt + ", " : fullOrt;
                fullOrt = argOrtByLocation.postal_code ? fullOrt + argOrtByLocation.postal_code : fullOrt;
                fullOrt = argOrtByLocation.name ? fullOrt + " " : fullOrt;
                fullOrt = argOrtByLocation.name ? fullOrt + argOrtByLocation.name : fullOrt;

                firstPage.drawText(fullOrt, {
                    x: 160,
                    y: height - 280,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });

                firstPage.drawText('Zeitpunkt:', {
                    x: 43,
                    y: height - 295,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });

                let vonDatum = 'von ';
                vonDatum = vonDatum + getDayOfWeek(argStartDatumZeit, isClient);
                vonDatum = vonDatum + ', den ';
                vonDatum = vonDatum + getDate(argStartDatumZeit, isClient);
                vonDatum = vonDatum + ', ';
                vonDatum = vonDatum + getTime(argStartDatumZeit, isClient);
                vonDatum = vonDatum + ' Uhr';

                firstPage.drawText(vonDatum, {
                    x: 160,
                    y: height - 295,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });

                let bisDatum = 'bis ';
                bisDatum = bisDatum + getDayOfWeek(argEndDatumZeit, isClient);
                bisDatum = bisDatum + ', den ';
                bisDatum = bisDatum + getDate(argEndDatumZeit, isClient);
                bisDatum = bisDatum + ', ';
                bisDatum = bisDatum + getTime(argEndDatumZeit, isClient);
                bisDatum = bisDatum + ' Uhr';

                firstPage.drawText(bisDatum, {
                    x: 160,
                    y: height - 310,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });

                firstPage.drawText('Referenzstation:', {
                    x: 43,
                    y: height - 325,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });

                firstPage.drawText(argReferenzstation ? argReferenzstation : "", {
                    x: 160,
                    y: height - 325,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            }
        }

        /* #####################First date###################*/

        if (dateDiffInDays(argStartDatumZeit, argEndDatumZeit) > -1) {

            firstPage.drawRectangle({
                x: 43,
                y: height - 375,
                width: width - 75,
                height: 23,
                color: rgb(.9, .9, .9),
            });

            firstPage.drawText('Datum:', {
                x: 48,
                y: height - 367,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText(getDate(argStartDatumZeit, isClient), {
                x: 200,
                y: height - 367,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText('Temperatur / Frost:', {
                x: 48,
                y: height - 390,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argTemperaturSelected) {
                firstPage.drawText('Minimalwert', {
                    x: 200,
                    y: height - 390,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                firstPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 390,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            firstPage.drawText(argTemperaturSelected ? argWeatherData ? argWeatherData[0] ? argWeatherData[0].temperature ? argWeatherData[0].temperature.TMin ? argWeatherData[0].temperature.TMin : "" : "" : "" : "" : "", {
                x: 330,
                y: height - 390,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText(argTemperaturSelected ? 'Maximalwert' : '', {
                x: 400,
                y: height - 390,
                size: 10,
                font: normalCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText(argTemperaturSelected ? argWeatherData ? argWeatherData[0] ? argWeatherData[0].temperature ? argWeatherData[0].temperature.TMax ? argWeatherData[0].temperature.TMax : "" : "" : "" : "" : "", {
                x: 500,
                y: height - 390,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawRectangle({
                x: 43,
                y: height - 400,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            firstPage.drawText('Niederschlagsmenge:', {
                x: 48,
                y: height - 415,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argNiederschlagsmengeSelected) {
                firstPage.drawText('Niederschlagssumme', {
                    x: 200,
                    y: height - 415,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                firstPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 415,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            firstPage.drawText(argNiederschlagsmengeSelected ? argWeatherData ? argWeatherData[0] ? argWeatherData[0].precipitation ? argWeatherData[0].precipitation.rain_Amount ? argWeatherData[0].precipitation.rain_Amount : "" : "" : "" : "" : "", {
                x: 330,
                y: height - 415,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawRectangle({
                x: 43,
                y: height - 422,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            firstPage.drawText('Windgeschwindigkeit:', {
                x: 48,
                y: height - 435,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argWindgeschwindigkeitSelected) {
                firstPage.drawText('Maximalwert', {
                    x: 200,
                    y: height - 435,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                firstPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 435,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            firstPage.drawText(argWindgeschwindigkeitSelected ? argWeatherData ? argWeatherData[0] ? argWeatherData[0].windspeed ? argWeatherData[0].windspeed.windspeed ? argWeatherData[0].windspeed.windspeed : "" : "" : "" : "" : "", {
                x: 330,
                y: height - 435,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawRectangle({
                x: 43,
                y: height - 442,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            firstPage.drawText('Glätte:', {
                x: 48,
                y: height - 455,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argGlatteFrostSelected) {
                firstPage.drawText(argWeatherData ? argWeatherData[0] ? argWeatherData[0].glatte ? argWeatherData[0].glatte.Glatte ? argWeatherData[0].glatte.Glatte.toString() === "Yes" ? "Ja" : 'Nein' : "" : "" : "" : "", {
                    x: 200,
                    y: height - 455,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                firstPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 455,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            firstPage.drawRectangle({
                x: 43,
                y: height - 462,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            firstPage.drawText('Hagel:', {
                x: 48,
                y: height - 475,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argHagelSelected) {
                firstPage.drawText(argWeatherData ? argWeatherData[0] ? argWeatherData[0].hagel ? argWeatherData[0].hagel.hail ? argWeatherData[0].hagel.hail.toString() === "Yes" ? "Ja" : "Nein" : "Keine Daten" : "Keine Daten" : "Keine Daten" : "Keine Daten", {
                    x: 200,
                    y: height - 475,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                firstPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 475,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            firstPage.drawRectangle({
                x: 43,
                y: height - 482,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            firstPage.drawText('Blitz:', {
                x: 48,
                y: height - 495,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argBlitzSelected) {
                firstPage.drawText(argWeatherData ? argWeatherData[0] ? argWeatherData[0].blitz ? argWeatherData[0].blitz.blitz ? argWeatherData[0].blitz.blitz : "" : "" : "" : "", {
                    x: 200,
                    y: height - 495,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                firstPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 495,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            firstPage.drawText(argBlitzSelected ? argWeatherData[0] ? argWeatherData[0].blitz ? argWeatherData[0].blitz.blitz ? argWeatherData[0].blitz.blitz === 'Ja' ? 'Zeit von - bis :' : '' : '' : '' : '' : '', {
                x: 400,
                y: height - 495,
                size: 10,
                font: normalCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText(argBlitzSelected ? argWeatherData ? argWeatherData[0] ? argWeatherData[0].blitz ? argWeatherData[0].blitz.from ? argWeatherData[0].blitz.from + " - " : "" : "" : "" : "" : "", {
                x: 500,
                y: height - 495,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText(argBlitzSelected ? argWeatherData ? argWeatherData[0] ? argWeatherData[0].blitz ? argWeatherData[0].blitz.to ? argWeatherData[0].blitz.to : "" : "" : "" : "" : "", {
                x: 533,
                y: height - 495,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawRectangle({
                x: 43,
                y: height - 502,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            firstPage.drawText('Sicht/Lichtverhältnisse:', {
                x: 48,
                y: height - 515,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argSichtLichtverhaltnisseSelected) {
                firstPage.drawText(argWeatherData ? argWeatherData[0] ? argWeatherData[0].visibility ? argWeatherData[0].visibility.visibility ? argWeatherData[0].visibility.visibility : "" : "" : "" : "", {
                    x: 200,
                    y: height - 515,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                firstPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 515,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }
        }

        /* #####################First date###################*/

        /* #####################Second date###################*/

        if (dateDiffInDays(argStartDatumZeit, argEndDatumZeit) > 0) {

            firstPage.drawRectangle({
                x: 43,
                y: height - 545,
                width: width - 75,
                height: 23,
                color: rgb(.9, .9, .9),
            });

            firstPage.drawText('Datum:', {
                x: 48,
                y: height - 537,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText(getDate(new Date(argStartDatumZeit).addDays(1), isClient), {
                x: 200,
                y: height - 537,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText('Temperatur / Frost:', {
                x: 48,
                y: height - 560,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argTemperaturSelected) {
                firstPage.drawText('Minimalwert', {
                    x: 200,
                    y: height - 560,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                firstPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 560,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            firstPage.drawText(argTemperaturSelected ? argWeatherData ? argWeatherData[1] ? argWeatherData[1].temperature ? argWeatherData[1].temperature.TMin ? argWeatherData[1].temperature.TMin : "" : "" : "" : "" : "", {
                x: 330,
                y: height - 560,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText(argTemperaturSelected ? 'Maximalwert' : '', {
                x: 400,
                y: height - 560,
                size: 10,
                font: normalCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText(argTemperaturSelected ? argWeatherData ? argWeatherData[1] ? argWeatherData[1].temperature ? argWeatherData[1].temperature.TMax ? argWeatherData[1].temperature.TMax : "" : "" : "" : "" : "", {
                x: 500,
                y: height - 560,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawRectangle({
                x: 43,
                y: height - 567,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            firstPage.drawText('Niederschlagsmenge:', {
                x: 48,
                y: height - 580,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argNiederschlagsmengeSelected) {
                firstPage.drawText('Niederschlagssumme', {
                    x: 200,
                    y: height - 580,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                firstPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 580,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            firstPage.drawText(argNiederschlagsmengeSelected ? argWeatherData ? argWeatherData[1] ? argWeatherData[1].precipitation ? argWeatherData[1].precipitation.rain_Amount ? argWeatherData[1].precipitation.rain_Amount : "" : "" : "" : "" : "", {
                x: 330,
                y: height - 580,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawRectangle({
                x: 43,
                y: height - 587,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            firstPage.drawText('Windgeschwindigkeit:', {
                x: 48,
                y: height - 600,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argWindgeschwindigkeitSelected) {
                firstPage.drawText('Maximalwert', {
                    x: 200,
                    y: height - 600,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                firstPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 600,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            firstPage.drawText(argWindgeschwindigkeitSelected ? argWeatherData ? argWeatherData[1] ? argWeatherData[1].windspeed ? argWeatherData[1].windspeed.windspeed ? argWeatherData[1].windspeed.windspeed : "" : "" : "" : "" : "", {
                x: 330,
                y: height - 600,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawRectangle({
                x: 43,
                y: height - 607,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            firstPage.drawText('Glätte:', {
                x: 48,
                y: height - 620,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argGlatteFrostSelected) {
                firstPage.drawText(argWeatherData ? argWeatherData[1] ? argWeatherData[1].glatte ? argWeatherData[1].glatte.Glatte ? argWeatherData[1].glatte.Glatte.toString() === "Yes" ? "Ja" : "Nein" : "" : "" : "" : "", {
                    x: 200,
                    y: height - 620,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                firstPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 620,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            firstPage.drawRectangle({
                x: 43,
                y: height - 627,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            firstPage.drawText('Hagel:', {
                x: 48,
                y: height - 640,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argHagelSelected) {
                firstPage.drawText(argWeatherData ? argWeatherData[1] ? argWeatherData[1].hagel ? argWeatherData[1].hagel.hail ? argWeatherData[1].hagel.hail.toString() === "Yes" ? "Ja" : "Nein" : "Keine Daten" : "Keine Daten" : "Keine Daten" : "Keine Daten", {
                    x: 200,
                    y: height - 640,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                firstPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 640,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            firstPage.drawRectangle({
                x: 43,
                y: height - 647,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            firstPage.drawText('Blitz:', {
                x: 48,
                y: height - 660,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argBlitzSelected) {
                firstPage.drawText(argWeatherData ? argWeatherData[1] ? argWeatherData[1].blitz ? argWeatherData[1].blitz.blitz ? argWeatherData[1].blitz.blitz : "" : "" : "" : "", {
                    x: 200,
                    y: height - 660,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                firstPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 660,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            firstPage.drawText(argBlitzSelected ? argWeatherData[1] ? argWeatherData[1].blitz ? argWeatherData[1].blitz.blitz ? argWeatherData[1].blitz.blitz === 'Ja' ? 'Zeit von - bis:' : '' : '' : '' : '' : '', {
                x: 400,
                y: height - 660,
                size: 10,
                font: normalCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText(argBlitzSelected ? argWeatherData ? argWeatherData[1] ? argWeatherData[1].blitz ? argWeatherData[1].blitz.from ? argWeatherData[1].blitz.from + " - " : "" : "" : "" : "" : "", {
                x: 500,
                y: height - 660,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawText(argBlitzSelected ? argWeatherData ? argWeatherData[1] ? argWeatherData[1].blitz ? argWeatherData[1].blitz.to ? argWeatherData[1].blitz.to : "" : "" : "" : "" : "", {
                x: 533,
                y: height - 660,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            firstPage.drawRectangle({
                x: 43,
                y: height - 667,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            firstPage.drawText('Sicht/Lichtverhältnisse:', {
                x: 48,
                y: height - 680,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argSichtLichtverhaltnisseSelected) {
                firstPage.drawText(argWeatherData ? argWeatherData[1] ? argWeatherData[1].visibility ? argWeatherData[1].visibility.visibility ? argWeatherData[1].visibility.visibility : "" : "" : "" : "", {
                    x: 200,
                    y: height - 680,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                firstPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 680,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

        }
        /* #####################Second date###################*/

        /* #####################Third date###################*/

        if (dateDiffInDays(argStartDatumZeit, argEndDatumZeit) > 1) {

            secondPage.drawRectangle({
                x: 43,
                y: height - 180,
                width: width - 75,
                height: 23,
                color: rgb(.9, .9, .9),
            });

            secondPage.drawText('Datum:', {
                x: 48,
                y: height - 172,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawText(getDate(new Date(argStartDatumZeit).addDays(2), isClient), {
                x: 200,
                y: height - 172,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawText('Temperatur / Frost:', {
                x: 48,
                y: height - 195,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argTemperaturSelected) {
                secondPage.drawText('Minimalwert', {
                    x: 200,
                    y: height - 195,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                secondPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 195,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            secondPage.drawText(argTemperaturSelected ? argWeatherData ? argWeatherData[2] ? argWeatherData[2].temperature ? argWeatherData[2].temperature.TMin ? argWeatherData[2].temperature.TMin : "" : "" : "" : "" : "", {
                x: 330,
                y: height - 195,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawText(argTemperaturSelected ? 'Maximalwert' : '', {
                x: 400,
                y: height - 195,
                size: 10,
                font: normalCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawText(argTemperaturSelected ? argWeatherData ? argWeatherData[2] ? argWeatherData[2].temperature ? argWeatherData[2].temperature.TMax ? argWeatherData[2].temperature.TMax : "" : "" : "" : "" : "", {
                x: 500,
                y: height - 195,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawRectangle({
                x: 43,
                y: height - 202,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            secondPage.drawText('Niederschlagsmenge:', {
                x: 48,
                y: height - 215,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argNiederschlagsmengeSelected) {
                secondPage.drawText('Niederschlagssumme', {
                    x: 200,
                    y: height - 215,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                secondPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 215,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            secondPage.drawText(argNiederschlagsmengeSelected ? argWeatherData ? argWeatherData[2] ? argWeatherData[2].precipitation ? argWeatherData[2].precipitation.rain_Amount ? argWeatherData[2].precipitation.rain_Amount : "" : "" : "" : "" : "", {
                x: 330,
                y: height - 215,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawRectangle({
                x: 43,
                y: height - 222,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            secondPage.drawText('Windgeschwindigkeit:', {
                x: 48,
                y: height - 235,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argWindgeschwindigkeitSelected) {
                secondPage.drawText('Maximalwert', {
                    x: 200,
                    y: height - 235,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                secondPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 235,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            secondPage.drawText(argWindgeschwindigkeitSelected ? argWeatherData ? argWeatherData[2] ? argWeatherData[2].windspeed ? argWeatherData[2].windspeed.windspeed ? argWeatherData[2].windspeed.windspeed : "" : "" : "" : "" : "", {
                x: 330,
                y: height - 235,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawRectangle({
                x: 43,
                y: height - 242,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            secondPage.drawText('Glätte:', {
                x: 48,
                y: height - 255,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argGlatteFrostSelected) {
                secondPage.drawText(argWeatherData ? argWeatherData[2] ? argWeatherData[2].glatte ? argWeatherData[2].glatte.Glatte ? argWeatherData[2].glatte.Glatte.toString() === "Yes" ? "Ja" : "Nein" : "" : "" : "" : "", {
                    x: 200,
                    y: height - 255,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                secondPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 255,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            secondPage.drawRectangle({
                x: 43,
                y: height - 262,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            secondPage.drawText('Hagel:', {
                x: 48,
                y: height - 275,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argHagelSelected) {
                secondPage.drawText(argWeatherData ? argWeatherData[2] ? argWeatherData[2].hagel ? argWeatherData[2].hagel.hail ? argWeatherData[2].hagel.hail.toString() === "Yes" ? "Ja" : "Nein" : "Keine Daten" : "Keine Daten" : "Keine Daten" : "Keine Daten", {
                    x: 200,
                    y: height - 275,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                secondPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 275,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            secondPage.drawRectangle({
                x: 43,
                y: height - 282,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            secondPage.drawText('Blitz:', {
                x: 48,
                y: height - 295,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argBlitzSelected) {
                secondPage.drawText(argWeatherData ? argWeatherData[2] ? argWeatherData[2].blitz ? argWeatherData[2].blitz.blitz ? argWeatherData[2].blitz.blitz : "" : "" : "" : "", {
                    x: 200,
                    y: height - 295,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                secondPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 295,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            secondPage.drawText(argBlitzSelected ? argWeatherData[2] ? argWeatherData[2].blitz ? argWeatherData[2].blitz.blitz ? argWeatherData[2].blitz.blitz === 'Ja' ? 'Zeit von - bis:' : '' : '' : '' : '' : '', {
                x: 400,
                y: height - 295,
                size: 10,
                font: normalCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawText(argBlitzSelected ? argWeatherData ? argWeatherData[2] ? argWeatherData[2].blitz ? argWeatherData[2].blitz.from ? argWeatherData[2].blitz.from + " - " : "" : "" : "" : "" : "", {
                x: 500,
                y: height - 295,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawText(argBlitzSelected ? argWeatherData ? argWeatherData[2] ? argWeatherData[2].blitz ? argWeatherData[2].blitz.to ? argWeatherData[2].blitz.to : "" : "" : "" : "" : "", {
                x: 533,
                y: height - 295,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawRectangle({
                x: 43,
                y: height - 302,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            secondPage.drawText('Sicht/Lichtverhältnisse:', {
                x: 48,
                y: height - 315,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argSichtLichtverhaltnisseSelected) {
                secondPage.drawText(argWeatherData ? argWeatherData[2] ? argWeatherData[2].visibility ? argWeatherData[2].visibility.visibility ? argWeatherData[2].visibility.visibility : "" : "" : "" : "", {
                    x: 200,
                    y: height - 315,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                secondPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 315,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

        }

        /* #####################Third date###################*/

        /* #####################Fourth date###################*/

        if (dateDiffInDays(argStartDatumZeit, argEndDatumZeit) > 2) {

            secondPage.drawRectangle({
                x: 43,
                y: height - 345,
                width: width - 75,
                height: 23,
                color: rgb(.9, .9, .9),
            });

            secondPage.drawText('Datum:', {
                x: 48,
                y: height - 337,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawText(getDate(new Date(argStartDatumZeit).addDays(3), isClient), {
                x: 200,
                y: height - 337,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawText('Temperatur / Frost:', {
                x: 48,
                y: height - 360,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argTemperaturSelected) {
                secondPage.drawText('Minimalwert', {
                    x: 200,
                    y: height - 360,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                secondPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 360,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            secondPage.drawText(argTemperaturSelected ? argWeatherData ? argWeatherData[3] ? argWeatherData[3].temperature ? argWeatherData[3].temperature.TMin ? argWeatherData[3].temperature.TMin : "" : "" : "" : "" : "", {
                x: 330,
                y: height - 360,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawText(argTemperaturSelected ? 'Maximalwert' : '', {
                x: 400,
                y: height - 360,
                size: 10,
                font: normalCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawText(argTemperaturSelected ? argWeatherData ? argWeatherData[3] ? argWeatherData[3].temperature ? argWeatherData[3].temperature.TMax ? argWeatherData[3].temperature.TMax : "" : "" : "" : "" : "", {
                x: 500,
                y: height - 360,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawRectangle({
                x: 43,
                y: height - 367,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            secondPage.drawText('Niederschlagsmenge:', {
                x: 48,
                y: height - 380,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argNiederschlagsmengeSelected) {
                secondPage.drawText('Niederschlagssumme', {
                    x: 200,
                    y: height - 380,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                secondPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 380,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            secondPage.drawText(argNiederschlagsmengeSelected ? argWeatherData ? argWeatherData[3] ? argWeatherData[3].precipitation ? argWeatherData[3].precipitation.rain_Amount ? argWeatherData[3].precipitation.rain_Amount : "" : "" : "" : "" : "", {
                x: 330,
                y: height - 380,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawRectangle({
                x: 43,
                y: height - 387,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            secondPage.drawText('Windgeschwindigkeit:', {
                x: 48,
                y: height - 400,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argWindgeschwindigkeitSelected) {
                secondPage.drawText('Maximalwert', {
                    x: 200,
                    y: height - 400,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                secondPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 400,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            secondPage.drawText(argWindgeschwindigkeitSelected ? argWeatherData ? argWeatherData[3] ? argWeatherData[3].windspeed ? argWeatherData[3].windspeed.windspeed ? argWeatherData[3].windspeed.windspeed : "" : "" : "" : "" : "", {
                x: 330,
                y: height - 400,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawRectangle({
                x: 43,
                y: height - 407,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            secondPage.drawText('Glätte:', {
                x: 48,
                y: height - 420,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argGlatteFrostSelected) {
                secondPage.drawText(argWeatherData ? argWeatherData[3] ? argWeatherData[3].glatte ? argWeatherData[3].glatte.Glatte ? argWeatherData[3].glatte.Glatte.toString() === "Yes" ? "Ja" : "Nein" : "" : "" : "" : "", {
                    x: 200,
                    y: height - 420,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                secondPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 420,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            secondPage.drawRectangle({
                x: 43,
                y: height - 427,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            secondPage.drawText('Hagel:', {
                x: 48,
                y: height - 440,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argHagelSelected) {
                secondPage.drawText(argWeatherData ? argWeatherData[3] ? argWeatherData[3].hagel ? argWeatherData[3].hagel.hail ? argWeatherData[3].hagel.hail.toString() === "Yes" ? "Ja" : "Nein" : "Keine Daten" : "Keine Daten" : "Keine Daten" : "Keine Daten", {
                    x: 200,
                    y: height - 440,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                secondPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 440,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            secondPage.drawRectangle({
                x: 43,
                y: height - 447,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            secondPage.drawText('Blitz:', {
                x: 48,
                y: height - 460,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argBlitzSelected) {
                secondPage.drawText(argWeatherData ? argWeatherData[3] ? argWeatherData[3].blitz ? argWeatherData[3].blitz.blitz ? argWeatherData[3].blitz.blitz : "" : "" : "" : "", {
                    x: 200,
                    y: height - 460,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                secondPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 460,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            secondPage.drawText(argBlitzSelected ? argWeatherData[3] ? argWeatherData[3].blitz ? argWeatherData[3].blitz.blitz ? argWeatherData[3].blitz.blitz === 'Ja' ? 'Zeit von - bis:' : '' : '' : '' : '' : '', {
                x: 400,
                y: height - 460,
                size: 10,
                font: normalCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawText(argBlitzSelected ? argWeatherData ? argWeatherData[3] ? argWeatherData[3].blitz ? argWeatherData[3].blitz.from ? argWeatherData[3].blitz.from + " - " : "" : "" : "" : "" : "", {
                x: 500,
                y: height - 460,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawText(argBlitzSelected ? argWeatherData ? argWeatherData[3] ? argWeatherData[3].blitz ? argWeatherData[3].blitz.to ? argWeatherData[3].blitz.to : "" : "" : "" : "" : "", {
                x: 533,
                y: height - 460,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawRectangle({
                x: 43,
                y: height - 467,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            secondPage.drawText('Sicht/Lichtverhältnisse:', {
                x: 48,
                y: height - 480,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argSichtLichtverhaltnisseSelected) {
                secondPage.drawText(argWeatherData ? argWeatherData[3] ? argWeatherData[3].visibility ? argWeatherData[3].visibility.visibility ? argWeatherData[3].visibility.visibility : "" : "" : "" : "", {
                    x: 200,
                    y: height - 480,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                secondPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 480,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }
        }
        /* #####################Fourth date###################*/

        /* #####################Fifth date###################*/

        if (dateDiffInDays(argStartDatumZeit, argEndDatumZeit) > 3) {

            secondPage.drawRectangle({
                x: 43,
                y: height - 510,
                width: width - 75,
                height: 23,
                color: rgb(.9, .9, .9),
            });

            secondPage.drawText('Datum:', {
                x: 48,
                y: height - 502,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawText(getDate(new Date(argStartDatumZeit).addDays(4), isClient), {
                x: 200,
                y: height - 502,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawText('Temperatur / Frost:', {
                x: 48,
                y: height - 525,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argTemperaturSelected) {
                secondPage.drawText('Minimalwert', {
                    x: 200,
                    y: height - 525,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                secondPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 525,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            secondPage.drawText(argTemperaturSelected ? argWeatherData ? argWeatherData[4] ? argWeatherData[4].temperature ? argWeatherData[4].temperature.TMin ? argWeatherData[4].temperature.TMin : "" : "" : "" : "" : "", {
                x: 330,
                y: height - 525,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawText(argTemperaturSelected ? 'Maximalwert' : '', {
                x: 400,
                y: height - 525,
                size: 10,
                font: normalCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawText(argTemperaturSelected ? argWeatherData ? argWeatherData[4] ? argWeatherData[4].temperature ? argWeatherData[4].temperature.TMax ? argWeatherData[4].temperature.TMax : "" : "" : "" : "" : "", {
                x: 500,
                y: height - 525,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawRectangle({
                x: 43,
                y: height - 532,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            secondPage.drawText('Niederschlagsmenge:', {
                x: 48,
                y: height - 545,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argNiederschlagsmengeSelected) {
                secondPage.drawText('Niederschlagssumme', {
                    x: 200,
                    y: height - 545,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                secondPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 545,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            secondPage.drawText(argNiederschlagsmengeSelected ? argWeatherData ? argWeatherData[4] ? argWeatherData[4].precipitation ? argWeatherData[4].precipitation.rain_Amount ? argWeatherData[4].precipitation.rain_Amount : "" : "" : "" : "" : "", {
                x: 330,
                y: height - 545,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawRectangle({
                x: 43,
                y: height - 552,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            secondPage.drawText('Windgeschwindigkeit:', {
                x: 48,
                y: height - 565,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argWindgeschwindigkeitSelected) {
                secondPage.drawText('Maximalwert', {
                    x: 200,
                    y: height - 565,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                secondPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 565,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            secondPage.drawText(argWindgeschwindigkeitSelected ? argWeatherData ? argWeatherData[4] ? argWeatherData[4].windspeed ? argWeatherData[4].windspeed.windspeed ? argWeatherData[4].windspeed.windspeed : "" : "" : "" : "" : "", {
                x: 330,
                y: height - 565,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawRectangle({
                x: 43,
                y: height - 572,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            secondPage.drawText('Glätte:', {
                x: 48,
                y: height - 585,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argGlatteFrostSelected) {
                secondPage.drawText(argWeatherData ? argWeatherData[4] ? argWeatherData[4].glatte ? argWeatherData[4].glatte.Glatte ? argWeatherData[4].glatte.Glatte.toString() === "Yes" ? "Ja" : "Nein" : "" : "" : "" : "", {
                    x: 200,
                    y: height - 585,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                secondPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 585,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            secondPage.drawRectangle({
                x: 43,
                y: height - 592,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            secondPage.drawText('Hagel:', {
                x: 48,
                y: height - 605,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argHagelSelected) {
                secondPage.drawText(argWeatherData ? argWeatherData[4] ? argWeatherData[4].hagel ? argWeatherData[4].hagel.hail ? argWeatherData[4].hagel.hail.toString() === "Yes" ? "Ja" : "Nein" : "Keine Daten" : "Keine Daten" : "Keine Daten" : "Keine Daten", {
                    x: 200,
                    y: height - 605,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                secondPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 605,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            secondPage.drawRectangle({
                x: 43,
                y: height - 612,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            secondPage.drawText('Blitz:', {
                x: 48,
                y: height - 625,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argBlitzSelected) {
                secondPage.drawText(argWeatherData ? argWeatherData[4] ? argWeatherData[4].blitz ? argWeatherData[4].blitz.blitz ? argWeatherData[4].blitz.blitz : "" : "" : "" : "", {
                    x: 200,
                    y: height - 625,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                secondPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 625,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            secondPage.drawText(argBlitzSelected ? argWeatherData[4] ? argWeatherData[4].blitz ? argWeatherData[4].blitz.blitz ? argWeatherData[4].blitz.blitz === 'Ja' ? 'Zeit von - bis:' : '' : '' : '' : '' : '', {
                x: 400,
                y: height - 625,
                size: 10,
                font: normalCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawText(argBlitzSelected ? argWeatherData ? argWeatherData[4] ? argWeatherData[4].blitz ? argWeatherData[4].blitz.from ? argWeatherData[4].blitz.from + " - " : "" : "" : "" : "" : "", {
                x: 500,
                y: height - 625,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawText(argBlitzSelected ? argWeatherData ? argWeatherData[4] ? argWeatherData[4].blitz ? argWeatherData[4].blitz.to ? argWeatherData[4].blitz.to : "" : "" : "" : "" : "", {
                x: 533,
                y: height - 625,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawRectangle({
                x: 43,
                y: height - 632,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });

            secondPage.drawText('Sicht/Lichtverhältnisse:', {
                x: 48,
                y: height - 645,
                size: 10,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            if (argSichtLichtverhaltnisseSelected) {
                secondPage.drawText(argWeatherData ? argWeatherData[4] ? argWeatherData[4].visibility ? argWeatherData[4].visibility.visibility ? argWeatherData[4].visibility.visibility : "" : "" : "" : "", {
                    x: 200,
                    y: height - 645,
                    size: 10,
                    font: boldCustomFont,
                    color: rgb(.2, .2, .2),
                    textAlign: TextAlignment.Right
                });
            } else {
                secondPage.drawText('Nicht abgefragt', {
                    x: 200,
                    y: height - 645,
                    size: 10,
                    font: normalCustomFont,
                    color: rgb(.6, .6, .6),
                    textAlign: TextAlignment.Right
                });
            }

            /*secondPage.drawRectangle({
                x: 43,
                y: height - 652,
                width: width - 75,
                height: .5,
                color: rgb(.9, .9, .9),
            });*/
        }

        /* #####################Fifth date###################*/

        if (argNiederschlagsmengeSelected) {

            secondPage.drawRectangle({
                x: 43,
                y: height - 675,
                width: width - 75,
                height: 23,
                color: rgb(.9, .9, .9),
                borderWidth: .5,
                borderColor: rgb(.7, .7, .7),
                borderOpacity: 1,
            });

            secondPage.drawText('Gesamtniederschlag im angefragten Zeitraum:', {
                x: 250,
                y: height - 667,
                size: 11,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });

            secondPage.drawText(argTotalPrecipitation ? formatePrecipitation(argTotalPrecipitation, isClient) + " mm" : "", {
                x: 500,
                y: height - 667,
                size: 11,
                font: boldCustomFont,
                color: rgb(.2, .2, .2),
                textAlign: TextAlignment.Right
            });
        }

        secondPage.drawText('Seite 2 von 2', {
            x: width - 90,
            y: height - 135,
            size: 10,
            color: rgb(.2, .2, .2),
            textAlign: TextAlignment.Right,
            font: normalCustomFont,
        });

        secondPage.drawRectangle({
            x: 43,
            y: height - 150,
            width: width - 75,
            height: .5,
            color: rgb(.7, .7, .7),
        });

        secondPage.drawRectangle({
            x: 43,
            y: height - 755,
            width: width - 75,
            height: .5,
            color: rgb(.7, .7, .7),
        });

        /*firstPage.drawRectangle({
            x: 43,
            y: height - 600,
            width: width - 75,
            height: .5,
            color: rgb(.8, .8, .8),
        });*/

        if (argLange !== null && argBreite !== null && !isNaN(argLange) && !isNaN(argBreite)) {
            pdfDoc.setTitle("Wetterauskunft_" + getDate(new Date(), isClient) + "_" + argBreite.toString() + "_" + argLange.toString());
        } else if (argOrt && argOrt.name) {
            pdfDoc.setTitle("Wetterauskunft_" + getDate(new Date(), isClient) + "_" + argOrt.name);
        } else {
            pdfDoc.setTitle("Wetterauskunft_" + getDate(new Date(), isClient));
        }
        pdfDoc.setSubject('RiskControl')
        pdfDoc.setAuthor('Qmet GmbH');
        pdfDoc.setCreationDate(new Date());
        pdfDoc.setModificationDate(new Date());

        const pdfBytes = await pdfDoc.save();
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        const blobURL = URL.createObjectURL(blob);

        //to view
        /*window.open(blobURL);*/

        //to print
        printJS(blobURL);
        return true;
    } catch (error) {
        if (isClient) {
            ReactGA.event("rc_pdfprint", {
                rc_pdfprint_error: "202208111421 - " + error
            });
        } else {
            console.log('202208111421 - ' + error);
        }
        return false;
    }
}