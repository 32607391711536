import React, { Component } from 'react';
import { Route, Switch } from "react-router-dom";
import { withRouter } from "react-router-dom";

import SettperContainer from '../StepperContainer/StepperContainer';
import Datenschutz from '../../Components/Datenschutz/Datenschutz';
import Impressum from '../../Components/Impressum/Impressum';
import Login from '../Login/Login';

class Navigation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
        }
    }

    /*componentDidMount = () => {
        try{
            let loginData = sessionStorage.getItem('isLoggedIn');
            if(loginData && loginData === 'true'){
                this.props.history.push("/");
            }else{
                this.props.history.push("/login");
            }
            console.log('******************',loginData);
        }catch(error){
            console.log('202203211416',error);
        }
    }*/

    setError = (argHasError) => {
        try {
            this.setState({ hasError: argHasError });
        } catch (error) { console.log('202108311600', error) }
    }

    render() {
        return (
            <main style={{marginTop:'12px'}}>
                <Switch>
                    {/*<Route path="/" exact render={(props) => { return (<SettperContainer {...props} deliverError={this.setError} />) }} />*/}
                    <Route path="/" exact render={(props) => { return (<Login {...props} deliverError={this.setError} />) }} />
                    <Route path="/datenschutz" exact render={(props) => { return (<Datenschutz {...props} deliverError={this.setError} />) }} />
                    <Route path="/impressum" exact render={(props) => { return (<Impressum {...props} deliverError={this.setError} />) }} />
                </Switch>
            </main>
        );
    }
}

export default withRouter(Navigation);