import Layout from './Containers/Layout/Layout';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import ReactGA from "react-ga4";

const theme = createTheme({
  palette: {
    primary: {
      main: '#009fe0'
    }
  }
});
/*G-MNSP30BJP8*/
function App() {
  ReactGA.initialize("G-MNSP30BJP8");
  return (
    <MuiThemeProvider theme={theme}>
      <BrowserRouter>
        <div className="App">
          <Layout />
        </div>
      </BrowserRouter>
    </MuiThemeProvider>
  );
}

export default App;
